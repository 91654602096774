import React from 'react'
import { TlaModal } from '../../../../components/pop-ups/tla-modal'
import TlaIcon from '../../../../components/tla-icon'
import { Button } from 'antd'
import { useNavigate } from 'react-router-dom'

function ApplyLoading () {
  const navigate = useNavigate()
  return (
        <TlaModal title={''} width={400}>
            <div className={'flex flex-col items-center'}>
                <TlaIcon name={'loading'} className={'tla-spin'}/>
                <p className={'text-lg font-medium my-[38px]'}>Our Premium AI models are working to customize your data.
                    The file will automatically download when it is ready.
                </p>
                <div className={'w-full'}>
                    <Button onClick={() => navigate(-1)}
                            block className={'btn btn-primary-700'}
                            size={'large'}>Close</Button>
                </div>
            </div>
        </TlaModal>
  )
}

ApplyLoading.propTypes = {}

export default ApplyLoading
