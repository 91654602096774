import React, { useEffect } from 'react'
import { FaGithub, FaGitlab, FaLinkedin } from 'react-icons/fa'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { formatDate } from '../../utils'
import { useReactToPrint } from 'react-to-print'

// eslint-disable-next-line react/prop-types
const SubTitle = ({ subTitle }) => (
    <h2 className={'!font-lato text-2xl font-semibold'}>{subTitle}</h2>
)

function DownloadResume ({ resume, callBack }) {
    // eslint-disable-next-line no-unused-vars
    const { contactInfo, experiences, educations, certificates, skills } = resume
    const printRef = React.useRef()

    useEffect(() => {
        handlePrint()
        callBack(false)
    }, [])

    const handlePrint = useReactToPrint({
        content: () => printRef.current
    })

    return (
        <div style={{ display: 'block' }}>
            <div style={{ display: 'block' }} ref={printRef}
                 className={'!font-lato bg-white-base pb-[120px] px-5 print-div'}>
                <div className={'print-div'}>
                    <div className={'w-full flex-wrap py-[40px] border-b'}>
                        <div className={'mx-auto w-fit text-center flex flex-col justify-center items-center'}>
                            <h1 className={'font-bold text-[34px] text-gray-800'}>{contactInfo?.name}</h1>
                             <h2 className={'text-gray-650 text-base font-semibold'}>{contactInfo?.resumeHeadline}</h2>
                            <div className={'flex gap-2'}>
                                {
                                    contactInfo?.linkedin &&
                                    <a target={'_blank'} href={`${contactInfo?.linkedin}`}
                                       className={'leading-0 text-primary-650 text-xl font-semibold mt-[9px]'}
                                       rel="noreferrer">
                                        <FaLinkedin/>
                                    </a>
                                }

                                {
                                    contactInfo?.github &&
                                    <a target={'_blank'} href={`${contactInfo?.github}`}
                                       className={'leading-0 text-primary-650 text-xl font-semibold mt-[9px]'}
                                       rel="noreferrer">
                                        <FaGithub/>
                                    </a>
                                }
                                {
                                    contactInfo?.gitLab &&
                                    <a target={'_blank'} href={`${contactInfo?.gitLab}`}
                                       className={'leading-0 text-primary-650 text-xl font-semibold mt-[9px]'}
                                       rel="noreferrer">
                                        <FaGitlab/>
                                    </a>
                                }
                            </div>
                        </div>
                    </div>
                    <div className={'flex gap-[46px] mx-auto w-fit pt-[58px]'}>
                        <div className={'w-[531px]'}>
                            <div>
                                <SubTitle subTitle={'Experience'}/>
                                <div className={'flex flex-col gap-5 mt-[27px]'}>
                                    {
                                        experiences.map((experience) => (
                                            <div key={experience?.id} className={'flex flex-col gap-1'}>
                                                <p className={'text-gray-300 text-sm font-normal !font-lato'}>
                                                    {formatDate(experience?.jobDurationStart)} - {formatDate(experience?.jobDurationEnd) ?? 'Present'}
                                                </p>
                                                <p className={'font-semibold text-sm !font-lato'}>{experience?.companyName}&nbsp;
                                                    <span
                                                        className={'font-normal !font-lato'}>{experience?.city}</span>
                                                </p>
                                                <p className={'font-semibold text-sm'}>{experience?.jobTitle}</p>
                                                <div className={'pl-6'}>
                                                    <ul className={'text-sm font-normal list-disc'}>
                                                        {
                                                            experience?.description?.map((desc, index) => (
                                                                <li className={'!font-lato'} key={index}>{desc}</li>
                                                            ))
                                                        }
                                                    </ul>
                                                </div>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                        </div>
                        <div className={'text-sm w-[220px] flex flex-col gap-[38px]'}>
                            <div className={'flex flex-col gap-2'}>
                                <SubTitle subTitle={'Contact'}/>
                                <p className={'!font-lato'}>{contactInfo?.mobileNumber}</p>
                                <p className={'!font-lato'}>{contactInfo?.resumeEmail}</p>
                                <p className={'!font-lato'}>{contactInfo?.address}</p>
                            </div>
                            <div>
                                <SubTitle subTitle={'Education'}/>
                                <div className={'!font-lato flex flex-col gap-[30px] mt-6'}>
                                    {
                                        educations?.map((education) => (
                                            <div key={education.id}>
                                                <h3 className={'!font-lato font-bold text-sm !capitalize'}>
                                                    {`${education?.school.toLowerCase()}, ${education?.degreeFieldOfStudy}`}
                                                </h3>
                                                <p className={'!font-lato'}>
                                                    {education.city} . {formatDate(education?.studyDurationStart)} - {formatDate(education?.studyDurationStart)}
                                                </p>
                                            </div>
                                        ))
                                    }
                                </div>
                            </div>
                            {
                                certificates.length > 0 &&
                                <div>
                                    <SubTitle subTitle={'Certifications'}/>
                                    <div className={'flex flex-col gap-2 mt-6'}>
                                        {
                                            certificates?.map((cert) => (
                                                <p className={'!font-lato'} key={cert?.id}>
                                                    {cert.title} - {cert.organisation}
                                                </p>
                                            ))
                                        }
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                    {
                        skills.length > 0 &&
                        <div className={'w-[816px] mt-[43px] mx-auto print-div'}>
                            <SubTitle subTitle={'Skills'}/>
                            <div className={'flex flex-col gap-6 mt-[18px]'}>
                                <div className={'flex gap-7'}>
                                    <h4 className={'text-sm font-semibold !font-lato'}>Technical</h4>
                                    <div className={'flex flex-wrap gap-[5px]'}>
                                        {
                                            skills?.[0]?.technicalSkills?.map((skill, index) => (
                                                <p key={index}
                                                   className={'rounded-[3px] px-[2px] bg-gray-200 border-b' +
                                                       ' border-gray-650 text-sm !font-lato'}>{skill}</p>
                                            ))
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

DownloadResume.propTypes = {
    resume: PropTypes.object.isRequired,
    callBack: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
    resume: state.apply10xReducer.resume
})

export default connect(mapStateToProps, null)(DownloadResume)
