import api from '../../utils/api'
import { getCountries } from './ActionCreators'

export const handleGetCountries = () => (dispatch) => {
    return new Promise((resolve, reject) => {
        api().get('https://restcountries.com/v3.1/all?fields=name').then((res) => {
            dispatch(getCountries(res.data))
            resolve(res)
        }).catch((err) => {
            reject(err)
        })
    })
}
