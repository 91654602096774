import React, { useState } from 'react'
import { connect } from 'react-redux'
import { TlaModal } from '../../../components/auto-apply/pop-ups/tla-modal'
import { Button, Form, Input } from 'antd'
import PropTypes from 'prop-types'
import { handleChangePassword } from '../../../actions/authenticate/Actions'
import { useNavigate } from 'react-router-dom'
import { TlaError, TlaSuccess } from '../../../utils/messages'

function Settings ({ changePassword, user }) {
    // eslint-disable-next-line no-unused-vars
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const [form] = Form.useForm()

    const onFinish = (values) => {
        setLoading(true)

        changePassword(user.id, values).then(() => {
            TlaSuccess('Password Saved')
            form.resetFields()
            setLoading(false)
            navigate(-1)
        }).catch((error) => {
            setLoading(false)
            TlaError(error.response.data.message)
        })
    }
    return (
        <TlaModal loading={loading} width={795} title={'Change Password'}>
            <Form colon={false} layout={'vertical'}
                  initialValues={{
                      email: user.email
                  }} onFinish={onFinish} form={form} requiredMark={false}>
                <div className={'w-[376px]'}>
                    <div className={'flex flex-col'}>
                        <Form.Item name="email"
                                   label={'Email'}
                                   rules={[
                                       { required: true, message: 'Password is required!' }
                                   ]}>
                            <Input size={'large'} disabled
                                   placeholder="christ@hotmail.com"/>
                        </Form.Item>
                        <div>
                            <Form.Item name="currentPassword"
                                       label={'Old password'}
                                       rules={[
                                           { required: true, message: 'Password is required!' },
                                           { min: 8, message: '' }
                                       ]}>
                                <Input.Password
                                    size={'large'}
                                    type={'password'}
                                    placeholder="••••••••"/>
                            </Form.Item>
                        </div>
                        <div>
                            <Form.Item name="password"
                                       label={'New Password'}
                                       rules={[
                                           { required: true, message: 'Password is required!' },
                                           { min: 8, message: '' }
                                       ]}>
                                <Input.Password
                                    size={'large'}
                                    type={'password'}
                                    placeholder="••••••••"/>
                            </Form.Item>
                        </div>
                        <div>
                            <Form.Item
                                dependencies={['password']}
                                name="passwordConfirm"
                                label={'Confirm Password'}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Password is required!'
                                    }, ({ getFieldValue }) => ({
                                        validator (_, value) {
                                            if (!value || getFieldValue('password') === value) {
                                                return Promise.resolve()
                                            }
                                            return Promise.reject(new Error('Password mismatch'))
                                        }
                                    }),
                                    {
                                        min: 8,
                                        message: 'Password should be at least 8 characters'
                                    }
                                ]}>
                                <Input.Password
                                    size={'large'}
                                    type="password"
                                    placeholder="••••••••"
                                />
                            </Form.Item>
                        </div>
                    </div>
                </div>
                <div className={'flex justify-end'}>
                    <Button className={'btn !px-[30px] !rounded-full bg-white-base'} htmlType={'submit'} size={'large'}>
                        Save Preferences
                    </Button>
                </div>
            </Form>
        </TlaModal>
    )
}

Settings.propTypes = {
    changePassword: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired
}

const mapStateToProps = (state) => ({
    user: state.loginReducer.authUser
})

const mapDispatchToProps = (dispatch) => ({
    changePassword: (userId, data) => dispatch(handleChangePassword(userId, data))
})

export default connect(mapStateToProps, mapDispatchToProps)(Settings)
