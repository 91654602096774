import React from 'react'
import PropTypes from 'prop-types'

function Title ({ text }) {
    return (
        <p className={'text-[24px] md:text-[26px] mb-6'}>{text}</p>
    )
}

Title.propTypes = {
    text: PropTypes.any.isRequired
}

export default Title
