import { Types } from '../../actions/build-resume/Types'
import { Types as ExperienceTypes } from '../../actions/experience/Types'
import { Types as EducationTypes } from '../../actions/education/Types'
import { Types as CertTypes } from '../../actions/certifications/Types'
import { Types as AwardTypes } from '../../actions/awards/Types'
import { Types as SkillTypes } from '../../actions/skills/Types'

import { formatExperience, formatHiddenItems, formatResumeSection, formatSkills } from '../../utils'

const initialState = {
    resumes: {
        data: [],
        meta: {}
    },
    resumeInfo: {},
    template: 'castform',
    currentResume: {
        content: {
            userInformation: {
                items: [],
                hiddenItems: {}
            }
        },
        metadata: {
            template: 'castform',
            photo: 'yes'
        }
    },
    hiddenItems: {},
    localResumes: [],
    refreshForm: false,
    draft: false
}

const expNames = ['experiences', 'items', 'hiddenItems']
const eduNames = ['educations', 'items', 'hiddenItems']
const certNames = ['certificates', 'items', 'hiddenItems']
const awardNames = ['achivementsAwards', 'items', 'hiddenItems']
const skillNames = ['skillTags', 'items', 'hiddenItems']

export default function buildResumeReducer (state = initialState, action) {
    switch (action.type) {
        case Types.SAVE_RESUME:
            return {
                ...state,
                currentResume: {
                    ...state.currentResume,
                    id: action.payload?.data?.resume?.id
                }
            }
        case Types.GET_ALL_RESUMES:
            return {
                ...state,
                resumes: {
                    data: action.payload.data.userResumes,
                    meta: {
                        total: action.payload.data.count,
                        from: action.payload.data.page,
                        current_page: parseInt(action.payload.data.page),
                        to: action.payload.data.pageSize
                    }
                }
            }
        case Types.GET_RESUME:
            return {
                ...state,
                resumeInfo: action.payload.data.user
            }

        case Types.SET_TEMPLATE:
            return {
                ...state,
                template: action.payload,
                currentResume: {
                    ...state.currentResume,
                    metadata: { ...state.currentResume.metadata, template: action.payload }
                }
            }
        case Types.NEW_RESUME:
            return {
                ...state,
                localResumes: state.localResumes.concat(action.payload),
                currentResume: { ...action.payload }
            }
        case Types.UPDATE_RESUME:
            // eslint-disable-next-line no-unused-vars,no-case-declarations
            const { name, value } = action.payload

            // eslint-disable-next-line no-case-declarations
            const total = name.length

            // eslint-disable-next-line no-case-declarations
            const data = state.currentResume
            if (total === 1) {
                data.content[name[0]] = value
            } else if (total === 2) {
                data.content[name[0]][name[1]] = value
            } else if (total === 3) {
                data.content[name[0]][name[1]][name[2]] = value
            } else if (total === 4) {
                data.content[name[0]][name[1]][name[2]][name[3]] = value
            } else if (total === 5) {
                data.content[name[0]][name[1]][name[2]][name[3]][name[4]] = value
            } else if (total === 6) {
                data.content[name[0]][name[1]][name[2]][name[3]][name[4]][name[5]] = value
            }

            return {
                ...state,
                currentResume: { ...data }
            }
        case Types.UPDATE_RESUME_DB:
            return {
                ...state,
                refreshForm: true
            }
        case Types.HIDE_ITEM:
            // eslint-disable-next-line no-unused-vars,no-case-declarations
            const names = action.payload.name

            // eslint-disable-next-line no-case-declarations
            const hTotal = names.length

            // eslint-disable-next-line no-case-declarations
            const res = state.currentResume
            // eslint-disable-next-line no-case-declarations
            const hiddenItems = state.hiddenItems
            if (hTotal === 1) {
                if (hiddenItems[names[0]] !== undefined) {
                    delete hiddenItems[names[0]]
                } else {
                    hiddenItems[names[0]] = names[0]
                }
            } else if (hTotal === 2) {
                if (res.content[names[0]][names[1]] !== undefined) {
                    delete res.content[names[0]][names[1]]
                } else {
                    res.content[names[0]][names[1]] = names[1]
                }
            } else if (hTotal === 3) {
                if (res.content[names[0]][names[1]][names[2]] !== undefined) {
                    delete res.content[names[0]][names[1]][names[2]]
                } else {
                    res.content[names[0]][names[1]][names[2]] = names[2]
                }
            } else if (hTotal === 4) {
                if (res.content[names[0]][names[1]][names[2]][names[3]] !== undefined) {
                    delete res.content[names[0]][names[1]][names[2]][names[3]]
                } else {
                    res.content[names[0]][names[1]][names[2]][names[3]] = names[3]
                }
            } else if (hTotal === 5) {
                if (res.content[names[0]][names[1]][names[2]][names[3]][names[4]] !== undefined) {
                    delete res.content[names[0]][names[1]][names[2]][names[3]][names[4]]
                } else {
                    res.content[names[0]][names[1]][names[2]][names[3]][names[4]] = names[4]
                }
            } else if (hTotal === 6) {
                if (res.content[names[0]][names[1]][names[2]][names[3]][names[4]][names[5]] !== undefined) {
                    delete res.content[names[0]][names[1]][names[2]][names[3]][names[4]][names[5]]
                } else {
                    res.content[names[0]][names[1]][names[2]][names[3]][names[4]][names[5]] = names[5]
                }
            }

            return {
                ...state,
                currentResume: { ...res },
                hiddenItems: { ...hiddenItems }
            }

        case ExperienceTypes.NEW_EXPERIENCE:

            if (localStorage.getItem('resume-editing') === 'true') { // eslint-disable-next-line no-case-declarations
                const expData = state.currentResume

                expData.content[expNames[0]][expNames[1]].push(formatExperience(action.payload.data.experience, true))

                // eslint-disable-next-line no-case-declarations
                const totalExps = expData.content[expNames[0]][expNames[1]].length - 1
                expData.content[expNames[0]][expNames[2]][totalExps] = totalExps

                return {
                    ...state,
                    currentResume: { ...expData },
                    refreshForm: true
                }
            }
            return {
                ...state
            }
        case ExperienceTypes.UPDATE_EXPERIENCE:

            if (localStorage.getItem('resume-editing') === 'true') { // eslint-disable-next-line no-case-declarations
                const expData = state.currentResume

                expData.content[expNames[0]][expNames[1]] = expData.content[expNames[0]][expNames[1]].map((item) => {
                    return item.id === action.payload.data.experience.id ? formatExperience(action.payload.data.experience, true) : item
                })

                return {
                    ...state,
                    currentResume: { ...expData },
                    refreshForm: true
                }
            }
            return {
                ...state
            }
        case ExperienceTypes.DELETE_EXPERIENCE:
            if (localStorage.getItem('resume-editing') === 'true') {
                // eslint-disable-next-line no-case-declarations
                const delData = state.currentResume

                // eslint-disable-next-line no-case-declarations
                let itemIndex = null
                delData.content[expNames[0]][expNames[1]] = delData.content[expNames[0]][expNames[1]].filter((experiences, index) => {
                    itemIndex = experiences.id === action.id ? index : null
                    return experiences.id !== action.id
                })

                delete delData.content[expNames[0]][expNames[2]][itemIndex]
                return {
                    ...state,
                    currentResume: { ...delData },
                    refreshForm: true
                }
            }
            return {
                ...state
            }

        case EducationTypes.NEW_EDUCATION:

            if (localStorage.getItem('resume-editing') === 'true') { // eslint-disable-next-line no-case-declarations
                const eduData = state.currentResume

                eduData.content[eduNames[0]][eduNames[1]].push(action.payload.data.education)

                // eslint-disable-next-line no-case-declarations
                const totalEdus = eduData.content[eduNames[0]][eduNames[1]].length - 1
                eduData.content[eduNames[0]][eduNames[2]][totalEdus] = totalEdus

                return {
                    ...state,
                    currentResume: { ...eduData },
                    refreshForm: true
                }
            }
            return {
                ...state
            }
        case EducationTypes.UPDATE_EDUCATION:

            if (localStorage.getItem('resume-editing') === 'true') { // eslint-disable-next-line no-case-declarations
                const eduData = state.currentResume

                eduData.content[eduNames[0]][eduNames[1]] = eduData.content[eduNames[0]][eduNames[1]].map((item) => {
                    return item.id === action.payload.data.education.id ? action.payload.data.education : item
                })

                return {
                    ...state,
                    currentResume: { ...eduData },
                    refreshForm: true
                }
            }
            return {
                ...state
            }
        case EducationTypes.DELETE_EDUCATION:

            if (localStorage.getItem('resume-editing') === 'true') { // eslint-disable-next-line no-case-declarations
                const delEdu = state.currentResume

                // eslint-disable-next-line no-case-declarations
                let delIndex = null
                delEdu.content[eduNames[0]][eduNames[1]] = delEdu.content[eduNames[0]][eduNames[1]].filter((edu, index) => {
                    delIndex = edu.id === action.id ? index : null
                    return edu.id !== action.id
                })

                delete delEdu.content[eduNames[0]][eduNames[2]][delIndex]
                return {
                    ...state,
                    currentResume: { ...delEdu },
                    refreshForm: true
                }
            }
            return {
                ...state
            }

        case CertTypes.NEW_CERTIFICATION:

            if (localStorage.getItem('resume-editing') === 'true') { // eslint-disable-next-line no-case-declarations
                const certData = state.currentResume

                certData.content[certNames[0]][certNames[1]].push(action.payload.data.certificate)

                // eslint-disable-next-line no-case-declarations
                const totalCerts = certData.content[certNames[0]][certNames[1]].length - 1
                certData.content[certNames[0]][certNames[2]][totalCerts] = totalCerts

                return {
                    ...state,
                    currentResume: { ...certData },
                    refreshForm: true
                }
            }
            return {
                ...state
            }
        case CertTypes.UPDATE_CERTIFICATION:

            if (localStorage.getItem('resume-editing') === 'true') { // eslint-disable-next-line no-case-declarations
                const certData = state.currentResume

                certData.content[certNames[0]][certNames[1]] = certData.content[certNames[0]][certNames[1]].map((item) => {
                    return item.id === action.payload.data.certificate.id ? action.payload.data.certificate : item
                })

                return {
                    ...state,
                    currentResume: { ...certData },
                    refreshForm: true
                }
            }
            return {
                ...state
            }
        case CertTypes.DELETE_CERTIFICATION:

            if (localStorage.getItem('resume-editing') === 'true') { // eslint-disable-next-line no-case-declarations
                const delCert = state.currentResume

                // eslint-disable-next-line no-case-declarations
                let certIndex = null
                delCert.content[certNames[0]][certNames[1]] = delCert.content[certNames[0]][certNames[1]].filter((edu, index) => {
                    certIndex = edu.id === action.id ? index : null
                    return edu.id !== action.id
                })

                delete delCert.content[certNames[0]][certNames[2]][certIndex]
                return {
                    ...state,
                    currentResume: { ...delCert },
                    refreshForm: true
                }
            }
            return {
                ...state
            }

        case AwardTypes.NEW_AWARD:

            if (localStorage.getItem('resume-editing') === 'true') { // eslint-disable-next-line no-case-declarations
                const awardData = state.currentResume

                awardData.content[awardNames[0]][awardNames[1]].push(action.payload.data.award)

                // eslint-disable-next-line no-case-declarations
                const totalAwards = awardData.content[awardNames[0]][awardNames[1]].length - 1
                awardData.content[awardNames[0]][awardNames[2]][totalAwards] = totalAwards

                return {
                    ...state,
                    currentResume: { ...awardData },
                    refreshForm: true
                }
            }
            return {
                ...state
            }
        case AwardTypes.UPDATE_AWARD:

            if (localStorage.getItem('resume-editing') === 'true') { // eslint-disable-next-line no-case-declarations
                const awardData = state.currentResume

                awardData.content[awardNames[0]][awardNames[1]] = awardData.content[awardNames[0]][awardNames[1]].map((item) => {
                    return item.id === action.payload.data.award.id ? action.payload.data.award : item
                })

                return {
                    ...state,
                    currentResume: { ...awardData },
                    refreshForm: true
                }
            }
            return {
                ...state
            }
        case AwardTypes.DELETE_AWARD:

            if (localStorage.getItem('resume-editing') === 'true') { // eslint-disable-next-line no-case-declarations
                const delAward = state.currentResume

                // eslint-disable-next-line no-case-declarations
                let awardIndex = null
                delAward.content[awardNames[0]][awardNames[1]] = delAward.content[awardNames[0]][awardNames[1]].filter((edu, index) => {
                    awardIndex = edu.id === action.id ? index : null
                    return edu.id !== action.id
                })

                delete delAward.content[awardNames[0]][awardNames[2]][awardIndex]
                return {
                    ...state,
                    currentResume: { ...delAward },
                    refreshForm: true
                }
            }
            return {
                ...state
            }

        case SkillTypes.NEW_SKILL:

            if (localStorage.getItem('resume-editing') === 'true') { // eslint-disable-next-line no-case-declarations
                const skillData = state.currentResume

                let data
                if (action.payload.data?.skillTags) {
                    data = formatSkills([action.payload?.data?.skillTags])
                } else {
                    data = formatSkills([action.payload?.data])
                }

                const { hiddenSkills, skillItems } = data

                // eslint-disable-next-line no-case-declarations
                // const { hiddenSkills, skillItems } = formatSkills([action.payload.data])
                skillData.content[skillNames[0]][skillNames[1]] = skillItems

                skillData.content[skillNames[0]][skillNames[2]] = hiddenSkills

                return {
                    ...state,
                    currentResume: { ...skillData },
                    refreshForm: true
                }
            }
            return {
                ...state
            }

        case Types.SET_REFRESH_FORM:
            return {
                ...state,
                refreshForm: false
            }
        case Types.UPDATE_PERSONAL_PROFILE:
            // eslint-disable-next-line no-case-declarations
            const pInfo = state.currentResume
            // eslint-disable-next-line no-case-declarations
            const newItem = {
                items: formatResumeSection(action.payload),
                hiddenItems: formatHiddenItems(action.payload)
            }

            pInfo.content.userInformation = newItem

            return {
                ...state,
                currentResume: { ...pInfo },
                refreshForm: true
            }

        case Types.UPDATE_RESUME_NAME:
            return {
                ...state,
                currentResume: { ...state.currentResume, name: action.payload }
            }
        case Types.DELETE_RESUME:
            return {
                ...state,
                resumes: {
                    ...state.resumes,
                    data: state.resumes.data.filter((resume) => resume.id !== action.id)
                }
            }

        case Types.TOGGLE_PHOTO:
            return {
                ...state,
                currentResume: {
                    ...state.currentResume,
                    metadata: {
                        ...state.currentResume.metadata,
                        photo: action.payload.value === true ? 'no' : 'yes'
                    }
                }
            }

        default:
            return state
    }
}
