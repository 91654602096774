import { Types } from './Types'

/**
 * @param payload
 * @returns {{payload, type: string}}
 */
export function updateResume (payload) {
  return {
    type: Types.UPDATE_RESUME,
    payload
  }
}
export function updateResumeDb (payload) {
  return {
    type: Types.UPDATE_RESUME_DB,
    payload
  }
}

export function hideItem (payload) {
  return {
    type: Types.HIDE_ITEM,
    payload
  }
}

/**
 * @param payload
 * @returns {{payload, type: string}}
 */
export function getResume (payload) {
  return {
    type: Types.GET_RESUME,
    payload
  }
}

/**
 * @param payload
 * @returns {{payload, type: string}}
 */
export function getAllResumes (payload) {
  return {
    type: Types.GET_ALL_RESUMES,
    payload
  }
}

/**
 * @param id
 * @returns {{payload, type: string}}
 */
export function deleteResume (id) {
  return {
    type: Types.DELETE_RESUME,
    id
  }
}

/**
 * @param payload
 * @returns {{payload, type: string}}
 */
export function newResume (payload) {
  return {
    type: Types.NEW_RESUME,
    payload
  }
}

/**
 * @param payload
 * @returns {{payload, type: string}}
 */
export function saveResume (payload) {
  return {
    type: Types.SAVE_RESUME,
    payload
  }
}
