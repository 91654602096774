import React from 'react'
import './App.css'
import { PublicRoutes } from './components/routes/public-routes'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { AppRoutes } from './components/routes'

function App ({ authenticated, authUser }) {
    return (
        <div>
              {authenticated ? <AppRoutes userType={authUser?.role}/> : <PublicRoutes/>}
        </div>
    )
}

App.propTypes = {
    authenticated: PropTypes.bool.isRequired,
    authUser: PropTypes.object.isRequired
}

const mapStateToProps = (state) => {
    return {
        authenticated: state.loginReducer.authenticated,
        authUser: state.loginReducer.authUser
    }
}

export default connect(mapStateToProps)(App)
