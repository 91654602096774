import api from '../../utils/api'
import { askChatGptExperience, askChatGptSkills } from './ActionCreators'

/**
 * @param data
 * @returns {function(*): Promise<unknown>}
 */
export const handleAskChatGpt = (data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    return api().post('/job/ask-chatgpt', data)
      .then((response) => {
          if (data.type === 'skill') {
              dispatch(askChatGptSkills(response.data))
          } else {
              dispatch(askChatGptExperience(response.data))
          }
        resolve(response)
      }).catch((err) => {
        reject(err)
      })
  })
}
