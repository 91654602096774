import { Types } from '../actions/questions/Types'

const initialState = {
  countries: []
}

/**
 *
 * @param state
 * @param action
 *
 * @returns {{authenticated: boolean, authToken: null, authUser: {}}|{authenticated: boolean, authToken: null, authUser: {}}|{authenticated: boolean, authToken, authUser: *}}
 */
export default function questionReducer (state = initialState, action) {
  switch (action.type) {
    case Types.GET_COUNTRIES:
      return {
        ...state,
        countries: action.payload
      }
    default:
      return state
  }
}
