import React from 'react'
import AuthLayout from './auth-layout'
import TlaFormWrapper from '../../components/tla-form-wrapper'
import { Form, Input } from 'antd'
import AuthBottomLink from './auth-bottom-link'
import { FiArrowLeft } from 'react-icons/fi'
import PropTypes from 'prop-types'
import { handleForgotPassword } from '../../actions/authenticate/Actions'
import { connect } from 'react-redux'

function ForgotPassword ({ sendPasswordEmail }) {
    return (
        <AuthLayout pageTitle={'Forgot password?'}>
            <div>
                <p className={'text-gray-500 text-base text-center mb-4'}>No worries, we’ll send you reset
                    instructions.</p>
                <TlaFormWrapper
                    raw
                    onSubmit={sendPasswordEmail} buttonText={'Send reset link'}>
                    <Form.Item
                        name="email"
                        label="Email"
                        rules={[
                            {
                                required: true,
                                message: 'Email is Required'
                            }
                        ]}>
                        <Input size={'large'} placeholder={'Enter your email'}/>
                    </Form.Item>
                </TlaFormWrapper>
                <div className={'mt-4'}>
                    <AuthBottomLink
                        text={<FiArrowLeft size={18}/>}
                        link={'/'}
                        linkText={'Back to login'}
                        size={'sm'}
                        color={'gray-500'}
                    />
                </div>
            </div>
        </AuthLayout>
    )
}

ForgotPassword.propTypes = {
    sendPasswordEmail: PropTypes.func.isRequired
}

/**
 *
 * @param dispatch
 * @returns {{sendPasswordEmail: (function(*): *)}}
 */
const mapDispatchToProps = (dispatch) => {
    return {
        sendPasswordEmail: (data) => dispatch(handleForgotPassword(data))
    }
}

export default connect(null, mapDispatchToProps)(ForgotPassword)
