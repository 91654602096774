import React, { useEffect, useRef } from 'react'
import DownloadPlainWhite from '../../../resume-builder/templates/plain-white/download/download-plain-white'
import DownloadCastForm from '../../../resume-builder/templates/castform/download/download-cast-form'
import DownloadSimple from '../../../resume-builder/templates/simple/download/download-simple'
import { Button } from 'antd'
import { PDFDownloadLink } from '@react-pdf/renderer'
import { makeId } from '../../../../utils'

// eslint-disable-next-line react/prop-types
export default function DownloadResume ({ template, content, hiddenSections, showPhoto, photo }) {
    const templates = {
        autoapply: <DownloadPlainWhite resume={content} hiddenSections={hiddenSections}/>,
        // eslint-disable-next-line react/prop-types
        castform: <DownloadCastForm showPhoto={showPhoto} photo={content?.userInformation?.items?.photo} resume={content} hiddenSections={hiddenSections}/>,
        simple: <DownloadSimple photo={photo} resume={content} hiddenSections={hiddenSections}/>
    }

    const buttonRef = useRef(null)
    const handleClick = () => { /* Download Resume */ }

    useEffect(() => {
        if (buttonRef.current) {
            buttonRef.current.addEventListener('click', handleClick)
            setTimeout(() => {
                buttonRef.current.click()
            }, 1000)
        }

        return () => {
            if (buttonRef.current) {
                buttonRef.current.removeEventListener('click', handleClick)
            }
        }
    }, [])

    return (
       <div className={'hidden'}>
            <PDFDownloadLink
               document={ templates[template] }
               fileName={ `${'Resume_' + makeId(8)}.pdf` }>
               { ({ blob, url, loading, error }) =>
                   <Button ref={buttonRef} loading={ loading }
                           className={ 'btn btn-primary-light-50' }>Download</Button>
               }
           </PDFDownloadLink>
       </div>
    )
}
