import { Types } from './Types'

/**
 * @param payload
 * @returns {{payload, type: string}}
 */
export function downloadResume (payload) {
  return {
    type: Types.DOWNLOAD_RESUME,
    payload
  }
}
/**
 * @param payload
 * @returns {{payload, type: string}}
 */
export function downloadCoverLetter (payload) {
  return {
    type: Types.DOWNLOAD_COVER_LETTER,
    payload
  }
}

/**
 * @param payload
 * @returns {{payload, type: string}}
 */
export function uploadResume (payload) {
  return {
    type: Types.UPLOAD_RESUME,
    payload
  }
}
