import React from 'react'
import { Form, Input } from 'antd'
import Title from './title'

function JobPassword () {
    return (
        <div className={'w-[340px] md:w-[376px]'}>
            <Title text={'Setup your password'}/>
            <div className={'mb-5'}>
                <Form.Item name="password"
                           help={'Must be at least 8 characters.'}
                           rules={[
                               { required: true, message: 'Password is required!' },
                               { min: 8, message: '' }
                           ]}>
                    <Input.Password
                        size={'large'}
                        type={'password'}
                        placeholder="Create a password"/>
                </Form.Item>
            </div>
            <Form.Item
                dependencies={['password']}
                name="passwordConfirm"
                rules={[
                    {
                        required: true,
                        message: 'Password is required!'
                    }, ({ getFieldValue }) => ({
                        validator (_, value) {
                            if (!value || getFieldValue('password') === value) {
                                return Promise.resolve()
                            }
                            return Promise.reject(new Error('Password mismatch'))
                        }
                    }),
                    {
                        min: 8,
                        message: 'Password should be at least 8 characters'
                    }
                ]}>
                <Input.Password
                    size={'large'}
                    type="password"
                    placeholder="Confirm password"
                />
            </Form.Item>
        </div>
    )
}

export default JobPassword
