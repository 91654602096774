import api from '../../utils/api'
import { addSkill, allSkills, deleteSkill, updateSkill } from './ActionCreator'

/**
 * Store a newly created resource in storage.
 * @param userId
 * @param data
 * @returns {function(*): Promise<unknown>}
 */
export const handleAddSkill = (userId, data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    api().post(`/users/skillTags/${userId}`, data).then((res) => {
      dispatch(addSkill(res.data))
      resolve(res)
    }).catch((err) => {
      reject(err)
    })
  })
}

/**
 * Display a listing of the resource.
 * @returns {function(*): Promise<unknown>}
 */
export const handleGetAllSkills = (userId, pageNumber = 1) => (dispatch) => {
  return new Promise((resolve, reject) => {
    api().get(`/users/skillTags?userId=${userId}&page=${pageNumber}&pageSize=200`).then((res) => {
      dispatch(allSkills(res.data))
      resolve(res)
    }).catch((err) => {
      reject(err)
    })
  })
}

/**
 * Update the specified resource in storage.
 * @param eduId
 * @param data
 * @returns {function(*): Promise<unknown>}
 */
export const handleUpdateSkill = (eduId, data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    api().put(`/users/skillTags/${eduId}`, data, {
      // headers: { 'Content-type': 'multipart/form-data' }
    }).then((res) => {
      dispatch(updateSkill(res.data))
      resolve(res)
    }).catch((err) => {
      reject(err)
    })
  })
}

/**
 * Remove the specified resource from storage.
 * @returns {function(*): Promise<unknown>}
 * @param userId
 * @param skill
 */
export const handleDeleteSkill = (userId, skill) => (dispatch) => {
  return new Promise((resolve, reject) => {
    api().delete(`/users/skillTags/${userId}/${skill}`).then((res) => {
      dispatch(deleteSkill(skill))
      resolve(res)
    }).catch((err) => {
      reject(err)
    })
  })
}
