import React, { useEffect, useState } from 'react'
import { Button, Form, Input, Spin } from 'antd'
import { connect } from 'react-redux'
import { handleGetProfile, handleUpdateProfile } from '../../actions/authenticate/Actions'
import PropTypes from 'prop-types'
import { TlaError, TlaSuccess } from '../../utils/messages'
import UploadPhoto from './upload-photo'

function General ({ profile, getProfile, updateProfile }) {
    const [loading, setLoading] = useState(true)
    const [updating, setUpdating] = useState(false)
    const [editing, setEditing] = useState(false)
    const [form] = Form.useForm()
    useEffect(() => {
        getProfile().then(() => {
            setLoading(false)
        })
    }, [])

    const onFinish = (values) => {
        setUpdating(true)

        updateProfile(profile?.userId, values).then(() => {
            TlaSuccess('Profile Update Success')
            form.resetFields()
            setUpdating(false)
            setEditing(false)
        }).catch((error) => {
            setUpdating(false)
            TlaError(error.response.data.message)
        })
    }

    return (
        <div className={'w-full bg-white-base'}>
            <Spin spinning={loading || updating}>
                {
                    !loading &&
                    <Form form={form} onFinish={onFinish}
                          initialValues={{ ...profile }}
                          colon={false}
                          requiredMark={false} className={'profile-form'}>
                        <div className={'bg-white-base pb-36'}>
                            <div className={'profile-header-img h-[150px] md:h-[240px]'}></div>
                            <div className={'absolute top-[80px] md:top-[195px] w-full'}>
                                <div className={'flex flex-wrap justify-between items-center mx-4 md:mx-8'}>
                                    <div className={'flex items-center gap-3 md:gap-6'}>
                                        <UploadPhoto/>
                                        <div>
                                            <h3 className={'text-3xl leading-[38px] font-medium mt-8 md:mt-10'}>Profile</h3>
                                            <p className={'text-sm md:text-base text-gray-500 font-normal' +
                                                ' leading-6'}>
                                                Update your personal details.
                                            </p>
                                        </div>
                                    </div>
                                    <div className={'flex gap-x-3 md:gap-x-7 mt-6 justify-end w-full md:w-fit'}>
                                        {
                                            !editing
                                                ? <Button className={'btn btn-primary-700'}
                                                          onClick={() => setEditing(true)}
                                                          size={'large'}>Edit Profile</Button>
                                                : <>
                                                    <Button
                                                        onClick={() => setEditing(false)}
                                                        className={'btn'} size={'large'}>Cancel</Button>
                                                    <Button className={'btn btn-primary-700'} htmlType={'submit'}
                                                            size={'large'}>Update Profile</Button>
                                                </>
                                        }
                                    </div>
                                </div>
                            </div>

                            <div className={'mt-[100px] md:mt-[150px] w-fit mx-5 md:mx-0 ml-0 md:mx-[190px] p-4' +
                                ' md:p-0'}>
                                <div>
                                    <Form.Item label={'Full Name*'}
                                               name={'name'}
                                               rules={[{
                                                   required: true
                                               }]}>
                                        <Input disabled={!editing} className={'profile-input'}
                                               placeholder={'Eg Chris Martin'}
                                               size={'large'}/>
                                    </Form.Item>
                                </div>
                                <div>
                                    <Form.Item
                                        rules={[
                                            {
                                                type: 'email',
                                                message: 'Provide a valid email'
                                            },
                                            {
                                                required: true,
                                                message: 'Resume Email required'
                                            }
                                        ]}
                                        name={'resumeEmail'}
                                        label={'Resume Email'}>
                                        <Input disabled={!editing} className={'profile-input'} size={'large'}/>
                                    </Form.Item>
                                </div>
                                <div>
                                    <Form.Item
                                        name={'address'}
                                        label={'Address'}>
                                        <Input disabled={!editing} className={'profile-input'} size={'large'}/>
                                    </Form.Item>
                                </div>
                                <div>
                                    <Form.Item
                                        rules={[
                                            {
                                                type: 'string',
                                                min: 10,
                                                message: 'Provide a valid phone number'
                                            },
                                            {
                                                required: true,
                                                message: 'Mobile Number required'
                                            }
                                        ]}
                                        name={'mobileNumber'}
                                        label={'Mobile Number'}>
                                        <Input disabled={!editing} className={'profile-input'} size={'large'}/>
                                    </Form.Item>
                                </div>
                                <div>
                                    <Form.Item
                                        rules={[
                                            {
                                                type: 'url',
                                                message: 'Provide a valid Linkedin URL'
                                            },
                                            {
                                                type: 'string',
                                                min: 6
                                            }
                                        ]}
                                        name={'linkedin'}
                                        label={'Linkedin'}>
                                        <Input disabled={!editing} className={'profile-input'} size={'large'}/>
                                    </Form.Item>

                                    <Form.Item hidden
                                               name={'photo'}
                                               label={'Photo'}>
                                        <Input disabled={!editing} className={'profile-input'} size={'large'}/>
                                    </Form.Item>
                                </div>
                                <div>
                                    <Form.Item
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Resume Headline required'
                                            }
                                        ]}
                                        name={'resumeHeadline'}
                                        label={'Resume Headline'}>
                                        <Input disabled={!editing} className={'profile-input'} size={'large'}/>
                                    </Form.Item>
                                </div>
                                <div>
                                    <Form.Item
                                        rules={[
                                            {
                                                type: 'url',
                                                message: 'Provide a valid Github URL'
                                            },
                                            {
                                                type: 'string',
                                                min: 6
                                            }
                                        ]}
                                        name={'github'}
                                        label={'Github'}>
                                        <Input disabled={!editing} className={'profile-input'} size={'large'}/>
                                    </Form.Item>
                                </div>
                                <div>
                                    <Form.Item
                                        rules={[
                                            {
                                                type: 'url',
                                                message: 'Provide a valid GitLab URL'
                                            },
                                            {
                                                type: 'string',
                                                min: 6
                                            }
                                        ]}
                                        name={'gitLab'}
                                        label={'GitLab'}>
                                        <Input disabled={!editing} className={'profile-input'} size={'large'}/>
                                    </Form.Item>
                                </div>
                            </div>
                        </div>
                    </Form>
                }
            </Spin>
        </div>
    )
}

General.propTypes = {
    profile: PropTypes.object.isRequired,
    getProfile: PropTypes.func.isRequired,
    updateProfile: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
    profile: state.loginReducer.authUserInfo
})

const mapDispatchToProps = (dispatch) => ({
    getProfile: () => dispatch(handleGetProfile()),
    updateProfile: (userId, data) => dispatch(handleUpdateProfile(userId, data))
})

export default connect(mapStateToProps, mapDispatchToProps)(General)
