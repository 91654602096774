import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { useReactToPrint } from 'react-to-print'

function DownloadCoverLetter ({ letter, callBack }) {
    const printRef = React.useRef()

    const { contactInfo, coverLetter } = letter

    useEffect(() => {
        handlePrint()
        callBack(false)
    }, [])

    // eslint-disable-next-line no-unused-vars
    const handlePrint = useReactToPrint({
        content: () => printRef.current
    })

    return (
        <div style={{ display: 'block' }}>
            <div style={{ display: 'block' }} ref={printRef} className={'bg-white-base pb-[120px] px-5 print-div'}>
                <div className={'print-div'}>
                    <div className={'text-center py-[60px]'}>
                        <h1 className={'font-bold text-[34px] text-gray-800'}>{contactInfo?.name}</h1>
                        <h3><a href={`mailto:${contactInfo?.resumeEmail}`}>{contactInfo?.resumeEmail}</a></h3>
                        <h3><a href={`tel:${contactInfo?.mobileNumber}`}>{contactInfo?.mobileNumber}</a></h3>
                    </div>
                    <div>
                        <p>Dear Hiring Manager</p> <br/>
                        {
                            coverLetter?.paragraphs.map((letter, index) => (
                                <>
                                    <p key={index}>{letter}</p>
                                    <br/>
                                </>
                            ))
                        }
                        <p>
                            Thank you very much for your time and for considering my application. I look forward to
                            hearing from you for the next steps.
                        </p> <br/>
                        <p>
                            Best Regards,
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

DownloadCoverLetter.propTypes = {
    letter: PropTypes.object.isRequired,
    callBack: PropTypes.func
}

const mapStateToProps = (state) => ({
    letter: state.apply10xReducer.coverLetter
})

export default connect(mapStateToProps, null)(DownloadCoverLetter)
