import React, { useEffect, useState } from 'react'
import TlaTableWrapper from '../../components/table/tla-table-wrapper'
import Column from 'antd/es/table/Column'
import PropTypes from 'prop-types'
import { connect, useSelector } from 'react-redux'
import { handleGetAppliedSessions } from '../../actions/job-applications/Actions'
import { DatePicker, Spin } from 'antd'
import { formatDate } from '../../utils'
import { FiCalendar } from 'react-icons/fi'
import dayjs from 'dayjs'

function AutoApplySession (props) {
    const [loading, setLoading] = useState(true)
    const userId = useSelector(state => state.loginReducer.authUser.id)
    const filter = useSelector(state => state.jobApplicationReducer.autoApplySessionFilter)
    const date = [filter.startDate ?? '', filter.endDate ?? '']
    filter.userId = userId

    const { getJobApplications, jobApplications } = props
    useEffect(() => {
        getData()
    }, [])

    const getData = () => {
        setLoading(true)
        getJobApplications(new URLSearchParams(filter)).then(() => {
            setLoading(false)
        })
    }

    const submitFilter = () => {
        filter.startDate = date[0]
        filter.endDate = date[1]
        getData()
    }
    return (
        <React.Fragment>
            <Spin spinning={loading}>
                <div className={'bg-white-base w-full rounded-lg'}>
                    <div className={'px-4 py-3 flex justify-between items-center'}>
                        <h3 className={'text-gray-900 text-lg font-medium'}>AutoApply Sessions History</h3>
                        <DatePicker.RangePicker
                            value={[date[0] ? dayjs(date[0]) : '', date[1] ? dayjs(date[1]) : '']}
                            onChange={(value) => {
                                if (value) {
                                    date[0] = formatDate(value[0], 'YYYY-MM-DD')
                                    date[1] = formatDate(value[1], 'YYYY-MM-DD')
                                } else {
                                    date[0] = ''
                                    date[1] = ''
                                }
                                submitFilter()
                            }}
                            className={'w-[280px] rounded-lg !text-sm'}
                            suffixIcon={<FiCalendar className={'text-gray-700'}/>}
                            size={'large'}/>
                    </div>
                    <TlaTableWrapper
                        size={'large'}
                        fetchId={userId}
                        callbackFunction={getJobApplications}
                        data={jobApplications.data}
                        meta={jobApplications.meta}
                        showHeader={false}
                        numberColumn>
                        <Column title={'Date'} render={(({ session }) => (
                            formatDate(session, 'MMM DD, YYYY')
                        ))}/>
                        <Column title={'Total Jobs Viewed'} dataIndex="totalJobs"/>
                        <Column title={'Total Jobs Applied'} dataIndex="totalEasyapply"/>
                    </TlaTableWrapper>
                </div>
            </Spin>
        </React.Fragment>
    )
}

AutoApplySession.propTypes = {
    jobApplications: PropTypes.object.isRequired,
    getJobApplications: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
    jobApplications: state.jobApplicationReducer.autoAppliedSessions
})

const mapDispatchToProps = (dispatch) => ({
    getJobApplications: (params) => dispatch(handleGetAppliedSessions(params))
})

export default connect(mapStateToProps, mapDispatchToProps)(AutoApplySession)
