import React from 'react'
import PropTypes from 'prop-types'
import Logo from '../../../assets/images/logo.png'

function AuthLayout ({ children, pageTitle }) {
  return (
      <div className={'bg-white-base auth-layout'}>
          <div className={'flex justify-center items-center h-screen'}>
              <div className={'w-full md:w-1/2 flex justify-center items-center'}>
                  <div className={'w-[360px]'}>
                      <div className={'w-fit mx-auto mb-5'}>
                          <img height={'auto'} src={Logo} width={250} alt={'AutoApply'}/>
                      </div>
                      <h3 className={'text-center text-gray-900 font-semibold text-3xl leading-[38px] mb-3'}>
                          {pageTitle}
                      </h3>
                      {children}
                  </div>
              </div>
          </div>
      </div>
  )
}

AuthLayout.propTypes = {
  children: PropTypes.node,
  pageTitle: PropTypes.string
}

export default AuthLayout
