import React from 'react'
import Apply from './apply'

function AiGenerator () {
    return (
        <div className={'w-full rounded-lg py-[42px] px-[35px]'}>
            <div>
                {/* <h3 className={'font-semibold text-xl text-blue-900 mb-5 font-lato'}>Apply 10X faster</h3> */}
                <Apply/>
                {/* <Overview/> */}
            </div>
        </div>
    )
}

export default AiGenerator
