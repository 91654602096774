import React from 'react'
import MenuHelper from '../../../components/navigation/menu-helper'
import { AdminSidebarMenus } from '../../../utils/menu-items'
import { FiBriefcase, FiSettings } from 'react-icons/fi'

export const Sidebar = () => {
  return (
        <div className={'w-[229px] min-h-[580px] bg-white-base rounded-lg pt-10'}>
          <MenuHelper linkStyles={{ fontWeight: 'normal', fontSize: 14 }} menus={AdminSidebarMenus} icons={{
            users: <FiBriefcase className={'text-gray-500'} size={24}/>,
            settings: <FiSettings className={'text-gray-500'} size={24}/>
          }} direction={'inline'}/>
        </div>
  )
}
