import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { setCurrentApp } from '../../../../actions/app-settings/ActionCreators'

function SwitchApp () {
    const navigate = useNavigate()

    const currentApp = useSelector(state => state.appSettingsReducer.currentApp)
    const dispatch = useDispatch()
    const switchApp = () => {
        dispatch(setCurrentApp(currentApp === 'auto-apply' ? 'cv-builder' : 'auto-apply'))
        navigate(currentApp === 'auto-apply' ? '/cv-builder/home' : '/home')
    }
    return (
        <span onClick={switchApp}
              className={'cursor-pointer bg-gray-200 px-3 py-2 rounded-[36px] text-gray-900 text-base font-medium'}>
            {currentApp === 'auto-apply' ? 'Open CV Builder' : 'Open AutoApply App'}
        </span>
    )
}

export default SwitchApp
