import React, { useEffect } from 'react'
import { Collapse, Form } from 'antd'
import PersonalProfile from './personal-profile'
import { FiChevronDown, FiMinimize, FiPlusCircle } from 'react-icons/fi'
import Summary from './summary'
import TlaAddNew from '../../../components/pop-ups/tla-add-new'
import Experience from './experience'
import Education from './education'
import Certificate from './certificates'
import Skills from './skills'
import Awards from './awards'
import { useDispatch, useSelector } from 'react-redux'
import { formatDate } from '../../../utils'

// eslint-disable-next-line react/prop-types
const Title = ({ title }) => (
    <div className={'flex items-center gap-x-[9px]'}><FiMinimize className={'text-gray-300'}/> {title}</div>
)

const ExpandIcon = (isActive) => (
    <FiChevronDown size={16} className={'font-medium'}
                   style={{ transform: `${isActive ? 'rotate(180deg)' : 'rotate(0deg)'}` }}/>
)

function ResumeForm () {
    const [form] = Form.useForm()
    const refreshForm = useSelector((state) => state.buildResumeReducer.refreshForm)
    const { content } = useSelector(state => state.buildResumeReducer.currentResume)

    const items = [
        {
            key: '1',
            label: <Title title={'Personal Information'}/>,
            children: <PersonalProfile form={form}/>,
            style: {
                background: '#F9FAFB',
                marginBottom: 15
            }
        },
        {
            key: '2',
            label: <Title title={'Summary'}/>,
            children: <Summary form={form}/>,
            style: {
                background: '#F9FAFB',
                marginBottom: 15
            }
        },
        {
            key: '3',
            label: <Title title={'Experience'}/>,
            children: <Experience form={form}/>,
            style: {
                background: '#F9FAFB',
                marginBottom: 15
            },
            extra:
                <TlaAddNew link={'/cv-builder/experience/form'}>
                    <span className={'text-primary-400 font-medium text-xs flex items-center gap-x-1'}>
                        <FiPlusCircle/>Add Experience
                    </span>
                </TlaAddNew>
        },
        {
            key: '4',
            label: <Title title={'Education'}/>,
            children: <Education form={form}/>,
            extra:
                <TlaAddNew link={'/cv-builder/education/form'}>
                    <span className={'text-primary-400 font-medium text-xs flex items-center gap-x-1'}>
                        <FiPlusCircle/>Add Education
                    </span>
                </TlaAddNew>
        },
        {
            key: '5',
            label: <Title title={'Certificates'}/>,
            children: <Certificate form={form}/>,
            extra:
                <TlaAddNew link={'/cv-builder/certificates/form'}>
                    <span className={'text-primary-400 font-medium text-xs flex items-center gap-x-1'}>
                        <FiPlusCircle/>Add Certificate
                    </span>
                </TlaAddNew>
        },
        {
            key: '6',
            label: <Title title={'Skills'}/>,
            children: <Skills form={form}/>,
            extra:
                <TlaAddNew data={{ previousPath: '/build-resume' }} link={'/cv-builder/skills/form'}>
                    <span className={'text-primary-400 font-medium text-xs flex items-center gap-x-1'}>
                        <FiPlusCircle/>Add/Edit Skills
                    </span>
                </TlaAddNew>
        },
        {
            key: '7',
            label: <Title title={'Awards & Achievements'}/>,
            children: <Awards form={form}/>,
            extra:
                <TlaAddNew link={'/cv-builder/awards-achievement/form'}>
                    <span className={'text-primary-400 font-medium text-xs flex items-center gap-x-1'}>
                        <FiPlusCircle/>Add Awards
                    </span>
                </TlaAddNew>
        }
    ]

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch({
            type: 'SET_DRAFT',
            payload: true
        })
    }, [content])

    useEffect(() => {
        form.resetFields()
        dispatch({
            type: 'SET_REFRESH_FORM',
            payload: true
        })
    }, [refreshForm])

    // eslint-disable-next-line no-unused-vars
    const onFieldsChange = (values, all) => {
        if (typeof values[0].value === 'object') {
            values[0].value = formatDate(values[0].value, 'YYYY-MM-DD')
        }
        dispatch({
            type: 'UPDATE_RESUME',
            payload: {
                name: values[0].name,
                value: values[0].value
            }
        })
    }

    return (
        <div style={{ height: 'calc(100% - 150px)' }} className={'overflow-y-auto overflow-x-hidden p-3'}>
            <TlaAddNew link={'/resume/upload'} data={{ isResume: true }}>
                <div className={'flex justify-center p-4 mb-2 rounded-lg bg-white-base border border-gray-300'}>
                    <span className={'text-primary-700 text-sm font-medium'}>Import Resume Data</span>
                </div>
            </TlaAddNew>
            <Form form={form} initialValues={content}
                  colon={false} className={'resume-form'}
                  requiredMark={false}
                  layout={'vertical'}
                  name={'resume-form'}>
                <Collapse
                    expandIcon={({ isActive }) => ExpandIcon(isActive)}
                    className={'resume-collapse'}
                    size={'small'}
                    expandIconPosition={'end'}
                    ghost accordion
                    items={items}/>
            </Form>
        </div>
    )
}

export default ResumeForm
