import React from 'react'
import { Form, Input } from 'antd'
import Title from './title'

function JobEmail () {
    return (
        <div className={'w-[340px] md:w-[376px]'}>
            <Title text={'What is your email?'}/>
            <Form.Item name={'email'} rules={[
                { required: true, message: 'Required' }
            ]}>
                <Input size={'large'} rootClassName={'first-time-input'} placeholder={'Eg.' +
                    ' john.doe@mail.com'}/>
            </Form.Item>
        </div>
    )
}

export default JobEmail
