import React from 'react'
import { FiArrowLeft } from 'react-icons/fi'
import { Link } from 'react-router-dom'

export default function BackToLogin () {
    return (
        <Link to={'/login'}>
            <p className={'text-gray-500 flex items-center justify-center gap-x-2'}>
                <FiArrowLeft/> Back to login
            </p>
        </Link>
    )
}
