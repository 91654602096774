import { Path, Svg, G, Defs, Rect, ClipPath } from '@react-pdf/renderer'
import React from 'react'
import PropTypes from 'prop-types'

export const Phone = ({ color, download }) => (
    download
        ? <Svg xmlns="http://www.w3.org/2000/svg" width="12" height="13" viewBox="0 0 12 13" fill={color}>
            <G clipPath="url(#clip0_2975_22226)">
                <Path
                    d="M11.0002 9.12606V10.6261C11.0008 10.7653 10.9723 10.9031 10.9165 11.0307C10.8607 11.1583 10.7789 11.2729 10.6762 11.367C10.5736 11.4611 10.4525 11.5328 10.3206 11.5774C10.1887 11.622 10.0489 11.6386 9.91021 11.6261C8.37163 11.4589 6.89371 10.9331 5.59521 10.0911C4.38712 9.3234 3.36288 8.29915 2.59521 7.09106C1.7502 5.78667 1.22433 4.30156 1.06021 2.75606C1.04772 2.6178 1.06415 2.47844 1.10846 2.34688C1.15277 2.21531 1.224 2.09441 1.31759 1.99187C1.41119 1.88934 1.52511 1.80742 1.65211 1.75133C1.7791 1.69523 1.91638 1.66619 2.05521 1.66606H3.55521C3.79786 1.66368 4.03311 1.7496 4.21709 1.90783C4.40108 2.06606 4.52125 2.28579 4.55521 2.52606C4.61852 3.0061 4.73594 3.47743 4.90521 3.93106C4.97248 4.11003 4.98704 4.30452 4.94716 4.4915C4.90729 4.67849 4.81464 4.85012 4.68021 4.98606L4.04521 5.62106C4.75699 6.87284 5.79344 7.90929 7.04521 8.62106L7.68021 7.98606C7.81616 7.85163 7.98779 7.75899 8.17477 7.71911C8.36175 7.67923 8.55625 7.69379 8.73521 7.76106C9.18885 7.93034 9.66018 8.04775 10.1402 8.11106C10.3831 8.14533 10.6049 8.26767 10.7635 8.45481C10.922 8.64196 11.0063 8.88085 11.0002 9.12606Z"
                    stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
            </G>
            <Defs>
                <ClipPath id="clip0_2975_22226">
                    <Rect width="12" height="12" fill="white" transform="translate(0 0.666016)"/>
                </ClipPath>
            </Defs>
        </Svg>
        : <svg xmlns="http://www.w3.org/2000/svg" width="12" height="13" viewBox="0 0 12 13" fill={color}>
            <g clipPath="url(#clip0_2975_22226)">
                <path
                    d="M11.0002 9.12606V10.6261C11.0008 10.7653 10.9723 10.9031 10.9165 11.0307C10.8607 11.1583 10.7789 11.2729 10.6762 11.367C10.5736 11.4611 10.4525 11.5328 10.3206 11.5774C10.1887 11.622 10.0489 11.6386 9.91021 11.6261C8.37163 11.4589 6.89371 10.9331 5.59521 10.0911C4.38712 9.3234 3.36288 8.29915 2.59521 7.09106C1.7502 5.78667 1.22433 4.30156 1.06021 2.75606C1.04772 2.6178 1.06415 2.47844 1.10846 2.34688C1.15277 2.21531 1.224 2.09441 1.31759 1.99187C1.41119 1.88934 1.52511 1.80742 1.65211 1.75133C1.7791 1.69523 1.91638 1.66619 2.05521 1.66606H3.55521C3.79786 1.66368 4.03311 1.7496 4.21709 1.90783C4.40108 2.06606 4.52125 2.28579 4.55521 2.52606C4.61852 3.0061 4.73594 3.47743 4.90521 3.93106C4.97248 4.11003 4.98704 4.30452 4.94716 4.4915C4.90729 4.67849 4.81464 4.85012 4.68021 4.98606L4.04521 5.62106C4.75699 6.87284 5.79344 7.90929 7.04521 8.62106L7.68021 7.98606C7.81616 7.85163 7.98779 7.75899 8.17477 7.71911C8.36175 7.67923 8.55625 7.69379 8.73521 7.76106C9.18885 7.93034 9.66018 8.04775 10.1402 8.11106C10.3831 8.14533 10.6049 8.26767 10.7635 8.45481C10.922 8.64196 11.0063 8.88085 11.0002 9.12606Z"
                    stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
            </g>
            <defs>
                <clipPath id="clip0_2975_22226">
                    <rect width="12" height="12" fill="white" transform="translate(0 0.666016)"/>
                </clipPath>
            </defs>
        </svg>
)

Phone.defaultProps = {
    color: '',
    download: false
}

Phone.propTypes = {
    color: PropTypes.string,
    download: PropTypes.bool
}
