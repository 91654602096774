import { Link, useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'
import React from 'react'

function TlaAddNew ({ link, children, data, pathname, className }) {
    const location = useLocation()
    return (
        <Link to={link} className={className} state={{ background: pathname ? { ...location, pathname } : location, data }}>
            {children}
        </Link>
    )
}

TlaAddNew.defaultProps = {
    link: '#',
    pathname: null,
    className: ''
}

TlaAddNew.propTypes = {
    children: PropTypes.node,
    pathname: PropTypes.string,
    data: PropTypes.object,
    link: PropTypes.string,
    className: PropTypes.string
}

export default TlaAddNew
