export const createDictionary = (items) => {
    // const rItems = [...items]
    /* const existItems = Object.values(rItems.hiddenItems)
        .filter(index => rItems.items[index] !== undefined)
        .map(index => rItems.items[index]) */

    return items.reduce((acc, item) => {
        const rItems = { ...item.description }
        acc[item.id] = Object.values(rItems.hiddenItems)
            .filter(index => rItems.items[index] !== undefined)
            .map(index => rItems.items[index])
        // acc[item.id] = item.description.items
        return acc
    }, {})
}
