import { Types } from '../actions/certifications/Types'

const initialState = {
  certifications: {
    data: [],
    meta: {}
  }
}

export default function certificationsReducer (state = initialState, action) {
  switch (action.type) {
    case Types.NEW_CERTIFICATION:
      return {
        ...state,
        certifications: {
          ...state.certifications,
          data: state.certifications.data.concat(action.payload.data.certificate)
        }
      }
    case Types.ALL_CERTIFICATIONS:
      return {
        ...state,
        certifications: {
          ...state.certifications,
          data: action.payload.data.userCertificates,
          meta: {
            total: action.payload.data.count,
            page: action.payload.data.page,
            pageSize: action.payload.data.pageSize
          }
        }
      }

    case Types.UPDATE_CERTIFICATION:
      return {
        ...state,
        certifications: {
          ...state.certifications,
          data: state.certifications.data.map((form) => {
            return form.id === action.payload.data.certificate.id ? action.payload.data.certificate : form
          })
        }
      }
    case Types.DELETE_CERTIFICATION:
      return {
        ...state,
        certifications: {
          ...state.certifications,
          data: state.certifications.data.filter((certifications) => certifications.id !== action.id)
        }
      }

    default:
      return state
  }
}
