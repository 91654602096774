import React from 'react'
import Visibility from '../visibility'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

export const SkillItem = ({ icon, title, onClick, type, index }) => {
    const {
        softSkills,
        technicalSkills
    } = useSelector(state => state.buildResumeReducer?.currentResume?.content?.skillTags?.hiddenItems)

    return (
        <span
            className={'flex w-fit items-center gap-1 text-blue-700 bg-blue-light-50 text-xs py-0.5 px-2 rounded-2xl'}>
          <span className={'font-medium'}>{title}</span>
            {
                icon &&
                <Visibility
                    name={['skillTags', 'hiddenItems', type === 'soft' ? 'softSkills' : 'technicalSkills', index]}
                    value={(type === 'soft' ? softSkills[index] : technicalSkills[index]) !== undefined}/>
            }
        </span>
    )
}

SkillItem.defaultProps = {
    icon: true,
    title: 'Title'
}

SkillItem.propTypes = {
    icon: PropTypes.bool,
    title: PropTypes.string,
    index: PropTypes.number.isRequired,
    type: PropTypes.string.isRequired,
    onClick: PropTypes.func
}
