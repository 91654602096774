import { Types } from '../actions/experience/Types'

const initialState = {
  experiences: {
    data: [],
    meta: {}
  }
}

export default function experiencesReducer (state = initialState, action) {
  switch (action.type) {
    case Types.NEW_EXPERIENCE:
      return {
        ...state,
        experiences: {
          ...state.experiences,
          data: state.experiences.data.concat(action.payload.data.experience)
        }
      }
    case Types.ALL_EXPERIENCES:
      return {
        ...state,
        experiences: {
          ...state.experiences,
          data: action.payload.data.userExperiences,
          meta: {
            total: action.payload.data.count,
            page: action.payload.data.page,
            pageSize: action.payload.data.pageSize
          }
        }
      }

    case Types.UPDATE_EXPERIENCE:
      return {
        ...state,
        experiences: {
          ...state.experiences,
          data: state.experiences.data.map((form) => {
            return form.id === action.payload.data.experience.id ? action.payload.data.experience : form
          })
        }
      }
    case Types.DELETE_EXPERIENCE:
      return {
        ...state,
        experiences: {
          ...state.experiences,
          data: state.experiences.data.filter((experiences) => experiences.id !== action.id)
        }
      }

    default:
      return state
  }
}
