import React from 'react'
import { Outlet } from 'react-router-dom'
import Header from '../../dashboard/layout/header'

function NoSidebar () {
    return (
        <div className={'max-w-screen-2xl mx-auto'}>
            <Header/>
            <div className={'flex gap-x-2.5 mx-2 md:mx-12 mt-[18px] relative'}>
                <div className={'mb-5 flex-1'}>
                    <Outlet/>
                </div>
            </div>
        </div>
    )
}

export default NoSidebar
