import React from 'react'
import TlaIcon from '../../../components/tla-icon'

function JobQuerySuccess () {
    return (
        <div className={'flex flex-col items-center justify-center mt-[174px]'}>
            <TlaIcon name={'congratulations'}/>
            <div className={'font-normal text-[26px] text-center mt-[52px]'}>
                <p>Congratulations</p>
                <p>You have created first <span className={'text-primary-600 font-medium'}>Job Role</span></p>
            </div>
        </div>
    )
}

export default JobQuerySuccess
