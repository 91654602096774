import React from 'react'
import { connect, useSelector } from 'react-redux'
import { TlaModal } from '../../../components/auto-apply/pop-ups/tla-modal'
import PropTypes from 'prop-types'
import { handleChangePassword } from '../../../actions/authenticate/Actions'
import { formatDate } from '../../../utils'

function Subscription () {
    const { linkToUpgrade, subscriptionPackage } = useSelector(state => state.dashboardReducer.userPackage)
    const pkg = useSelector(state => state.dashboardReducer?.userPackage)

    return (
        <TlaModal width={ 574 } title={ 'Subscription' }>
            <div className={ 'bg-primary-600 p-5 rounded-lg mb-4' }>
                <div className={ 'text-[13px] font-normal text-white-base flex items-center justify-between' }>
                    <small>Current Plan</small>
                    <small>Renews on: { formatDate(pkg?.nextRenewalDate) }</small>
                </div>
                <h3 className={ 'text-[38px] font-medium capitalize text-white-base leading-none mb-[30px]' }>{ subscriptionPackage?.subscriptionName }</h3>
                <a className={ 'w-fit text-base font-medium text-primary-600 px-[30px] h-[46px] py-2.5 bg-white-base rounded-full' }
                   href={ 'https://docs.google.com/forms/d/e/1FAIpQLSeIzdgiVCT22OEj1yvjrkWObg1jLgHHxLKnXC6q7N6UJzSlAw/viewform' }
                   target={ '_blank' } rel="noreferrer">
                    Upgrade Plan
                </a>
            </div>
            <div className={ 'bg-white-base p-5 rounded-lg mb-4' }>
                <h3 className={ 'text-[26px] font-medium capitalize text-gray-800 leading-none mb-[18px]' }>
                    Payments
                </h3>
                <p className={ 'mb-18' }>
                    <a className={ 'text-sm underline hover:text-gray-900 hover:underline' } href={ linkToUpgrade }
                       target={ '_blank' } rel="noreferrer">
                        See Invoices & Payment Details
                    </a>
                </p>
            </div>
            <div className={ 'bg-white-base p-5 rounded-lg' }>
                <h3 className={ 'text-[26px] font-medium capitalize text-gray-800 leading-none mb-[18px]' }>
                    Need to cancel plan?
                </h3>
                <p className={ 'mb-18 text-base font-normal text-gray-800' }>
                    The plan cancels at the last day of the current plan. You may cancel by
                    clicking below.
                </p>

                <div className={ 'text-[13px] font-normal text-white-base flex items-center justify-end' }>
                    <a href={linkToUpgrade} target={ '_blank' } rel="noreferrer">
                        <small className={ 'text-gray-800 underline' }>Cancel Now</small>
                    </a>
                </div>
            </div>
        </TlaModal>
    )
}

Subscription.propTypes = {
    changePassword: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired
}

const mapStateToProps = (state) => ({
    user: state.loginReducer.authUser
})

const mapDispatchToProps = (dispatch) => ({
    changePassword: (userId, data) => dispatch(handleChangePassword(userId, data))
})

export default connect(mapStateToProps, mapDispatchToProps)(Subscription)
