import React, { useEffect, useState } from 'react'
import WidgetItem from '../dashboard/widget/widget-item'
import { handleJobApplicationStat } from '../../actions/job-applications/Actions'
import { connect, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'

function JobsAppliedStats ({ getJobApplicationStats }) {
    const userId = useSelector(state => state.loginReducer.authUser.id)
    const jobStats = useSelector(state => state.jobApplicationReducer.jobStats)
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        getJobApplicationStats(userId)
            .then(() => setLoading(false))
            .catch(() => setLoading(false))
    }, [])
  return (
      <Spin spinning={loading} indicator={<LoadingOutlined/>}>
          <div className={'w-full rounded-lg grid grid-cols-1 md:grid-cols-3 gap-[33px]'}>
              <WidgetItem title={'Total Jobs Viewed'} total={jobStats?.totalJobsVied ?? 0}/>
              <WidgetItem title={'AutoApply Jobs (Paid) Applied'} total={jobStats?.totalJobsApplied ?? 0}/>
              <WidgetItem title={'Self Service Jobs (Basic) Applied'} total={jobStats?.totalSelfAppliedJobs ?? 0}/>
          </div>
      </Spin>
  )
}

JobsAppliedStats.propTypes = {
    getJobApplicationStats: PropTypes.func
}

const mapDispatchToProps = (dispatch) => ({
    getJobApplicationStats: (userId) => dispatch(handleJobApplicationStat(userId))
})
export default connect(null, mapDispatchToProps)(JobsAppliedStats)
