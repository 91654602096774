import React from 'react'
import { FiTrash2 } from 'react-icons/fi'
import TlaConfirm from '../../../../components/tla-confirm'
import PropTypes from 'prop-types'

const DeleteIcon = ({ deleteTitle, deleteItem }) => {
    return (
        <TlaConfirm
            showIcon={false}
            danger
            title={deleteTitle}
            callBack={deleteItem}>
            <FiTrash2 size={16} className={'text-gray-400 cursor-pointer'}/>
        </TlaConfirm>
    )
}

DeleteIcon.defaultProps = {
    deleteTitle: 'Confirm Delete'
}

DeleteIcon.propTypes = {
    deleteTitle: PropTypes.string,
    deleteItem: PropTypes.func
}

export default DeleteIcon
