import React, { useEffect, useState } from 'react'
import { Button, Form, Input, Select, Spin, Switch } from 'antd'
import { connect, useSelector } from 'react-redux'
import { TlaError, TlaSuccess } from '../../utils/messages'
import PropTypes from 'prop-types'
import { handleGetCountries } from '../../actions/questions/Action'
import { handleUpdateProfile } from '../../actions/authenticate/Actions'

function Questions ({ getCountries, updateProfile }) {
    const [allow, setAllow] = useState(false)
    const countries = useSelector(state => state.questionReducer.countries)
    // eslint-disable-next-line no-unused-vars
    const userId = useSelector(state => state.loginReducer.authUser?.id)
    const otherQuestions = useSelector(state => state.loginReducer.authUser?.userInformation?.otherQuestions)
    const [loading, setLoading] = useState(false)
    const [form] = Form.useForm()

    useEffect(() => {
        getCountries().then(() => {
            setLoading(false)
        }).catch((error) => {
            setLoading(false)
            TlaError(error.response.data.message)
        })
    }, [])

    let questions = otherQuestions ? JSON.parse(otherQuestions) : []
    console.log(questions)
    const initialAnswers = questions.map((q) => {
        if (q.type === 'switch' && q.options.includes('yes')) {
            q.answer = q.answer === '' ? true : q.answer
        } else {
            q.answer = q.answer === '' ? null : q.answer
        }

        return q.answer
    })
// else if (q.type === 'country') {
//         q.answer = q.answer === '' && []
//     }

    const onFinish = (values) => {
        setLoading(true)
        questions = questions.map((q) => {
            if (q.answer === true) {
                q.answer = 'yes'
            } else if (q.answer === false) {
                q.answer = 'no'
            }
            return q
        })

        updateProfile(userId, {
            otherQuestions: JSON.stringify(questions)
        }).then(() => {
            setLoading(false)
            TlaSuccess('Answers Saved Successfully')
        }).catch((error) => {
            setLoading(false)
            TlaError(error.response.data.message)
        })
    }

    const onFieldsChange = (changedFields, allFields) => {
        let answer = changedFields[0].value
        const name = changedFields[0].name[0]

        if (answer === true) {
            answer = 'yes'
        } else if (answer === false) {
            answer = 'no'
        } else {
            answer = changedFields[0].value
        }
        if (name === 'newGender') {
            questions[7].answer = answer
        } else {
            questions[changedFields[0].name[0]].answer = answer
        }
    }

    const onChange = (value) => {
        if (value === 'other') {
            setAllow(true)
        } else {
            setAllow(false)
        }
    }

    return (
        <div className={'bg-white-base py-[42px] px-[34px] rounded-lg'}>
            <Spin spinning={loading}>
                <Form colon={false}
                      initialValues={{ ...initialAnswers }}
                      onFieldsChange={onFieldsChange}
                      className={'w-full md:w-[789px]'}
                      onFinish={onFinish}
                      layout={'vertical'}
                      form={form}
                      requiredMark>
                    <div className={'mb-[38px]'}>
                        <h3 className={'text-xl font-medium text-gray-900'}>Must-Answer Questions</h3>
                        <p className={'text-xs text-gray-900 font-normal'}>The following questions will help automate
                            answering question that many job applications require.</p>
                    </div>
                    <div>
                        {
                            questions.map(({ type, title, options }, index) => {
                                if (type === 'text') {
                                    return (
                                        <Form.Item key={index} name={index}
                                                   label={title}
                                                   rules={[{
                                                       required: true,
                                                       message: 'Required'
                                                   }]}>
                                            <Input size={'large'}
                                                   placeholder={title === 'What is your availability? (notice perod)' ? '4 Weekes' : 'Eg 150,000'}/>
                                        </Form.Item>
                                    )
                                }
                                if (type === 'switch') {
                                    return (
                                        <Form.Item className={'testing'} key={index} name={index} label={title}
                                                   rules={[{
                                                       required: true,
                                                       message: 'Required'
                                                   }]}
                                                   valuePropName="checked">
                                            <Switch/>
                                        </Form.Item>
                                    )
                                }

                                if (type === 'country') {
                                    return (
                                        <Form.Item key={index} name={index}
                                                   rules={[{
                                                       required: true,
                                                       message: 'Required'
                                                   }]}
                                                   label={'In which countries you have work rights? (where you don’t need work visa))'}>
                                            <Select size={'large'} showSearch mode={'multiple'}
                                                    placeholder="Select list of country(ies)">
                                                {
                                                    countries.map(({ name }) => (
                                                        <Select.Option
                                                            key={name?.common}
                                                            value={name?.common}>
                                                            {name?.common}
                                                        </Select.Option>
                                                    ))
                                                }
                                            </Select>
                                        </Form.Item>
                                    )
                                }

                                if (type === 'dropdown') {
                                    return (
                                        <React.Fragment key={index}>
                                            <Form.Item
                                                name={index} label={title}
                                                rules={[{
                                                    required: true,
                                                    message: 'Required'
                                                }]}>
                                                <Select
                                                    placeholder={`Select ${title === 'Gender' ? 'gender' : 'option'}`}
                                                    onChange={title === 'Gender'
                                                        ? onChange
                                                        : () => {
                                                        }}
                                                    size={'large'}>
                                                    {
                                                        options.map((option) => (
                                                            <Select.Option key={option}
                                                                           value={option}>{option}</Select.Option>
                                                        ))
                                                    }
                                                </Select>
                                            </Form.Item>
                                            {
                                                (title === 'Gender' && allow === true) &&
                                                <Form.Item name={'newGender'} label={'Describe'}
                                                           rules={[{
                                                               required: true,
                                                               message: 'Required'
                                                           }]}>
                                                    <Input size={'large'}/>
                                                </Form.Item>
                                            }
                                        </React.Fragment>
                                    )
                                }

                                if (type === 'multi-select') {
                                    return (
                                        <Form.Item key={index} name={index} label={title}
                                                   rules={[{
                                                       required: true,
                                                       message: 'Required'
                                                   }]}>
                                            <Select size={'large'} showSearch
                                                    mode={'multiple'}
                                                    placeholder={'Select option(s)'}>
                                                {
                                                    options.map((option) => (
                                                        <Select.Option
                                                            key={option}
                                                            value={option}>
                                                            {option}
                                                        </Select.Option>
                                                    ))
                                                }
                                            </Select>
                                        </Form.Item>
                                    )
                                }

                                return null
                            })
                        }
                    </div>
                    <div className={'flex gap-3 mt-[67px]'}>
                        <Button className={'btn py-2.5 px-[18px] w-[172px]'} size={'large'}>Cancel</Button>
                        <Button htmlType={'submit'} className={'btn btn-primary-400 py-2.5 px-[18px] w-[172px]'}
                                size={'large'}>Save</Button>
                    </div>
                </Form>
            </Spin>
        </div>
    )
}

Questions.propTypes = {
    getCountries: PropTypes.func.isRequired,
    updateProfile: PropTypes.func.isRequired
}

const mapDispatchToProps = (dispatch) => ({
    getCountries: () => dispatch(handleGetCountries()),
    updateProfile: (userId, data) => dispatch(handleUpdateProfile(userId, data))
})

export default connect(null, mapDispatchToProps)(Questions)
