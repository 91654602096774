import React from 'react'
import { Document, Link, Page, Text, View } from '@react-pdf/renderer'
import PropTypes from 'prop-types'
import Header from './plain-white-title'
import { Location } from '../../../icons/Location'
import { Email } from '../../../icons/Email'
import { Phone } from '../../../icons/Phone'
import Validate from '../../../components/validate'
import PlainWhiteStyles from '../styles'
import { formatDate } from '../../../../../utils'
import { Github } from '../../../icons/Github'
import { Linkedin } from '../../../icons/Linkedin'
import { Gitlab } from '../../../icons/Gitlab'
import { Dot } from '../../../icons/Dot'

const styles = PlainWhiteStyles

const DownloadPlainWhite = ({ resume, hiddenSections }) => {
    const basics = resume.userInformation?.items
    const skills = resume.skillTags?.items
    const awards = resume.achivementsAwards?.items
    const certifications = resume.certificates?.items
    const work = resume.experiences?.items
    const education = resume.educations?.items
    const projects = resume.projects?.items
    const volunteer = resume.volunteer?.items
    const references = resume.references?.items

    // eslint-disable-next-line no-unused-vars
    const basicHiddenItems = resume.userInformation?.hiddenItems
    const workHiddenItems = resume.experiences?.hiddenItems
    const educationHiddenItems = resume.educations?.hiddenItems
    const awardsHiddenItems = resume.achivementsAwards?.hiddenItems
    const certificationsHiddenItems = resume?.certificates?.hiddenItems

    const { softSkills, technicalSkills } = resume.skillTags?.hiddenItems

    const checkVisibility = (name, index) => {
        return resume.experiences.items[name].description.hiddenItems[index]
    }

    return (
        <Document style={{ backgroundColor: 'red' }}>
            <Page style={{ ...styles.page }}>
                <View style={{ textAlign: 'center' }}>
                    <Validate
                        render={basics?.name && basics?.name !== '' && basicHiddenItems.name !== undefined}>
                        <Text style={{ ...styles.headings, marginBottom: 3 }}>{basics?.name}</Text>
                    </Validate>

                    <Validate
                        render={basics?.resumeHeadline && basics?.resumeHeadline !== '' && basicHiddenItems.resumeHeadline !== undefined}>
                        <Text style={{ ...styles.subHeading, marginBottom: 5 }}>{basics?.resumeHeadline}</Text>
                    </Validate>

                    <View style={{
                        ...styles.flex,
                        justifyContent: 'center',
                        borderBottom: '1px solid #F1F1F1',
                        paddingBottom: 40
                    }}>
                        <Validate
                            render={basics?.linkedin && basics?.linkedin !== '' && basicHiddenItems.linkedin !== undefined}>
                            <View>
                                <Link style={{ textDecoration: 'none' }} target={'_blank'} src={basics?.linkedin}
                                      rel="noreferrer">
                                    <Linkedin download color={'#0A66C2'}/>
                                </Link>
                            </View>
                        </Validate>
                        <Validate
                            render={basics?.github && basics?.github !== '' && basicHiddenItems.github !== undefined}>
                            <View>
                                <Link style={{ textDecoration: 'none' }} target={'_blank'} src={basics?.github}
                                      rel="noreferrer">
                                    <Github download color={'#0A66C2'}/>
                                </Link>
                            </View>
                        </Validate>
                        <Validate
                            render={basics?.gitLab && basics?.gitLab !== '' && basicHiddenItems.gitLab !== undefined}>
                            <View>
                                <Link style={{ textDecoration: 'none' }} target={'_blank'} src={basics?.gitLab}
                                      rel="noreferrer">
                                    <Gitlab download color={'#0A66C2'}/>
                                </Link>
                            </View>
                        </Validate>
                    </View>
                </View>
                <View style={{ display: 'flex' }}>
                    <View style={{
                        display: 'flex',
                        flexDirection: 'row',
                        marginTop: 20,
                        marginLeft: 'auto',
                        marginRight: 'auto',
                        columnGap: 25
                    }}>
                        <View style={{ width: '55%', paddingLeft: 10, paddingRight: 10, paddingBottom: 50 }}>
                            <Validate render={work?.length > 0 && Object.keys(workHiddenItems).length !== 0}>
                                <View>
                                    <Header title={'Experience'}/>
                                    {
                                        work?.map(({
                                                       jobDurationStart,
                                                       jobDurationEnd,
                                                       url,
                                                       companyName,
                                                       jobTitle,
                                                       description,
                                                       city
                                                   }, index) => (
                                            <Validate key={index} render={workHiddenItems[index] !== undefined}>
                                                <View style={{ marginBottom: 10 }}>
                                                    <View style={{ marginBottom: 3 }}>
                                                        <Text style={{ ...styles.body, color: '#667085', fontSize: 9 }}>
                                                            {formatDate(jobDurationStart, 'MMM DD, YYYY')} - {jobDurationEnd ? formatDate(jobDurationEnd, 'MMM DD, YYYY') : 'Present'}
                                                        </Text>
                                                    </View>
                                                    <View style={{ marginBottom: 5 }}>
                                                        <View style={{ ...styles.flex, marginBottom: 3 }}>
                                                            <Validate render={companyName !== ''}>
                                                                <Text style={{
                                                                    ...styles.body,
                                                                    fontWeight: 700
                                                                }}>{companyName}</Text>
                                                            </Validate>
                                                            <Validate render={city !== ''}>
                                                                <Text style={styles.body}>{city}</Text>
                                                            </Validate>
                                                        </View>
                                                        <Validate render={jobTitle !== ''}>
                                                            <Text wrap={false}
                                                                style={{
                                                                    ...styles.body,
                                                                    flexWrap: 'nowrap',
                                                                    fontWeight: 700
                                                                }}>{jobTitle}</Text>
                                                        </Validate>
                                                    </View>
                                                    <Validate render={description?.items.length > 0}>
                                                        {
                                                            description?.items?.map((item, index1) => (
                                                                <Validate
                                                                    render={checkVisibility(index, index1) !== undefined}
                                                                    key={index1}>
                                                                    <View style={{ ...styles.flex, alignItems: 'flex-start', alignContent: 'flex-start', justifyContent: 'flex-start', marginBottom: 2 }}>
                                                                        <View style={{ marginTop: 5 }}>
                                                                            <Dot download/>
                                                                        </View>
                                                                        <Text style={{ ...styles.body }}>{item}</Text>
                                                                    </View>
                                                                </Validate>
                                                            ))
                                                        }
                                                    </Validate>
                                                </View>
                                            </Validate>
                                        ))
                                    }
                                </View>
                            </Validate>
                            <Validate render={projects?.items?.length > 0}>
                                <View>
                                    <Header title={'Projects'}/>
                                    {
                                        projects?.items.map(({
                                                                 date,
                                                                 url,
                                                                 name,
                                                                 summary,
                                                                 description,
                                                                 keywords
                                                             }, index) => (
                                            <View key={index} style={{ marginBottom: 10 }}>
                                                <View>
                                                    <View style={{ ...styles.flex, justifyContent: 'space-between' }}>
                                                        <Text style={styles.bodyTitle}>{name}</Text>
                                                        <Text style={styles.bodySubTitle}>
                                                            {date?.start} - {date?.end}
                                                        </Text>
                                                    </View>
                                                    <View>
                                                        <Text style={styles.body}>{description}</Text>
                                                        <Text style={styles.body}>{summary}</Text>
                                                        <Text style={styles.body}>{url}</Text>
                                                    </View>
                                                </View>
                                            </View>

                                        ))
                                    }
                                </View>
                            </Validate>
                            <Validate render={volunteer?.items?.length > 0}>
                                <View>
                                    <Header title={'Volunteer'}/>
                                    {
                                        volunteer?.items.map(({ date, url, summary, position, organization }, index) => (
                                            <View key={index} style={{ marginBottom: 10 }}>
                                                <View>
                                                    <View style={{ ...styles.flex, justifyContent: 'space-between' }}>
                                                        <View>
                                                            <Text style={styles.bodyTitle}>{organization}</Text>
                                                            <Text style={styles.bodySubTitle}>{position}</Text>
                                                        </View>
                                                        <Text>{date?.start}-{date?.end}</Text>
                                                    </View>
                                                    <View>
                                                        <Text style={styles.body}>{summary}</Text>
                                                        <Text style={styles.body}>{url}</Text>
                                                    </View>
                                                </View>
                                            </View>

                                        ))
                                    }
                                </View>
                            </Validate>
                            <Validate render={references?.items?.length > 0}>
                                <View>
                                    <Header title={'References'}/>
                                    {
                                        references?.items.map(({ name, email, phone, summary, relationship }, index) => (
                                            <View key={index} style={{ marginBottom: 10 }}>
                                                <View>
                                                    <View>
                                                        <Text style={styles.bodyTitle}>{name}</Text>
                                                        <Text style={styles.bodySubTitle}>{relationship}</Text>
                                                        <Text style={styles.bodySubTitle}>{phone}</Text>
                                                        <Text style={styles.bodySubTitle}>{email}</Text>
                                                    </View>
                                                    <View>
                                                        <Text style={styles.body}>{summary}</Text>
                                                    </View>
                                                </View>
                                            </View>
                                        ))
                                    }
                                </View>
                            </Validate>
                            <Validate render={hiddenSections?.skillTags !== undefined}>
                                <Validate
                                    render={(skills?.technicalSkills?.length > 0 || skills?.technical?.softSkills > 0) && (Object.keys(softSkills).length > 0 || Object.keys(technicalSkills).length > 0)}>
                                    <View>
                                        <Header title={'Skills'}/>
                                        <Validate
                                            render={skills?.technicalSkills?.length > 0 && Object.keys(technicalSkills).length !== 0}>
                                            <View style={{ ...styles.flex, alignItems: 'flex-start', marginBottom: 10 }}>
                                                <Text style={{ ...styles.sectionTitle, width: '20%' }}>Technical:</Text>
                                                <View style={{
                                                    ...styles.sectionContent,
                                                    ...styles.flex,
                                                    flexWrap: 'wrap',
                                                    width: '80%'
                                                }}>
                                                    {skills?.technicalSkills.map((skill, index) => (
                                                        <Validate render={technicalSkills[index] !== undefined}
                                                                  key={skill}>
                                                            <View style={{ width: 'auto' }}>
                                                                <Text style={{
                                                                    borderRadius: '3px',
                                                                    paddingLeft: 2,
                                                                    paddingRight: 2,
                                                                    borderBottomColor: '#64748B',
                                                                    borderBottomWidth: '1px',
                                                                    backgroundColor: '#F1F5F9'
                                                                }}>{skill}</Text>
                                                            </View>
                                                        </Validate>
                                                    ))}
                                                </View>
                                            </View>
                                        </Validate>
                                        <Validate
                                            render={skills?.softSkills?.length > 0 && Object.keys(softSkills).length !== 0}>
                                            <View style={{ ...styles.flex, alignItems: 'flex-start', marginBottom: 10 }}>
                                                <Text style={{ ...styles.sectionTitle, width: '20%' }}>Soft:</Text>
                                                <View style={{
                                                    ...styles.sectionContent,
                                                    ...styles.flex,
                                                    flexWrap: 'wrap',
                                                    width: '80%'
                                                }}>
                                                    {skills?.softSkills.map((skill, index) => (
                                                        <Validate render={softSkills[index] !== undefined}
                                                                  key={skill}>
                                                            <View style={{ width: 'auto' }}>
                                                                <Text style={{
                                                                    borderRadius: '3px',
                                                                    paddingLeft: 2,
                                                                    paddingRight: 2,
                                                                    borderBottomColor: '#64748B',
                                                                    borderBottomWidth: '1px',
                                                                    backgroundColor: '#F1F5F9'
                                                                }}>{skill}</Text>
                                                            </View>
                                                        </Validate>
                                                    ))}
                                                </View>
                                            </View>
                                        </Validate>
                                    </View>
                                </Validate>
                            </Validate>
                        </View>
                        <View style={{ width: '25%', paddingLeft: 10, paddingRight: 8, paddingBottom: 50 }}>
                            <View style={{ ...styles.basics, marginBottom: 10 }}>
                                <Header title={'Contact'}/>
                                <Validate
                                    render={basics?.address && basics?.address !== '' && basicHiddenItems.address !== undefined}>
                                    <View style={{ ...styles.flex, marginBottom: 5 }}>
                                        <Location color={'#334155'} download/>
                                        <Text style={{ lineHeight: 1.2 }}>
                                            {basics?.address}
                                        </Text>
                                    </View>
                                </Validate>
                                <Validate
                                    render={basics?.resumeEmail && basics?.resumeEmail !== '' && basicHiddenItems.resumeEmail !== undefined}>
                                    <View style={{ ...styles.flex, marginBottom: 5 }}>
                                        <Email color={'#334155'} download/>
                                        <Link style={{ textDecoration: 'none', color: '#000000' }}
                                              src={`mailto:${basics?.resumeEmail}`}>
                                            {basics?.resumeEmail}
                                        </Link>
                                    </View>
                                </Validate>
                                <Validate
                                    render={basics?.mobileNumber && basics?.mobileNumber !== '' && basicHiddenItems.mobileNumber !== undefined}>
                                    <View style={styles.flex}>
                                        <Phone color={'#334155'} download/>
                                        <Link style={{ textDecoration: 'none', color: '#000000' }}
                                              src={`tel:${basics?.mobileNumber}`}>
                                            {basics?.mobileNumber}
                                        </Link>
                                    </View>
                                </Validate>
                            </View>
                            <Validate render={education?.length > 0 && Object.keys(educationHiddenItems).length !== 0}>
                                <View>
                                    <Header title={'Education'}/>
                                    {
                                        education?.map(({
                                                            studyDurationEnd,
                                                            studyDurationStart,
                                                            school,
                                                            degreeFieldOfStudy,
                                                            city
                                                        }, index) => (
                                            <Validate key={index} render={educationHiddenItems[index] !== undefined}>
                                                <View style={{ marginBottom: 20 }}>
                                                    <View>
                                                        <Text style={{
                                                            ...styles.bodyTitle,
                                                            fontSize: 10,
                                                            fontWeight: 700
                                                        }}>{degreeFieldOfStudy}</Text>
                                                        <View style={{ fontSize: 10, fontWeight: 400, color: '#000' }}>
                                                            <Text>{school}</Text>
                                                            <Text>{city}</Text>
                                                            <Text>
                                                                {formatDate(studyDurationStart, 'YYYY')} - {formatDate(studyDurationEnd, 'YYYY')}
                                                            </Text>
                                                        </View>
                                                    </View>
                                                </View>
                                            </Validate>
                                        ))
                                    }
                                </View>
                            </Validate>
                            <Validate
                                render={certifications?.length > 0 && Object.keys(certificationsHiddenItems).length !== 0}>
                                <View>
                                    <Header title={'Certifications'}/>
                                    <View>
                                        {
                                            certifications?.map(({ title, organisation }, index) => (
                                                <Validate key={index}
                                                          render={certificationsHiddenItems[index] !== undefined}>
                                                    <Text style={{
                                                        fontSize: 10,
                                                        marginBottom: 10
                                                    }}>{title} - {organisation}</Text>
                                                </Validate>
                                            ))
                                        }
                                    </View>
                                </View>
                            </Validate>
                            <Validate render={awards?.length > 0 && Object.keys(awardsHiddenItems).length !== 0}>
                                <View>
                                    <Header title={'Achievements'}/>
                                    <View>
                                        {
                                            awards?.map(({ details, title }, index) => (
                                                <Validate key={index} render={awardsHiddenItems[index] !== undefined}>
                                                    <View style={{ marginBottom: 10 }}>
                                                        <View style={{
                                                            display: 'flex',
                                                            flexDirection: 'row',
                                                            flexWrap: 'wrap',
                                                            justifyContent: 'space-between'
                                                        }}>
                                                            <Text style={{ fontSize: 10 }}>{title}</Text>
                                                        </View>
                                                        <Text style={styles.sectionContent}>{details}</Text>
                                                    </View>
                                                </Validate>
                                            ))
                                        }
                                    </View>
                                </View>
                            </Validate>
                        </View>
                    </View>
                </View>
            </Page>
        </Document>
    )
}

DownloadPlainWhite.defaultProps = {
    fontSize: 10
}

DownloadPlainWhite.propTypes = {
    resume: PropTypes.object,
    hiddenSections: PropTypes.object,
    fontSize: PropTypes.number
}

export default DownloadPlainWhite
