import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Form, Input, Spin } from 'antd'
import { SkillItem } from './skill-item'

const TagsInput = ({ softSkills, setSoftSkills, technicalSkills, setTechnicalSkills }) => {
    const [value, setValue] = useState('')
    const [form] = Form.useForm()
    // eslint-disable-next-line no-unused-vars
    const [loading, setLoading] = useState(false)
    const removeTags = (indexToRemove, type) => {
        if (type === 'soft') {
            setSoftSkills([...softSkills.filter((_, index) => index !== indexToRemove)])
        } else {
            setTechnicalSkills([...technicalSkills.filter((_, index) => index !== indexToRemove)])
        }
    }

    const addTags = (value, type) => {
        if (value !== '') {
            addToSkills(value, type)
        }
    }

    const addToSkills = (tag, type) => {
        if (tag !== '') {
            const newTags = tag.split(',')
            if (type === 'soft') {
                setSoftSkills([...softSkills, ...newTags])
            } else {
                setTechnicalSkills([...technicalSkills, ...newTags])
            }
        }
        setValue('')
        form.resetFields()
    }

    return (
        <Spin spinning={loading}>
            <Form form={form} layout={'vertical'}>
                <div className={'mb-6'}>
                    <Form.Item name={'softInput'} label={'Soft Skills'}>
                        <Input
                            suffix={
                                <Button onClick={() => addToSkills(value, 'soft')} type={'text'}>Add</Button>
                            }
                            size={'large'}
                            type="text"
                            id={'softInput'}
                            onPressEnter={(event) => {
                                addTags(event.target.value, 'soft')
                            }}
                            onChange={(e) => setValue(e.target.value)}
                            placeholder="Eg Communication Skills"
                        />
                    </Form.Item>
                    <div className={'flex flex-wrap gap-2'}>
                        {softSkills.map((tag, index) => (
                            <SkillItem key={index} title={tag} onClick={() => removeTags(index, 'soft')}/>
                        ))}
                    </div>
                </div>
                <div className={'mb-[43px]'}>
                    <Form.Item name={'techInput'} label={'Technical Skills'}>
                        <Input
                            suffix={
                                <Button onClick={() => addToSkills(value, 'tech')} type={'text'}>Add</Button>
                            }
                            size={'large'}
                            type="text"
                            id={'techInput'}
                            onPressEnter={(event) => {
                                addTags(event.target.value, 'tech')
                            }}
                            onChange={(e) => setValue(e.target.value)}
                            placeholder="Eg Communication Skills"
                        />
                    </Form.Item>
                    <div className={'flex flex-wrap gap-2'}>
                        {technicalSkills.map((tag, index) => (
                            <SkillItem key={index} title={tag} onClick={() => removeTags(index, 'tech')}/>
                        ))}
                    </div>
                </div>
            </Form>
        </Spin>
    )
}

TagsInput.propTypes = {
    softSkills: PropTypes.array,
    setSoftSkills: PropTypes.func,
    technicalSkills: PropTypes.array,
    setTechnicalSkills: PropTypes.func
}

export default TagsInput
