import { Path, Svg, G, Defs, ClipPath, Rect } from '@react-pdf/renderer'
import React from 'react'
import PropTypes from 'prop-types'

export const Gitlab = ({ color, download }) => (
    download
        ? <Svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill={color}>
            <G clipPath="url(#clip0_2980_22280)">
                <Path
                    d="M10.7289 7.19461L5.68419 11.0646L0.639452 7.19461C0.571822 7.14211 0.521656 7.06838 0.496132 6.98396C0.470607 6.89954 0.471033 6.80876 0.497347 6.72461L1.07524 4.83461L2.23103 1.07961C2.24224 1.04902 2.26009 1.02162 2.28314 0.999614C2.32067 0.963425 2.36967 0.943359 2.4205 0.943359C2.47134 0.943359 2.52034 0.963425 2.55787 0.999614C2.58221 1.02445 2.60015 1.05542 2.60998 1.08961L3.76577 4.83461H7.60261L8.7584 1.07961C8.76961 1.04902 8.78745 1.02162 8.8105 0.999614C8.84804 0.963425 8.89704 0.943359 8.94787 0.943359C8.99871 0.943359 9.04771 0.963425 9.08524 0.999614C9.10958 1.02445 9.12751 1.05542 9.13735 1.08961L10.2931 4.84461L10.8947 6.72461C10.9186 6.81136 10.9154 6.90393 10.8856 6.9886C10.8557 7.07327 10.8008 7.14552 10.7289 7.19461V7.19461Z"
                    stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
            </G>
            <Defs>
                <ClipPath id="clip0_2980_22280">
                    <Rect width="11.3684" height="12" fill="white"/>
                </ClipPath>
            </Defs>
        </Svg>
        : <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12" fill={color}>
            <g clipPath="url(#clip0_2980_22280)">
                <path
                    d="M10.7289 7.19461L5.68419 11.0646L0.639452 7.19461C0.571822 7.14211 0.521656 7.06838 0.496132 6.98396C0.470607 6.89954 0.471033 6.80876 0.497347 6.72461L1.07524 4.83461L2.23103 1.07961C2.24224 1.04902 2.26009 1.02162 2.28314 0.999614C2.32067 0.963425 2.36967 0.943359 2.4205 0.943359C2.47134 0.943359 2.52034 0.963425 2.55787 0.999614C2.58221 1.02445 2.60015 1.05542 2.60998 1.08961L3.76577 4.83461H7.60261L8.7584 1.07961C8.76961 1.04902 8.78745 1.02162 8.8105 0.999614C8.84804 0.963425 8.89704 0.943359 8.94787 0.943359C8.99871 0.943359 9.04771 0.963425 9.08524 0.999614C9.10958 1.02445 9.12751 1.05542 9.13735 1.08961L10.2931 4.84461L10.8947 6.72461C10.9186 6.81136 10.9154 6.90393 10.8856 6.9886C10.8557 7.07327 10.8008 7.14552 10.7289 7.19461V7.19461Z"
                    stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
            </g>
            <defs>
                <clipPath id="clip0_2980_22280">
                    <rect width="11.3684" height="12" fill="white"/>
                </clipPath>
            </defs>
        </svg>
)

Gitlab.defaultProps = {
    color: 'currentColor',
    download: false
}

Gitlab.propTypes = {
    color: PropTypes.string,
    download: PropTypes.bool
}
