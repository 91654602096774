import React from 'react'
import { Form, Upload } from 'antd'
import TlaIcon from '../../../components/tla-icon'
import Title from './title'
import PropTypes from 'prop-types'

const { Dragger } = Upload

function FirstResume ({ uploadProps }) {
    return (
        <div>
            <Title text={'Last we need your resume'}/>
            <Form.Item rules={[
                {
                    required: true,
                    message: 'Resume is required'
                }
            ]} className={'w-[282px]'} name={'resume'}>
                <Dragger style={{
                    background: 'white',
                    paddingTop: 30,
                    paddingBottom: 30,
                    paddingLeft: 50,
                    paddingRight: 50,
                    minHeight: 100,
                    border: 'solid black 1px'
                }} {...uploadProps}>
                    <div className={'flex items-center justify-center gap-x-2.5'}>
                        <TlaIcon name={'upload-1'}/>
                        <p className="text-base font-normal">Upload Resume</p>
                    </div>
                </Dragger>
            </Form.Item>
        </div>
    )
}

FirstResume.propTypes = {
    uploadProps: PropTypes.object
}

export default FirstResume
