import React, { useEffect, useState } from 'react'
import SectionHeader from '../../components/section-header'
import TlaAddNew from '../../components/pop-ups/tla-add-new'
import { Button, Spin } from 'antd'
import { FiPlus } from 'react-icons/fi'
import { JobCard } from '../../components/job-card'
import PropTypes from 'prop-types'
import { handleDeleteExperience, handleGetAllExperiences } from '../../actions/experience/Action'
import { connect } from 'react-redux'
import { formatDate } from '../../utils'
import { BsDot } from 'react-icons/bs'
import { TlaSuccess } from '../../utils/messages'

const Experience = ({ userId, getExperiences, deleteExperience, experiences }) => {
  const { data } = experiences

  const [loading, setLoading] = useState(true)
  useEffect(() => {
    getExperiences(userId).then(() => setLoading(false))
  }, [])
  return (
        <div className={'pb-4 rounded-lg py-[29px] px-3 md:px-[28px]'}>
            <div className={'mb-11'}>
                <SectionHeader title={'Experience'}>
                    <TlaAddNew link={'form'}>
                        <Button className={'btn btn-rounded'} size={'large'} icon={<FiPlus/>}>
                            Add Experience
                        </Button>
                    </TlaAddNew>
                </SectionHeader>
            </div>
            <Spin spinning={loading}>
                {
                    data.map((exp) => (
                        <JobCard
                            key={exp.id}
                            deleteItem={() => deleteExperience(exp.id).then(() => TlaSuccess())}
                            editData={exp}
                            deleteTitle={'Experience'}>
                            <div>
                                <h4 className={'text-gray-900 text-base font-medium'}>
                                    {exp.jobTitle}
                                </h4>
                                <p className={'text-sm text-gray-500'}>
                                    <span className={'font-semibold'}>{exp.companyName}</span>
                                    &nbsp;&nbsp;
                                </p>
                                <ol className={'text-sm text-gray-500 mt-4'}>
                                    {
                                        exp.description.map((desc, index) => (
                                            <li className={'flex items-center'} key={index}><BsDot size={20}/>{desc}</li>
                                        ))
                                    }
                                </ol>
                                <p className={'mt-4 text-sm font-normal text-gray-500'}>
                                    {formatDate(exp.jobDurationStart)} – {formatDate(exp.jobDurationEnd) ?? 'Present'}
                                </p>
                            </div>
                        </JobCard>
                    ))
                }
            </Spin>
        </div>
  )
}

Experience.propTypes = {
  getExperiences: PropTypes.func.isRequired,
  deleteExperience: PropTypes.func.isRequired,
  experiences: PropTypes.object.isRequired,
  userId: PropTypes.any.isRequired
}

const mapStateToProps = (state) => ({
  userId: state.loginReducer.authUser.id,
  experiences: state.experiencesReducer.experiences
})

const mapDispatchToProps = (dispatch) => ({
  getExperiences: (userId) => dispatch(handleGetAllExperiences(userId)),
  deleteExperience: (id) => dispatch(handleDeleteExperience(id))
})

export default connect(mapStateToProps, mapDispatchToProps)(Experience)
