import React, { useState } from 'react'
import { Form } from 'antd'
import { useNavigate } from 'react-router-dom'
import { TlaModal } from './pop-ups/tla-modal'
import PropTypes from 'prop-types'
import { TlaError, TlaSuccess } from '../utils/messages'
import { connect } from 'react-redux'
import dayjs from 'dayjs'
import { getErrors } from '../utils'

function TlaModalFormWrapper (props) {
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()
    const [form] = Form.useForm()
    // eslint-disable-next-line no-unused-vars
    const { onSubmit, initialValues, formTitle, children, file, width, userId, resourceId, customForm } = props

    // eslint-disable-next-line no-unused-vars
    const dateFields = [
        'studyDurationStart',
        'studyDurationEnd',
        'jobDurationStart',
        'jobDurationEnd'
    ]

    const submit = (values) => {
        setLoading(true)
        // eslint-disable-next-line no-prototype-builtins
        values.hasOwnProperty('present') && delete values.present
        // values.userId = userId
        for (const key in values) {
            if (dateFields.includes(key)) {
                if (values[key] === null) {
                    values[key] = ''
                } else {
                    values[key] = dayjs(values[key]).format('YYYY-MM-DD')
                }
            }
        }

        onSubmit(resourceId ?? userId, values).then(() => {
            setLoading(false)
            TlaSuccess()
            customForm ? customForm.resetFields() : form.resetFields()
            navigate(-1)
        }).catch((error) => {
            setLoading(false)
            TlaError(error.response.data.message ?? getErrors(error.response.data?.errors))
        })
    }
    return (
        <TlaModal loading={loading} title={formTitle} width={width}>
            <Form
                requiredMark={false}
                form={customForm ?? form}
                onFinish={(values) => {
                    submit(values)
                }}
                layout="vertical"
                name="formName"
                initialValues={initialValues}>
                {children}
            </Form>
        </TlaModal>
    )
}

TlaModalFormWrapper.defaultProps = {
    file: null,
    customForm: null,
    resourceId: null,
    width: 520
}

TlaModalFormWrapper.propTypes = {
    initialValues: PropTypes.object,
    submitValues: PropTypes.object,
    formTitle: PropTypes.string,
    onSubmit: PropTypes.func,
    file: PropTypes.any,
    width: PropTypes.any,
    children: PropTypes.any,
    userId: PropTypes.any,
    resourceId: PropTypes.number,
    customForm: PropTypes.any
}

const mapStateToProps = (state) => ({
    userId: state.loginReducer.authUser.id
})

export default connect(mapStateToProps)(TlaModalFormWrapper)
