import React, { useEffect, useState } from 'react'
import { Button, Form, Input, Select, Spin } from 'antd'
import { connect, useSelector } from 'react-redux'
import { handleDownloadResume } from '../../../../actions/apply10x/Action'
import PropTypes from 'prop-types'
import { useLocation, useNavigate } from 'react-router-dom'
import { TlaError, TlaSuccess } from '../../../../utils/messages'
import { handleGetAllResumes } from '../../../../actions/build-resume/Action'
import { formatResume, getErrors } from '../../../../utils'
import DownloadResume from './download-resume'

function Resume ({ downloadResume, getResumes, userId }) {
    const [form] = Form.useForm()
    const [loading, setLoading] = useState(false)
    const [readyForDownload, setReadyForDownload] = useState(false)
    const navigate = useNavigate()
    const location = useLocation()
    const resumes = useSelector(state => state.buildResumeReducer.resumes)
    const [loadResume, setLoadResume] = useState(true)
    // eslint-disable-next-line no-unused-vars
    const [selected, setSelected] = useState({ template: 'castform', photo: 'no' })
    const [content, setContent] = useState({})

    useEffect(() => {
        getResumes(userId)
            .then((res) => {
                setLoadResume(false)
            })
            .catch(() => setLoadResume(false))
    }, [])

    const submit = (values) => {
        setLoading(true)
        setReadyForDownload(false)
        navigate('/resume/success', { state: { background: location } })
        downloadResume(values).then((res) => {
            const data = res.data.data
            setContent(formatResume(data.user))
            TlaSuccess()
            setLoading(false)
            setReadyForDownload(true)
            navigate(-1)
        }).catch((error) => {
            TlaError(error.response.data.message ?? getErrors(error.response.data?.errors))
            setLoading(false)
            setReadyForDownload(false)
            navigate(-1)
        })
    }
    return (
        <>
            <Spin spinning={ loading }>
                <div className={ 'w-full md:w-[764px]' }>
                    <Form requiredMark={ false }
                          form={ form }
                          onFinish={ submit }
                          initialValues={ { userId: userId.toString() } }
                          layout={ 'vertical' }>
                        <Form.Item
                            hidden
                            name={ 'userId' }
                            rules={ [
                                {
                                    required: true
                                }
                            ] }
                            label={ 'Job Description' }>
                            <Input className={ 'text-base' }></Input>
                        </Form.Item>
                        <Form.Item
                            name={ 'description' }
                            rules={ [
                                {
                                    required: true,
                                    message: 'Required'
                                },
                                {
                                    min: 200,
                                    message: 'Must at least 200 characters.'
                                },
                                {
                                    max: 5000,
                                    message: 'Maximum should be 2000 characters.'
                                }
                            ] }
                            label={ 'Job Description' }>
                            <Input.TextArea
                                rows={ 5 } placeholder={ 'Paste your job description' }
                                className={ 'text-base' }></Input.TextArea>
                        </Form.Item>

                        <div className={ 'flex justify-between items-center' }>
                            <Form.Item
                                name={ 'tempId' }
                                rules={ [
                                    {
                                        required: true,
                                        message: 'Required'
                                    }
                                ] }
                                className={ 'min-w-[320px]' }
                                label={ 'Select Resume Template*' }>
                                <Select loading={ loadResume } placeholder={ 'Select Template' }>
                                    {
                                        resumes?.data?.map(({ id, title }) => (
                                            <Select.Option key={ id } value={ id.toString() }>{ title }</Select.Option>
                                        ))
                                    }
                                    <Select.Option value={ '-1' }>Default</Select.Option>
                                </Select>
                            </Form.Item>
                            <Form.Item>
                                <div className={ 'flex gap-3 justify-end' }>
                                    <Button className={ 'btn bg-white-base' } size={ 'large' }
                                            onClick={ () => form.resetFields() }
                                            htmlType={ 'button' }>Reset</Button>
                                    <Button className={ 'btn btn-primary-700' } size={ 'large' }
                                            htmlType={ 'submit' }>Create Now</Button>
                                </div>
                            </Form.Item>
                        </div>
                    </Form>
                </div>
            </Spin>
            { readyForDownload
                ? <DownloadResume
                    content={ content } photo={ '' } hiddenSections={ { skillTags: 'skillTags' } }
                    showPhoto={ selected?.photo }
                    template={ selected?.template ?? 'castform' }
                />
                : '' }
            {/* {readyForDownload ? <DownloadResume callBack={setReadyForDownload}/> : ''} */ }
        </>
    )
}

Resume.propTypes = {
    downloadResume: PropTypes.func.isRequired,
    getResumes: PropTypes.func.isRequired,
    userId: PropTypes.number.isRequired
}

const mapStateToProps = (state) => ({
    userId: state.loginReducer.authUser.id
})

const mapDispatchToProps = (dispatch) => ({
    downloadResume: (data) => dispatch(handleDownloadResume(data)),
    getResumes: (userId) => dispatch(handleGetAllResumes(userId, 1, 100))
})

export default connect(mapStateToProps, mapDispatchToProps)(Resume)
