import React from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import TlaAddNew from '../../components/pop-ups/tla-add-new'

function AuthBottomLink ({ text, linkText, link, size, color, modal }) {
    return (
        <div className={ 'text-center mt-8' }>
            <p className={ `text-${size} text-gray-500 flex justify-center items-center` }>
                <span>{ text }</span>&nbsp;
                {
                    modal
                        ? <TlaAddNew link={ link }>{ linkText }</TlaAddNew>
                        : <Link className={ `text-${color}` } to={ link }>{ linkText }</Link>
                }
            </p>
        </div>
    )
}

AuthBottomLink.defaultProps = {
    size: 'base',
    color: 'primary-700',
    modal: false
}

AuthBottomLink.propTypes = {
    text: PropTypes.any,
    linkText: PropTypes.string,
    size: PropTypes.string,
    color: PropTypes.string,
    modal: PropTypes.bool,
    link: PropTypes.string
}

export default AuthBottomLink
