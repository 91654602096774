import React from 'react'

function VerifyEmail () {
    return (
        <div className={'w-[494px]'}>
            <p className={'text-primary-600 text-xl font-normal'}>AUTOMATE YOUR JOB APPLICATION</p>
            <h1 className={'text-[34px] text-primary-600 font-bold mb-[72px]'}>
                Verify your Email
            </h1>
            <div className={'w-[474px]'}>
                <p className={'text-[26px] font-normal mb-6 leading-[29px]'}>
                    We have sent an email. Please verify your account and start AutoApplying.
                </p>
                <p className={'text-[20px] font-normal leading-[29px]'}>
                    AutoApply to 5 jobs for free. <br/>
                    <span className={'underline'}>
                        <a href="https://autoapply.jobs/pricing/"
                           className={'hover:text-gray-900'} target={'_blank'}
                           rel="noreferrer">See our packages</a></span> for AutoApply up to 500 jobs
                </p>
            </div>
        </div>
    )
}

export default VerifyEmail
