import React from 'react'
import PropTypes from 'prop-types'

function WidgetItem ({ title, total, other, border }) {
    return (
        <div className={`px-6 py-2.5 w-full ${border ? 'border pl-6' : ''} rounded bg-white-base`}>
            <h6 className={'text-gray-500 text-sm mb-2'}>{title}</h6>
            <h1 className={'text-gray-900 font-semibold text-3xl flex items-center flex-wrap gap-x-4'}>
                {total}
                {
                    other &&
                    <span
                        className={'text-xs md:text-sm text-success-700 font-medium bg-success-50 px-2.5 py-1 rounded-full'}>
                    {other}
                </span>
                }
            </h1>
        </div>
    )
}

WidgetItem.defaultProps = {
    border: false
}

WidgetItem.propTypes = {
    title: PropTypes.string,
    border: PropTypes.bool,
    other: PropTypes.any,
    total: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ])
}

export default WidgetItem
