import { Types } from '../actions/apply10x/Types'

const initialState = {
    resume: {
        contactInfo: {},
        skills: [],
        certificates: [],
        educations: [],
        experiences: []
    },
    coverLetter: {
        contactInfo: {},
        coverLetter: []
    }
}

export default function apply10xReducer (state = initialState, action) {
    switch (action.type) {
        case Types.DOWNLOAD_RESUME:
            return {
                ...state, resume: action.payload.data
            }

        case Types.DOWNLOAD_COVER_LETTER:
            return {
                ...state,
                coverLetter: {
                    ...action.payload.data, coverLetter: JSON.parse(action.payload.data.coverLetter)
                }
            }

        default:
            return state
    }
}
