import React, { useState } from 'react'
import { Button, Form, Spin, Upload } from 'antd'
import { FiChevronsLeft, FiChevronsRight } from 'react-icons/fi'
import JobTitle from './job-title'
import ExperienceLevel from './experience-level'
import FirstResume from './first-resume'
import { TlaError, TlaSuccess } from '../../../utils/messages'
import { handleAddJobQuery } from '../../../actions/job-queries/Actions'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { useNavigate } from 'react-router-dom'
import { allowedResumeFileTypes } from '../../../utils'
import Title from './title'
import LocationSearch from '../../../components/places-api/location-search'

function FirstTimeUser ({ addJobQuery }) {
    const [form] = Form.useForm()
    const [selectedFile, setSelectedFile] = useState(null)
    const [loading, setLoading] = useState(false)
    const [current, setCurrent] = useState(0)
    const next = () => {
        form.validateFields().then(() => {
            setCurrent(current + 1)
        })
    }
    const prev = () => {
        setCurrent(current - 1)
    }

    const uploadProps = {
        accept: allowedResumeFileTypes,
        name: 'resume',
        multiple: false,
        maxCount: 1,
        method: 'get',
        beforeUpload: (info) => {
            const isPDF = allowedResumeFileTypes.includes(info.type)
            if (!isPDF) {
                TlaError('Only PDF and word documents are allowed')
            } else {
                setSelectedFile(info)
            }
            return isPDF || Upload.LIST_IGNORE
        },
        onDrop (e) {
            // console.log('Dropped files', e.dataTransfer.files)
        }
    }

    const navigate = useNavigate()
    const onSubmit = (values) => {
        setLoading(true)
        form.validateFields().then(() => {
            if (selectedFile === null) {
                TlaError('Resume is required')
                setLoading(false)
                return
            }

            values.resume = selectedFile
            values.remoteOnSite = ['']
            values.jobType = ['']
            addJobQuery({ ...form.getFieldsValue(['title', 'location', 'experienceLevel']), ...values })
                .then(() => {
                    navigate('/home')
                    setLoading(false)
                    TlaSuccess()
                    form.resetFields()
                }).catch((error) => {
                setLoading(false)
                TlaError(error.response.data.message)
            })
        }).catch(() => {
            setLoading(false)
        })
    }

    const items = [
        <JobTitle key={'title'}/>,
        <div key={'experienceLevel'}>
            <Title text={'Your experience level'}/>
            <ExperienceLevel/>
        </div>,
        <div key={'location'}>
            <Title text={ 'Where are you looking for a job' }/>
            <LocationSearch classes={'first-time-input'} form={form} initialValue={form.getFieldValue('location')}/>
        </div>,
        <FirstResume key={'resume'} uploadProps={uploadProps}/>
    ]
    return (
        <div className={'pb-5 pl-12'}>
            <h3 className={'text-[38px] mt-[50px] mb-[87px]'}>Let the magic begin...</h3>
            <Spin spinning={loading}>
                <Form form={form} onFinish={onSubmit} initialValues={{ experienceLevel: [''] }}>
                    <div className={'mb-[66px]'}>
                        {items[current]}
                    </div>

                    <div className={'ml-auto mr-[300px] gap-x-[11px] flex justify-end'}>
                        {current > 0 && (
                            <span className={'flex items-end text-base cursor-pointer font-weight pb-2'}
                                  onClick={() => {
                                      prev()
                                  }}>
                            <FiChevronsLeft size={24}/> Previous
                        </span>
                        )}
                        {current < items.length - 1 && (
                            <span className={'btn-next'}
                                  onClick={() => {
                                      next()
                                  }}> Next <FiChevronsRight size={24}/></span>
                        )}

                        {current === items.length - 1 && (
                            <Button
                                htmlType={'submit'}
                                className={'rounded-full bg-primary-600 h-[46px] text-base font-medium text-white-base w-[98px]'}>
                                Save
                            </Button>
                        )}
                    </div>
                </Form>
            </Spin>
        </div>
    )
}

FirstTimeUser.propTypes = {
    addJobQuery: PropTypes.func.isRequired
}
const mapDispatchToProps = (dispatch) => ({
    addJobQuery: (data) => dispatch(handleAddJobQuery(data))
})

export default connect(null, mapDispatchToProps)(FirstTimeUser)
