import React from 'react'
import { Outlet, Route, Routes, useLocation } from 'react-router-dom'
import SignIn from '../../pages/auth/sign-in'
import ForgotPassword from '../../pages/auth/forgot-password'
import ResetPassword from '../../pages/auth/reset-password'
import VerificationMessage from '../../pages/auth/verification-message'
import VerifyEmail from '../../pages/auth/verify-email'
import ResetPasswordSuccess from '../../pages/auth/reset-password-success'
import BuildResume from '../../pages/resume-builder'
import Iframe from '../../pages/iframe'
import SignUp from '../../pages/auth/sign-up/sign-up'
import Verify from '../../pages/auth/sign-up/verify'
import Manual from '../../pages/auth/sign-up/verify/manual'
import Verified from '../../pages/auth/sign-up/verify/verified'
import { PublicModalRoute } from './public-modal-route'

export const PublicRoutes = () => {
    const location = useLocation()
    const background = location.state && location.state.background
    return (
        <>
            <Routes location={ background || location }>
                <Route exact element={ <Iframe/> } path="/auto-apply"/>
                <Route exact element={ <SignIn/> } path="/"/>
                <Route exact element={ <SignIn/> } path="/sign-in"/>
                <Route element={ <SignUp/> } path={ '/sign-up' }/>
                <Route element={ <VerificationMessage/> } path={ '/verify-email-message' }/>
                <Route element={ <VerifyEmail/> } path={ '/verifyemail/:token' }/>
                <Route element={ <Verify/> } path={ '/verify' }/>
                <Route element={ <Manual/> } path={ '/verify/manual' }/>
                <Route element={ <Verified/> } path={ '/verify/verified' }/>
                <Route element={ <ForgotPassword/> } path={ '/forgot-password' }/>
                <Route element={ <ResetPassword/> } path={ '/resetpassword/:token' }/>
                <Route element={ <ResetPasswordSuccess/> } path={ '/reset-password-success' }/>
                <Route path={ '*' } element={ <SignIn/> }/>
                <Route path="resume/build" index element={ <BuildResume/> }/>
            </Routes>
            { background && (<><PublicModalRoute/> <Outlet/></>) }
        </>
    )
}
