import api from '../../utils/api'
import { getGraphData, getStatistics, getSubscriptionTiers, getUserPackages } from './ActionCreators'

/**
 * @param userId
 * @returns {function(*): Promise<unknown>}
 */
export const handleGetStatistics = (userId) => (dispatch) => {
  return new Promise((resolve, reject) => {
    return api().get(`/job-application/db-stats/${userId}`)
      .then((response) => {
        dispatch(getStatistics(response.data))
        resolve(response)
      }).catch((err) => {
        reject(err)
      })
  })
}
/**
 * @param userId
 * @returns {function(*): Promise<unknown>}
 */
export const handleGetGraphData = (userId) => (dispatch) => {
  return new Promise((resolve, reject) => {
    return api().get(`/job-application/job-graph/${userId}`)
      .then((response) => {
        dispatch(getGraphData(response.data))
        resolve(response)
      }).catch((err) => {
        reject(err)
      })
  })
}

export const handleGetSubscriptionTiers = (userId) => (dispatch) => {
  return new Promise((resolve, reject) => {
    return api().get(`/users/userSubscription/${userId}`)
      .then((response) => {
        dispatch(getSubscriptionTiers(response.data))
        resolve(response)
      }).catch((err) => {
        reject(err)
      })
  })
}

export const handleGetUserPackages = (userId) => (dispatch) => {
  return new Promise((resolve, reject) => {
    return api().get(`/user-packages/${userId}`)
      .then((response) => {
        dispatch(getUserPackages(response.data))
        resolve(response)
      }).catch((err) => {
        reject(err)
      })
  })
}

export const handleUpdateAutoApplyStatus = () => (dispatch) => {
  return new Promise((resolve, reject) => {
    return api().put('/users/activate-autoapply')
      .then((response) => {
        resolve(response.data)
      }).catch((err) => {
        reject(err)
      })
  })
}
