import React from 'react'
import { Button, DatePicker, Form, Input } from 'antd'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom'
import TlaModalFormWrapper from '../../components/tla-modal-form-wrapper'
import CloseModal from '../../components/close-modal'
import { FiCalendar } from 'react-icons/fi'
import { handleAddEducation, handleUpdateEducation } from '../../actions/education/Action'
import { formDate } from '../../utils'

function EducationForm ({ addEducation, updateEducation }) {
  const { state } = useLocation()

  const formValues = {
    id: 0,
    ...state?.data,
    studyDurationStart: state?.data ? formDate(state?.data.studyDurationStart) : null,
    studyDurationEnd: state?.data ? formDate(state?.data.studyDurationEnd) : null
  }

  return (
        <TlaModalFormWrapper
            resourceId={state?.data?.id ?? null}
            initialValues={formValues}
            formTitle={'Add Education'}
            onSubmit={state?.data?.id ? updateEducation : addEducation}>
            <Form.Item
                name={'school'}
                rules={[
                  {
                    required: true,
                    message: 'Required'
                  }
                ]}
                label={'School'}>
                <Input size={'large'} placeholder={'Eg NY University'}/>
            </Form.Item>
            <Form.Item
                name={'degreeFieldOfStudy'}
                rules={[
                  {
                    required: true,
                    message: 'Required'
                  }
                ]}
                label={'Degree & Field of study*'}>
                <Input size={'large'} placeholder={'Eg Bachelor in Computer Science'}/>
            </Form.Item>
            <Form.Item
                rules={[
                    {
                        required: true,
                        message: 'Required'
                    }
                ]}
                name={'city'}
                label={'City, Country'}>
                <Input size={'large'} placeholder={'Eg New York, USA'}/>
            </Form.Item>
            <div className={'flex mb-[37px] gap-x-[7px]'}>
                <Form.Item label={'Study Duration'} name={'studyDurationStart'}>
                    <DatePicker
                        className={'w-[160px]'}
                        format={'YYYY-MM-DD'}
                        size={'large'} placeholder={'Start date'}
                        suffixIcon={<FiCalendar className={'text-gray-700'}/>}/>
                </Form.Item>
                <Form.Item label={<>&nbsp;</>} name={'studyDurationEnd'}>
                    <DatePicker
                        className={'w-[160px]'}
                        format={'YYYY-MM-DD'}
                        size={'large'} placeholder={'Start date'}
                        suffixIcon={<FiCalendar className={'text-gray-700'}/>}/>
                </Form.Item>
            </div>
            <div className={'flex gap-2'}>
                <div className={'w-full'}>
                    <CloseModal height={'!h-11'}/>
                </div>
                <Form.Item className={'w-full'}>
                    <Button className={'btn btn-primary w-full !h-11'} size={'large'} htmlType="submit">
                        Save
                    </Button>
                </Form.Item>
            </div>
        </TlaModalFormWrapper>
  )
}

EducationForm.propTypes = {
  addEducation: PropTypes.func.isRequired,
  updateEducation: PropTypes.func.isRequired
}

/**
 *
 * @param dispatch
 * @returns {{addEducation: (function(*, *): *)}}
 */
const mapDispatchToProps = (dispatch) => ({
  addEducation: (userId, data) => dispatch(handleAddEducation(userId, data)),
  updateEducation: (eduId, data) => dispatch(handleUpdateEducation(eduId, data))
})

export default connect(null, mapDispatchToProps)(EducationForm)
