import React, { useEffect, useState } from 'react'
import Extensions from './index'
import { connect, useSelector } from 'react-redux'
import { handleGetUserPackages } from '../../../../../actions/dashboard/Actions'
import { Spin } from 'antd'
import PropTypes from 'prop-types'
import TlaAddNew from '../../../../../components/pop-ups/tla-add-new'
import autoApply from '../../../../../utils/routes/auto-apply'

function SubscribeNow ({ getPackages }) {
    const [loading, setLoading] = useState(true)
    const userId = useSelector(state => state.loginReducer.authUser.id)
    const { subscriptionPackage } = useSelector(state => state.dashboardReducer.userPackage)

    useEffect(() => {
        getPackages(userId).then(() => setLoading(false)).catch(() => setLoading(false))
    }, [])

    return (
        <Spin spinning={loading}>
            {
                subscriptionPackage?.subscriptionName === 'free'
                    ? <Extensions modal link={autoApply.subscription}
                        title={'Be a Premium Member'}
                        text={'Use premium AI models, multiple templates, 100 resumes & cover letters.'}
                        btnText={'Subscribe Now'}
                    />
                    : <TlaAddNew link={'/job-queries/form'}>
                        <span className={'text-base font-medium px-3.5 h-[46px] rounded-full bg-primary-600 text-white-base flex items-center justify-center mx-auto'}>Create Job Role</span>
                    </TlaAddNew>
            }

        </Spin>
    )
}

SubscribeNow.propTypes = {
    getPackages: PropTypes.func.isRequired
}

const mapDispatchToProps = (dispatch) => ({
    getPackages: (userId) => dispatch(handleGetUserPackages(userId))
})

export default connect(null, mapDispatchToProps)(SubscribeNow)
