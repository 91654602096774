import { Types } from '../actions/dashboard/Types'

const initialState = {
  statistics: {},
  jobsApplied: {},
  subscription: {},
  userPackage: {}
}

/**
 *
 * @param state
 * @param action
 * @returns {{statistics}|{statistics: {}}}
 */
export default function dashboardReducer (state = initialState, action) {
  switch (action.type) {
    case Types.GET_STATISTICS: {
      return {
        ...state,
        statistics: action.payload.data
      }
    }

    case Types.GET_USER_PACKAGES: {
      return {
        ...state,
        userPackage: action.payload.data.userSubscriptionPackage
      }
    }

    case Types.GET_GRAPH_DATA: {
      return {
        ...state,
        jobsApplied: action.payload.data
      }
    }

    case Types.GET_SUBSCRIPTION_TIERS: {
      return {
        ...state,
        subscription: action.payload?.data?.userSubscription
      }
    }

    default:
      return state
  }
}
