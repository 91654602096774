import PropTypes from 'prop-types'

const Validate = ({ render, children }) => {
    return (render && children)
}

Validate.propTypes = {
    render: PropTypes.bool,
    children: PropTypes.node
}

export default Validate
