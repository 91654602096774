import React from 'react'
import PropTypes from 'prop-types'

function DashboardWidgetItem ({ title, total, other, color, bg }) {
    return (
        <div className={`p-5 w-full h-[163px] flex flex-col justify-between rounded-lg bg-${bg}`}>
            <h6 className={`text-${color} font-medium text-sm mb-2`}>{title}</h6>
            <div className={'flex items-end flex-wrap gap-x-2'}>
                <h1 className={`text-${color} font-semibold text-[38px] leading-9`}>
                    {total}
                </h1>
                {
                    other &&
                    <span className={`text-[13px] text-${color} font-normal`}>{other}</span>
                }
            </div>
        </div>
    )
}

DashboardWidgetItem.defaultProps = {
    color: 'gray-700',
    bg: 'primary-600'
}

DashboardWidgetItem.propTypes = {
    title: PropTypes.string,
    bg: PropTypes.string,
    color: PropTypes.string,
    other: PropTypes.any,
    total: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ])
}

export default DashboardWidgetItem
