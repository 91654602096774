import React, { useState } from 'react'
import { Button } from 'antd'
import { FiChevronLeft, FiEdit3 } from 'react-icons/fi'
import { connect, useSelector } from 'react-redux'
import { PDFDownloadLink } from '@react-pdf/renderer'
import { Link } from 'react-router-dom'
import TlaAddNew from '../../../components/pop-ups/tla-add-new'
import { makeId } from '../../../utils'
import DownloadPlainWhite from '../templates/plain-white/download/download-plain-white'
import { handleSaveResume, handleUpdateResume } from '../../../actions/build-resume/Action'
import PropTypes from 'prop-types'
import { TlaError, TlaSuccess } from '../../../utils/messages'
import DownloadCastForm from '../templates/castform/download/download-cast-form'
import DownloadSimple from '../templates/simple/download/download-simple'

function ResumeHeader ({ saveResume, updateResume }) {
    const { id, name, content, shortId, slug, metadata } = useSelector(state => state.buildResumeReducer.currentResume)

    const currentTemplate = metadata?.template

    const hiddenSections = useSelector(state => state.buildResumeReducer.hiddenItems)
    // const draft = useSelector(state => state.buildResumeReducer.draft)
    const userId = useSelector(state => state.loginReducer.authUser.id)
    const [saving, setSaving] = useState(false)

    const handleSave = () => {
        setSaving(true)
        const data = {
            title: name,
            shortId,
            slug,
            name,
            resumeContent: JSON.stringify(content),
            basics: '',
            sections: '',
            metadata: JSON.stringify(metadata)
        };

        (id ? updateResume(id, data) : saveResume(userId, data)).then(() => {
            setSaving(false)
            TlaSuccess(`Resume ${id ? 'Updated' : 'Saved'} Successfully`)
        }).catch((err) => {
            setSaving(false)
            TlaError(err.response?.data?.message)
        })
    }
    const photo = useSelector(state => state.loginReducer?.authUser?.userInformation?.photo)

    const templates = {
        autoapply: <DownloadPlainWhite resume={content} hiddenSections={hiddenSections}/>,
        castform: <DownloadCastForm showPhoto={metadata?.photo} photo={photo} resume={content} hiddenSections={hiddenSections}/>,
        simple: <DownloadSimple photo={photo} resume={content} hiddenSections={hiddenSections}/>
    }

    return (
        <div className={'bg-white-base h-14 flex items-center justify-between px-12 border-t-2'}>
            <div className={'flex gap-x-[9px] items-center'}>
                <Link to={'/cv-builder/resume-template'} className={'flex gap-x-[9px] items-center'}>
                    <FiChevronLeft size={16} className={'text-gray-300'}/>
                    <h3 className={'text-gray-600 text-base font-medium'}>{name}</h3>
                </Link>
                <TlaAddNew link={'/cv-builder/resume-template/form'} data={{ name }}>
                    <FiEdit3 className={'text-gray-400 cursor-pointer'} title={'Edit Title'}/>
                </TlaAddNew>
            </div>
            <div className={'flex gap-x-4 items-center'}>
                <PDFDownloadLink
                    document={templates[currentTemplate]}
                    fileName={`${name + '_' + makeId(8)}.pdf`}>
                    {({ blob, url, loading, error }) =>
                        <Button loading={loading} className={'btn btn-primary-light-50'}>Download</Button>
                    }
                </PDFDownloadLink>
                <Button loading={saving} onClick={handleSave}
                        className={'btn btn-primary-500'}>{`${id ? 'Update' : 'Save'} Resume`}</Button>
                <TlaAddNew link={'/cv-builder/build-resume/template'}>
                    <Button className={'btn btn-primary-700'}>Templates</Button>
                </TlaAddNew>
            </div>
        </div>
    )
}

ResumeHeader.propTypes = {
    saveResume: PropTypes.func,
    updateResume: PropTypes.func
}

/**
 *
 * @param dispatch
 * @returns {{addJobQuery: (function(*): *)}}
 */
const mapDispatchToProps = (dispatch) => ({
    saveResume: (userId, data) => dispatch(handleSaveResume(userId, data)),
    updateResume: (resumeId, data) => dispatch(handleUpdateResume(resumeId, data))
})

export default connect(null, mapDispatchToProps)(ResumeHeader)
