import React, { useState } from 'react'
import { Button, Col, Form, Input, Row, Spin } from 'antd'
import { connect } from 'react-redux'
import { handleDownloadCoverLetter } from '../../../../actions/apply10x/Action'
import PropTypes from 'prop-types'
import { useLocation, useNavigate } from 'react-router-dom'
import { TlaError, TlaSuccess } from '../../../../utils/messages'
import DownloadCoverLetter from '../../download-cover-letter'
import { getErrors } from '../../../../utils'

function CoverLetter ({ userId, downloadDoverLetter }) {
    const [form] = Form.useForm()
    const [loading, setLoading] = useState(false)
    const [readyForDownload, setReadyForDownload] = useState(false)
    const navigate = useNavigate()
    const location = useLocation()
    const submit = (values) => {
        setLoading(true)
        navigate('/resume/success', { state: { background: location } })
        downloadDoverLetter(values).then(() => {
            TlaSuccess()
            setLoading(false)
            setReadyForDownload(true)
            navigate(-1)
        }).catch((error) => {
            TlaError(error.response.data.message ?? getErrors(error.response.data?.errors))
            setLoading(false)
            setReadyForDownload(false)
            navigate(-1)
        })
    }

    return (
        <>
            <Spin spinning={loading}>
                <div className={'w-full md:w-[764px]'}>
                    <Form requiredMark={false}
                          form={form}
                          onFinish={submit}
                          initialValues={{ userId: userId.toString() }}
                          layout={'vertical'}>
                        <Row gutter={20}>
                            <Col span={'24'} xs={24} md={12}>
                                <Form.Item name={'jobTitle'} rules={[
                                    {
                                        required: true,
                                        message: 'Required'
                                    }
                                ]} label={'Job'}>
                                    <Input size={'large'} placeholder={'Job Title'} className={'text-base'}></Input>
                                </Form.Item>
                            </Col>
                            <Col span={'24'} xs={24} md={12}>
                                <Form.Item name={'companyName'} rules={[
                                    {
                                        required: true,
                                        message: 'Required'
                                    }
                                ]} label={'Company'}>
                                    <Input size={'large'} placeholder={'Company Name'}
                                           className={'text-base'}></Input>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Form.Item hidden name={'userId'} rules={[
                            {
                                required: true
                            }
                        ]} label={'Job Description'}>
                            <Input className={'text-base'}></Input>
                        </Form.Item>
                        <Form.Item name={'description'} rules={[
                            {
                                required: true,
                                message: 'Required'
                            },
                            {
                                min: 200,
                                message: 'Must at least 200 characters.'
                            },
                            {
                                max: 2000,
                                message: 'Maximum should be 2000 characters.'
                            }
                        ]} label={'Job Description'}>
                            <Input.TextArea
                                rows={5} placeholder={'Paste your job description'}
                                className={'text-base'}></Input.TextArea>
                        </Form.Item>

                        <Form.Item>
                            <div className={'flex gap-3 justify-end'}>
                                <Button className={'btn bg-white-base'} size={'large'}
                                        onClick={() => form.resetFields()}
                                        htmlType={'button'}>Reset</Button>
                                <Button className={'btn btn-primary-700'} size={'large'}
                                        htmlType={'submit'}>Create Now</Button>
                            </div>
                        </Form.Item>
                    </Form>
                </div>
            </Spin>
            {readyForDownload ? <DownloadCoverLetter callBack={setReadyForDownload}/> : ''}
        </>
    )
}

CoverLetter.propTypes = {
    downloadDoverLetter: PropTypes.func.isRequired,
    userId: PropTypes.number.isRequired
}

const mapStateToProps = (state) => ({
    userId: state.loginReducer.authUser.id
})

const mapDispatchToProps = (dispatch) => ({
    downloadDoverLetter: (data) => dispatch(handleDownloadCoverLetter(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(CoverLetter)
