import React from 'react'
import PropTypes from 'prop-types'
import { Button } from 'antd'

const PrimaryButton = ({ text, icon }) => (
    <Button className={'bg-primary-400 text-white-base border-primary-400' +
        ' hover:border-primary-400 hover:!text-white-base hover:bg-primary-400 !ring-primary-400' +
        ' hover:!outline-none rounded-lg shadow-1xl flex items-center gap-3 !text-sm'} size={'large'}>
        {
            icon && icon
        } {text}
    </Button>
)

PrimaryButton.propTypes = {
  icon: null
}

PrimaryButton.propTypes = {
  text: PropTypes.string,
  icon: PropTypes.any
}

export default (PrimaryButton)
