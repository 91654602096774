import { Types } from '../actions/job-applications/Types'

const initialState = {
  filter: {
    userId: 0,
    page: 1,
    pageSize: 10
  },
  autoApplySessionFilter: {
    userId: 0,
    page: 1,
    pageSize: 10
  },
  jobApplications: {
    data: [],
    meta: {
      page: 1,
      pageSize: 10
    }
  },
  autoAppliedSessions: {
    data: [],
    meta: {}
  },
  jobStats: { }
}

/**
 *
 * @param state
 * @param action
 *
 * @returns {{authenticated: boolean, authToken: null, authUser: {}}|{authenticated: boolean, authToken: null, authUser: {}}|{authenticated: boolean, authToken, authUser: *}}
 */
export default function jobApplicationReducer (state = initialState, action) {
  switch (action.type) {
    case Types.GET_JOB_APPLICATIONS: {
      const no = Number(action.payload.data.page)
      const pSize = action.payload.data.pageSize
      return {
        ...state,
        jobApplications: {
          data: action.payload.data.userJobApplications,
          meta: {
            total: Number(action.payload.data.count),
            from: no === 1 ? no : (pSize + pSize) / 2,
            current_page: Number(action.payload.data.page),
            page: action.payload.data.page,
            pageSize: state.jobApplications.meta.pageSize,
            to: action.payload.data.pageSize
          }
        }
      }
    }

    case Types.ADD_JOB_APPLICATION_FILTER:
      return {
        ...state,
        filter: action.payload
      }

    case Types.ADD_AUTO_APPLY_SESSION_FILTER:
      return {
        ...state,
        filter: action.payload
      }

    case Types.GET_APPLIED_SESSIONS: {
      const no = Number(action.payload.data.page)
      const pSize = action.payload.data.pageSize
      return {
        ...state,
        autoAppliedSessions: {
          data: action.payload.data.userJobSessions,
          meta: {
            total: Number(action.payload.data.count),
            from: no === 1 ? no : (pSize + pSize) / 2,
            current_page: Number(action.payload.data.page),
            page: action.payload.data.page,
            pageSize: pSize,
            to: action.payload.data.pageSize
          }
        }
      }
    }

    case Types.GET_JOB_APPLICATION_STATS:
      return {
        ...state,
        jobStats: action.payload.data
      }
    default:
      return state
  }
}
