import React from 'react'
import { Collapse, Form } from 'antd'
import ExpandIcon from '../commons/expand-icon'
import PropTypes from 'prop-types'
import Title from '../commons/title'
import Visibility from '../visibility'
import EditIcon from '../commons/edit-icon'
import DeleteIcon from '../commons/delete-icon'
import SingleCertificate from './single-certificate'
import { handleDeleteCertification } from '../../../../actions/certifications/Action'
import { connect, useSelector } from 'react-redux'
import { TlaSuccess } from '../../../../utils/messages'

const Certificate = ({ form, deleteCertification }) => {
    const resume = useSelector(state => state.buildResumeReducer.currentResume)
    const hiddenItems = resume?.content?.certificates?.hiddenItems

    return (
        <div className={'bg-white-base py-[13px] px-6 rounded-lg'}>
            <Form.List name={['certificates', 'items']}>
                {(fields, { add, remove }) => (
                    <Collapse
                        expandIcon={({ isActive }) => ExpandIcon(isActive)}
                        className={'resume-collapse'}
                        size={'small'}
                        expandIconPosition={'end'}
                        ghost accordion
                        items={
                            fields.map((field) => (
                                {
                                    key: field.key,
                                    label: <Title
                                        title={form.getFieldValue(['certificates', 'items', field.name, 'title'])}/>,
                                    children: <SingleCertificate cert={field}/>,
                                    extra:
                                        <div className={'flex items-end justify-end gap-x-3'}>
                                            <Visibility
                                                value={hiddenItems[field.name] !== undefined}
                                                text name={['certificates', 'hiddenItems', field.name]}/>
                                            <EditIcon link={'cv-builder/certificates'}
                                                      editData={form.getFieldValue(['certificates', 'items', field.name])}/>
                                            <DeleteIcon deleteItem={() => deleteCertification(
                                                form.getFieldValue(['certificates', 'items', field.name, 'id'])
                                            ).then(() => TlaSuccess())}/>
                                        </div>,
                                    collapsible: 'header'
                                }
                            ))
                        }/>
                )}
            </Form.List>
        </div>
    )
}

Certificate.propTypes = {
    form: PropTypes.any.isRequired,
    deleteCertification: PropTypes.func.isRequired
}

const mapDispatchToProps = (dispatch) => ({
    deleteCertification: (id) => dispatch(handleDeleteCertification(id))
})

export default connect(null, mapDispatchToProps)(Certificate)
