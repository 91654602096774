import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { TlaModal } from '../../components/pop-ups/tla-modal'
import CloseModal from '../../components/close-modal'
import { Button, Form, Input, Spin } from 'antd'
import { handleAskChatGpt } from '../../actions/ask-chat-gpt/Actions'
import { Loading3QuartersOutlined } from '@ant-design/icons'
import { TlaError } from '../../utils/messages'

function ExperienceAskGpt () {
    const navigate = useNavigate()
    const [form] = Form.useForm()
    const [loading, setLoading] = useState(false)

    const dispatch = useDispatch()

    const submit = (values) => {
        setLoading(true)
        dispatch(handleAskChatGpt(values)).then(() => {
            setLoading(false)
            navigate(-1)
        }).catch((err) => {
            TlaError(err.response.data.message)
            setLoading(false)
        })
    }
    return (
        <TlaModal title={''} width={527}>
            <h3 className={'text-lg text-gray-900 font-medium mb-[31px]'}>Ask ChatGPT to suggest Job Responsibilities</h3>
            <Spin spinning={loading} indicator={<Loading3QuartersOutlined spin/>}>
                <Form initialValues={{ type: 'experience' }} form={form} layout={'vertical'} onFinish={submit}>
                    <div className={'mb-6'}>
                        <Form.Item hidden name={'type'}>
                            <Input/>
                        </Form.Item>
                        <Form.Item
                            name={'userPrompt'} label={<span className={'text-sm font-medium text-gray-700'}>Prompt *</span>}
                            help={<span className={'text-xs'}>Provide some basic information on position and tasks to get good suggestions.</span>}>
                            <Input.TextArea
                                size={'large'}
                                rows={4}
                                className={'text-sm'}
                                id={'userPrompt'}
                                placeholder="e.g. worked as Intern project management trainee, did documentation, assisted PM in tracking tasks of the team"
                            />
                        </Form.Item>
                    </div>
                    <div className={'flex gap-2'}>
                        <div className={'w-full'}>
                            <CloseModal height={'!h-11'}/>
                        </div>
                        <Button className={'btn btn-primary w-full !h-11'}
                                size={'large'} htmlType={'submit'}>
                            Send
                        </Button>
                    </div>
                </Form>
            </Spin>
        </TlaModal>
    )
}

ExperienceAskGpt.propTypes = {}

export default ExperienceAskGpt
