import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { TlaModal } from '../../../components/auto-apply/pop-ups/tla-modal'
import { Button, Form, Input } from 'antd'
import PropTypes from 'prop-types'
import { handleGetProfile, handleUpdateProfile } from '../../../actions/authenticate/Actions'
import { useNavigate } from 'react-router-dom'
import { TlaError, TlaSuccess } from '../../../utils/messages'
import UploadPhoto from '../../my-profile/upload-photo'
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import LocationSearch from '../../../components/places-api/location-search'

function Profile ({ getProfile, profile, updateProfile }) {
    const [value, setValue] = useState()
    const navigate = useNavigate()
    const [loading, setLoading] = useState(true)
    const [updating, setUpdating] = useState(false)
    const [form] = Form.useForm()
    useEffect(() => {
        getProfile().then(() => {
            setLoading(false)
        })
    }, [])
    const onFinish = (values) => {
        setUpdating(true)

        updateProfile(profile?.userId, values).then((res) => {
            TlaSuccess('Profile Update Success')
            form.resetFields()
            setUpdating(false)
            navigate('/')
        }).catch((error) => {
            setUpdating(false)
            TlaError(error.response.data.message)
        })
    }

    return (
        <TlaModal loading={loading || updating} width={795} title={'My Profile'}>
            {
                !loading &&
                <Form form={form} onFinish={onFinish}
                      initialValues={{ ...profile }}
                      colon={false} layout={'vertical'}
                      requiredMark={false}>
                    <div>
                        <div className={'w-fit mb-10'}>
                            <UploadPhoto/>
                        </div>
                        <div className={'w-[376px]'}>
                            <div>
                                <Form.Item label={'Full Name*'}
                                           name={'name'}
                                           rules={[{
                                               required: true
                                           }]}>
                                    <Input
                                        placeholder={'Eg Chris Martin'}
                                        size={'large'}/>
                                </Form.Item>
                            </div>
                            <div>
                                <Form.Item
                                    rules={[
                                        {
                                            type: 'email',
                                            message: 'Provide a valid email'
                                        },
                                        {
                                            required: true,
                                            message: 'Resume Email required'
                                        }
                                    ]}
                                    name={'resumeEmail'}
                                    label={'Resume Email *'}>
                                    <Input size={'large'}/>
                                </Form.Item>
                            </div>
                            <div>
                                <Form.Item
                                    rules={[
                                        {
                                            required: true,
                                            message: 'City is required'
                                        }
                                    ]}
                                    name={'address'}
                                    label={'City *'}>
                                    <LocationSearch fieldName={'address'} form={form} initialValue={profile?.address}/>
                                </Form.Item>
                            </div>
                            <div>
                                <Form.Item
                                    rules={[
                                        {
                                            type: 'string',
                                            min: 10,
                                            message: 'Provide a valid phone number'
                                        },
                                        {
                                            required: true,
                                            message: 'Mobile Number required'
                                        }
                                    ]}
                                    name={'mobileNumber'}
                                    label={'Mobile Number *'}>

                                    <PhoneInput flags
                                        placeholder=""
                                        value={value}
                                        onChange={setValue}/>
                                </Form.Item>
                            </div>
                            <div>
                                <Form.Item
                                    rules={[
                                        {
                                            type: 'url',
                                            message: 'Provide a valid Linkedin URL'
                                        },
                                        {
                                            type: 'string',
                                            min: 6
                                        },
                                        {
                                            required: true,
                                            message: 'Required'
                                        }
                                    ]}
                                    name={'linkedin'}
                                    label={'Linkedin *'}>
                                    <Input size={'large'}/>
                                </Form.Item>

                                <Form.Item hidden
                                           name={'photo'}
                                           label={'Photo'}>
                                    <Input size={'large'}/>
                                </Form.Item>
                            </div>
                            <div>
                                <Form.Item
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Resume Headline required'
                                        }
                                    ]}
                                    name={'resumeHeadline'}
                                    label={'Resume Headline *'}>
                                    <Input size={'large'}/>
                                </Form.Item>
                            </div>
                            <div>
                                <Form.Item
                                    rules={[
                                        {
                                            type: 'url',
                                            message: 'Provide a valid Github URL'
                                        },
                                        {
                                            type: 'string',
                                            min: 6
                                        }
                                    ]}
                                    name={'github'}
                                    label={'Github'}>
                                    <Input size={'large'}/>
                                </Form.Item>
                            </div>
                            <div>
                                <Form.Item
                                    rules={[
                                        {
                                            type: 'url',
                                            message: 'Provide a valid GitLab URL'
                                        },
                                        {
                                            type: 'string',
                                            min: 6
                                        }
                                    ]}
                                    name={'gitLab'}
                                    label={'GitLab'}>
                                    <Input size={'large'}/>
                                </Form.Item>
                            </div>
                        </div>
                    </div>
                    <div className={'flex justify-end'}>
                        <Button className={'btn !px-[30px] !rounded-full bg-white-base'} htmlType={'submit'}
                                size={'large'}>
                            Update Profile
                        </Button>
                    </div>
                </Form>
            }
        </TlaModal>
    )
}

Profile.propTypes = {
    profile: PropTypes.object.isRequired,
    getProfile: PropTypes.func.isRequired,
    updateProfile: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
    profile: state.loginReducer.authUserInfo
})

const mapDispatchToProps = (dispatch) => ({
    getProfile: () => dispatch(handleGetProfile()),
    updateProfile: (userId, data) => dispatch(handleUpdateProfile(userId, data))
})

export default connect(mapStateToProps, mapDispatchToProps)(Profile)
