import React, { useEffect, useState } from 'react'
import SectionHeader from '../../components/section-header'
import TlaAddNew from '../../components/pop-ups/tla-add-new'
import { Button, Spin } from 'antd'
import { FiPlus } from 'react-icons/fi'
import { JobCard } from '../../components/job-card'
import { connect } from 'react-redux'
import { handleDeleteEducation, handleGetAllEducations } from '../../actions/education/Action'
import PropTypes from 'prop-types'
import { TlaSuccess } from '../../utils/messages'
import { formatDate } from '../../utils'

const Education = ({ userId, getEducations, deleteEducation, educations }) => {
  const { data } = educations

  const [loading, setLoading] = useState(true)
  useEffect(() => {
    getEducations(userId).then(() => setLoading(false))
  }, [])
  return (
        <div className={'pb-4 rounded-lg py-[29px] px-3 md:px-[28px]'}>
            <div className={'mb-11'}>
                <SectionHeader title={'Education'}>
                    <TlaAddNew link={'form'}>
                        <Button className={'btn btn-rounded'} size={'large'} icon={<FiPlus/>}>
                            Add Education
                        </Button>
                    </TlaAddNew>
                </SectionHeader>
            </div>
            <Spin spinning={loading}>
                {
                    data.map((edu) => (
                        <JobCard
                            key={edu.id}
                            deleteTitle={'Education'}
                            editData={edu}
                            deleteItem={() => deleteEducation(edu.id).then(() => TlaSuccess())}>
                            <div>
                                <h4 className={'text-gray-900 text-base font-medium'}>{edu.degreeFieldOfStudy}</h4>
                                <p className={'text-sm text-gray-500'}>
                                    <span className={'font-semibold'}>{edu.school}</span>
                                    &nbsp;&nbsp;{edu.city}
                                </p>
                                <p className={'mt-4 text-sm font-normal text-gray-500'}>
                                    {formatDate(edu.studyDurationStart)} – {formatDate(edu.studyDurationEnd)}
                                </p>
                            </div>
                        </JobCard>
                    ))}
            </Spin>
        </div>
  )
}

Education.propTypes = {
  getEducations: PropTypes.func.isRequired,
  deleteEducation: PropTypes.func.isRequired,
  educations: PropTypes.object.isRequired,
  userId: PropTypes.any.isRequired
}

const mapStateToProps = (state) => ({
  userId: state.loginReducer.authUser.id,
  educations: state.educationsReducer.educations
})

const mapDispatchToProps = (dispatch) => ({
  getEducations: (userId) => dispatch(handleGetAllEducations(userId)),
  deleteEducation: (id) => dispatch(handleDeleteEducation(id))
})

export default connect(mapStateToProps, mapDispatchToProps)(Education)
