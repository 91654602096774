import { Types } from './Types'

/**
 * @param payload
 * @returns {{payload, type: string}}
 */
export function addCertification (payload) {
  return {
    type: Types.NEW_CERTIFICATION,
    payload
  }
}

/**
 *
 * @param payload
 * @returns {{payload, type: string}}
 */
export function allCertifications (payload) {
  return {
    type: Types.ALL_CERTIFICATIONS,
    payload
  }
}

/**
 *
 * @param id
 * @returns {{id, type: string}}
 */
export function deleteCertification (id) {
  return {
    type: Types.DELETE_CERTIFICATION,
    id
  }
}

/**
 *
 * @param payload
 * @returns {{payload, type: string}}
 */
export function updateCertification (payload) {
  return {
    type: Types.UPDATE_CERTIFICATION,
    payload
  }
}
