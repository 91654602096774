import React, { useState } from 'react'
import TlaIcon from '../../components/tla-icon'
import JobTitle from '../v1-1/first-time-user/job-title'
import JobRole from '../v1-1/first-time-user/job-role'
import FirstResume from '../v1-1/first-time-user/first-resume'
import { FiChevronsLeft, FiChevronsRight } from 'react-icons/fi'
import { Button, Form, Spin, Upload } from 'antd'
import { allowedResumeFileTypes, getErrors } from '../../utils'
import { TlaError, TlaSuccess } from '../../utils/messages'
import { useNavigate } from 'react-router-dom'
import JobEmail from '../v1-1/first-time-user/job-email'
import JobPassword from '../v1-1/first-time-user/job-password'
import { useDispatch, useSelector } from 'react-redux'
import { handleChangePassword, handleRegistration } from '../../actions/authenticate/Actions'
import { handleAddJobQueryAuth } from '../../actions/job-queries/Actions'
import VerifyEmail from './verify-email'
import { LoadingOutlined } from '@ant-design/icons'
import { createGlobalStyle } from 'styled-components'
import YourName from '../v1-1/first-time-user/your-name'

const GlobalStyles = createGlobalStyle`
    html, body {
        background-color: #F5FAFF !important;
        margin: 0;
        padding: 0;
    }
`

function Iframe () {
    const [form] = Form.useForm()
    // eslint-disable-next-line no-unused-vars
    const [loading, setLoading] = useState(false)
    // eslint-disable-next-line no-unused-vars
    const [completed, setCompleted] = useState(false)

    const {
        resume, remoteOnSite,
        experienceLevel,
        jobType, title, location
    } = useSelector(state => state.jobQueryReducer.formData)
    const [current, setCurrent] = useState(0)
    const next = () => {
        form.validateFields().then(() => {
            setCurrent(current + 1)
        })
    }

    const prev = () => {
        setCurrent(current - 1)
    }

    const dispatch = useDispatch()

    const uploadProps = {
        accept: allowedResumeFileTypes,
        name: 'resume',
        multiple: false,
        maxCount: 1,
        method: 'get',
        beforeUpload: (info) => {
            const isPDF = allowedResumeFileTypes.includes(info.type)
            if (!isPDF) {
                TlaError('Only PDF and word documents are allowed')
            } else {
                dispatch({
                    type: 'SET_RESUME',
                    payload: {
                        ...form.getFieldsValue(['title', 'location']),
                        resume: info
                    }
                })
            }
            return isPDF || Upload.LIST_IGNORE
        }
    }

    const items = [
        <JobTitle key={'title'}/>,
        <JobRole key={'location'}/>,
        <FirstResume key={'resume'} uploadProps={uploadProps}/>,
        <JobEmail key={'email'}/>,
        <YourName key={'name'}/>,
        <JobPassword key={'password'}/>
    ]

    // eslint-disable-next-line no-unused-vars
    const navigate = useNavigate()
    const onSubmit = (values) => {
        setLoading(true)
        form.validateFields().then(() => {
            if (resume === null) {
                TlaError('Resume is required')
                setLoading(false)
                return
            }

            values.resume = resume
            dispatch(handleRegistration({
                name: form.getFieldValue('name'),
                email: form.getFieldValue('email'),
                password: values?.password,
                passwordConfirm: values?.passwordConfirm
            })).then((res) => {
                dispatch(handleAddJobQueryAuth({
                    userId: res?.user?.id,
                    remoteOnSite,
                    experienceLevel,
                    jobType,
                    resume,
                    title,
                    location
                })).then(() => {
                    form.resetFields()
                    setCompleted(true)
                    setLoading(false)
                })
            }).catch((error) => {
                setLoading(false)
                TlaError(error.response.data.message ?? getErrors(error.response.data?.errors))
            })
        }).catch(() => {
            setLoading(false)
        })
    }
    const { id, email } = useSelector(state => state.loginReducer?.authUser)

    // eslint-disable-next-line no-unused-vars
    const changeYourPassword = () => {
        setLoading(true)
        dispatch(handleChangePassword(id, {
            email,
            currentPassword: email,
            password: form.getFieldValue('password'),
            passwordConfirm: form.getFieldValue('passwordConfirm')
        })).then(() => {
            TlaSuccess('Password set successfully')
            form.resetFields()
            setCompleted(true)
            setLoading(false)
        }).catch((error) => {
            setLoading(false)
            setCompleted(false)
            TlaError(error.response.data.message ?? getErrors(error.response.data?.errors))
        })
    }

    return (
        <div className={'flex items-center justify-center gap-x-[54px] py-[70px] px-5 md:px-0'}>
            <GlobalStyles/>
            <div>
                <Spin spinning={loading} tip={'Please Wait...'} indicator={<LoadingOutlined/>}>
                    {
                        completed
                            ? <VerifyEmail/>
                            : <div>
                                <div>
                                    <p className={'text-primary-600 text-xl font-normal'}>AUTOMATE YOUR JOB APPLICATION</p>
                                    <h1 className={'text-[30px] text-[34px] text-black font-bold mb-[72px]'}>
                                        500 Jobs with 1-Click*
                                    </h1>
                                </div>
                                <Form form={form} onFinish={onSubmit} initialValues={{ experienceLevel: [''] }}>
                                    <div className={'mb-[66px]'}>
                                        {items[current]}
                                    </div>

                                    <div className={'mx-auto md:ml-auto md:mr-[300px] gap-x-[11px] flex' +
                                        ' justify-start md:justify-end'}>
                                        {
                                            current > 0 && (
                                                <span className={'flex items-end text-base cursor-pointer font-weight pb-2'}
                                                      onClick={prev}>
                                                    <FiChevronsLeft size={24}/> Previous
                                                </span>
                                            )
                                        }
                                        {current < items.length - 1 && (
                                            <span className={'btn-next'} onClick={next}>
                                                Next <FiChevronsRight size={24}/>
                                            </span>
                                        )}

                                        {current === items.length - 1 && (
                                            <Button
                                                htmlType={'submit'}
                                                className={'rounded-full bg-primary-600 h-[46px] text-base font-medium text-white-base w-[98px]'}>
                                                Save
                                            </Button>
                                        )}
                                    </div>
                                </Form>
                            </div>
                    }
                </Spin>
            </div>

            <div className={'hidden md:block'}>
                <TlaIcon name={'apply'}/>
            </div>
        </div>
    )
}

export default Iframe
