const baseUrl = '/auto-apply'

export default {
    settings: `${baseUrl}/settings`,
    subscription: `${baseUrl}/subscription`,
    preferences: `${baseUrl}/preferences`,
    profile: `${baseUrl}/profile`,
    extension: 'https://chrome.google.com/webstore/detail/autoapply/aihojinhpngojiaghgoekajdhcjenbce',
    support: 'https://autoapplyjobs.zohodesk.com/portal/en/home'
}
