import { Types } from './Types'

/**
 * @param payload
 * @returns {{payload, type: string}}
 */
export function addEducation (payload) {
  return {
    type: Types.NEW_EDUCATION,
    payload
  }
}

/**
 *
 * @param payload
 * @returns {{payload, type: string}}
 */
export function allEducations (payload) {
  return {
    type: Types.ALL_EDUCATIONS,
    payload
  }
}

/**
 *
 * @param id
 * @returns {{id, type: string}}
 */
export function deleteEducation (id) {
  return {
    type: Types.DELETE_EDUCATION,
    id
  }
}

/**
 *
 * @param payload
 * @returns {{payload, type: string}}
 */
export function updateEducation (payload) {
  return {
    type: Types.UPDATE_EDUCATION,
    payload
  }
}
