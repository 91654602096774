import { Types } from '../actions/awards/Types'

const initialState = {
  awards: {
    data: [],
    meta: {}
  }
}

export default function awardsReducer (state = initialState, action) {
  switch (action.type) {
    case Types.NEW_AWARD:
      return {
        ...state,
        awards: {
          ...state.awards,
          data: state.awards.data.concat(action.payload.data.award)
        }
      }
    case Types.ALL_AWARDS:
      return {
        ...state,
        awards: {
          ...state.awards,
          data: action.payload.data.userAwards,
          meta: {
            total: action.payload.data.count,
            page: action.payload.data.page,
            pageSize: action.payload.data.pageSize
          }
        }
      }

    case Types.UPDATE_AWARD:
      return {
        ...state,
        awards: {
          ...state.awards,
          data: state.awards.data.map((form) => {
            return form.id === action.payload.data.award.id ? action.payload.data.award : form
          })
        }
      }
    case Types.DELETE_AWARD:
      return {
        ...state,
        awards: {
          ...state.awards,
          data: state.awards.data.filter((awards) => awards.id !== action.id)
        }
      }

    default:
      return state
  }
}
