import { Types } from '../actions/skills/Types'

const initialState = {
  skills: {
    data: [],
    meta: {}
  }
}

export default function skillsReducer (state = initialState, action) {
  switch (action.type) {
    case Types.NEW_SKILL:
      return {
        ...state,
        skills: {
          ...state.skills,
          data: {
            technicalSkills: action.payload?.data?.technicalSkills,
            softSkills: action.payload?.data?.softSkills
          }
        }
      }
    case Types.ALL_SKILLS:
      // eslint-disable-next-line no-case-declarations
      const tags = action.payload.data.userSkillTags
      return {
        ...state,
        skills: {
          ...state.skills,
          data: tags.length > 0 ? tags[0] : [],
          meta: {
            total: action.payload.data.count,
            page: action.payload.data.page,
            pageSize: action.payload.data.pageSize
          }
        }
      }

    case Types.UPDATE_SKILL:
      return {
        ...state,
        skills: {
          ...state.skills,
          data: state.skills.data.map((form) => {
            return form.id === action.payload.data.skill.id ? action.payload.data.skill : form
          })
        }
      }
    case Types.DELETE_SKILL:
      return {
        ...state,
        skills: {
          ...state.skills,
          data: state.skills.data.filter((skills) => skills !== action.skill)
        }
      }

    default:
      return state
  }
}
