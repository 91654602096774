import { Types } from './Types'

/**
 *
 * @param payload
 * @returns {{payload, type: string}}
 */
export const askChatGptSkills = (payload) => {
  return {
    type: Types.ASK_CHAT_GPT_SKILLS,
    payload
  }
}

export function askChatGptExperience (payload) {
  return {
    type: Types.ASK_CHAT_GPT_EXPERIENCES,
    payload
  }
}
