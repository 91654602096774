import React from 'react'
import logo from '../../../assets/images/logo.png'
import NavProfile from '../../../components/navigation/nav-profile'
import { Link, useNavigate } from 'react-router-dom'
import { Affix } from 'antd'
import { useSelector } from 'react-redux'
import { HeaderMenus } from '../../../utils/menu-items'
import SwitchApp from '../../cv-builder/dashboard/layout/switch-app'

function Header () {
    const navigate = useNavigate()
    const userRole = useSelector((state) => state.loginReducer.authUser.role)

    return (
        <Affix offsetTop={0}>
            <div className={'flex items-center justify-between bg-blue-dark-50 border-b border-b-gray-200 px-2 md:px-[49px] h-[73px]'}>
                <div className={'flex items-center gap-x-8'}>
                    <div onClick={() => {
                        navigate('/home')
                    }} className={'cursor-pointer'}>
                        <img className={'hidden md:block'} src={logo} alt={'AutoApply'} width={200} height={'auto'}/>
                        <img className={'block md:hidden'} src={logo} alt={'AutoApply'} width={200} height={'auto'}/>
                    </div>
                    {
                        userRole === 'user' && HeaderMenus.map((menu) => (
                            <Link className={'text-base font-medium text-gray-700'} to={menu.link} key={menu.link}>{menu.title}</Link>
                        ))
                    }
                    {/* <div className={'block md:hidden'}>
                        <MobileMenu/>
                    </div> */}
                </div>
                <div>
                    <div className={'flex items-center gap-x-2 md:gap-x-6'}>
                        {
                            userRole === 'user' &&
                            <SwitchApp/>
                        }
                        {/* <FiBell className={'text-xl text-gray-500'}/> */}
                        <NavProfile/>
                    </div>
                </div>
            </div>
        </Affix>
    )
}

export default Header
