import api from '../../utils/api'
import {
    addAutoApplySessionFilter,
    addFilter,
    getAppliedSessions,
    getJobApplications,
    getJobApplicationStats
} from './ActionCreators'

/**
 *
 * @param params
 * @returns {function(*): Promise<unknown>}
 */
export const handleGetJobApplications = (params) => (dispatch) => {
    return new Promise((resolve, reject) => {
        api().get(`/job-application/get-all?${params}`)
            .then((res) => {
                dispatch(getJobApplications(res.data))
                params && dispatch(addFilter(Object.fromEntries(params)))
                resolve(res)
            }).catch((err) => {
            reject(err)
        })
    })
}

/**
 *
 * @param params
 * @returns {function(*): Promise<unknown>}
 */
export const handleGetAppliedSessions = (params) => (dispatch) => {
    return new Promise((resolve, reject) => {
        api().get(`/job-application/stats?${params}`)
            .then((res) => {
                dispatch(getAppliedSessions(res.data))
                params && dispatch(addAutoApplySessionFilter(Object.fromEntries(params)))
                resolve(res)
            }).catch((err) => {
            reject(err)
        })
    })
}

/**
 *
 * @param userId
 * @returns {function(*): Promise<unknown>}
 */
export const handleJobApplicationStat = (userId) => (dispatch) => {
    return new Promise((resolve, reject) => {
        api().get(`/job-application/dashboard-stats?userId=${userId}`)
            .then((res) => {
                dispatch(getJobApplicationStats(res.data))
                resolve(res)
            }).catch((err) => {
            reject(err)
        })
    })
}
