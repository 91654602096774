import React from 'react'
import PropTypes from 'prop-types'
import Pagination from 'react-js-pagination'
import { Card, Typography } from 'antd'
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi'

// eslint-disable-next-line react/prop-types
const NavItem = ({ icon, title, iconFirst }) => (
    <div className={'flex items-center gap-2'}>
        {
            iconFirst
              ? <React.Fragment>{icon} <span className={'hidden md:block text-gray-800'}>{title}</span></React.Fragment>
              : <React.Fragment><span className={'hidden md:block text-gray-800'}>{title}</span> {icon}</React.Fragment>
        }
    </div>
)

function TlaPagination (props) {
  const { meta, loadData, children, showHeader, paginate } = props

  return (
        <div className={'mb-4'}>
            {
                showHeader &&
                <Card size={'small'}>
                    <Typography.Text>
                        Showing {meta.from} - {meta.to} of {meta.total}
                    </Typography.Text>
                </Card>
            }
            <div>{children}</div>
            {
                paginate &&
                <div style={{ marginTop: 10 }}>
                    <Pagination
                        activePage={meta.current_page ?? 1}
                        itemsCountPerPage={ meta.pageSize !== undefined ? Number(meta.pageSize) : 10 }
                        totalItemsCount={meta.total ?? 0}
                        onChange={loadData}
                        pageRangeDisplayed={8}
                        itemClass="page-item"
                        linkClass="page-link"
                        firstPageText="First"
                        lastPageText="Last"
                        hideFirstLastPages={true}
                        nextPageText={<NavItem icon={<FiChevronRight size={20} className={'text-gray-800'}/>}/>}
                        prevPageText={<NavItem icon={<FiChevronLeft size={20} className={'text-gray-800'}/>}
                                               iconFirst/>}
                    />
                </div>
            }

        </div>

  )
}

TlaPagination.defaultProps = {
    meta: {
        from: 0,
        to: 0,
        total: 0
    },
    showHeader: false,
    paginate: true
}

TlaPagination.propTypes = {
    meta: PropTypes.object.isRequired,
    children: PropTypes.node,
    loadData: PropTypes.func.isRequired,
    showHeader: PropTypes.bool,
    paginate: PropTypes.bool
}

export default TlaPagination
