import api from '../../utils/api'
import { addCertification, allCertifications, deleteCertification, updateCertification } from './ActionCreator'

/**
 * Store a newly created resource in storage.
 * @param userId
 * @param data
 * @returns {function(*): Promise<unknown>}
 */
export const handleAddCertification = (userId, data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    api().post(`/users/certificate/${userId}`, data).then((res) => {
      dispatch(addCertification(res.data))
      resolve(res)
    }).catch((err) => {
      reject(err)
    })
  })
}

/**
 * Display a listing of the resource.
 * @returns {function(*): Promise<unknown>}
 */
export const handleGetAllCertifications = (userId, pageNumber = 1) => (dispatch) => {
  return new Promise((resolve, reject) => {
    api().get(`/users/certificate?userId=${userId}&page=${pageNumber}&pageSize=20`).then((res) => {
      dispatch(allCertifications(res.data))
      resolve(res)
    }).catch((err) => {
      reject(err)
    })
  })
}

/**
 * Update the specified resource in storage.
 * @param certId
 * @param data
 * @returns {function(*): Promise<unknown>}
 */
export const handleUpdateCertification = (certId, data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    api().put(`/users/certificate/${certId}`, data, {
      // headers: { 'Content-type': 'multipart/form-data' }
    }).then((res) => {
      dispatch(updateCertification(res.data))
      resolve(res)
    }).catch((err) => {
      reject(err)
    })
  })
}

/**
 * Remove the specified resource from storage.
 * @param id
 * @returns {function(*): Promise<unknown>}
 */
export const handleDeleteCertification = (id) => (dispatch) => {
  return new Promise((resolve, reject) => {
    api().delete(`/users/certificate/${id}`).then((res) => {
      dispatch(deleteCertification(id))
      resolve(res)
    }).catch((err) => {
      reject(err)
    })
  })
}
