import React, { useState } from 'react'
import { Button, Col, Form, Row } from 'antd'
import { connect, useSelector } from 'react-redux'
import { handleAddJobQuery, handleUpdateJobQuery } from '../../../actions/job-queries/Actions'
import PropTypes from 'prop-types'
import { useLocation, useNavigate } from 'react-router-dom'
import { TlaModal } from '../../../components/auto-apply/pop-ups/tla-modal'
import { TlaError, TlaSuccess } from '../../../utils/messages'
import { getErrors } from '../../../utils'
import JobQueryFormContent from './job-query-form-content'

function JobQueryForm ({ addJobQuery, updateJobQuery }) {
    const { subscriptionPackage } = useSelector(state => state.dashboardReducer.userPackage)
    const location = useLocation()
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const [form] = Form.useForm()
    const { state } = useLocation()

    const [selectedFile, setSelectedFile] = useState(state?.data?.resume ?? null)

    const formValues = {
        resume: null,
        experienceLevel: [''],
        ...state?.data
    }
    const onSubmit = (values) => {
        setLoading(true)
        form.validateFields().then(() => {
            if (selectedFile === null) {
                TlaError('Resume is required')
                setLoading(false)
                return
            }
            values.resume = selectedFile
            values.remoteOnSite = ['']
            values.jobType = ['']

            delete values?.confirm;

            (state?.data?.id ? updateJobQuery(state?.data?.id, values) : addJobQuery(values)).then(() => {
                setLoading(false)
                TlaSuccess()
                form.resetFields()
                navigate(-1)
            }).catch((error) => {
                setLoading(false)
                if (error.response.status === 444) {
                    navigate(subscriptionPackage.subscriptionName === 'starter' ? '/max-starter-job-queries' : '/max-job-queries', {
                        state: {
                            data: {
                                message: error.response.data.message
                            },
                            background: {
                                ...location,
                                pathname: '/home'
                            }
                        }
                    })
                } else {
                    TlaError(error.response.data.message ?? getErrors(error.response.data?.errors))
                }
            })
        }).catch(() => {
            setLoading(false)
        })
        /* const job = JobList.find((job) => job === values.title)
        if (job === undefined) {
            setLoading(false)
            TlaError('Select a job from the dropdown list')
        } else {

        } */
    }

    return (
        <TlaModal loading={ loading } width={ 642 } title={ `${state?.data?.id ? 'Update' : ''} Job Role` }>
            <Form requiredMark={ false }
                  form={ form }
                  onFinish={ onSubmit }
                  layout="vertical"
                  name="job-query-form"
                  initialValues={ formValues }>
                <JobQueryFormContent form={ form } setSelectedFile={ setSelectedFile }/>
                <Row gutter={ [10, 0] }>
                    <Col span={ 24 }>
                        <div className={ 'flex justify-end' }>
                            <Button htmlType={ 'submit' } className={ 'btn !px-[30px] !rounded-full bg-white-base' }
                                    size={ 'large' }>
                                { `${state?.data?.id ? 'Update' : 'Create'}` } Job Role
                            </Button>
                        </div>
                    </Col>
                </Row>
            </Form>
        </TlaModal>
    )
}

JobQueryForm.propTypes = {
    addJobQuery: PropTypes.func.isRequired,
    updateJobQuery: PropTypes.func.isRequired,
    userId: PropTypes.number.isRequired
}

/**
 *
 * @param state
 * @returns {{userId: BufferSource}}
 */
const mapStateToProps = (state) => ({
    userId: state.loginReducer.authUser.id
})

/**
 *
 * @param dispatch
 * @returns {{addJobQuery: (function(*): *)}}
 */
const mapDispatchToProps = (dispatch) => ({
    addJobQuery: (data) => dispatch(handleAddJobQuery(data)),
    updateJobQuery: (queryId, data) => dispatch(handleUpdateJobQuery(queryId, data))
})

export default connect(mapStateToProps, mapDispatchToProps)(JobQueryForm)
