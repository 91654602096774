import { Types } from './Types'

/**
 * @param payload
 * @returns {{payload, type: string}}
 */
export function addUser (payload) {
  return {
    type: Types.NEW_USER,
    payload
  }
}

/**
 *
 * @param payload
 * @returns {{payload, type: string}}
 */
export function allUsers (payload) {
  return {
    type: Types.ALL_USERS,
    payload
  }
}

/**
 *
 * @param user
 * @returns {{user, type: string}}
 */
export function deleteUser (user) {
  return {
    type: Types.DELETE_USER,
    user
  }
}

/**
 *
 * @param payload
 * @returns {{payload, type: string}}
 */
export function updateUser (payload) {
  return {
    type: Types.UPDATE_USER,
    payload
  }
}

/**
 *
 * @param payload
 * @returns {{payload, type: string}}
 */
export function updateSubscription (payload) {
  return {
    type: Types.UPDATE_SUBSCRIPTION,
    payload
  }
}

/**
 *
 * @param payload
 * @returns {{payload, type: string}}
 */
export function getAllSubscriptions (payload) {
  return {
    type: Types.GET_ALL_SUBSCRIPTIONS,
    payload
  }
}
