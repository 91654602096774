import { Types } from '../actions/app-settings/Types'

const initialState = {
  currentApp: 'auto-apply'
}

/**
 *
 * @param state
 * @param action
 * @returns {{currentApp: string}|{currentApp}}
 */
export default function appSettingsReducer (state = initialState, action) {
  switch (action.type) {
    case Types.SET_CURRENT_APP: {
      return {
        ...state,
        currentApp: action.payload
      }
    }

    default:
      return state
  }
}
