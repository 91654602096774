import React, { useEffect, useState } from 'react'
import SectionHeader from '../../components/section-header'
import TlaAddNew from '../../components/pop-ups/tla-add-new'
import { Button, Spin } from 'antd'
import { FiPlus } from 'react-icons/fi'
import { JobCard } from '../../components/job-card'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { handleDeleteCertification, handleGetAllCertifications } from '../../actions/certifications/Action'
import { TlaSuccess } from '../../utils/messages'

const Certification = ({ getCertifications, userId, certifications, deleteCertification }) => {
  const { data } = certifications
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    getCertifications(userId).then(() => setLoading(false))
  }, [])
  return (
        <div className={'pb-4 rounded-lg py-[29px] px-3 md:px-[28px]'}>
            <div className={'mb-11'}>
                <SectionHeader title={'Certification'}>
                    <TlaAddNew link={'form'}>
                        <Button className={'btn btn-rounded'} size={'large'} icon={<FiPlus/>}>
                            Add Certification
                        </Button>
                    </TlaAddNew>
                </SectionHeader>
            </div>

            <Spin spinning={loading}>
                {
                    data.map(({ id, title, organisation }) => (
                        <JobCard
                            key={id}
                            deleteItem={() => {
                              deleteCertification(id).then(() => TlaSuccess())
                            }}
                            deleteTitle={'Certification'}
                            editData={{
                              id, title, organisation
                            }}>
                            <div>
                                <h4 className={'text-gray-900 text-base font-medium'}>
                                    {title}
                                </h4>
                                <p className={'text-sm text-gray-500'}>
                                    <span className={'font-semibold'}>{organisation}</span>
                                </p>
                            </div>
                        </JobCard>
                    ))
                }
            </Spin>
        </div>
  )
}

Certification.propTypes = {
  deleteCertification: PropTypes.func.isRequired,
  getCertifications: PropTypes.func.isRequired,
  userId: PropTypes.any.isRequired,
  certifications: PropTypes.object.isRequired
}

const mapStateToProps = (state) => ({
  userId: state.loginReducer.authUser.id,
  certifications: state.certificationsReducer.certifications
})

const mapDispatchToProps = (dispatch) => ({
  getCertifications: (userId) => dispatch(handleGetAllCertifications(userId)),
  deleteCertification: (id) => dispatch(handleDeleteCertification(id))
})

export default connect(mapStateToProps, mapDispatchToProps)(Certification)
