import React from 'react'
import { Form, Input } from 'antd'
import EditIcon from './commons/edit-icon'
import Visibility from './visibility'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import UploadPhoto from '../components/upload-photo'

// eslint-disable-next-line react/prop-types
const VisibilityItem = ({ children, show, name, value }) => (
    <div className={'flex justify-between items-center gap-x-3'}>
        <div className={'w-full'}>{children}</div>
        <div className={'mt-2'}>{show ? <Visibility name={name} value={value}/> : <>&nbsp;&nbsp;&nbsp;</>}</div>
    </div>
)

VisibilityItem.defaultProps = {
    show: true
}

function PersonalProfile ({ form }) {
    const data = form.getFieldValue(['userInformation', 'items'])
    const photo = useSelector(state => state.buildResumeReducer?.currentResume?.metadata?.photo)
    const { hiddenItems } = useSelector(state => state.buildResumeReducer?.currentResume?.content?.userInformation)

    return (
        <div className={'bg-white-base py-[13px] px-6 rounded-lg'}>
            <div className={'flex justify-end'}>
                <EditIcon editData={data} link={'cv-builder/personal-info'}/>
            </div>
            <div className={'mb-5'}>
                <div className={'flex items-center gap-x-4'}>
                    <UploadPhoto/>
                    <div>
                        <h3 className={'text-lg font-medium text-gray-900'}>{data?.name}</h3>
                        <div className={'flex items-center gap-x-2'}>
                            <p className={'w-fit text-sm font-normal text-gray-500'}>Profile Picture</p>
                            <Visibility isPhoto name={['metadata', 'photo']} value={photo === 'yes'}/>
                        </div>
                    </div>
                </div>
            </div>
            <VisibilityItem
                value={hiddenItems.name !== undefined}
                show={false}
                name={['userInformation', 'hiddenItems', 'name']}>
                <Form.Item label={'Full Name *'}
                           name={['userInformation', 'items', 'name']}
                           rules={[{
                               required: true,
                               message: 'Name is required'
                           }]}>
                    <Input disabled placeholder={'Eg Chris Martin'} size={'large'}/>
                </Form.Item>
            </VisibilityItem>
            <VisibilityItem
                value={hiddenItems.resumeEmail !== undefined}
                name={['userInformation', 'hiddenItems', 'resumeEmail']}>
                <Form.Item
                    rules={[{
                        type: 'email',
                        message: 'Provide a valid email'
                    }]}
                    name={['userInformation', 'items', 'resumeEmail']}
                    label={'Resume Email'}>
                    <Input disabled size={'large'}/>
                </Form.Item>
            </VisibilityItem>
            <VisibilityItem
                value={hiddenItems.address !== undefined}
                name={['userInformation', 'hiddenItems', 'address']}>
                <Form.Item
                    name={['userInformation', 'items', 'address']}
                    label={'Address'}>
                    <Input disabled size={'large'}/>
                </Form.Item>
            </VisibilityItem>
            <VisibilityItem
                value={hiddenItems.mobileNumber !== undefined}
                name={['userInformation', 'hiddenItems', 'mobileNumber']}>
                <Form.Item
                    rules={[
                        {
                            type: 'string',
                            min: 10,
                            message: 'Provide a valid phone number'
                        }
                    ]}
                    name={['userInformation', 'items', 'mobileNumber']}
                    label={'Mobile Number'}>
                    <Input disabled size={'large'}/>
                </Form.Item>
            </VisibilityItem>
            <VisibilityItem
                value={hiddenItems.linkedin !== undefined}
                name={['userInformation', 'hiddenItems', 'linkedin']}>
                <Form.Item
                    rules={[
                        {
                            type: 'url',
                            message: 'Provide a valid Linkedin URL'
                        },
                        {
                            type: 'string',
                            min: 6
                        }
                    ]}
                    name={['userInformation', 'items', 'linkedin']}
                    label={'Linkedin'}>
                    <Input disabled size={'large'}/>
                </Form.Item>
            </VisibilityItem>
            <VisibilityItem
                value={hiddenItems.resumeHeadline !== undefined}
                name={['userInformation', 'hiddenItems', 'resumeHeadline']}>
                <Form.Item
                    name={['userInformation', 'items', 'resumeHeadline']}
                    label={'Resume Headline'}>
                    <Input disabled size={'large'}/>
                </Form.Item>
            </VisibilityItem>
            <VisibilityItem
                value={hiddenItems.github !== undefined}
                name={['userInformation', 'hiddenItems', 'github']}>
                <Form.Item
                    rules={[
                        {
                            type: 'url',
                            message: 'Provide a valid Github URL'
                        },
                        {
                            type: 'string',
                            min: 6
                        }
                    ]}
                    name={['userInformation', 'items', 'github']}
                    label={'Github'}>
                    <Input disabled size={'large'}/>
                </Form.Item>
            </VisibilityItem>
            <VisibilityItem
                value={hiddenItems.gitLab !== undefined}
                name={['userInformation', 'hiddenItems', 'gitLab']}>
                <Form.Item
                    rules={[
                        {
                            type: 'url',
                            message: 'Provide a valid GitLab URL'
                        },
                        {
                            type: 'string',
                            min: 6
                        }
                    ]}
                    name={['userInformation', 'items', 'gitLab']}
                    label={'GitLab'}>
                    <Input disabled size={'large'}/>
                </Form.Item>
            </VisibilityItem>
        </div>
    )
}

PersonalProfile.propTypes = {
    form: PropTypes.any
}

export default PersonalProfile
