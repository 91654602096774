import React from 'react'
import { Outlet } from 'react-router-dom'
import { HeaderMenus } from '../../../utils/menu-items'
import ResumeHeader from './resume-header'
import Header from '../../cv-builder/dashboard/layout/header'

function ResumeLayout () {
    localStorage.setItem('resume-editing', 'true')
  return (
      <div className={'max-w-screen-2xl mx-auto'}>
          <Header menuItems={HeaderMenus}/>
          <ResumeHeader/>
          <div className={'mx-2 md:mx-12'}>
              <div className={'w-full mb-5'}>
                  <Outlet/>
              </div>
          </div>
      </div>
  )
}

export default ResumeLayout
