import { Path, Svg } from '@react-pdf/renderer'
import React from 'react'
import PropTypes from 'prop-types'

export const Linkedin = ({ color, download }) => (
    download
        ? <Svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill={color}>
            <Path
                d="M8.84231 4.66602C9.72171 4.66602 10.5651 5.03476 11.1869 5.69114C11.8088 6.34752 12.1581 7.23776 12.1581 8.16602V12.2493H9.94757V8.16602C9.94757 7.8566 9.83113 7.55985 9.62385 7.34106C9.41657 7.12227 9.13545 6.99935 8.84231 6.99935C8.54918 6.99935 8.26805 7.12227 8.06077 7.34106C7.8535 7.55985 7.73705 7.8566 7.73705 8.16602V12.2493H5.52652V8.16602C5.52652 7.23776 5.87586 6.34752 6.49769 5.69114C7.11953 5.03476 7.96291 4.66602 8.84231 4.66602Z"
                stroke="white" strokeWidth="0.8" strokeLinecap="round" strokeLinejoin="round"/>
            <Path d="M3.316 5.24935H1.10547V12.2493H3.316V5.24935Z" stroke="white" strokeWidth="0.8" strokeLinecap="round"
                  strokeLinejoin="round"/>
            <Path
                d="M2.21073 3.49935C2.82115 3.49935 3.316 2.97701 3.316 2.33268C3.316 1.68835 2.82115 1.16602 2.21073 1.16602C1.60031 1.16602 1.10547 1.68835 1.10547 2.33268C1.10547 2.97701 1.60031 3.49935 2.21073 3.49935Z"
                stroke="white" strokeWidth="0.8" strokeLinecap="round" strokeLinejoin="round"/>
        </Svg>
        : <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill={color}>
            <path
                d="M8.84231 4.66602C9.72171 4.66602 10.5651 5.03476 11.1869 5.69114C11.8088 6.34752 12.1581 7.23776 12.1581 8.16602V12.2493H9.94757V8.16602C9.94757 7.8566 9.83113 7.55985 9.62385 7.34106C9.41657 7.12227 9.13545 6.99935 8.84231 6.99935C8.54918 6.99935 8.26805 7.12227 8.06077 7.34106C7.8535 7.55985 7.73705 7.8566 7.73705 8.16602V12.2493H5.52652V8.16602C5.52652 7.23776 5.87586 6.34752 6.49769 5.69114C7.11953 5.03476 7.96291 4.66602 8.84231 4.66602Z"
                stroke="white" strokeWidth="0.8" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M3.316 5.24935H1.10547V12.2493H3.316V5.24935Z" stroke="white" strokeWidth="0.8" strokeLinecap="round"
                  strokeLinejoin="round"/>
            <path
                d="M2.21073 3.49935C2.82115 3.49935 3.316 2.97701 3.316 2.33268C3.316 1.68835 2.82115 1.16602 2.21073 1.16602C1.60031 1.16602 1.10547 1.68835 1.10547 2.33268C1.10547 2.97701 1.60031 3.49935 2.21073 3.49935Z"
                stroke="white" strokeWidth="0.8" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
)

Linkedin.defaultProps = {
    color: 'currentColor',
    download: false
}

Linkedin.propTypes = {
    color: PropTypes.string,
    download: PropTypes.bool
}
