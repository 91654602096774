import React, { useEffect } from 'react'
import { Form } from 'antd'
import Title from './title'
import PropTypes from 'prop-types'

// eslint-disable-next-line react/prop-types
function JobRole ({ form }) {
    useEffect(() => {
        const initAutocomplete = () => {
            const input = document.getElementById('city-input')
            const options = {
                types: ['(cities)']
            }

            const autocomplete = new window.google.maps.places.Autocomplete(input, options)
            autocomplete.addListener('place_changed', () => {
                // eslint-disable-next-line no-unused-vars
                const place = autocomplete.getPlace()
                form.setFieldValue('location', place?.formatted_address)
            })
        }
        if (window.google) {
            initAutocomplete()
        } else {
            // Load the Google Maps JavaScript API script
            const script = document.createElement('script')
            script.src = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyCvaqG9NvQaGqH9oq3Pc1gjp4BeEU4JFOE&libraries=places'
            script.onload = initAutocomplete
            document.head.appendChild(script)
        }
    }, [])

    return (
        <div className={ 'w-[340px] md:w-[376px]' }>
            <Title text={ 'Where are you looking for a job' }/>
            <Form.Item name={ 'location' } rules={ [
                { required: true, message: 'Required' }
            ] }>
                <input
                    style={ {
                        height: 46,
                        border: 'solid 1px black',
                        fontSize: 16,
                        fontWeight: 400,
                        width: '100%',
                        borderRadius: 10
                    } }
                    id={ 'city-input' }
                    className={ 'hover:border-black-base' +
                        ' focus:border-black-base px-[30px]' } placeholder={ 'Eg.' +
                    ' Toronto' }/>
            </Form.Item>
        </div>
    )
}

JobRole.propTypes = {
    form: PropTypes.any
}

export default JobRole
