import React from 'react'
import PropTypes from 'prop-types'
import TlaConfirm from './tla-confirm'
import TlaEdit from './tla-edit'

export const JobCard = ({ children, deleteItem, editData, deleteTitle, actions }) => {
  return (
        <div className={'shadow-2.5xl bg-white-base rounded-lg mb-2'}>
            <div className={'p-6'}>
                {children}
            </div>
            {
                actions &&
                <div className={'border-t'}>
                    <div className={'px-6 py-3 flex justify-end items-center gap-x-4'}>
                        <TlaEdit text={'Edit'} data={editData}
                                 className={'!p-0 text-primary-700 hover:!text-primary-700 !bg-transparent'}/>
                        <TlaConfirm
                            showIcon={false}
                            danger
                            title={deleteTitle}
                            callBack={deleteItem}>
                            Delete
                        </TlaConfirm>
                    </div>
                </div>
            }
        </div>
  )
}

JobCard.defaultProps = {
  editData: {},
  actions: true
}

JobCard.propTypes = {
  children: PropTypes.node,
  editData: PropTypes.object,
  deleteTitle: PropTypes.string,
  deleteItem: PropTypes.func,
  actions: PropTypes.bool
}
