import React, { useEffect, useState } from 'react'
import SectionHeader from '../../components/section-header'
import TlaAddNew from '../../components/pop-ups/tla-add-new'
import { Button, Spin } from 'antd'
import { FiPlus } from 'react-icons/fi'
import { JobCard } from '../../components/job-card'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { handleDeleteAward, handleGetAllAwards } from '../../actions/awards/Action'
import { TlaSuccess } from '../../utils/messages'

const Award = ({ getAwards, userId, awards, deleteAward }) => {
  const { data } = awards
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    getAwards(userId).then(() => setLoading(false))
  }, [])
  return (
        <div className={'pb-4 rounded-lg py-[29px] px-3 md:px-[28px]'}>
            <div className={'mb-11'}>
                <SectionHeader title={'Awards & Achievements'}>
                    <TlaAddNew link={'form'}>
                        <Button className={'btn btn-rounded'} size={'large'} icon={<FiPlus/>}>
                            Add Achievement
                        </Button>
                    </TlaAddNew>
                </SectionHeader>
            </div>

            <Spin spinning={loading}>
                {
                    data.map(({ id, title, details }) => (
                        <JobCard
                            key={id}
                            deleteItem={() => {
                              deleteAward(id).then(() => TlaSuccess())
                            }}
                            deleteTitle={'Award'}
                            editData={{
                              id, title, details
                            }}>
                            <div>
                                <h4 className={'text-gray-900 text-base font-medium'}>
                                    {title}
                                </h4>
                                <p className={'text-sm text-gray-500'}>
                                    <span className={'font-semibold'}>{details}</span>
                                </p>
                            </div>
                        </JobCard>
                    ))
                }
            </Spin>
        </div>
  )
}

Award.propTypes = {
  deleteAward: PropTypes.func.isRequired,
  getAwards: PropTypes.func.isRequired,
  userId: PropTypes.any.isRequired,
  awards: PropTypes.object.isRequired
}

const mapStateToProps = (state) => ({
  userId: state.loginReducer.authUser.id,
  awards: state.awardsReducer.awards
})

const mapDispatchToProps = (dispatch) => ({
  getAwards: (userId) => dispatch(handleGetAllAwards(userId)),
  deleteAward: (id) => dispatch(handleDeleteAward(id))
})

export default connect(mapStateToProps, mapDispatchToProps)(Award)
