import { Types } from './Types'

/**
 *
 * @param payload
 * @returns {{payload, type: string}}
 */
export const getStatistics = (payload) => {
  return {
    type: Types.GET_STATISTICS,
    payload
  }
}

export const getSubscriptionTiers = (payload) => {
  return {
    type: Types.GET_SUBSCRIPTION_TIERS,
    payload
  }
}

export const getGraphData = (payload) => {
  return {
    type: Types.GET_GRAPH_DATA,
    payload
  }
}

export const getUserPackages = (payload) => {
  return {
    type: Types.GET_USER_PACKAGES,
    payload
  }
}
