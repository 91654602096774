import React from 'react'
import { Document, Image, Link, Page, Text, View } from '@react-pdf/renderer'
import PropTypes from 'prop-types'
import Header from './castform-title'
import { Location } from '../../../icons/Location'
import { Email } from '../../../icons/Email'
import { Phone } from '../../../icons/Phone'
import Validate from '../../../components/validate'
import CastFormStyles, { Colors } from '../styles'
import { formatDate } from '../../../../../utils'
import { Linkedin } from '../icons/Linkedin'
import { Github } from '../icons/Github'
import { Gitlab } from '../icons/Gitlab'
import { Dot } from '../../../icons/Dot'

const styles = CastFormStyles

const DownloadCastForm = ({ resume, hiddenSections, photo, showPhoto }) => {
    const basics = resume.userInformation?.items
    const skills = resume.skillTags?.items
    const awards = resume.achivementsAwards?.items
    const certifications = resume.certificates?.items
    const work = resume.experiences?.items
    const education = resume.educations?.items
    const projects = resume.projects?.items
    const volunteer = resume.volunteer?.items
    const references = resume.references?.items

    // eslint-disable-next-line no-unused-vars
    const basicHiddenItems = resume.userInformation?.hiddenItems
    const workHiddenItems = resume.experiences?.hiddenItems
    const educationHiddenItems = resume.educations?.hiddenItems
    const awardsHiddenItems = resume.achivementsAwards?.hiddenItems
    const certificationsHiddenItems = resume?.certificates?.hiddenItems

    const { softSkills, technicalSkills } = resume.skillTags?.hiddenItems

    const checkVisibility = (name, index) => {
        return resume.experiences.items[name].description.hiddenItems[index]
    }

    return (
        <Document style={{ backgroundColor: 'red' }}>
            <Page style={styles.page}>
                <View style={{ display: 'flex', flexDirection: 'row' }}>
                    <View style={{
                        width: '35%',
                        backgroundColor: '#4D69A0',
                        paddingTop: 42,
                        paddingLeft: 27,
                        paddingRight: 27,
                        minHeight: '100vh'
                    }}>
                        <Text fixed style={{ fontSize: 8 }}>&nbsp;</Text>
                        <View style={{ textAlign: showPhoto === 'yes' ? 'center' : 'left' }}>
                            <Validate render={photo !== null && showPhoto === 'yes'}>
                                <Image style={{
                                    width: 50,
                                    height: 50,
                                    borderRadius: 100,
                                    marginLeft: 'auto',
                                    marginRight: 'auto',
                                    marginBottom: 5
                                }} src={photo}/>
                            </Validate>
                            <Validate
                                render={basics?.name && basics?.name !== '' && basicHiddenItems.name !== undefined}>
                                <Text style={{
                                    ...styles.headings,
                                    fontSize: showPhoto === 'yes' ? '15px' : '18px',
                                    marginBottom: 9
                                }}>{basics?.name}</Text>
                            </Validate>
                            <Validate
                                render={basics?.resumeHeadline && basics?.resumeHeadline !== '' && basicHiddenItems.resumeHeadline !== undefined}>
                                <Text style={{ ...styles.subHeading, marginBottom: 3 }}>{basics?.resumeHeadline}</Text>
                            </Validate>
                        </View>
                        <View style={{
                            ...styles.flex,
                            justifyContent: showPhoto === 'yes' ? 'center' : 'flex-start',
                            columnGap: 9,
                            marginTop: 3,
                            marginBottom: 35
                        }}>
                            <Validate
                                render={basics?.linkedin && basics?.linkedin !== '' && basicHiddenItems.linkedin !== undefined}>
                                <View>
                                    <Link style={{ color: 'white', textDecoration: 'none' }} target={'_blank'}
                                          src={basics?.linkedin}
                                          rel="noreferrer">
                                        <Linkedin download color={Colors.bg}/>
                                    </Link>
                                </View>
                            </Validate>
                            <Validate
                                render={basics?.github && basics?.github !== '' && basicHiddenItems.github !== undefined}>
                                <View>
                                    <Link style={{ color: 'white', textDecoration: 'none' }} target={'_blank'}
                                          src={basics?.github}
                                          rel="noreferrer">
                                        <Github download color={Colors.bg}/>
                                    </Link>
                                </View>
                            </Validate>
                            <Validate
                                render={basics?.gitLab && basics?.gitLab !== '' && basicHiddenItems.gitLab !== undefined}>
                                <View>
                                    <Link style={{ color: 'white', textDecoration: 'none' }} target={'_blank'}
                                          src={basics?.gitLab}
                                          rel="noreferrer">
                                        <Gitlab download color={Colors.bg}/>
                                    </Link>
                                </View>
                            </Validate>
                        </View>
                        <View style={{ ...styles.basics, marginBottom: 10 }}>
                            <Validate
                                render={basics?.address && basics?.address !== '' && basicHiddenItems.address !== undefined}>
                                <View style={{ ...styles.flex, marginBottom: 5 }}>
                                    <Location color={Colors.bg} download/>
                                    <Text style={{ lineHeight: 1.2 }}>
                                        {basics?.address}
                                    </Text>
                                </View>
                            </Validate>
                            <Validate
                                render={basics?.mobileNumber && basics?.mobileNumber !== '' && basicHiddenItems.mobileNumber !== undefined}>
                                <View style={{ ...styles.flex, marginBottom: 5 }}>
                                    <Phone color={Colors.bg} download/>
                                    <Text>{basics?.mobileNumber}</Text>
                                </View>
                            </Validate>
                            <Validate
                                render={basics?.resumeEmail && basics?.resumeEmail !== '' && basicHiddenItems.resumeEmail !== undefined}>
                                <View style={{ ...styles.flex, marginBottom: 5 }}>
                                    <Email color={Colors.bg} download/>
                                    <Text>{basics?.resumeEmail}</Text>
                                </View>
                            </Validate>
                        </View>

                        <Validate render={awards?.length > 0 && Object.keys(awardsHiddenItems).length !== 0}>
                            <View style={{ marginTop: 20 }}>
                                <Header title={'Awards'} color={'#fff'}/>
                                <View>
                                    {
                                        awards?.map(({ details, title }, index) => (
                                            <Validate key={index} render={awardsHiddenItems[index] !== undefined}>
                                                <View style={{ marginBottom: 10 }}>
                                                    <Text style={{
                                                        ...styles.sectionContent,
                                                        fontWeight: 700
                                                    }}>{details}</Text>
                                                    <Text style={styles.sectionContent}>{title}</Text>
                                                </View>
                                            </Validate>
                                        ))
                                    }
                                </View>
                            </View>
                        </Validate>
                        <Validate
                            render={certifications?.length > 0 && Object.keys(certificationsHiddenItems).length !== 0}>
                            <View style={{ marginTop: 30 }}>
                                <Header title={'Certifications'} color={'#fff'}/>
                                <View>
                                    {
                                        certifications?.map(({ title, organisation }, index) => (
                                            <Validate key={index}
                                                      render={certificationsHiddenItems[index] !== undefined}>
                                                <View style={{ marginBottom: 8 }}>
                                                    <Text style={{
                                                        ...styles.sectionContent,
                                                        fontWeight: 700
                                                    }}>{organisation}</Text>
                                                    <Text style={styles.sectionContent}>{title}</Text>
                                                </View>
                                            </Validate>
                                        ))
                                    }
                                </View>
                            </View>
                        </Validate>
                        <Validate render={hiddenSections?.skillTags !== undefined}>
                            <Validate
                                render={(skills?.technicalSkills?.length > 0 || skills?.technical?.softSkills > 0) && (Object.keys(softSkills).length > 0 || Object.keys(technicalSkills).length > 0)}>
                                <View style={{ marginTop: 30 }}>
                                    <Header title={'Skills'} color={'#fff'}/>
                                    <Validate
                                        render={skills?.softSkills?.length > 0 && Object.keys(softSkills).length !== 0}>
                                        <Text style={{ ...styles.sectionContent, fontWeight: 700 }}>Soft Skills</Text>
                                        <View style={{
                                            marginBottom: 8,
                                            marginTop: 2,
                                            ...styles.flex,
                                            columnGap: 2,
                                            rowGap: 1,
                                            flexWrap: 'wrap'
                                        }}>
                                            {skills?.softSkills.map((skill, index) => (
                                                <Validate render={softSkills[index] !== undefined}
                                                          key={skill}>
                                                    <Text wrap={false}
                                                          style={{ ...styles.sectionContent }}>{skill}{index !== (skills?.softSkills?.length - 1) ? ',' : ''}
                                                    </Text>
                                                </Validate>
                                            ))}
                                        </View>
                                    </Validate>

                                    <Validate
                                        render={skills?.technicalSkills?.length > 0 && Object.keys(technicalSkills).length !== 0}>
                                        <Text style={{ ...styles.sectionContent, fontWeight: 700 }}>Technical
                                            Skills</Text>
                                        <View style={{
                                            marginBottom: 8,
                                            marginTop: 2,
                                            ...styles.flex,
                                            columnGap: 2,
                                            rowGap: 1,
                                            flexWrap: 'wrap'
                                        }}>
                                            {skills?.technicalSkills.map((skill, index) => (
                                                <Validate render={technicalSkills[index] !== undefined}
                                                          key={skill}>
                                                    <Text wrap={false}
                                                          style={{ ...styles.sectionContent }}>{skill}{index !== (skills?.technicalSkills?.length - 1) ? ',' : ''}
                                                    </Text>
                                                </Validate>
                                            ))}
                                        </View>
                                    </Validate>
                                </View>
                            </Validate>
                        </Validate>
                        <Text fixed style={{ fontSize: 5 }}>&nbsp;</Text>
                    </View>
                    <View style={{ width: '65%', paddingLeft: 30, paddingTop: 42, paddingRight: 30, minHeight: '100vh' }}>
                        <Text fixed style={{ fontSize: 8 }}>&nbsp;</Text>
                        <Validate
                            render={basics?.summary && basics?.summary !== '' && basicHiddenItems.summary !== undefined}>
                            <Header title={'Profile'}/>
                            <Text style={{ ...styles.body, color: Colors.black, marginBottom: 10 }}>
                                {basics?.summary}
                            </Text>
                        </Validate>
                        <Validate render={work?.length > 0 && Object.keys(workHiddenItems).length !== 0}>
                            <View>
                                <Header title={'Experience'}/>
                                {
                                    work?.map(({
                                                   jobDurationStart,
                                                   jobDurationEnd,
                                                   url,
                                                   companyName,
                                                   jobTitle,
                                                   description,
                                                   city
                                               }, index) => (
                                        <Validate key={index} render={workHiddenItems[index] !== undefined}>
                                            <View style={{ marginBottom: 10 }}>
                                                <View style={{ ...styles.flex, justifyContent: 'space-between' }}>
                                                    <View>
                                                        <Text style={{
                                                            ...styles.sectionContent,
                                                            fontWeight: 700,
                                                            color: Colors.black,
                                                            marginBottom: 3
                                                        }}>
                                                            <Validate render={companyName !== ''}>
                                                                {companyName}
                                                            </Validate>
                                                        </Text>
                                                        <Text style={{
                                                            ...styles.body,
                                                            color: Colors.black,
                                                            marginBottom: 3
                                                        }}>
                                                            <Validate render={jobTitle !== ''}>
                                                                {jobTitle}
                                                            </Validate>
                                                        </Text>
                                                        <Text style={{ ...styles.body }}>
                                                            {formatDate(jobDurationStart, 'MMM DD, YYYY')}
                                                            - {jobDurationEnd ? formatDate(jobDurationEnd, 'MMM DD, YYYY') : 'Present'}
                                                        </Text>
                                                    </View>
                                                </View>
                                                <Validate render={description?.items.length > 0}>
                                                    {
                                                        description?.items?.map((item, index1) => (
                                                            <Validate
                                                                render={checkVisibility(index, index1) !== undefined}
                                                                key={index1}>
                                                                <View style={{
                                                                    ...styles.flex,
                                                                    alignItems: 'flex-start',
                                                                    alignContent: 'flex-start',
                                                                    justifyContent: 'flex-start',
                                                                    marginBottom: 0
                                                                }}>
                                                                    <View style={{ marginTop: 5 }}>
                                                                        <Dot download/>
                                                                    </View>
                                                                    <Text style={{ ...styles.body, color: Colors.black }}>
                                                                        {item}
                                                                    </Text>
                                                                </View>
                                                            </Validate>
                                                        ))
                                                    }
                                                </Validate>
                                            </View>
                                        </Validate>
                                    ))
                                }
                            </View>
                        </Validate>
                        <Validate render={education?.length > 0 && Object.keys(educationHiddenItems).length !== 0}>
                            <View style={{ marginTop: 10 }} wrap={false}>
                                <Header title={'Education'}/>
                                {
                                    education?.map(({
                                                        studyDurationEnd,
                                                        studyDurationStart,
                                                        school,
                                                        degreeFieldOfStudy
                                                    }, index) => (
                                        <Validate key={index} render={educationHiddenItems[index] !== undefined}>
                                            <View style={{ marginBottom: 10 }}>
                                                <View style={{ ...styles.flex, justifyContent: 'space-between' }}>
                                                    <View style={{ width: '70%' }}>
                                                        <View style={styles.flex}>
                                                            <Text style={{
                                                                ...styles.sectionContent,
                                                                color: Colors.black,
                                                                fontWeight: 700
                                                            }}>{school},
                                                            </Text>
                                                            <Text style={{ ...styles.body }}>
                                                                {formatDate(studyDurationStart, 'MMM, YYYY')} - {formatDate(studyDurationEnd, 'MMM, YYYY')}
                                                            </Text>
                                                        </View>
                                                        <Text
                                                            style={styles.body}>{degreeFieldOfStudy}
                                                        </Text>
                                                    </View>
                                                </View>
                                            </View>
                                        </Validate>
                                    ))
                                }
                            </View>
                        </Validate>
                        <Validate render={projects?.items?.length > 0}>
                            <View>
                                <Header title={'Projects'}/>
                                {
                                    projects?.items.map(({
                                                             date,
                                                             url,
                                                             name,
                                                             summary,
                                                             description,
                                                             keywords
                                                         }, index) => (
                                        <View key={index} style={{ marginBottom: 10 }}>
                                            <View>
                                                <View style={{ ...styles.flex, justifyContent: 'space-between' }}>
                                                    <Text style={styles.bodyTitle}>{name}</Text>
                                                    <Text
                                                        style={styles.bodySubTitle}>{date?.start}-{date?.end}</Text>
                                                </View>
                                                <View>
                                                    <Text style={styles.body}>{description}</Text>
                                                    <Text style={styles.body}>{summary}</Text>
                                                    <Text style={styles.body}>{url}</Text>
                                                </View>
                                            </View>
                                        </View>

                                    ))
                                }
                            </View>
                        </Validate>
                        <Validate render={volunteer?.items?.length > 0}>
                            <View>
                                <Header title={'Volunteer'}/>
                                {
                                    volunteer?.items.map(({ date, url, summary, position, organization }, index) => (
                                        <View key={index} style={{ marginBottom: 10 }}>
                                            <View>
                                                <View style={{ ...styles.flex, justifyContent: 'space-between' }}>
                                                    <View>
                                                        <Text style={styles.bodyTitle}>{organization}</Text>
                                                        <Text style={styles.bodySubTitle}>{position}</Text>
                                                    </View>
                                                    <Text>{date?.start}-{date?.end}</Text>
                                                </View>
                                                <View>
                                                    <Text style={styles.body}>{summary}</Text>
                                                    <Text style={styles.body}>{url}</Text>
                                                </View>
                                            </View>
                                        </View>

                                    ))
                                }
                            </View>
                        </Validate>
                        <Validate render={references?.items?.length > 0}>
                            <View>
                                <Header title={'References'}/>
                                {
                                    references?.items.map(({ name, email, phone, summary, relationship }, index) => (
                                        <View key={index} style={{ marginBottom: 10 }}>
                                            <View>
                                                <View>
                                                    <Text style={styles.bodyTitle}>{name}</Text>
                                                    <Text style={styles.bodySubTitle}>{relationship}</Text>
                                                    <Text style={styles.bodySubTitle}>{phone}</Text>
                                                    <Text style={styles.bodySubTitle}>{email}</Text>
                                                </View>
                                                <View>
                                                    <Text style={styles.body}>{summary}</Text>
                                                </View>
                                            </View>
                                        </View>
                                    ))
                                }
                            </View>
                        </Validate>
                        <Text fixed style={{ fontSize: 5 }}>&nbsp;</Text>
                    </View>
                </View>
            </Page>
        </Document>
    )
}

DownloadCastForm.defaultProps = {
    fontSize: 10,
    photo: null
}

DownloadCastForm.propTypes = {
    resume: PropTypes.object,
    hiddenSections: PropTypes.object,
    showPhoto: PropTypes.string,
    photo: PropTypes.string,
    fontSize: PropTypes.number
}

export default DownloadCastForm
