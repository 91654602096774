import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { connect, useSelector } from 'react-redux'
import { HiOutlineChartSquareBar } from 'react-icons/hi'
import { FiSettings } from 'react-icons/fi'
import SubscribeNow from './extensions/subscribe-now'
import { Link, useLocation } from 'react-router-dom'
import TlaDropDown from '../../../../../components/menu/tla-drop-down'
import { SidebarMenus } from '../../../../../utils/menu-items'
import { TbUserCircle } from 'react-icons/tb'
import TlaIcon from '../../../../../components/tla-icon'
import Validate from '../../../../resume-builder/components/validate'

function AppSidebar () {
    const currentApp = useSelector(state => state.appSettingsReducer.currentApp)
    const { home, profile, resumeBuilder, applyJobs } = SidebarMenus
    const [showProfile, setShowProfile] = useState(false)
    // eslint-disable-next-line no-unused-vars
    const [showResume, setShowResume] = useState(false)
    // eslint-disable-next-line no-unused-vars
    const [showNew, setShowNew] = useState(false)
    const { pathname } = useLocation()

    const icons = {
        chart: <HiOutlineChartSquareBar size={20}/>,
        settings: <FiSettings size={20}/>,
        user: <TbUserCircle size={20}/>,
        job: <TlaIcon name={'automation'}/>,
        subscription: <TlaIcon name={'subscription'}/>,
        resume: <TlaIcon name={'resume'}/>,
        'ai-generator': <TlaIcon name={'ai-generator'}/>,
        'applied-jobs': <TlaIcon name={'applied-jobs'}/>,
        'job-roles': <TlaIcon name={'job-roles'}/>,
        sessions: <TlaIcon name={'sessions'}/>,
        questions: <TlaIcon name={'questions'}/>
    }

    // eslint-disable-next-line react/prop-types
    const LinkItem = ({ link, children, active, external }) => (
        external
            ? <a href={link} target={'_blank'}
                 className={`${active ? 'text-primary-700' : 'text-gray-700'} flex items-center gap-x-2 hover:text-primary-700 rounded-md text-sm px-4 py-2`} rel="noreferrer">
                {children}
            </a>
            : <Link to={link} rel="opener"
                    className={`${active ? 'text-primary-700' : 'text-gray-700'} flex items-center gap-x-2 hover:text-primary-700 rounded-md text-sm px-4 py-2`}>
                {children}
            </Link>
    )

    return (
        <div
            className={'flex flex-col justify-between w-[200px] md:min-w-[229px] min-h-[580px] md:pt-[47px]' +
                ' rounded-lg h-fit p-4 mb-5'}>
            <div className={'flex flex-col gap-1'}>
                <LinkItem link={home.cvBuilderLink} active={pathname === home.cvBuilderLink}>
                    {icons.chart} {home.title}
                </LinkItem>
                <Validate render={currentApp === 'cv-builder'}>
                    {
                        resumeBuilder.children.map((itm, index) => (
                            <LinkItem key={index} link={itm.link} active={pathname === itm.link}>
                                {icons[itm.icon]} {itm.title}
                            </LinkItem>
                        ))
                    }
                    <TlaDropDown icon={icons[profile.icon]} item={profile}
                                 show={showProfile}
                                 setShow={() => {
                                     setShowNew(false)
                                     setShowResume(false)
                                     setShowProfile(opener => !opener)
                                 }}/>
                </Validate>
                <Validate render={currentApp === 'auto-apply'}>
                    {
                        applyJobs.children.map((itm, index) => (
                            <LinkItem key={index} link={itm.link} active={pathname === itm.link}>
                                {icons[itm.icon]} {itm.title}
                            </LinkItem>
                        ))
                    }
                </Validate>
            </div>
            <div className={'flex flex-col gap-8 mt-[17px]'}>
                <Validate render={currentApp === 'auto-apply'}>
                    <SubscribeNow/>
                </Validate>
            </div>
        </div>

    )
}

AppSidebar.propTypes = {
    authUser: PropTypes.object.isRequired
}

const mapStateToProps = (state) => {
    return {
        authUser: state.loginReducer.authUser
    }
}

export default connect(mapStateToProps)(AppSidebar)
