import { Path, Svg } from '@react-pdf/renderer'
import React from 'react'
import PropTypes from 'prop-types'

export const Dot = ({ color, download, size }) => (
    download
        ? <Svg fill={color} strokeWidth="0" viewBox="0 0 512 512" heigh={`${size}px`}
               width={`${size}px`} xmlns="http://www.w3.org/2000/svg">
            <Path d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z"></Path>
        </Svg>
        : <svg fill={color} strokeWidth="0" viewBox="0 0 512 512" height="2px"
               width="2px" xmlns="http://www.w3.org/2000/svg">
            <path d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8z"></path>
        </svg>
)

Dot.defaultProps = {
    color: 'currentColor',
    download: false,
    size: 2
}

Dot.propTypes = {
    color: PropTypes.string,
    download: PropTypes.bool,
    size: PropTypes.number
}
