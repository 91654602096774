import { Path, Svg, G, Defs, Rect, ClipPath } from '@react-pdf/renderer'
import React from 'react'
import PropTypes from 'prop-types'

export const Location = ({ color, download }) => (
    download
        ? <Svg xmlns="http://www.w3.org/2000/svg" width="12" height="13" viewBox="0 0 12 13" fill={color}>
            <G clipPath="url(#clip0_2975_22223)">
                <Path
                    d="M10.5 5.27734C10.5 8.97179 6 12.1385 6 12.1385C6 12.1385 1.5 8.97179 1.5 5.27734C1.5 4.01757 1.97411 2.80938 2.81802 1.91859C3.66193 1.02779 4.80653 0.527344 6 0.527344C7.19347 0.527344 8.33807 1.02779 9.18198 1.91859C10.0259 2.80938 10.5 4.01757 10.5 5.27734Z"
                    stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
                <Path
                    d="M6 6.86068C6.82843 6.86068 7.5 6.15179 7.5 5.27734C7.5 4.40289 6.82843 3.69401 6 3.69401C5.17157 3.69401 4.5 4.40289 4.5 5.27734C4.5 6.15179 5.17157 6.86068 6 6.86068Z"
                    stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
            </G>
            <Defs>
                <ClipPath id="clip0_2975_22223">
                    <Rect width="12" height="12.6667" fill="white"/>
                </ClipPath>
            </Defs>
        </Svg>
        : <svg xmlns="http://www.w3.org/2000/svg" width="12" height="13" viewBox="0 0 12 13" fill={color}>
            <g clipPath="url(#clip0_2975_22223)">
                <path
                    d="M10.5 5.27734C10.5 8.97179 6 12.1385 6 12.1385C6 12.1385 1.5 8.97179 1.5 5.27734C1.5 4.01757 1.97411 2.80938 2.81802 1.91859C3.66193 1.02779 4.80653 0.527344 6 0.527344C7.19347 0.527344 8.33807 1.02779 9.18198 1.91859C10.0259 2.80938 10.5 4.01757 10.5 5.27734Z"
                    stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
                <path
                    d="M6 6.86068C6.82843 6.86068 7.5 6.15179 7.5 5.27734C7.5 4.40289 6.82843 3.69401 6 3.69401C5.17157 3.69401 4.5 4.40289 4.5 5.27734C4.5 6.15179 5.17157 6.86068 6 6.86068Z"
                    stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
            </g>
            <defs>
                <clipPath id="clip0_2975_22223">
                    <rect width="12" height="12.6667" fill="white"/>
                </clipPath>
            </defs>
        </svg>
)

Location.defaultProps = {
    color: '#000',
    download: false
}

Location.propTypes = {
    color: PropTypes.string,
    download: PropTypes.bool
}
