import { Path, Svg } from '@react-pdf/renderer'
import React from 'react'
import PropTypes from 'prop-types'

export const Gitlab = ({ color, download }) => (
    download
        ? <Svg fill={color} strokeWidth="0" viewBox="0 0 512 512" height="16px"
               width="16px" xmlns="http://www.w3.org/2000/svg">
            <Path
                d="M105.2 24.9c-3.1-8.9-15.7-8.9-18.9 0L29.8 199.7h132c-.1 0-56.6-174.8-56.6-174.8zM.9 287.7c-2.6 8 .3 16.9 7.1 22l247.9 184-226.2-294zm160.8-88l94.3 294 94.3-294zm349.4 88l-28.8-88-226.3 294 247.9-184c6.9-5.1 9.7-14 7.2-22zM425.7 24.9c-3.1-8.9-15.7-8.9-18.9 0l-56.6 174.8h132z"></Path>
        </Svg>
        : <svg fill={color} strokeWidth="0" viewBox="0 0 512 512" height="16px"
               width="16px" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M105.2 24.9c-3.1-8.9-15.7-8.9-18.9 0L29.8 199.7h132c-.1 0-56.6-174.8-56.6-174.8zM.9 287.7c-2.6 8 .3 16.9 7.1 22l247.9 184-226.2-294zm160.8-88l94.3 294 94.3-294zm349.4 88l-28.8-88-226.3 294 247.9-184c6.9-5.1 9.7-14 7.2-22zM425.7 24.9c-3.1-8.9-15.7-8.9-18.9 0l-56.6 174.8h132z"></path>
        </svg>
)

Gitlab.defaultProps = {
    color: 'currentColor',
    download: false
}

Gitlab.propTypes = {
    color: PropTypes.string,
    download: PropTypes.bool
}
