import api from '../../utils/api'
import { downloadCoverLetter, downloadResume, uploadResume } from './ActionCreator'

/**
 * Store a newly created resource in storage.
 * @param data
 * @returns {function(*): Promise<unknown>}
 */
export const handleDownloadResume = (data) => (dispatch) => {
    return new Promise((resolve, reject) => {
        api().post('/job/download-user-resume', data, {
            timeout: 300000
        }).then((res) => {
            dispatch(downloadResume(res.data))
            resolve(res)
        }).catch((err) => {
            reject(err)
        })
    })
}

/**
 * Store a newly created resource in storage.
 * @param data
 * @returns {function(*): Promise<unknown>}
 */
export const handleDownloadCoverLetter = (data) => (dispatch) => {
    return new Promise((resolve, reject) => {
        api().post('/job/download-user-cover-letter', data).then((res) => {
            dispatch(downloadCoverLetter(res.data))
            resolve(res)
        }).catch((err) => {
            reject(err)
        })
    })
}

/**
 * Store a newly created resource in storage.
 * @returns {function(*): Promise<unknown>}
 * @param data
 * @param id
 */
export const handleUploadResume = (data, id) => (dispatch) => {
    return new Promise((resolve, reject) => {
        api().post(`/job/resume-reader/${id}`, data, {
            headers: { 'Content-type': 'multipart/form-data' }
        }).then((res) => {
            dispatch(uploadResume(res.data))
            resolve(res)
        }).catch((err) => {
            reject(err)
        })
    })
}
