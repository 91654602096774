import api from '../../utils/api'
import sessionStorage from 'redux-persist/lib/storage/session'
import { authenticate, getProfile, removeAuth, updateProfile } from './ActionCreators'

/**
 * @param data
 * @returns {function(*): Promise<unknown>}
 */
export const handleAuthentication = (data) => (dispatch) => {
    return new Promise((resolve, reject) => {
        return api().post('/auth/login', data).then((response) => {
            dispatch(authenticate(response.data))
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const handleLogout = () => (dispatch) => {
    return new Promise((resolve, reject) => {
        api().get('/auth/logout').then(() => {
            sessionStorage.removeItem('persist:root')
            dispatch(removeAuth())
            resolve()
        }).catch((err) => {
            reject(err)
        })
    })
}

/**
 * @param userId
 * @param data
 * @returns {function(): Promise<unknown>}
 */
export const handleChangePassword = (userId, data) => () => {
    return new Promise((resolve, reject) => {
        api().patch(`/auth/changepassword/${userId}`, data).then((res) => {
            resolve(res)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const handleGetProfile = () => dispatch => {
    return new Promise((resolve, reject) => {
        api().get('/users/me').then((res) => {
            dispatch(getProfile(res.data))
            resolve(res)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const handleUpdateProfile = (userId, data) => dispatch => {
    return new Promise((resolve, reject) => {
        api('multipart/form-data').put(`/users/updateuser/${userId}`, data).then((res) => {
            dispatch(updateProfile(res.data))
            resolve(res)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const handleAskGptForSummary = (data) => dispatch => {
    return new Promise((resolve, reject) => {
        api().post('/job/ask-chatgpt4-job-resume-summary', data).then((res) => {
            resolve(res)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const handleUploadDp = (data) => dispatch => {
    return new Promise((resolve, reject) => {
        api().post(`/profile/${data.id}/upload-dp`, data).then((response) => {
            dispatch(authenticate(response.data))
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

/**
 *
 * @param data
 * @returns {function(*): Promise<unknown>}
 */
export const handleRegistration = (data) => (dispatch) => {
    return new Promise((resolve, reject) => {
        api().post('/auth/register', data).then((res) => {
            dispatch(authenticate(res.data))
            resolve(res.data)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const handleRegister = (data) => () => {
    return new Promise((resolve, reject) => {
        api().post('/auth/register-with-job-query', data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then((res) => {
            resolve(res.data)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const handleResetPassword = (data, token) => () => {
    return new Promise((resolve, reject) => {
        api().patch(`/auth/resetpassword/${token}`, data).then((res) => {
            resolve(res)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const handleForgotPassword = (data) => (dispatch) => {
    return new Promise((resolve, reject) => {
        api().post('/auth/forgotpassword', data).then((response) => {
            // dispatch(authenticate(response.data))
            resolve(response)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const handleEmailVerification = (token) => () => {
    return new Promise((resolve, reject) => {
        api().get(`/auth/verifyemail/${token}`).then(() => {
            resolve()
        }).catch((err) => {
            reject(err)
        })
    })
}

export const handleResendEmailVerification = () => (dispatch) => {
    return new Promise((resolve, reject) => {
        api().post('/auth/email/verify/resend')
            .then((res) => {
                resolve(res)
            }).catch((err) => {
            reject(err)
        })
    })
}
