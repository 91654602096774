import { Font, StyleSheet } from '@react-pdf/renderer'
import { Colors } from '../castform/styles'

Font.register({
    fontStyle: 'normal',
    fontWeight: 'normal',
    src: '',
    family: 'Times-Roman'
})

Font.registerHyphenationCallback(word => [word])

const SimpleStyles = StyleSheet.create({
    page: {
        fontFamily: 'Times-Roman',
        backgroundColor: '#fff',
        paddingTop: 35,
        paddingBottom: 35
    },
    body: {
        fontSize: 12
    },
    itemsEnd: {
        display: 'flex', flexDirection: 'column', justifyContent: 'flex-end', alignItems: 'flex-end'
    },
    bodyTitle: {
        fontFamily: 'Times-Roman',
        fontSize: 10,
        fontWeight: 'bold'
    },
    bodySubTitle: {
        fontFamily: 'Times-Roman',
        fontSize: 10,
        opacity: 0.5
    },
    sectionTitle: {
        fontFamily: 'Times-Roman',
        fontSize: 12,
        color: 'black'
    },
    sectionContent: {
        fontFamily: 'Times-Roman',
        fontSize: 10,
        color: 'black'
    },
    basics: {
        fontFamily: 'Times-Roman',
        fontSize: 10,
        color: 'black'
    },
    headings: {
        fontFamily: 'Times-Roman',
        fontSize: 25,
        fontWeight: 700,
        color: Colors.black
    },
    subHeading: {
        fontFamily: 'Times-Roman',
        fontSize: 14,
        color: '#64748B'
    },
    flex: {
        fontFamily: 'Times-Roman',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        alignContent: 'center',
        gap: 5,
        marginBottom: 10
    },
    p: {
        fontFamily: 'Times-Roman',
        margin: 10,
        fontSize: 12,
        pAlign: 'justify'
    }
})

export default SimpleStyles
