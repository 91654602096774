import React from 'react'
import { useDispatch } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import { TlaModal } from '../../components/pop-ups/tla-modal'
import { Button } from 'antd'

function ResumeNotInSync () {
    const { state } = useLocation()
    const navigate = useNavigate()

    const dispatch = useDispatch()

    const goToResume = () => {
        dispatch({
            type: 'NEW_RESUME',
            payload: state?.data
        })
        navigate('/build-resume')
    }
    return (
        <TlaModal allowClose={false} width={500} title={'Resume Not in Sync with Profile'}>
            <p className={'my-3'}>
                This action will sync resume data with profile data by editing or removing data from the resume
            </p>
            <div className={'flex justify-between gap-x-3'}>
                <Button onClick={() => navigate(-1)} className={'btn btn-light w-full'} size={'large'}>Cancel</Button>
                <Button onClick={goToResume} className={'btn btn-primary-700 w-full'} size={'large'}>Continue</Button>
            </div>
        </TlaModal>
    )
}

ResumeNotInSync.propTypes = {

}

export default ResumeNotInSync
