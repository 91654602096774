import React from 'react'
import { IoClose } from 'react-icons/io5'
import PropTypes from 'prop-types'

export const SkillItem = ({ icon, title, onClick }) => {
  return (
        <span
            className={'flex w-fit items-center gap-1 text-blue-700 bg-blue-50 text-xs py-0.5 px-2 rounded-2xl'}>
            {icon && <IoClose onClick={onClick} className={'cursor-pointer text-primary-500 font-bold'}/>} <span className={'font-medium'}>{title}</span>
        </span>
  )
}

SkillItem.defaultProps = {
  icon: true,
  title: 'Title'
}

SkillItem.propTypes = {
  icon: PropTypes.bool,
  title: PropTypes.string,
  onClick: PropTypes.func
}
