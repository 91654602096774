import React from 'react'
import { Button } from 'antd'
import { useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import { createGlobalStyle } from 'styled-components'

const GlobalStyles = createGlobalStyle`

  .cancel-button {
    background: var(--White);
    border: 1px solid var(--Gray-300);
    box-shadow: var(--Shadow-xm);
    border-radius: 8px !important;
    height: 40px !important;
  }
`

function TlaCloseModal ({ text }) {
    const navigate = useNavigate()
    return (
        <>
            <GlobalStyles/>
            <Button size={'large'} className={'btn cancel-button'} block onClick={() => {
                navigate(-1)
            }}>
                {text}
            </Button>
        </>
    )
}

TlaCloseModal.defaultProps = {
    text: 'Cancel'
}

TlaCloseModal.propTypes = {
    text: PropTypes.string
}

export default TlaCloseModal
