import React, { useEffect, useState } from 'react'
import { useOutletContext } from 'react-router'
import TlaTableWrapper from '../../../components/table/tla-table-wrapper'
import { Button, Spin, Table } from 'antd'
import PropTypes from 'prop-types'
import { handleDeleteUser, handleGetAllUsers } from '../../../actions/admin/users/Action'
import { connect, useSelector } from 'react-redux'
import TlaEdit from '../../../components/tla-edit'
import { FiEdit2 } from 'react-icons/fi'
import TlaConfirm from '../../../components/tla-confirm'
import { TlaError, TlaSuccess } from '../../../utils/messages'
import { formatDate } from '../../../utils'
import TlaAddNew from '../../../components/common/tla-add-new'
import TlaIcon from '../../../components/tla-icon'

const Users = ({ getUsers, deleteUser }) => {
    const { setPageInfo } = useOutletContext()

    const { users, filter } = useSelector(state => state.adminUsersReducer)
    const { data, meta } = users
    const [loading, setLoading] = useState(true)
    useEffect(() => {
        setPageInfo({
            title: 'Users',
            buttonText: '',
            buttonLink: '',
            subTitle: '',
            helpingText: '',
            showSearch: true
        })
        getUsers(new URLSearchParams(filter)).then(() => setLoading(false)).catch((err) => TlaError(err.response?.data?.message))
    }, [])

    return (
        <div className={'pb-4'}>
            <Spin spinning={loading}>
                <TlaTableWrapper filterObj={filter} meta={meta} data={data} showHeader={false} callbackFunction={getUsers}>
                    <Table.Column title={'Name'} render={((record) => (
                        <div className={'flex items-center gap-2'}>
                            <TlaIcon name={'avatar'}/>
                            <p>{record.userInformation.name}</p>
                        </div>
                    ))}/>
                    <Table.Column title={'Subscription Tier'} dataIndex={['subscriptionTier', 'subscriptionName']}/>
                    <Table.Column title={'Date Purchased'} render={((record) => (
                        <p>{formatDate(record?.UserSubscription?.datePurchased, 'MMM DD, YYYY')}</p>
                    ))}/>
                    <Table.Column title={'Renewal Date'} render={((record) => (
                        <p>{formatDate(record?.UserSubscription?.dateRenewal, 'MMM DD, YYYY')}</p>
                    ))}/>
                    <Table.Column title={'Quota Date'} render={((record) => (
                        <p>{formatDate(record?.UserSubscription?.quoteRenewalDate, 'MMM DD, YYYY')}</p>
                    ))}/>
                    <Table.Column title={'Actions'} render={((record) => (
                        <TlaAddNew data={{
                            userSubscriptionId: '' + record?.UserSubscription?.id
                        }} link={'subscription'}>
                            <Button className={'btn btn-primary-light'}>Change Quota</Button>
                        </TlaAddNew>
                    ))}/>
                    <Table.Column title={'Actions'} render={((record) => (
                        <div className={'flex items-center  gap-3'}>
                            <TlaConfirm title={'Delete User'}
                                        fullText={'Do you really want to delete this User?'}
                                        callBack={() => {
                                            setLoading(true)
                                            deleteUser(record.id, !record.active).then(() => {
                                                setLoading(false)
                                                TlaSuccess(`User ${record.active ? 'Deactivated ' : 'Activated'} Successfully`)
                                            })
                                        }}/>
                            <TlaEdit data={record} icon={<FiEdit2 size={20} className={'icon text-gray-500'}/>}
                                     link={'form'}/>
                        </div>
                    ))}/>
                </TlaTableWrapper>
            </Spin>
        </div>
    )
}

Users.propTypes = {
    getUsers: PropTypes.func.isRequired,
    deleteUser: PropTypes.func.isRequired
}

/**
 *
 * @param dispatch
 * @returns {{getUsers: (function(*): *)}}
 */
const mapDispatchToProps = (dispatch) => ({
    getUsers: (params) => dispatch(handleGetAllUsers(params)),
    deleteUser: (userId, active) => dispatch(handleDeleteUser(userId, active))
})

export default connect(null, mapDispatchToProps)(Users)
