import React from 'react'
import { Outlet, Route, Routes, useLocation } from 'react-router-dom'
import SignIn from '../../pages/auth/sign-in'
import Register from '../../pages/auth/register'
import ForgotPassword from '../../pages/auth/forgot-password'
import ResetPassword from '../../pages/auth/reset-password'
import Layout from '../../pages/admin/layout'
import { Dashboard } from '../../pages/admin/dashboard'
import PageWrapper from '../../pages/admin/layout/page-wrapper'
import { AdminModalRoute } from './admin-modal-route'
import Users from '../../pages/admin/users'
import ChangePassword from '../../pages/my-profile/change-password'

export const AdminProtectedRoutes = () => {
  const location = useLocation()
  const background = location.state && location.state.background

  return (
        <>
            <Routes location={background || location}>
                <Route exact element={<Layout/>} path="/">
                    <Route index element={<Dashboard/>}/>
                    <Route exact element={<Dashboard/>} path="dashboard"/>
                    <Route path="settings" index element={<ChangePassword/>}/>
                    <Route exact element={<PageWrapper/>}>
                        <Route exact element={<Users/>} path="/users"/>
                    </Route>
                    <Route exact element={<>Settings</>} path="/settings"/>
                </Route>
                <Route exact element={<SignIn/>} path="/"/>
                <Route exact element={<SignIn/>} path="/sign-in"/>
                <Route element={<Register/>} path={'/sign-up'}/>
                <Route element={<ForgotPassword/>} path={'/forgot-password'}/>
                <Route element={<ResetPassword/>} path={'/reset-password'}/>
                <Route path={'*'} element={<SignIn/>}/>
            </Routes>

            {background && (<><AdminModalRoute/> <Outlet/></>)}
        </>
  )
}
