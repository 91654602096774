import axios from 'axios'
import { Store } from './store'
import sessionStorage from 'redux-persist/lib/storage/session'
import { removeAuth } from '../actions/authenticate/ActionCreators'

export default function api (contentType = 'application/json') {
    const makeRequest = axios.create({
        baseURL: process.env.REACT_APP_API_PATH,
        headers: {
            Accept: 'application/json',
            'Content-Type': contentType,
            Authorization: `Bearer ${Store.getState().loginReducer.authToken}`
        }
    })

    makeRequest.interceptors.request.use(function (config) {
        return config
    }, function (error) {
        return Promise.reject(error)
    })

    makeRequest.interceptors.response.use(response => {
            return response
        }, error => {
            const code = error && error.response ? error.response.status : 0
            const paths = [
                '/sign-in',
                '/forgot-password',
                '/resetpassword/:token'
            ]

            // if ((code === 401 || code === 419) &&
            if ((code === 401 || code === 403 || code === 419) &&
                !paths.includes(window.location.pathname.replace(/\/[^\\/]+$/, '/:token'))) {
                document.cookie = 'authData=; expires=Thu, 01 Jan 1970 00:00:00 UTC;'
                sessionStorage.removeItem('persist:root')
                Store.dispatch(removeAuth())
                window.location.replace('/sign-in')
            }
            return Promise.reject(error)
        }
    )

    return makeRequest
}
