import React from 'react'
import { useSelector } from 'react-redux'
import Validate from '../../resume-builder/components/validate'
import DashboardWidgetItem from './dashboard-widget-item'
import { formatDate } from '../../../utils'

function Widget () {
    const currentApp = useSelector(state => state.appSettingsReducer.currentApp)
    const statistics = useSelector(state => state.dashboardReducer.statistics)
    const pkg = useSelector(state => state.dashboardReducer?.userPackage)

    const items = [
        {
            title: 'AutoApply Jobs (Paid) Applied',
            total: statistics?.totalJobsApplied?.used ?? 0,
            other: `${statistics?.totalJobsApplied?.left ?? 0} Remaining`,
            app: 'auto-apply',
            bg: 'primary-600',
            color: 'white-base'
        },
        {
            title: 'Total Jobs Viewed',
            total: statistics?.totalJobsVied ?? 0,
            app: 'auto-apply',
            bg: 'primary-100'
        },
        {
            title: 'Total Job Roles',
            total: statistics?.jobQueries ?? 0,
            other: '',
            app: 'auto-apply',
            bg: 'primary-100'
        },
        {
            title: 'Resume Templates Created',
            total: statistics?.resumeTemplates ?? 0,
            other: '',
            app: 'cv-builder'
        },
        {
            title: 'Custom Letters Generated',
            total: statistics?.customCoverLetters?.used ?? 0,
            other: `${statistics?.customCoverLetters?.left ?? 0} Remaining`,
            app: 'cv-builder'
        },
        {
            title: 'Custom Resumes Generated',
            total: statistics?.customResumes?.used ?? 0,
            other: `${statistics?.customResumes?.left ?? 0} Left`,
            app: 'cv-builder'
        }
    ]

    return (
        <>
            <div className={'mb-5'}>
                <p className={'text-gray-900 text-[18px] font-medium'}>Your Package Stats</p>
                <p className={'text-gray-500 text-sm font-normal'}>
                    Renews on: {formatDate(pkg?.nextRenewalDate, 'DD MMM, YYYY')}
                </p>
            </div>
            <div className={'flex justify-between gap-x-[17px]'}>
                {
                    items.map(({ title, total, other, app, bg, color }, index) => (
                        <Validate render={currentApp === app} key={title}>
                            <div className={`${index === 0 ? 'w-[378px]' : 'w-[260px]'}`}>
                                <DashboardWidgetItem bg={bg ?? ''} color={color ?? ''} other={other} title={title}
                                                     total={total}/>
                            </div>
                        </Validate>
                    ))
                }
            </div>
        </>
    )
}

export default Widget
