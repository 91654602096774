import React, { useEffect, useState } from 'react'
import { Button } from 'antd'
import { connect, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { useLocation, useNavigate } from 'react-router-dom'
import CloseModal from '../../components/close-modal'
import TagsInput from './tags-input'
import { TlaModal } from '../../components/pop-ups/tla-modal'
import { handleAddSkill, handleDeleteSkill, handleGetAllSkills } from '../../actions/skills/Action'
import { TlaError, TlaSuccess } from '../../utils/messages'
import TlaAddNew from '../../components/common/tla-add-new'

function SkillsForm ({ userId, addSkills, deleteSkill }) {
    const { data } = useSelector(state => state.skillsReducer.skills)
    const { state } = useLocation()
    // eslint-disable-next-line no-unused-vars
    const { subscriptionPackage } = useSelector(state => state.dashboardReducer.userPackage)
    const [loading, setLoading] = useState(false)
    const [softSkills, setSoftSkills] = useState(data?.softSkills ?? [])
    const [technicalSkills, setTechnicalSkills] = useState(data?.technicalSkills ?? [])
    const navigate = useNavigate()
    const gtpSkills = useSelector(state => state.askChatGptReducer.chatGptSkills)

    useEffect(() => {
        mergeSkills()
    }, [])

    const submit = () => {
        setLoading(true)
        addSkills(userId, { technicalSkills, softSkills }).then(() => {
            setLoading(false)
            TlaSuccess('Skill Tags Updated')
            navigate(-1)
        }).catch(() => {
            setLoading(false)
            TlaError('Something went wrong')
        })
    }

    const mergeSkills = () => {
        const newSoftSkills = gtpSkills?.soft_skills
        setSoftSkills(Array.from(new Set([...softSkills, ...newSoftSkills])).slice(0, 15))

        const newTechSkills = gtpSkills?.technical_skills
        setTechnicalSkills(Array.from(new Set([...technicalSkills, ...newTechSkills])).slice(0, 15))
    }

    return (
        <TlaModal allowClose={false} title={''} loading={loading}>
            <div className={'flex justify-between mb-[31px]'}>
                <h3 className={'text-lg text-gray-900 font-medium'}>Add Skills</h3>
                {/* {
                    subscriptionPackage.subscriptionName === 'free'
                        ? <UpgradeModal/>
                        : <TlaAddNew link={'ask-gpt'} pathname={'/skills'}>
                            <span className={'text-sm font-medium text-primary-700'}>Ask ChatGPT</span>
                        </TlaAddNew>
                } */}
                <TlaAddNew link={'ask-gpt'} pathname={state?.data?.previousPath ?? '/cv-builder/skills'}>
                    <span className={'text-sm font-medium text-primary-700'}>Ask ChatGPT</span>
                </TlaAddNew>

            </div>
            <div>
                <TagsInput userId={userId}
                           deleteTag={deleteSkill}
                           setSoftSkills={setSoftSkills}
                           softSkills={softSkills}
                           setTechnicalSkills={setTechnicalSkills}
                           technicalSkills={technicalSkills}/>
            </div>
            <div className={'flex gap-2'}>
                <div className={'w-full'}>
                    <CloseModal height={'!h-11'}/>
                </div>
                <Button className={'btn btn-primary w-full !h-11'} size={'large'} onClick={submit}>
                    Save
                </Button>
            </div>
        </TlaModal>
    )
}

SkillsForm.propTypes = {
    addSkills: PropTypes.func.isRequired,
    deleteSkill: PropTypes.func.isRequired,
    getSkills: PropTypes.func.isRequired,
    userId: PropTypes.number.isRequired
}

/**
 *
 * @param state
 * @returns {{userId: BufferSource}}
 */
const mapStateToProps = (state) => ({
    userId: state.loginReducer.authUser.id
})

/**
 *
 * @param dispatch
 * @returns {{addSkills: (function(*, *): *)}}
 */
const mapDispatchToProps = (dispatch) => ({
    addSkills: (userId, data) => dispatch(handleAddSkill(userId, data)),
    deleteSkill: (userId, skill) => dispatch(handleDeleteSkill(userId, skill)),
    getSkills: (userId) => dispatch(handleGetAllSkills(userId))
})

export default connect(mapStateToProps, mapDispatchToProps)(SkillsForm)
