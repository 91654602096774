import React, { useEffect, useState } from 'react'
import Widget from './widget'
import JobsApplied from './jobs-applied'
import { connect, useSelector } from 'react-redux'
import Validate from '../resume-builder/components/validate'
import JobQueries from '../auto-apply/job-queries'
import { handleGetStatistics } from '../../actions/dashboard/Actions'
import PropTypes from 'prop-types'
import { Spin } from 'antd'
import { useNavigate } from 'react-router-dom'
import Banner from '../../components/auto-apply/banner'

function Dashboard ({ getStatistics }) {
    const user = useSelector(state => state.loginReducer.authUser)
    const navigate = useNavigate()
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        getStatistics(user?.id).then((res) => {
            if (res?.data?.data?.jobQueries === 0) {
                navigate('/first-time-user')
            }
            setLoading(false)
        })
    }, [])

    const currentApp = useSelector(state => state.appSettingsReducer.currentApp)

    return (
        <div className={'max-w-[907px] rounded-lg py-[30px] px-[35px]'}>
            <div className={'border-b pb-1 mb-[40px]'}>
                {/* <AutoApplyPaused/> */}
                <Banner/>
                <JobQueries/>
            </div>
            <div className={'mb-[46px]'}>
                <Spin spinning={loading}>
                    <Widget/>
                </Spin>
            </div>
            <Validate render={currentApp === 'auto-apply'}>
                <JobsApplied/>
            </Validate>
        </div>
    )
}

Dashboard.propTypes = {
    getStatistics: PropTypes.func.isRequired
}

const mapDispatchToProps = (dispatch) => ({
    getStatistics: (userId) => dispatch(handleGetStatistics(userId))
})

export default connect(null, mapDispatchToProps)(Dashboard)
