import React, { useState } from 'react'
import { Menu } from 'antd'
import { createGlobalStyle } from 'styled-components'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { useLocation } from 'react-router'

const GlobalStyles = createGlobalStyle`
  .ant-menu-inline {
    border: none !important;
    background-color: transparent !important;
  }

  .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    background-color: #FFFFFF !important;
  }

  :where(.css-dev-only-do-not-override-1n7nwfa).ant-menu-light .ant-menu-item-selected {
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    padding: 8px 12px !important;
    color: #53B1FD !important;
    width: fit-content !important;
    height: 36px !important;
    background: #EFF8FF !important;
    border-radius: 6px !important;
  }

  .ant-menu-title-content .nav-item {
    color: var(--Gray-500);
    font-family: var(--Popins) !important;
  }

  .nav-icon {
    font-size: 20px !important;
    color: var(--Gray-500) !important;
  }

  .ant-menu-horizontal,
  :where(.css-dev-only-do-not-override-1n7nwfa).ant-menu-light.ant-menu-horizontal > .ant-menu-item-selected::after {
    border: none !important;
    background-color: transparent !important;
  }

  .menu-bar {
    height: 2px;
    background-color: var(--Gray-900);
    width: 15px;
    margin-bottom: 3px;
  }

  :where(.css-dev-only-do-not-override-1n7nwfa).ant-menu-light.ant-menu-horizontal > .ant-menu-item:after {
    border: none !important;
  }

  :where(.css-dev-only-do-not-override-1n7nwfa).ant-menu-light.ant-menu-horizontal > .ant-menu-item:hover {
    border: none !important;
    color: #53B1FD !important;
  }
`

const rootSubmenuKeys = []

// eslint-disable-next-line react/prop-types
function MenuHelper ({ permissions, role, menus, direction, icons, linkStyles }) {
  const location = useLocation()
  const [openKeys, setOpenKeys] = useState([])

  // eslint-disable-next-line no-unused-vars
  const { pathname } = useLocation()

  const setActiveNav = (navItem) => {
    return navItem === pathname.replace('/', '') ? 'active-nav-item' : 'nav-item'
  }
  const onOpenChange = keys => {
    const latestOpenKey = keys.find(key => openKeys.indexOf(key) === -1)
    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys)
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : [])
    }
  }

  function getItem (label, icon, key, children) {
    return {
      key,
      icon,
      children,
      label
    }
  }

  const items = menus.map((nav) => {
    rootSubmenuKeys.push(nav.title)

    return getItem(
            <Link
                className={'nav-item'}
                style={linkStyles} to={nav.link}>
                {nav.title}
            </Link>,
            <>{icons[nav.icon]}&nbsp;&nbsp;</> ?? '',
            nav.title,
            nav.children.length && nav.children.map((child) => (
              getItem(
                        <Link className={`${setActiveNav(child.title.toLowerCase())}`}
                              style={linkStyles}
                              state={{ background: child.modal && location }}
                              to={child.link}>
                            {child.title}
                        </Link>, icons[child.icon] ?? '', child.title)
            )
            )
    )
  })

  return (
        <>
            <GlobalStyles/>
            <Menu overflowedIndicator={
                <>
                    <div className={'menu-bar'}/>
                    <div className={'menu-bar'}/>
                    <div className={'menu-bar'}/>
                </>
            }
                  defaultSelectedKeys={['home']} openKeys={openKeys} onOpenChange={onOpenChange}
                  mode={direction}
                  theme="light"
                  items={items}
            />
        </>
  )
}

MenuHelper.defaultProps = {
  direction: 'horizontal',
  icons: {},
  role: ''
}

MenuHelper.propTypes = {
  permissions: PropTypes.array.isRequired,
  menus: PropTypes.array.isRequired,
  direction: PropTypes.string,
  role: PropTypes.string.isRequired,
  icons: PropTypes.object
}

const mapStateToProps = (state) => {
  return {
    permissions: [],
    role: state.loginReducer.authUser.user_type
  }
}

export default connect(mapStateToProps)(MenuHelper)
