import { Types } from '../actions/ask-chat-gpt/Types'
import { Types as ExperienceType } from '../actions/experience/Types'

const initialState = {
    chatGptSkills: {
        soft_skills: [],
        technical_skills: []
    },
    chatGptExperiences: {
        responsibilities: []
    }
}

/**
 *
 * @param state
 * @param action
 *
 * @returns {{gptResponse}}
 */
export default function askChatGptReducer (state = initialState, action) {
    switch (action.type) {
        case Types.ASK_CHAT_GPT_SKILLS: {
            return {
                ...state,
                chatGptSkills: action.payload.chatgptResponse
            }
        }

        case Types.ASK_CHAT_GPT_EXPERIENCES: {
            return {
                ...state,
                chatGptExperiences: {
                    responsibilities: [...state.chatGptExperiences.responsibilities, ...action.payload.chatgptResponse.responsibilities]
                }
            }
        }

        case ExperienceType.NEW_EXPERIENCE:
        case Types.CLEAR_EXPERIENCES: {
            return {
                ...state,
                chatGptExperiences: {
                    responsibilities: []
                }
            }
        }

        default:
            return state
    }
}
