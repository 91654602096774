import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'

function Extensions ({ title, text, btnText, link }) {
    return (
        <div className={'w-[201px] mx-auto bg-primary-50 px-4 py-5 flex flex-col align-start gap-4 rounded-lg'}>
            <div>
                <h6 className={'text-sm font-medium text-primary-600'}>
                    {title}
                </h6>
                <p className={'text-sm font-normal text-primary-500'}>
                    {text}
                </p>
            </div>
            <Link to={link}
               rel="noreferrer">
                <span style={{ boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.05)' }}
                      className={'text-white-base text-sm font-medium py-2 px-3.5' +
                    ' bg-primary-600 rounded-lg'}>
                    {btnText}
                </span>
            </Link>
        </div>
    )
}

Extensions.propTypes = {
    link: '#'
}

Extensions.propTypes = {
    title: PropTypes.string,
    text: PropTypes.string,
    link: PropTypes.string,
    btnText: PropTypes.string
}

export default Extensions
