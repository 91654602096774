import React, { useEffect, useRef, useState } from 'react'
import { Autocomplete, useLoadScript } from '@react-google-maps/api'
import PropTypes from 'prop-types'
import { createGlobalStyle } from 'styled-components'
import axios from 'axios'

const libraries = ['places']

const GlobalStyles = createGlobalStyle`
    /* General container styling */
    .pac-container {
        border: 0 solid #ccc;
        z-index: 1000;
        border-radius: 10px;
    }

    /* Item styling */
    .pac-item {
        padding: 5px 10px;
        text-align: left;
        font-size: 16px;
    }

    /* Highlighted item on hover */
    .pac-item:hover {
        background-color: #efefef;
        cursor: pointer;
    }

    /* Matched text styling */
    .pac-item-query {
        font-weight: normal;
    }

`
function LocationSearch ({ initialValue, form, classes, fieldName }) {
    const { isLoaded } = useLoadScript({
        googleMapsApiKey: 'AIzaSyDuZxEritPikayVVR_C3P2YkIT9BpFOa2A',
        libraries
    })

    const [input, setInput] = useState('')
    const autocompleteRef = useRef(null)

    useEffect(() => {
        setInput(initialValue)
        if (initialValue) {
            fetchCoordinates(initialValue)
        }
    }, [initialValue])

    const handleLoad = (autocomplete) => {
        autocompleteRef.current = autocomplete
    }

    const handlePlaceChanged = () => {
        const place = autocompleteRef.current.getPlace()
        const address = place.formatted_address
        const lat = place.geometry?.location?.lat()
        const lng = place.geometry?.location?.lng()

        setInput(place.formatted_address) // Update the input with the selected place
        form.setFieldValue(fieldName, address)
        form.setFieldValue('lat', lat)
        form.setFieldValue('long', lng)
    }

    const fetchCoordinates = async (address) => {
        try {
            const response = await axios.get(
                'https://maps.googleapis.com/maps/api/geocode/json',
                {
                    params: {
                        address,
                        key: 'AIzaSyDuZxEritPikayVVR_C3P2YkIT9BpFOa2A'
                    }
                }
            )

            const location = response.data?.results[0]?.geometry?.location
            form.setFieldValue('lat', location?.lat)
            form.setFieldValue('long', location?.lng)

            return response
        } catch (error) {
            console.error('Error fetching coordinates: ', error)
        }
    }

    if (!isLoaded) {
        return <div>Loading...</div>
    }

    return (
        <div>
            <GlobalStyles/>
            <Autocomplete
                onLoad={handleLoad}
                onPlaceChanged={handlePlaceChanged}
            >
                <input
                    // className={}
                    className={classes ?? 'h-10 rounded-lg border w-full md:w-[376px] text-base px-3'}
                    type="text"
                    value={input}
                    onChange={(e) => setInput(e.target.value)}
                    placeholder="Eg. Toronto"
                />
            </Autocomplete>
        </div>
    )
}

LocationSearch.defaultProps = {
    fieldName: 'location'
}

LocationSearch.propTypes = {
    form: PropTypes.any,
    initialValue: PropTypes.string,
    classes: PropTypes.string,
    fieldName: PropTypes.string
}

export default LocationSearch
