import React from 'react'
import { Form, Input } from 'antd'
import Title from './title'

function JobTitle () {
    return (
        <div className={'w-[340px] md:w-[376px]'}>
           <Title text={'What job you are looking for'}/>
           <Form.Item name={'title'} rules={[
               { required: true, message: 'Required' }
           ]}>
               <Input size={ 'large' } placeholder={ 'Eg. Product Manager' }/>
               {/* <Select showArrow={false} rootClassName={'first-time-select'} showSearch size={'large'}
                       filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                       placeholder={'Eg. Product Manager'}>
                   {
                       JobList.map((job) => (
                           <Select.Option key={job}>{job}</Select.Option>
                       ))
                   }
               </Select> */}
           </Form.Item>
        </div>
    )
}

export default JobTitle
