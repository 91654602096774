import React, { useEffect, useState } from 'react'
import TlaTableWrapper from '../../components/cv-builder/table/tla-table-wrapper'
import Column from 'antd/es/table/Column'
import { connect, useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { Spin } from 'antd'
import TlaConfirm from '../../components/tla-confirm'
import { FiEdit2 } from 'react-icons/fi'
import { TlaSuccess } from '../../utils/messages'
import { handleDeleteResume, handleGetAllResumes, handleGetResume } from '../../actions/build-resume/Action'
import { formatDate } from '../../utils'
import { useLocation, useNavigate } from 'react-router-dom'
import { compare, IS_SAME } from '../../utils/sync-resume'

function Resumes ({ deleteResume, getResumes, getProfileForResume }) {
    // eslint-disable-next-line no-unused-vars
    const location = useLocation()
    const userId = useSelector(state => state.loginReducer.authUser.id)
    const resumes = useSelector(state => state.buildResumeReducer.resumes)
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        getResumes(userId).then(() => {
            setLoading(false)
        })
    }, [])

    // eslint-disable-next-line no-unused-vars
    const dispatch = useDispatch()
    // eslint-disable-next-line no-unused-vars
    const navigate = useNavigate()

    const handleEdit = (record) => {
        // setLoading(true)
        getProfileForResume().then((res) => {
            const oldContent = JSON.parse(record?.resumeContent)
            // const formatted = formatResume(res.data.data?.user)
            const {
                educations,
                certificates,
                achivementsAwards,
                userInformation,
                experiences
            } = compare(res.data.data?.user, oldContent)

            oldContent.educations = educations
            oldContent.certificates = certificates
            oldContent.achivementsAwards = achivementsAwards
            oldContent.userInformation = userInformation
            oldContent.experiences = experiences

            const data = {
                id: record?.id,
                title: record?.title,
                name: record?.name,
                shortId: record?.shortId,
                slug: record?.slug,
                content: { ...oldContent },
                metadata: JSON.parse(record?.metadata)
            }

            dispatch({
                type: 'NEW_RESUME',
                payload: { ...data }
            })
            const allTrue = IS_SAME.every(item => item === true)

            console.log(allTrue, 'allTrue')
            setLoading(false)
            navigate('/build-resume')

            /* if (isSame) {
               dispatch({
                   type: 'NEW_RESUME',
                   payload: data
               })
               setLoading(false)
               navigate('/build-resume')
           } else {
               setLoading(false)
               navigate('/cv-builder/resumes/not-in-sync', {
                   state: {
                       data,
                       background: {
                           ...location,
                           pathname: '/cv-builder/resume-template'
                       }
                   }
               })
           } */
        })
    }

    return (
        <div className={'w-full rounded-lg'}>
            <Spin spinning={loading}>
                <TlaTableWrapper
                    paginate={false}
                    callbackFunction={getResumes}
                    data={resumes?.data} showHeader={false}
                    title={'Resume'}
                    addLink={'form'}
                    addText={'Create Resume'}
                    meta={resumes?.meta}>
                    <Column title={'Resume Title'} dataIndex={'title'}/>
                    <Column title={'Created at'} render={(({ createdAt }) => (
                        <>{formatDate(createdAt, 'MMM, DD, YYYY')}</>
                    ))}/>
                    <Column title={'Update at'} render={(({ updatedAt }) => (
                        <>{formatDate(updatedAt, 'MMM, DD, YYYY')}</>
                    ))}/>
                    <Column title={'Download'} dataIndex={'title'}/>
                    <Column title={'Actions'} render={((record) => (
                        <div className={'flex items-center gap-x-0.5'}>
                            <div className={'p-2.5'}>
                                <TlaConfirm
                                    title={'Delete Resume'}
                                    fullText={'Do you really want to delete this resume?'}
                                    callBack={() => {
                                        deleteResume(record.id).then(() => TlaSuccess('Resume Deleted'))
                                    }}/>
                            </div>
                            <div className={'p-2.5'}>
                                <FiEdit2 onClick={() => {
                                    handleEdit(record)
                                }} className={'cursor-pointer'}/>
                            </div>
                        </div>
                    ))}/>
                </TlaTableWrapper>
            </Spin>
        </div>
    )
}

Resumes.propTypes = {
    getResumes: PropTypes.func.isRequired,
    getProfileForResume: PropTypes.func.isRequired,
    deleteResume: PropTypes.func.isRequired
}

const mapDispatchToProps = (dispatch) => ({
    getResumes: (userId, page) => dispatch(handleGetAllResumes(userId, page)),
    getProfileForResume: () => dispatch(handleGetResume()),
    deleteResume: (id) => dispatch(handleDeleteResume(id))
})

export default connect(null, mapDispatchToProps)(Resumes)
