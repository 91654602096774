import { Types } from './Types'

/**
 * @param payload
 * @returns {{payload, type: string}}
 */
export function addExperience (payload) {
  return {
    type: Types.NEW_EXPERIENCE,
    payload
  }
}

/**
 *
 * @param payload
 * @returns {{payload, type: string}}
 */
export function allExperiences (payload) {
  return {
    type: Types.ALL_EXPERIENCES,
    payload
  }
}

/**
 *
 * @param id
 * @returns {{id, type: string}}
 */
export function deleteExperience (id) {
  return {
    type: Types.DELETE_EXPERIENCE,
    id
  }
}

/**
 *
 * @param payload
 * @returns {{payload, type: string}}
 */
export function updateExperience (payload) {
  return {
    type: Types.UPDATE_EXPERIENCE,
    payload
  }
}
