import { combineReducers } from 'redux'
import sessionStorage from 'redux-persist/lib/storage/session'
import { persistReducer } from 'redux-persist'
import loginReducer from './login-reducer'
import jobQueryReducer from './job-query-reducer'
import jobApplicationReducer from './job-application-reducer'
import dashboardReducer from './dashboard-reducer'
import educationsReducer from './education-reducer'
import certificationsReducer from './certification-reducer'
import experiencesReducer from './experience-reducer'
import skillsReducer from './skills-reducer'
import apply10xReducer from './apply-10x-reducer'
import adminUsersReducer from './admin/admin-users-reducer'
import askChatGptReducer from './ask-chat-gpt-reducer'
import buildResumeReducer from './resume/build-resume-reducer'
import awardsReducer from './awards-reducer'
import questionReducer from './question'
import appSettingsReducer from './app-settings-reducer'

const persistConfig = {
    key: 'root',
    storage: sessionStorage,
    whitelist: [
        'loginReducer',
        'jobQueryReducer',
        'jobApplicationReducer',
        'dashboardReducer',
        'educationsReducer',
        'certificationsReducer',
        'experiencesReducer',
        'skillsReducer',
        'apply10xReducer',
        'adminUsersReducer',
        'askChatGptReducer',
        'buildResumeReducer',
        'awardsReducer',
        'questionReducer',
        'appSettingsReducer'
    ]
}

const appReducer = combineReducers({
    loginReducer,
    jobQueryReducer,
    jobApplicationReducer,
    dashboardReducer,
    educationsReducer,
    certificationsReducer,
    experiencesReducer,
    skillsReducer,
    apply10xReducer,
    adminUsersReducer,
    askChatGptReducer,
    buildResumeReducer,
    awardsReducer,
    questionReducer,
    appSettingsReducer
})

const rootReducer = (state, action) => {
    if (action.type === 'REMOVE_AUTH') {
        document.cookie = 'authData=; expires=Thu, 01 Jan 1970 00:00:00 UTC;'
        document.cookie = 'accessToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC;'
        localStorage.clear()
        return appReducer(undefined, action)
    }
    return appReducer(state, action)
}

export default persistReducer(persistConfig, rootReducer)
