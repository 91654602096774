import React, { useState } from 'react'
import { TlaModal } from '../../../../components/pop-ups/tla-modal'
import { Button } from 'antd'
import Castform from './images/castform.png'
import AutoApply from './images/auto-apply-1.png'
import Simple from './images/straight.png'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

function TemplateOptions () {
    const currentTemplate = useSelector(state => state.buildResumeReducer.currentResume?.metadata?.template)
    const [selected, setSelected] = useState(currentTemplate)

    const templates = {
        Castform,
        AutoApply,
        Simple
    }

    const dispatch = useDispatch()

    const setTemplate = () => {
        dispatch({
            type: 'SET_TEMPLATE',
            payload: selected.toLowerCase()
        })
        navigate(-1)
    }

    const navigate = useNavigate()

    return (
        <TlaModal width={'auto'} title={<span className={'text-lg'}>Select Template</span>} allowClose={false}>
            <div className={'flex gap-x-6 mb-6'}>
                {
                    Object.keys(templates).map((temp, index) => (
                        <div key={index} onClick={() => setSelected(temp.toLowerCase())}
                             className={`cursor-pointer w-[229px] h-[292px] border-[5px]
                              hover:border-primary-500 ${selected === temp.toLowerCase() ? 'border-primary-500' : ''}
                               bg-cover rounded-md`}
                             style={{ backgroundImage: `url('${templates[temp]}')` }}>
                        </div>
                    ))
                }
            </div>
            <div className={'flex justify-end gap-x-3'}>
                <div className={'w-fit'}>
                    <Button className={'!h-11 w-full'} size={'large'}
                            onClick={() => {
                                setSelected(currentTemplate)
                                navigate(-1)
                            }}>
                        Discard
                    </Button>
                </div>
                <Button onClick={setTemplate}
                        className={'btn btn-primary-600 !h-11'}
                        size={'large'}>
                    Save Changes
                </Button>
            </div>
        </TlaModal>
    )
}

export default TemplateOptions
