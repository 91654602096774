import React, { useState } from 'react'
import { connect, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { useLocation, useNavigate } from 'react-router-dom'
import { TlaModal } from '../../components/pop-ups/tla-modal'
import { Button, Form, Input } from 'antd'
import CloseModal from '../../components/close-modal'
import { handleAskGptForSummary, handleUpdateProfile } from '../../actions/authenticate/Actions'
import { TlaError, TlaSuccess } from '../../utils/messages'
import { getErrors } from '../../utils'
import TlaConfirm from '../../components/tla-confirm'

function PersonalInfoForm ({ updateProfile }) {
    const [updating, setUpdating] = useState(false)
    const { state } = useLocation()
    const [form] = Form.useForm()

    const formValues = {
        ...state?.data
    }
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const onFinish = (values) => {
        setUpdating(true)
        updateProfile(state?.data?.userId, values).then((res) => {
            dispatch({
                type: 'UPDATE_PERSONAL_PROFILE',
                payload: res.data?.data?.user
            })

            TlaSuccess('Profile Update Success')
            form.resetFields()
            setUpdating(false)
            navigate(-1)
        }).catch((error) => {
            setUpdating(false)
            TlaError(error.response.data.message)
        })
    }

    const askGpt = () => {
        setUpdating(true)
        dispatch(handleAskGptForSummary({
            userId: state?.data?.userId.toString()
        })).then((res) => {
            form.setFieldValue('summary', res.data.data.summary)
            setUpdating(false)
        }).catch((error) => {
            setUpdating(false)
            TlaError(error.response.data.message ?? getErrors(error.response.data?.errors))
        })
    }
    return (
        <TlaModal loading={updating} title={state?.data?.isSummary ? '' : 'Personal Information'}>
            <Form form={form} initialValues={formValues} onFinish={onFinish} colon={false}
                  requiredMark={false} layout={'vertical'}>
                <div className={'bg-white-base'}>
                    {
                        state?.data?.isSummary
                            ? <div>
                                <div className={'flex items-center justify-between mt-5 mb-2'}>
                                    <p>Summary</p>
                                    <TlaConfirm
                                        title={'Confirm'}
                                        fullText={'The summary will be generated based on profile data. The response from' +
                                            ' ChatGPT will overwrite the current summary.'}
                                        showIcon={false}
                                        callBack={askGpt}
                                        okText={'Yes Continue'}
                                        cancelText={'No, Ignore it!'}
                                        okClass={'btn-primary-700'}>
                                        <span className={'text-sm font-medium text-primary-700'}>
                                            Ask ChatGPT
                                        </span>
                                    </TlaConfirm>

                                </div>
                                <Form.Item label={''} name={'summary'}>
                                    <Input.TextArea className={'rounded-lg shadow-sm'} rows={5}/>
                                </Form.Item>
                            </div>

                            : <>
                                <div>
                                    <Form.Item label={'Full Name*'}
                                               name={'name'}
                                               rules={[{
                                                   required: true,
                                                   message: 'Name is required'
                                               }]}>
                                        <Input
                                            placeholder={'Eg Chris Martin'}
                                            size={'large'}/>
                                    </Form.Item>
                                </div>
                                <div>
                                    <Form.Item
                                        rules={[
                                            {
                                                type: 'email',
                                                message: 'Provide a valid email'
                                            },
                                            {
                                                required: true,
                                                message: 'Resume Email required'
                                            }
                                        ]}
                                        name={'resumeEmail'}
                                        label={'Resume Email'}>
                                        <Input size={'large'}/>
                                    </Form.Item>
                                </div>
                                <div>
                                    <Form.Item
                                        name={'address'}
                                        label={'Address'}>
                                        <Input size={'large'}/>
                                    </Form.Item>
                                </div>
                                <div>
                                    <Form.Item
                                        rules={[
                                            {
                                                type: 'string',
                                                min: 10,
                                                message: 'Provide a valid phone number'
                                            },
                                            {
                                                required: true,
                                                message: 'Mobile Number required'
                                            }
                                        ]}
                                        name={'mobileNumber'}
                                        label={'Mobile Number'}>
                                        <Input size={'large'}/>
                                    </Form.Item>
                                </div>
                                <div>
                                    <Form.Item
                                        rules={[
                                            {
                                                type: 'url',
                                                message: 'Provide a valid Linkedin URL'
                                            },
                                            {
                                                type: 'string',
                                                min: 6
                                            }
                                        ]}
                                        name={'linkedin'}
                                        label={'Linkedin'}>
                                        <Input size={'large'}/>
                                    </Form.Item>
                                </div>
                                <div>
                                    <Form.Item
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Resume Headline required'
                                            }
                                        ]}
                                        name={'resumeHeadline'}
                                        label={'Resume Headline'}>
                                        <Input size={'large'}/>
                                    </Form.Item>
                                </div>
                                <div>
                                    <Form.Item
                                        rules={[
                                            {
                                                type: 'url',
                                                message: 'Provide a valid Github URL'
                                            },
                                            {
                                                type: 'string',
                                                min: 6
                                            }
                                        ]}
                                        name={'github'}
                                        label={'Github'}>
                                        <Input size={'large'}/>
                                    </Form.Item>
                                </div>
                                <div>
                                    <Form.Item
                                        rules={[
                                            {
                                                type: 'url',
                                                message: 'Provide a valid GitLab URL'
                                            },
                                            {
                                                type: 'string',
                                                min: 6
                                            }
                                        ]}
                                        name={'gitLab'}
                                        label={'GitLab'}>
                                        <Input size={'large'}/>
                                    </Form.Item>
                                </div>
                            </>
                    }
                </div>
                <div className={'flex gap-x-2'}>
                    <div className={'w-1/2'}>
                        <CloseModal/>
                    </div>
                    <div className={'w-1/2'}>
                        <Form.Item>
                            <Button size={'large'} className={'btn btn-primary-500'} block
                                    htmlType={'submit'}>Save</Button>
                        </Form.Item>
                    </div>
                </div>
            </Form>
        </TlaModal>
    )
}

PersonalInfoForm.propTypes = {
    updateProfile: PropTypes.func.isRequired
}

/**
 *
 * @param dispatch
 */
const mapDispatchToProps = (dispatch) => ({
    updateProfile: (userId, data) => dispatch(handleUpdateProfile(userId, data))
})

export default connect(null, mapDispatchToProps)(PersonalInfoForm)
