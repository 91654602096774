import { Path, Rect, Svg } from '@react-pdf/renderer'
import React from 'react'
import PropTypes from 'prop-types'

export const LinkedinSimple = ({ color, download }) => (
    download
        ? <Svg width="20px" height="20px" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <Rect x="1.5" y="1" ry="6" width="14" height="14" rx="7" fill="black"/>
            <Path
                d="M6.80928 4.84607C6.80928 5.31335 6.40426 5.69215 5.90464 5.69215C5.40502 5.69215 5 5.31335 5 4.84607C5 4.3788 5.40502 4 5.90464 4C6.40426 4 6.80928 4.3788 6.80928 4.84607Z"
                fill="white"/>
            <Path d="M5.12371 6.31405H6.6701V11H5.12371V6.31405Z" fill="white"/>
            <Path
                d="M9.15979 6.31405H7.6134V11H9.15979C9.15979 11 9.15979 9.52479 9.15979 8.60243C9.15979 8.04881 9.34883 7.49277 10.1031 7.49277C10.9555 7.49277 10.9504 8.21727 10.9464 8.77855C10.9412 9.51222 10.9536 10.2609 10.9536 11H12.5V8.52686C12.4869 6.94769 12.0754 6.22004 10.7216 6.22004C9.9177 6.22004 9.41936 6.58503 9.15979 6.91525V6.31405Z"
                fill="white"/>
        </Svg>

        : <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="1.5" y="1" width="14" height="14" rx="7" fill={color}/>
            <path
                d="M6.80928 4.84607C6.80928 5.31335 6.40426 5.69215 5.90464 5.69215C5.40502 5.69215 5 5.31335 5 4.84607C5 4.3788 5.40502 4 5.90464 4C6.40426 4 6.80928 4.3788 6.80928 4.84607Z"
                fill="white"/>
            <path d="M5.12371 6.31405H6.6701V11H5.12371V6.31405Z" fill="white"/>
            <path
                d="M9.15979 6.31405H7.6134V11H9.15979C9.15979 11 9.15979 9.52479 9.15979 8.60243C9.15979 8.04881 9.34883 7.49277 10.1031 7.49277C10.9555 7.49277 10.9504 8.21727 10.9464 8.77855C10.9412 9.51222 10.9536 10.2609 10.9536 11H12.5V8.52686C12.4869 6.94769 12.0754 6.22004 10.7216 6.22004C9.9177 6.22004 9.41936 6.58503 9.15979 6.91525V6.31405Z"
                fill="white"/>
        </svg>

)

LinkedinSimple.defaultProps = {
    color: 'currentColor',
    download: false
}

LinkedinSimple.propTypes = {
    color: PropTypes.string,
    download: PropTypes.bool
}
