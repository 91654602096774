import React from 'react'
import { useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import PopUps from './index'
import { Spin } from 'antd'
import { MdClose } from 'react-icons/md'

export const TlaModal = ({ title, children, width, loading, allowClose }) => {
    const navigate = useNavigate()

    return (
        <PopUps>
            <div className="ant-modal-root tla-modal bg-primary-25" style={{ pointerEvents: 'none' }}>
                <div className="ant-modal-mask" style={{ pointerEvents: 'none' }} ></div>
                <div tabIndex="-1" className="ant-modal-wrap flex items-center justify-center"
                     aria-labelledby="rc_unique_0">
                    <div role="dialog" aria-modal="true" className="ant-modal tla-modal"
                         style={{
                             pointerEvents: 'none',
                             width,
                             transformOrigin: '-208px 142px',
                             maxHeight: '100vh',
                             borderRadius: 'px'
                         }}>
                        <div className="ant-modal-content tla-modal-content bg-primary-25">

                            <div className={'flex items-center justify-between'}>
                                <div className="ant-modal-header tla-modal-header bg-primary-25">
                                    <div className="font-medium text-[38px]" id="rc_unique_0">{title}</div>
                                </div>
                                {
                                    allowClose &&
                                    <div onClick={() => { navigate(-1) }}
                                         aria-label="Close"
                                         className="cursor-pointer bg-gray-100 p-2.5 rounded-full">
                                      <MdClose className={'text-gray-600'}/>
                                    </div>
                                }
                            </div>

                            <div className="ant-modal-body">
                                <Spin spinning={loading} tip={'Please Wait...'}>
                                    {children}
                                </Spin>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </PopUps>
    )
}

TlaModal.defaultProps = {
    title: <>&nbsp;</>,
    type: 'text',
    width: 520,
    icon: true,
    loading: false,
    allowClose: true
}

TlaModal.propTypes = {
    width: PropTypes.any,
    type: PropTypes.string,
    children: PropTypes.any,
    title: PropTypes.any,
    loading: PropTypes.bool,
    allowClose: PropTypes.bool,
    modalAction: PropTypes.any
}
