import React from 'react'
import { Text, View } from '@react-pdf/renderer'
import PropTypes from 'prop-types'

const CastformTitle = ({ title, color }) => (
    <View style={{ marginBottom: 8 }}>
        <Text style={{
            fontFamily: 'Roboto',
            fontSize: '18px',
            fontStyle: 'normal',
            fontWeight: 700,
            lineHeight: 'normal',
            textTransform: 'capitalize',
            color
        }}>
            {title}
        </Text>
    </View>
)

CastformTitle.defaultProps = {
    color: '#000'
}

CastformTitle.propTypes = {
    title: PropTypes.string,
    color: PropTypes.string
}

export default CastformTitle
