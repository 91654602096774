import React from 'react'
import { Button } from 'antd'
import { TlaModal } from './pop-ups/tla-modal'
import { useLocation, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'

// eslint-disable-next-line react/prop-types
function QueryError ({ type }) {
    const { state } = useLocation()
    const { linkToUpgrade } = useSelector(state => state.dashboardReducer.userPackage)

    let message = 'Your allotted quota has been consumed. To maximize the benefits, you need to upgrade to Professional Package.'
    const navigate = useNavigate()
    if (type === 'pro') {
        message = 'Professional package allows maximum of 3 job queries.'
    }
    message = state?.data?.message ?? message

    return (
        <TlaModal width={400}>
            <div>
                <p className={'text-lg text-gray-900 font-medium mb-[32px]'}>{message}</p>
                {
                    type === 'pro'
                        ? <Button onClick={() => {
                            navigate(-1)
                        }} className={'btn btn-primary-600 w-full !h-11'} size={'large'}>
                            Close
                        </Button>
                        : <a href={linkToUpgrade} target={'_blank'} rel="noreferrer">
                            <Button className={'btn btn-primary-600 w-full !h-11'} size={'large'}>
                                Upgrade
                            </Button>
                        </a>

                }
            </div>
        </TlaModal>
    )
}

export default QueryError
