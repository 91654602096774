import React, { useState } from 'react'
import { Form, message, Spin } from 'antd'
import { useDispatch } from 'react-redux'
import { handleRegister } from '../../../actions/authenticate/Actions'
import JobQueryFormContent from '../../auto-apply/job-queries/job-query-form-content'
import SignUpContent from './sign-up-content'
import Logo from '../../../assets/images/logo.png'
import { useNavigate } from 'react-router-dom'
import { TlaModal } from '../../../components/pop-ups/tla-modal'

const SignUp = () => {
    const [current, setCurrent] = useState(0)
    const [loading, setLoading] = useState(false)
    const [formData, setFormData] = useState({})
    const [selectedFile, setSelectedFile] = useState(null)
    const [form] = Form.useForm()
    const [resumeStatus, setResumeStatus] = useState(null)
    const dispatch = useDispatch()

    const items = {
        0: <JobQueryFormContent resumeStatus={ resumeStatus } selectedFile={ selectedFile } form={ form }
                                setSelectedFile={ setSelectedFile } experienceLevelName={ 'experienceLevel[]' }/>,
        1: <SignUpContent/>
    }

    const total = Object.keys(items).length

    const previous = () => {
        if (current > 0) {
            setCurrent(current - 1)
        }
    }

    const next = () => {
        if (selectedFile === null) {
            setResumeStatus('Resume is required')
        }
        form.validateFields().then((values) => {
            setFormData(prevData => ({ ...prevData, ...values }))
            if (selectedFile !== null) {
                setResumeStatus('')
                if (current < total - 1) {
                    setCurrent(current + 1)
                }
            }
        })
    }

    const navigate = useNavigate()

    const onFinish = async (values) => {
        setLoading(true)
        const allValues = { ...formData, ...values }

        const formDataObj = new FormData()
        Object.keys(allValues).forEach(key => {
            formDataObj.append(key, allValues[key])
        })

        // @ts-ignore
        formDataObj.append('resume', selectedFile)

        dispatch(handleRegister(formDataObj)).then(() => {
            message.success('Account crated successfully!')
            navigate('/verify', {
                state: {
                    email: allValues.email
                }
            })
        }).catch((error) => {
            setLoading(false)
            console.error('Error submitting form:', error)
            message.error('An error occurred while submitting the form.')
        })
    }

    return (
        <TlaModal width={600}>
            <div className={'bg-white mb-5'}>
                <div className={ 'w-fit mx-auto' }>
                    <img height={ 'auto' } src={ Logo } width={ 200 } alt={ 'AutoApply' }/>
                </div>
                <h3 className={ 'text-center text-gray-900 font-semibold text-2xl leading-[38px] mb-3' }>
                    Create an account
                </h3>
                <div>
                    <Form requiredMark={ false }
                          form={ form }
                          layout="vertical"
                          className={ '-mb-6' }
                          name="job-query-form" onFinish={ onFinish }>
                        <Spin spinning={ loading } tip={ 'Please wait...' }>
                            <div>
                                { items[current] }
                                <div className={ 'flex justify-end gap-x-2 items-center' }>
                                    {
                                        current > 0 &&
                                        <button onClick={ previous } className={ 'modal-button' }>Previous</button>
                                    }
                                    {
                                        total - current === 1
                                            ? <Form.Item noStyle>
                                                <button
                                                    type={ 'submit' }
                                                    className={ 'modal-button bg-primary-600 border-primary-600' +
                                                        ' text-white-base' }>
                                                    Submit
                                                </button>
                                            </Form.Item>
                                            : <button
                                                type={ 'button' }
                                                onClick={ next }
                                                className={ 'modal-button bg-primary-600 border-primary-600' +
                                                    ' text-white-base' }>
                                                Next
                                            </button>
                                    }

                                </div>
                            </div>
                        </Spin>
                    </Form>
                </div>
            </div>
        </TlaModal>
    )
}

export default SignUp
