import React from 'react'
import DefaultUser from '../../assets/images/profile/user.png'
import { useSelector } from 'react-redux'

function ProfilePhoto () {
    const loginInfo = useSelector(state => state.loginReducer)

    const photo = loginInfo?.authUserInfo?.photo || loginInfo?.authUser?.userInformation?.photo

    return (
        photo === 'default.png'
            ? <div
                className={'rounded-full p-5 md:p-10 border-white-base border-4' +
                    ' bg-gray-50 h-[150px] w-[150px] shadow-3.5xl'}>
                <img src={DefaultUser}
                     className={'w-12 md:w-full'} alt="ProfileImage"/>
            </div>

            : <img src={photo}
                   className={'h-[100px] w-[100px] md:h-[150px] md:w-[150px]' +
                       ' rounded-full shadow-3.5xl border-white-base border-4'}
                   alt="ProfileImage"/>
    )
}

export default ProfilePhoto
