import React from 'react'
import { Text, View } from '@react-pdf/renderer'
import PropTypes from 'prop-types'

const SimpleTitle = ({ title, color }) => (
    <View style={{ marginBottom: 5, paddingTop: 4, paddingBottom: 1, borderBottom: 1 }}>
        <Text style={{ fontSize: 12, fontFamily: 'Times-Bold', textTransform: 'uppercase', lineHeight: 'normal', color, fontWeight: 900 }}>{title}</Text>
    </View>
)

SimpleTitle.defaultProps = {
    color: 'black'
}

SimpleTitle.propTypes = {
    title: PropTypes.string,
    color: PropTypes.string
}

export default SimpleTitle
