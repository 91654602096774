import { formatHiddenItems } from './index'
import { createDictionary } from './sync-util'

export const IS_SAME = []
// eslint-disable-next-line no-unused-vars
let expDescriptions = []
export const compare = (profile, resume) => {
    const newDesc = [...resume.experiences.items]
    expDescriptions = createDictionary(newDesc)

    const educations = validateItems(resume.educations, profile.educations, 'school', 'degreeFieldOfStudy')
    const certificates = validateItems(resume.certificates, profile.certificates, 'organization', 'title')
    const achivementsAwards = validateItems(resume.achivementsAwards, profile.achivementsAwards, 'details', 'title')
    const userInformation = validateUserInfo(profile.userInformation, resume.userInformation)
    const experiences = validateExperience(resume.experiences, profile.experiences)

    return { educations, certificates, achivementsAwards, userInformation, experiences }
}

const validateItems = (resumeItems, profileItems, key1, key2) => {
    const rItems = { ...resumeItems }

    const existItems = Object.values(rItems.hiddenItems)
        .filter(index => rItems.items[index] !== undefined)
        .map(index => rItems.items[index])

    // const iItems = rItems.items.filter(it => )
    const pItems = [...profileItems]
    const profileItemsMap = pItems.reduce((acc, item) => {
        acc[item.id] = item
        return acc
    }, {})

    const filteredResumeItems = existItems.filter(item => {
        const profileItem = profileItemsMap[item.id]
        const check = profileItem &&
            profileItem[key1] === item[key1] &&
            profileItem[key2] === item[key2]

        IS_SAME.push(check)
        return check
    })

    const results = {
        hiddenItems: {},
        items: []
    }

// Merge existItems and profileItems, avoiding duplicates
    const mergedItems = [...new Map([...filteredResumeItems, ...pItems].map(item => [item[key1] + item[key2], item])).values()]

    // Populating items in the results object and extracting keys for hiddenItems
    mergedItems.forEach((item, index) => {
        // Populating items
        results.items.push(item)
    })

    results.hiddenItems = formatHiddenItems(Object.keys(filteredResumeItems))

    return results
}

const validateUserInfo = (profileInfo, resumeInfo) => {
    const { items, hiddenItems } = resumeInfo

    Object.keys(items).forEach(item => {
        if ((items[item] !== profileInfo[item])) {
            items[item] = profileInfo[item]

            if (item !== 'name') {
                delete hiddenItems[item]
            }
        }
    })

    return { items, hiddenItems }
}

// eslint-disable-next-line no-unused-vars
function validateExperience (resumeExperience, profileExperience) {
    const { hiddenItems, items } = validateItems(resumeExperience, profileExperience, 'companyName', 'jobTitle')

    return {
        hiddenItems,
        items: formatExperienceSection(items, profileExperience)
    }
}

const formatExperienceSection = (items, profileItems) => {
     return items.map((work) => {
        const pItem = profileItems.find(item => item.id === work.id)
        return {
            ...work,
            description: validateExpDescription(work.description, pItem.description, work.id)
        }
    })
}

const validateExpDescription = (resumeItems, profileItems, id) => {
    const profileItemsMap = profileItems.reduce((acc, item) => {
        acc[item] = item
        return acc
    }, {})

    let filteredResumeItems = []
    if (expDescriptions[id]) {
       filteredResumeItems = expDescriptions[id].filter(item => {
            const profileItem = profileItemsMap[item]
            const check = profileItem && profileItem === item

           IS_SAME.push(check)
           return check
        })
    }

    const results = {
        hiddenItems: {},
        items: []
    }

// Merge resumeItems and profileItems, avoiding duplicates
    const mergedItems = [...new Map([...filteredResumeItems, ...profileItems].map(item => [item, item])).values()]

    // Populating items in the results object and extracting keys for hiddenItems
    mergedItems.forEach((item, index) => {
        // Populating items
        results.items.push(item)
    })

    results.hiddenItems = formatHiddenItems(Object.keys(filteredResumeItems))

    return results
}
