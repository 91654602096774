import React, { useEffect, useState } from 'react'
import { Button, Form, Input, Spin } from 'antd'
import { connect, useDispatch } from 'react-redux'
import { handleAskGptForSummary, handleGetProfile, handleUpdateProfile } from '../../actions/authenticate/Actions'
import PropTypes from 'prop-types'
import { TlaError, TlaSuccess } from '../../utils/messages'
import { getErrors } from '../../utils'
import TlaConfirm from '../../components/tla-confirm'

function Summary ({ profile, getProfile, updateProfile }) {
    const [loading, setLoading] = useState(true)
    const [updating, setUpdating] = useState(false)
    const [form] = Form.useForm()
    useEffect(() => {
        getProfile().then(() => {
            setLoading(false)
        })
    }, [])

    const onFinish = (values) => {
        setUpdating(true)

        updateProfile(profile?.userId, values).then(() => {
            TlaSuccess('Summary Updated Successfully')
            setUpdating(false)
        }).catch((error) => {
            setUpdating(false)
            TlaError(error.response.data.message)
        })
    }

    const initialValues = { ...profile }
    const dispatch = useDispatch()
    const askGpt = () => {
        setUpdating(true)
        dispatch(handleAskGptForSummary({
            userId: profile?.userId.toString()
        })).then((res) => {
            form.setFieldValue('summary', res.data.data.summary)
            setUpdating(false)
        }).catch((error) => {
            setUpdating(false)
            TlaError(error.response.data.message ?? getErrors(error.response.data?.errors))
        })
    }
    return (
        <div className={'p-5 rounded-lg'}>
            <Spin spinning={loading || updating }>
                {
                    !loading &&
                    <Form form={form} onFinish={onFinish}
                          initialValues={initialValues}
                          colon={false}
                          layout={'vertical'}
                          requiredMark={false}>
                        <div className={'flex items-center justify-between mb-2'}>
                            <p className={'text-sm font-medium'}>Summary</p>
                            <TlaConfirm
                                title={'Confirm'}
                                fullText={'The summary will be generated based on profile data. The response from' +
                                    ' ChatGPT will overwrite the current summary.'}
                                showIcon={false}
                                callBack={askGpt}
                                okText={'Yes Continue'}
                                cancelText={'No, Ignore it!'}
                                okClass={'btn-primary-700'}>
                                <Button className={'btn btn-rounded ml-auto'} htmlType={'button'}>
                                    Ask ChatGPT
                                </Button>
                            </TlaConfirm>
                        </div>
                            <Form.Item
                                name={'summary'}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Required'
                                    }
                                ]}>
                                <Input.TextArea rows={10} size={'large'}/>
                            </Form.Item>
                        <Form.Item>
                            <Button size={'large'} className={'btn btn-rounded'} htmlType={'submit'}>Save</Button>
                        </Form.Item>
                    </Form>
                }
            </Spin>
        </div>
    )
}

Summary.propTypes = {
    profile: PropTypes.object.isRequired,
    getProfile: PropTypes.func.isRequired,
    updateProfile: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
    profile: state.loginReducer.authUserInfo
})

const mapDispatchToProps = (dispatch) => ({
    getProfile: () => dispatch(handleGetProfile()),
    updateProfile: (userId, data) => dispatch(handleUpdateProfile(userId, data))
})

export default connect(mapStateToProps, mapDispatchToProps)(Summary)
