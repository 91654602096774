import React from 'react'
import { Button } from 'antd'
import { TlaModal } from './pop-ups/tla-modal'
import { useNavigate } from 'react-router-dom'

function NoSubscription () {
    const navigate = useNavigate()
  return (
        <TlaModal width={400}>
            <div>
                <p className={'text-lg text-gray-900 font-medium mb-[32px]'}>Your don’t have any paid subscription. Please buy subscription.</p>
                <a href="https://autoapply.jobs/pricing/" target={'_blank'} rel="noreferrer">
                    <Button className={'btn btn-primary-600 w-full !h-11'} size={'large'}>
                        Buy Subscription
                    </Button>
                </a>
                <p className={'text-sm text-gray-500 mt-5 mx-auto w-fit cursor-pointer'} onClick={() => { navigate(-1) }}>Close</p>
            </div>
        </TlaModal>
  )
}

export default NoSubscription
