import React from 'react'
import Mail from '../../../../assets/images/icons/mail.svg'
import { Link, useLocation } from 'react-router-dom'
import Title from '../verification/title'
import BackToLogin from '../verification/back-to-login'

export default function Verify () {
    const { state } = useLocation()
    return (
        <div className={ 'h-screen flex items-center justify-start pt-20 flex-col bg-white-base' }>
            <img src={ Mail } alt={ 'mail' }/>
            <div className={ 'mb-8' }>
                <Title/>
            </div>
            <Link to={ '/verify/manual' } state={ { email: state?.email } } className={ 'mb-8' }>
                <button
                    className={ 'bg-primary-400 border-primary-400 text-white-base text-base h-11 w-[360px] rounded-lg' }>
                    Enter code manually
                </button>
            </Link>
            <BackToLogin/>
        </div>
    )
}
