import React from 'react'
import { Form, Input } from 'antd'
import Title from './title'

function YourName () {
    return (
        <div className={'w-[340px] md:w-[376px]'}>
            <Title text={'What is your name?'}/>
            <Form.Item name={'name'} rules={[
                { required: true, message: 'Required' }
            ]}>
                <Input size={'large'} rootClassName={'first-time-input'} placeholder={'Eg. John Doe'}/>
            </Form.Item>
        </div>
    )
}

export default YourName
