import React from 'react'
import { Button, Col, Form, Input, Row } from 'antd'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom'
import TlaModalFormWrapper from '../../components/tla-modal-form-wrapper'
import CloseModal from '../../components/close-modal'
import { handleAddAward, handleUpdateAward } from '../../actions/awards/Action'

function AwardForm ({ addAward, updateAward }) {
  const { state } = useLocation()

  const formValues = {
    ...state?.data
  }
  return (
        <TlaModalFormWrapper
            resourceId={state?.data?.id ?? null}
            initialValues={formValues}
            formTitle={'Add Award or Achievement'}
            onSubmit={state?.data?.id ? updateAward : addAward}>
            <Row gutter={14}>
                <Col span={24}>
                    <Form.Item
                        name={'title'}
                        rules={[
                          {
                            required: true,
                            message: 'Required'
                          }
                        ]}
                        label={'Title *'}>
                        <Input size={'large'} placeholder={'AWS Certified Administrator'}/>
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item
                        name={'details'}
                        label={'Details'}>
                        <Input size={'large'} placeholder={'AWS'}/>
                    </Form.Item>
                </Col>
            </Row>

            <div className={'flex gap-2'}>
                <div className={'w-full'}>
                    <CloseModal height={'!h-11'}/>
                </div>
                <Form.Item className={'w-full'}>
                    <Button className={'btn btn-primary w-full !h-11'} size={'large'} htmlType="submit">
                        Save
                    </Button>
                </Form.Item>
            </div>
        </TlaModalFormWrapper>
  )
}

AwardForm.propTypes = {
  addAward: PropTypes.func.isRequired,
  updateAward: PropTypes.func.isRequired
}

/**
 *
 * @param dispatch
 */
const mapDispatchToProps = (dispatch) => ({
  addAward: (userId, data) => dispatch(handleAddAward(userId, data)),
  updateAward: (awardId, data) => dispatch(handleUpdateAward(awardId, data))
})

export default connect(null, mapDispatchToProps)(AwardForm)
