import React, { useState } from 'react'
import { Button, DatePicker, Form, Input, Select } from 'antd'
import { connect, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom'
import TlaModalFormWrapper from '../../../components/tla-modal-form-wrapper'
import CloseModal from '../../../components/close-modal'
import { handleGetAllSubscriptions, handleUpdateSubscription } from '../../../actions/admin/users/Action'

function UsersForm ({ updateSubscription, getAllSubscriptions }) {
    const { state } = useLocation()
    const subscriptions = useSelector(state => state.adminUsersReducer.subscriptions)
    const [loading, setLoading] = useState(false)

    const userId = useSelector((state) => state.loginReducer.authUser.id)
    const formValues = {
        ...state?.data,
        dateRenewal: null
    }
    return (
        <TlaModalFormWrapper width={642}
            initialValues={formValues}
            formTitle={'Select Subscription Type'}
            resourceId={userId ?? null}
            onSubmit={updateSubscription}>
            <Form.Item
                name={'subscriptionTierId'}
                rules={[
                    {
                        required: true,
                        message: 'Required'
                    }
                ]}
                label={'Select Subscription Type'}>
                <Select loading={loading}
                        onFocus={() => {
                            if (subscriptions.length === 0) {
                                setLoading(true)
                                getAllSubscriptions().then(() => setLoading(false))
                            }
                        }}
                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                        showSearch
                        className={'w-[188px]'} size={'large'} placeholder={'Select Skill'}>
                    {
                        subscriptions.map((skill) => (
                            <Select.Option key={skill.id} value={'' + skill.id}>
                                {skill?.subscriptionName}
                            </Select.Option>
                        ))
                    }
                </Select>
            </Form.Item>
            <Form.Item
                name={'dateRenewal'}
                rules={[
                    {
                        required: true,
                        message: 'Required'
                    }
                ]}
                label={'Quota Renewal Date'}>
                <DatePicker style={{ width: '100%' }} size={'large'} placeholder={'Date'}/>
            </Form.Item>
            <Form.Item
                hidden
                name={'userSubscriptionId'}
                rules={[
                    {
                        required: true,
                        message: 'Required'
                    }
                ]}
                label={'userSubscriptionId'}>
                <Input size={'large'} placeholder={'userSubscriptionId'}/>
            </Form.Item>
            <div className={'flex gap-2'}>
                <div className={'w-full'}>
                    <CloseModal/>
                </div>
                <Form.Item className={'w-full'}>
                    <Button className={'btn-primary w-full'} size={'large'} htmlType="submit">
                        Submit
                    </Button>
                </Form.Item>
            </div>
        </TlaModalFormWrapper>
    )
}

UsersForm.propTypes = {
    updateSubscription: PropTypes.func.isRequired,
    getAllSubscriptions: PropTypes.func.isRequired
}

/**
 *
 * @param dispatch
 * @returns {{updateSubscription: (function(*): *), getAllSubscriptions: (function(*): *)}}
 */
const mapDispatchToProps = (dispatch) => ({
    updateSubscription: (userId, data) => dispatch(handleUpdateSubscription(userId, data)),
    getAllSubscriptions: () => dispatch(handleGetAllSubscriptions())
})

export default connect(null, mapDispatchToProps)(UsersForm)
