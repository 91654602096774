import React from 'react'
import { FiEdit3 } from 'react-icons/fi'
import TlaEdit from '../../../../components/tla-edit'
import PropTypes from 'prop-types'

const EditIcon = ({ editData, experience, link }) => {
    return (
        <TlaEdit
            data={experience
                ? {
                    ...editData,
                    description: editData.description.items
                }
                : editData
            }
            link={`/${link}/form`}
            className={'text-gray-400 text-base leading-none'}>
            <FiEdit3 size={16} className={'text-gray-400 cursor-pointer leading-none'}/>
        </TlaEdit>
    )
}

EditIcon.defaultProps = {
    editData: {},
    experience: false
}

EditIcon.propTypes = {
    editData: PropTypes.object,
    link: PropTypes.string.isRequired,
    experience: PropTypes.bool
}

export default EditIcon
