import React, { useEffect, useState } from 'react'
import { Button, Checkbox, Col, DatePicker, Form, Input, Row } from 'antd'
import { connect, useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { useLocation, useNavigate } from 'react-router-dom'
import TlaModalFormWrapper from '../../components/tla-modal-form-wrapper'
import { FiCalendar, FiPlus, FiTrash } from 'react-icons/fi'
import { handleAddExperience, handleUpdateExperience } from '../../actions/experience/Action'
import { formDate } from '../../utils'
import TlaAddNew from '../../components/common/tla-add-new'

function ExperienceForm ({ addExperience, updateExperience }) {
    // eslint-disable-next-line no-unused-vars
    const { subscriptionPackage } = useSelector(state => state.dashboardReducer.userPackage)

    const { state } = useLocation()
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const responsibilities = useSelector(state => state.askChatGptReducer.chatGptExperiences.responsibilities)

    const formValues = {
        id: 0,
        ...state?.data,
        jobDurationStart: state?.data ? formDate(state?.data.jobDurationStart) : '',
        jobDurationEnd: state?.data ? formDate(state?.data.jobDurationEnd) : null,
        present: state?.data ? state?.data?.jobDurationEnd === null : true,
        description: state?.data?.description ? [...state?.data?.description, ...responsibilities].slice(0, 15) : [...responsibilities].slice(0, 15)
    }

    const [required, setRequired] = useState(false)

    const [form] = Form.useForm()
    const description = Form.useWatch('description', form)
    useEffect(() => {
        form.validateFields(['jobDurationEnd'])
    }, [required, form])

    return (
        <TlaModalFormWrapper
            customForm={form}
            width={983} resourceId={state?.data?.id ?? null}
            initialValues={formValues}
            formTitle={'Add Experience'}
            onSubmit={state?.data?.id ? updateExperience : addExperience}>
            <Row gutter={14}>
                <Col span={12} xs={24} md={12}>
                    <Form.Item
                        name={'jobTitle'}
                        rules={[
                            {
                                required: true,
                                message: 'Required'
                            }
                        ]}
                        label={'Job Title *'}>
                        <Input size={'large'} placeholder={'Eg  UI Designer'}/>
                    </Form.Item>
                </Col>
                <Col span={12} xs={24} md={12}>
                    <Form.Item
                        name={'companyName'}
                        rules={[
                            {
                                required: true,
                                message: 'Required'
                            }
                        ]}
                        label={'Company Name'}>
                        <Input size={'large'} placeholder={'Eg dennis@infosys.com'}/>
                    </Form.Item>
                </Col>
                <Col span={12} xs={24} md={12}>
                    <Form.Item
                        rules={[
                            {
                                required: true,
                                message: 'Required'
                            }
                        ]}
                        name={'city'}
                        label={'City, Country'}>
                        <Input size={'large'} placeholder={'Eg New York, USA'}/>
                    </Form.Item>
                </Col>
                <Col span={4} xs={12} md={4} className={'mr-1 md:mr-6'}>
                    <p className={'mb-2 text-sm'}>Job Duration</p>
                    <div className={'tla-date-wrapper'}>
                        <FiCalendar className={'tla-date-icon'}/>
                        <Form.Item
                            name={'jobDurationStart'}
                            rules={[
                                {
                                    required: true,
                                    message: 'Required'
                                }
                            ]}>
                            <DatePicker
                                size={'large'} placeholder={'Start date'}
                                className={'tla-date tla-start-date'} suffixIcon={<></>}/>
                        </Form.Item>
                    </div>
                </Col>
                <Col span={4} xs={8} md={4}>
                    <p className={'mb-2 text-sm'}>&nbsp;</p>
                    <div className={'tla-date-wrapper'}>
                        <FiCalendar className={'tla-date-icon'}/>
                        <Form.Item
                            rules={[
                                {
                                    required,
                                    message: 'Required'
                                }
                            ]}
                            name={'jobDurationEnd'}>
                            <DatePicker onChange={() => setRequired(true)}
                                        size={'large'} placeholder={'End date'}
                                        className={'tla-date tla-end-date'} suffixIcon={<></>}/>
                        </Form.Item>
                    </div>
                </Col>
                <Col span={2} xs={24} md={2} className={'flex items-end ml-3 md:ml-6 justify-end md:justify-center'}>
                    <Form.Item
                        name="present"
                        valuePropName="checked"
                        dependencies={['jobDurationEnd']}>
                        <Checkbox onChange={() => setRequired(!required)}>Present</Checkbox>
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.List name="description">
                        {(fields, { add, remove }) => (
                            <>
                                <div className={'flex justify-between items-center mb-2'}>
                                    <h3 className={'text-sm font-medium'}>Job Description</h3>
                                    {/* {
                                        subscriptionPackage.subscriptionName === 'free'
                                            ? <UpgradeModal/>
                                            : <TlaAddNew link={'ask-gpt'} pathname={'/experience'}>
                                                <span
                                                    className={'text-sm font-medium text-primary-700'}>Ask ChatGPT</span>
                                            </TlaAddNew>
                                    } */}
                                    <TlaAddNew link={'ask-gpt'} pathname={ state?.data?.previousPath ?? '/cv-builder/experience'}>
                                                <span
                                                    className={'text-sm font-medium text-primary-700'}>Ask ChatGPT</span>
                                    </TlaAddNew>
                                </div>
                                {fields.map(({ key, name, ...restField }) => (
                                    <div key={key} className={'flex gap-2'}>
                                        <div className={'w-full'}>
                                            <Form.Item
                                                {...restField}
                                                name={name}
                                                rules={[
                                                    {
                                                        required: true,
                                                        message: 'Required'
                                                    }
                                                ]}>
                                                <Input size={'large'} placeholder="Bullet Point"/>
                                            </Form.Item>
                                        </div>
                                        <Button
                                            icon={<FiTrash/>}
                                            className={'btn'} danger
                                            size={'large'}
                                            onClick={() => remove(name)}/>
                                    </div>
                                ))}
                                {
                                    (description?.length <= 15) &&
                                    <Button type="text" className={'btn text-sm text-gray-500 hover:!bg-transparent'}
                                            onClick={() => add()} icon={<FiPlus size={20}/>}>
                                        Add Bullet Point
                                    </Button>
                                }
                            </>
                        )}
                    </Form.List>
                </Col>
            </Row>

            <div className={'flex gap-2 mx-auto md:mx-[178px]'}>
                <div className={'w-full'}>
                    <Button className={'!h-11 w-full'} size={'large'} onClick={() => {
                        dispatch({
                            type: 'CLEAR_EXPERIENCES', payload: { responsibilities: [] }
                        })
                        navigate(-1)
                    }}>
                        Cancel
                    </Button>
                </div>
                <Form.Item className={'w-full'}>
                    <Button className={'btn btn-primary w-full !h-11'} size={'large'} htmlType="submit">
                        Save
                    </Button>
                </Form.Item>
            </div>
        </TlaModalFormWrapper>
    )
}

ExperienceForm.propTypes = {
    addExperience: PropTypes.func.isRequired,
    updateExperience: PropTypes.func.isRequired
}

/**
 *
 * @param dispatch
 * @returns {{addExperience: (function(*, *): *)}}
 */
const mapDispatchToProps = (dispatch) => ({
    addExperience: (expId, data) => dispatch(handleAddExperience(expId, data)),
    updateExperience: (expId, data) => dispatch(handleUpdateExperience(expId, data))
})

export default connect(null, mapDispatchToProps)(ExperienceForm)
