import api from '../../utils/api'
import { addAward, allAwards, deleteAward, updateAward } from './ActionCreator'

/**
 * Store a newly created resource in storage.
 * @param userId
 * @param data
 * @returns {function(*): Promise<unknown>}
 */
export const handleAddAward = (userId, data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    api().post(`/users/award/${userId}`, data).then((res) => {
      dispatch(addAward(res.data))
      resolve(res)
    }).catch((err) => {
      reject(err)
    })
  })
}

/**
 * Display a listing of the resource.
 * @returns {function(*): Promise<unknown>}
 */
export const handleGetAllAwards = (userId, pageNumber = 1) => (dispatch) => {
  return new Promise((resolve, reject) => {
    api().get(`/users/award?userId=${userId}&page=${pageNumber}&pageSize=20`).then((res) => {
      dispatch(allAwards(res.data))
      resolve(res)
    }).catch((err) => {
      reject(err)
    })
  })
}

/**
 * Update the specified resource in storage.
 * @param awardId
 * @param data
 * @returns {function(*): Promise<unknown>}
 */
export const handleUpdateAward = (awardId, data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    api().put(`/users/award/${awardId}`, data, {
      // headers: { 'Content-type': 'multipart/form-data' }
    }).then((res) => {
      dispatch(updateAward(res.data))
      resolve(res)
    }).catch((err) => {
      reject(err)
    })
  })
}

/**
 * Remove the specified resource from storage.
 * @param id
 * @returns {function(*): Promise<unknown>}
 */
export const handleDeleteAward = (id) => (dispatch) => {
  return new Promise((resolve, reject) => {
    api().delete(`/users/award/${id}`).then((res) => {
      dispatch(deleteAward(id))
      resolve(res)
    }).catch((err) => {
      reject(err)
    })
  })
}
