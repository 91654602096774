import React, { useState } from 'react'
import { TlaModal } from '../../components/common/tla-modal'
import { Button, Spin, Upload } from 'antd'
import { Link, useLocation, useNavigate } from 'react-router-dom'
import { TlaError, TlaSuccess } from '../../utils/messages'
import { connect, useDispatch, useSelector } from 'react-redux'
import { handleUploadResume } from '../../actions/apply10x/Action'
import PropTypes from 'prop-types'
import { allowedResumeFileTypes, formatResume } from '../../utils'
import { handleGetResume } from '../../actions/build-resume/Action'

function UploadResume ({ userId, uploadFile, getResume }) {
    const { name, shortId, slug, metadata } = useSelector(state => state.buildResumeReducer.currentResume)
    const [loading, setLoading] = useState(false)
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { state } = useLocation()
    const uploadProps = {
        accept: allowedResumeFileTypes,
        beforeUpload: (info) => {
            const isPDF = allowedResumeFileTypes.includes(info.file.type)
            if (!isPDF) {
                TlaError('Only PDF and word documents are allowed')
            }
            return isPDF || Upload.LIST_IGNORE
        },
        listType: 'text',
        maxCount: 1,
        onRemove: () => {
            // setFile(null)
        },
        method: 'get',
        onChange (info) {
            const isPDF = allowedResumeFileTypes.includes(info.file.type)
            if (!isPDF) {
                TlaError('Only PDF and word documents are allowed')
            } else {
                setLoading(true)
                const formData = new FormData()
                formData.append('resume', info.file)

                uploadFile(formData, userId)
                    .then((res) => {
                        setLoading(false)
                        TlaSuccess('Your resume data has been added to the profile. Please verify and correct the information where needed.')
                        if (state?.data?.isResume) {
                            getResume().then(res => {
                                const data = {
                                    title: name,
                                    shortId,
                                    slug,
                                    name,
                                    content: formatResume(res.data.data?.user),
                                    basics: '',
                                    sections: '',
                                    metadata
                                }
                                dispatch({
                                    type: 'NEW_RESUME',
                                    payload: data
                                })
                                navigate(-1)
                            })
                        } else {
                            navigate(-1)
                        }
                    }).catch((error) => {
                    setLoading(false)
                    TlaError(error.response.data.message)
                })
            }
        }
    }
    return (
        <TlaModal title={''} width={'w-[350px] md:w-[400px]'}>
            <Spin spinning={loading}>
                <div className={'flex flex-col items-center text-center'}>
                    <h3 className={'text-base text-gray-900 font-medium leading-[28px]'}>Create your profile with our AI
                        powered Resume Parser</h3>
                    <p className={'text-gray-500 font-normal text-sm leading-[20px] mb-8'}>After uploading resume,
                        please check your profile to ensure all data is parsed well, placed nicely.</p>
                    <Upload {...uploadProps} className={'resume-upload'}>
                        <Button size={'large'} className={'btn btn-primary-700 mb-4'} block>Upload CV</Button>
                    </Upload>

                    <Link to={'/profile'} className={'w-full'}>
                        <Button size={'large'} className={'btn !text-gray-700'} block>Add profile data manually</Button>
                    </Link>
                </div>

                <p className={'text-sm text-gray-500 mt-5 mx-auto w-fit cursor-pointer'} onClick={() => {
                    navigate(-1)
                }}>Close</p>

            </Spin>
        </TlaModal>
    )
}

UploadResume.propTypes = {
    uploadFile: PropTypes.func.isRequired,
    getResume: PropTypes.func.isRequired,
    userId: PropTypes.number.isRequired
}

const mapStateToProps = (state) => ({
    userId: state.loginReducer.authUser.id
})

const mapDispatchToProps = (dispatch) => ({
    getResume: () => dispatch(handleGetResume()),
    uploadFile: (data, id) => dispatch(handleUploadResume(data, id))
})

export default connect(mapStateToProps, mapDispatchToProps)(UploadResume)
