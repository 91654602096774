import React from 'react'
import { Form, Input, Upload } from 'antd'
import { useLocation } from 'react-router-dom'
import TlaIcon from '../../../components/tla-icon'
import { TlaError } from '../../../utils/messages'
import { allowedResumeFileTypes, getResumeName } from '../../../utils'
import { FiDownload } from 'react-icons/fi'
import ExperienceLevel from '../../v1-1/first-time-user/experience-level'
import LocationSearch from '../../../components/places-api/location-search'

const { Dragger } = Upload

// eslint-disable-next-line react/prop-types
function JobQueryFormContent ({ form, selectedFile, resumeStatus, setSelectedFile, experienceLevelName }) {
    const { state } = useLocation()

    const props = {
        accept: allowedResumeFileTypes,
        beforeUpload: (info) => {
            const isPDF = allowedResumeFileTypes.includes(info.type)
            if (!isPDF) {
                TlaError('Only PDF and word documents are allowed')
            } else {
                setSelectedFile(info)
            }
            return isPDF || Upload.LIST_IGNORE
        },
        name: 'resume',
        multiple: false,
        maxCount: 1,
        method: 'get'
    }

    return (
       <>
           <Form.Item hidden rules={ [{ required: true, message: 'required' }] } name={ 'lat' } label={ 'Lat' }>
               <Input/>
           </Form.Item>
           <Form.Item hidden rules={ [{ required: true, message: 'required' }] } name={ 'long' }
                      label={ 'Long' }>
               <Input/>
           </Form.Item>
           <Form.Item
               className={ 'w-full md:w-[376px]' }
               name={ 'title' }
               rules={ [
                   {
                       required: true,
                       message: 'Required'
                   }
               ] }
               label={ 'What job you are looking for' }>
               <Input size={ 'large' } placeholder={ 'Eg. Product Manager' }/>
              {/* <Select showArrow={ false } showSearch size={ 'large' }
                       filterOption={ (input, option) => option.children.toLowerCase().includes(input.toLowerCase()) }
                       placeholder={ 'Eg. Product Manager' }>
                   {
                       JobList.map((job, index) => (
                           <Select.Option key={ index } value={ job }>{ job }</Select.Option>
                       ))
                   }
               </Select> */}
           </Form.Item>
           <div>
               <p className={ 'font-medium mb-1' }>Where are you looking for a job</p>
               <Form.Item name={ 'location' } rules={ [
                   { required: true, message: 'Required' }
               ] }>
                   {/* eslint-disable-next-line react/prop-types */}
                   <LocationSearch form={form} initialValue={form.getFieldValue('location') ?? state?.data?.location}/>
                   {/* <PlacesApi form={ form }/> */}
               </Form.Item>
           </div>
           {/* <Form.Item label="Job Type" name="jobType"
                <Input rootClassName={'w-full md:w-[376px]'} size={'large'} placeholder={'Eg. Toronto'}/>
                           rules={[
                               {
                                   required: true,
                                   message: 'Required'
                               }
                           ]}>
                    <Select size={'large'} mode={'multiple'}
                            placeholder="Select Job Type">
                        <Select.Option value="FULL_TIME">Full Time</Select.Option>
                        <Select.Option value="PART_TIME">Part Time</Select.Option>
                        <Select.Option value="CONTRACT">Contract</Select.Option>
                        <Select.Option value="INTERNSHIP">Internship</Select.Option>
                    </Select>
                </Form.Item> */ }
           <div className={ 'mb-2' }>
               <p className={ 'font-medium mb-1' }>Your experience level</p>
               <ExperienceLevel name={experienceLevelName}/>
           </div>
           {/* <Form.Item label="Onsite/Remote" name="remoteOnSite"
                           rules={[{
                               required: true,
                               message: 'Required'
                           }]}>
                    <Select size={'large'} mode={'multiple'}
                            placeholder="On Site">
                        <Select.Option value="ONSITE">On Site</Select.Option>
                        <Select.Option value="REMOTE">Remote</Select.Option>
                        <Select.Option value="HYBRID">Hybrid</Select.Option>
                    </Select>
                </Form.Item> */ }
           <Form.Item rules={ [
               {
                   required: true,
                   message: 'Resume is required'
               }
           ] }>
               {
                   state?.data?.resume &&
                   <div className={ 'flex mb-2 gap-x-2' }>
                       <p className={ 'text-sm font-medium' }>Existing Resume:</p>
                       <a className={ 'text-primary-700 flex items-center gap-x-2' }
                          href={ state?.data?.resume ?? '#' }
                          target={ '_blank' } rel="noreferrer">
                           { getResumeName(state?.data?.resume ?? '') }<FiDownload/>
                       </a>
                   </div>
               }

               <Dragger style={ { background: 'white' } } { ...props }>
                   <div className={ 'w-fit mx-auto' }>
                       <TlaIcon name={ 'upload' }/>
                   </div>
                   <p className="text-sm text-primary-700 font-medium">Click to upload Resume <span
                       className={ 'text-gray-500 font-normal' }>(PDF only)</span></p>
                   <p className="text-xs text-gray-500">
                       This resume is this job query. Ensure you have latest resume on LinkedIn for EasyApply
                   </p>
               </Dragger>
           </Form.Item>
           {
               // eslint-disable-next-line react/prop-types
               resumeStatus === '' ? <span>{selectedFile?.name} </span> : <span className={'text-red-500 '}>{resumeStatus} </span>
           }
       </>
    )
}

export default JobQueryFormContent
