import React, { useState } from 'react'
import { Button, Col, Form, Input, Row } from 'antd'
import { connect, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { useLocation, useNavigate } from 'react-router-dom'
import TlaCloseModal from '../../components/common/tla-close-modal'
import { TlaModal } from '../../components/pop-ups/tla-modal'
import { handleGetResume, handleUpdateResume } from '../../actions/build-resume/Action'
import { formatResume, makeId } from '../../utils'

function CreateResumeForm ({ getResume, userId }) {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [loading, setLoading] = useState(false)
    const [form] = Form.useForm()
    const { state } = useLocation()
    const formValues = {
        ...state?.data
    }

    const onSubmit = (values) => {
         setLoading(true)

        if (state.data) {
            dispatch({
                type: 'UPDATE_RESUME_NAME',
                payload: values.name
            })
            navigate(-1)
            setLoading(false)
        } else {
            getResume().then((res) => {
                dispatch({
                    type: 'NEW_RESUME',
                    payload: {
                        ...values,
                        shortId: makeId(10),
                        slug: 'testing',
                        content: formatResume(res.data.data?.user),
                        metadata: {
                            template: 'castform',
                            photo: 'yes'
                        }
                    }
                })
                setLoading(false)
                navigate('/build-resume')
            }).catch((err) => console.log(err))
        }
    }

    return (
        <TlaModal loading={loading} width={500} title={`${state?.data ? 'Update' : 'Create'} Resume Title`}>
            <Form requiredMark={false}
                  form={form}
                  onFinish={onSubmit}
                  layout="vertical"
                  name="job-query-form"
                  initialValues={formValues}>
                <Form.Item
                    name={'name'}
                    rules={[
                        {
                            required: true,
                            message: 'Required'
                        }
                    ]}
                    label={'Resume Title *'}>
                    <Input size={'large'} placeholder={'Eg Technical Project Manager'}/>
                </Form.Item>

                <Row gutter={[10, 0]}>
                    <Col span={12}>
                        <TlaCloseModal/>
                    </Col>
                    <Col span={12}>
                        <Form.Item>
                            <Button
                                htmlType={'submit'} size={'large'}
                                className={'btn tla-btn-primary submit-button'}
                                block type="primary">
                                {state?.data ? 'Update' : 'Next'}
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </TlaModal>
    )
}

CreateResumeForm.propTypes = {
    updateResume: PropTypes.func.isRequired,
    getResume: PropTypes.func.isRequired,
    userId: PropTypes.number.isRequired
}

/**
 *
 * @param state
 * @returns {{userId: BufferSource}}
 */
const mapStateToProps = (state) => ({
    userId: state.loginReducer.authUser.id
})

/**
 *
 * @param dispatch
 * @returns {{addJobQuery: (function(*): *)}}
 */
const mapDispatchToProps = (dispatch) => ({
    getResume: () => dispatch(handleGetResume()),
    updateResume: (resumeId, data) => dispatch(handleUpdateResume(resumeId, data))
})

export default connect(mapStateToProps, mapDispatchToProps)(CreateResumeForm)
