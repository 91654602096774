import React, { useEffect, useState } from 'react'
import { Drawer } from 'antd'
import AppSidebar from './app-sidebar'
import { FiMenu } from 'react-icons/fi'
import { useLocation } from 'react-router-dom'

function MobileMenu () {
    const [open, setOpen] = useState(false)
    const location = useLocation()
    const showDrawer = () => {
        setOpen(true)
    }
    const onClose = () => {
        setOpen(false)
    }

    useEffect(() => {
        onClose()
    }, [location])
    return (
        <>
            <FiMenu size={25} onClick={showDrawer} cursor={'pointer'}/>
            <Drawer size={250} title="" placement="left" onClose={onClose} open={open}>
                <AppSidebar/>
            </Drawer>
        </>
    )
}

export default MobileMenu
