import React from 'react'
import PropTypes from 'prop-types'

function WidgetItem ({ title, total, other, border }) {
    return (
        <div className={`p-6 w-full ${border ? 'border' : 'pl-0'} rounded-lg`}>
            <h6 className={'text-gray-500 font-medium text-sm mb-2'}>{title}</h6>
            <div className={'flex items-end flex-wrap gap-x-2'}>
                <h1 className={'text-gray-900 font-semibold text-[38px] leading-9'}>
                    {total}
                </h1>
                {
                    other &&
                    <span className={'text-[13px] font-normal'}>{other}</span>
                }
            </div>
        </div>
    )
}

WidgetItem.defaultProps = {
    border: false
}

WidgetItem.propTypes = {
    title: PropTypes.string,
    border: PropTypes.bool,
    other: PropTypes.any,
    total: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ])
}

export default WidgetItem
