import React, { useEffect, useState } from 'react'
import ReactApexChart from 'react-apexcharts'
import { MdCircle } from 'react-icons/md'
import { connect, useSelector } from 'react-redux'
import { handleGetGraphData } from '../../../../actions/dashboard/Actions'
import PropTypes from 'prop-types'

const JobsApplied = ({ getGraphData }) => {
    const userId = useSelector(state => state.loginReducer.authUser.id)
    const jobsApplied = useSelector(state => state.dashboardReducer?.jobsApplied?.months)
    // eslint-disable-next-line no-unused-vars
    const [loading, setLoading] = useState(true)

    const pluck = (arr, key) => arr.map(i => i[key])

    useEffect(() => {
        getGraphData(userId).then(() => setLoading(false))
    }, [])
    const data = {
        series: [
            {
                name: 'Jobs Applied',
                data: pluck(jobsApplied ?? [], 'userJobApplications')
            },
             {
                name: 'Jobs Viewed',
                data: pluck(jobsApplied ?? [], 'totalJobsVied')
            }],
        options: {
            chart: {
                type: 'bar',
                height: 240,
                stacked: true,
                toolbar: {
                    show: false
                },
                zoom: {
                    enabled: true
                }
            },
            dataLabels: {
                enabled: false
            },
            responsive: [{
                breakpoint: 480,
                options: {
                    legend: {
                        show: false
                    }
                }
            }],
            plotOptions: {
                bar: {
                    horizontal: false,
                    borderRadius: 5,
                    rangeBarOverlap: true,
                    columnWidth: '40%',
                    // borderRadiusWhenStacked: 'all'
                    borderRadiusApplication: 'end'
                }
            },
            xaxis: {
                type: 'category',
                categories: pluck(jobsApplied ?? [], 'monthName'),
                axisTicks: {
                    show: false
                },
                labels: {
                    style: {
                        colors: '#667085'
                    }
                },
                show: true,
                title: {
                    text: 'Months',
                    style: {
                        color: '#667085',
                        fontSize: '12px',
                        fontFamily: 'Poppins, Arial, sans-serif',
                        fontWeight: 500
                    }
                }
            },
            yaxis: {
                show: true,
                title: {
                    text: 'Jobs',
                    style: {
                        color: '#667085',
                        fontSize: '12px',
                        fontFamily: 'Poppins, Arial, sans-serif',
                        fontWeight: 500
                    }
                }
            },
            legend: {
                show: false
            },
            fill: {
                opacity: 1
            },
            colors: ['#2E90FA', '#84CAFF'],
            grid: {
                show: true,
                borderColor: '#F2F4F7'
            }
        }
    }

    return (
        <div>
            <div className={'flex justify-between items-center border-b pb-5'}>
                <h3 className={'text-gray-900 text-sm md:text-lg'}>Jobs Applied</h3>
                <div className={'jobs-months'}>
                    {/* <span>This month</span>
                    <span>12 months</span> */}
                </div>
            </div>
            <div className={'jobs-legend -mb-4'}>
                <p><MdCircle className={'text-primary-600'}/><span>Jobs Applied</span></p>
                <p><MdCircle className={'text-primary-400'}/><span>Jobs Viewed</span></p>
            </div>
            <ReactApexChart options={data.options} series={data.series} type="bar" height={240}/>
        </div>
    )
}

JobsApplied.propTypes = {
    getGraphData: PropTypes.func.isRequired
}
const mapDispatchToProps = (dispatch) => ({
    getGraphData: (userId) => dispatch(handleGetGraphData(userId))
})

export default connect(null, mapDispatchToProps)(JobsApplied)
