import React from 'react'
import { EditOutlined } from '@ant-design/icons'
import { Link, useLocation } from 'react-router-dom'
import PropTypes from 'prop-types'

function TlaEdit ({ link, data, text, icon, type, className }) {
    const location = useLocation()
    return (
        <Link to={link} state={{ background: location, data }}>
            <span className={className}>{icon}{text}</span>
        </Link>
    )
}

TlaEdit.defaultProps = {
    text: '',
    type: 'text',
    className: '',
    link: 'form',
    icon: <EditOutlined/>
}

TlaEdit.propTypes = {
    text: PropTypes.any,
    icon: PropTypes.node,
    type: PropTypes.any,
    className: PropTypes.string,
    data: PropTypes.object.isRequired,
    link: PropTypes.string.isRequired
}

export default TlaEdit
