import React, { useEffect, useState } from 'react'
import SectionHeader from '../../components/section-header'
import TlaAddNew from '../../components/pop-ups/tla-add-new'
import { Button, Spin } from 'antd'
import { FiPlus } from 'react-icons/fi'
import { JobCard } from '../../components/job-card'
import { connect } from 'react-redux'
import { handleGetAllSkills } from '../../actions/skills/Action'
import PropTypes from 'prop-types'
import { SkillItem } from './skill-item'

const Skills = ({ userId, getSkills, skills }) => {
    const [loading, setLoading] = useState(true)
    useEffect(() => {
        getSkills(userId).then(() => setLoading(false))
    }, [])

    return (
        <div className={'pb-4 rounded-lg py-[29px] px-[28px]'}>
            <div className={'mb-11'}>
                <SectionHeader title={'Skills Tag'}>
                    <TlaAddNew link={'form'}>
                        <Button className={'btn btn-rounded'} size={'large'} icon={<FiPlus/>}>
                            Add/Edit Skills
                        </Button>
                    </TlaAddNew>
                </SectionHeader>
            </div>

            <JobCard actions={false}>
                <Spin spinning={loading}>
                    <h3 className={'text-lg text-gray-900 font-medium'}>Technical Skills</h3>
                    <div className={'flex flex-wrap gap-2 py-2'}>
                        {
                            skills?.technicalSkills?.map((skill, index) => (
                                <SkillItem key={index} icon={false} title={skill}/>
                            ))
                        }
                    </div>
                    <h3 className={'text-lg text-gray-900 font-medium mt-2'}>Soft Skills</h3>
                    <div className={'flex flex-wrap gap-2 py-2'}>
                        {
                            skills?.softSkills?.map((skill, index) => (
                                <SkillItem key={index} icon={false} title={skill}/>
                            ))
                        }
                    </div>
                </Spin>
            </JobCard>
        </div>
    )
}

Skills.propTypes = {
    skills: PropTypes.any.isRequired,
    getSkills: PropTypes.func.isRequired,
    userId: PropTypes.number.isRequired
}

const mapStateToProps = (state) => ({
    skills: state.skillsReducer.skills.data,
    userId: state.loginReducer.authUser.id
})

const mapDispatchToProps = (dispatch) => ({
    getSkills: (userId) => dispatch(handleGetAllSkills(userId))
})

export default connect(mapStateToProps, mapDispatchToProps)(Skills)
