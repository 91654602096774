import React, { useState } from 'react'
import TlaIcon from '../../tla-icon'
import { MdClose } from 'react-icons/md'

function Banner () {
    const [show, setShow] = useState(localStorage.getItem('banner') ?? 'true')
    const closeBanner = () => {
        localStorage.setItem('banner', 'false')
        setShow(false)
    }

    return (
        show === 'true' &&
        <div style={{ boxShadow: '0px 4px 6px -2px rgba(16, 24, 40, 0.03), 0px 12px 16px -4px rgba(16, 24, 40, 0.08)' }}
             className={'flex items-center justify-between bg-primary-25 p-3 rounded-xl border border-primary-300' +
                 ' mb-[40px]'}>
            <div className={'flex items-center gap-x-4'}>
                <TlaIcon name={'heart'}/>
                <div>
                    <p className={'text-primary-700 text-lg font-semibold'}>Install our extension to start applying
                        automatically</p>
                    <p className={'text-base text-primary-600 font-normal'}>
                        Learn <a className={'underline'} href="https://autoapplyjobs.notion.site/How-to-Use-AutoApply-Jobs-A-Step-by-Step-Guide-e65d6fe29b4341f0b3358153d03715a9"
                                 target={'_blank'} rel="noreferrer">how it works</a>
                    </p>
                </div>
            </div>
            <div className={'flex items-center gap-x-2.5'}>
                <a className={'bg-primary-500 py-2.5 px-[18px] text-base font-medium text-white-base rounded-lg'}
                   target={'_blank'}
                   href={'https://chrome.google.com/webstore/detail/autoapply/aihojinhpngojiaghgoekajdhcjenbce'}
                   rel="noreferrer">
                    Install Extension
                </a>
                <MdClose onClick={closeBanner} className={'cursor-pointer text-primary-500'} size={20}/>
            </div>
        </div>
    )
}

export default Banner
