import React, { useState } from 'react'
import { Button, Form, Input, Spin } from 'antd'
import { connect } from 'react-redux'
import { handleChangePassword } from '../../actions/authenticate/Actions'
import { TlaError, TlaSuccess } from '../../utils/messages'
import PropTypes from 'prop-types'
import SectionHeader from '../../components/section-header'
import { useNavigate } from 'react-router-dom'

function ChangePassword ({ changePassword, user }) {
    const navigate = useNavigate()
    const [loading, setLoading] = useState(false)
    const [form] = Form.useForm()

    const onFinish = (values) => {
        setLoading(true)

        changePassword(user.id, values).then(() => {
            TlaSuccess()
            form.resetFields()
            setLoading(false)
        }).catch((error) => {
            setLoading(false)
            TlaError(error.response.data.message)
        })
    }

    return (
        <div className={'w-full py-[42px] px-[34px] rounded-lg'}>
            <Spin spinning={loading}>
                <Form className={'passwordForm'} colon={false}
                      initialValues={{
                          email: user.email
                      }} onFinish={onFinish} form={form} requiredMark={false}>
                    <SectionHeader title={'Settings'}>
                        <div className={'flex gap-3'}>
                            <Button onClick={() => navigate(-1)} className={'btn'} size={'large'}>Cancel</Button>
                            <Button className={'btn btn-primary-700'} htmlType={'submit'} size={'large'}>
                                Update Password
                            </Button>
                        </div>
                    </SectionHeader>
                    <div className={'flex flex-wrap'}>
                        <div className={'mt-5 md:mt-[64px]'}>
                            <Form.Item name="email"
                                       label={'Email'}
                                       rules={[
                                           { required: true, message: 'Password is required!' }
                                       ]}>
                                <Input className={'password-form-input'} size={'large'} disabled
                                       placeholder="christ@hotmail.com"/>
                            </Form.Item>
                        </div>
                        <div>
                            <Form.Item name="currentPassword"
                                       label={'Current password'}
                                       rules={[
                                           { required: true, message: 'Password is required!' },
                                           { min: 8, message: '' }
                                       ]}>
                                <Input.Password
                                    className={'password-form-input'} size={'large'}
                                    type={'password'}
                                    placeholder="••••••••"/>
                            </Form.Item>
                        </div>
                        <div>
                            <Form.Item name="password"
                                       label={'New password'}
                                       rules={[
                                           { required: true, message: 'Password is required!' },
                                           { min: 8, message: '' }
                                       ]}>
                                <Input.Password
                                    className={'password-form-input'} size={'large'}
                                    type={'password'}
                                    placeholder="••••••••"/>
                            </Form.Item>
                        </div>
                        <div>
                            <Form.Item
                                dependencies={['password']}
                                name="passwordConfirm"
                                label={'Confirm new password'}
                                rules={[
                                    {
                                        required: true,
                                        message: 'Password is required!'
                                    }, ({ getFieldValue }) => ({
                                        validator (_, value) {
                                            if (!value || getFieldValue('password') === value) {
                                                return Promise.resolve()
                                            }
                                            return Promise.reject(new Error('Password mismatch'))
                                        }
                                    }),
                                    {
                                        min: 8,
                                        message: 'Password should be at least 8 characters'
                                    }
                                ]}>
                                <Input.Password
                                    className={'password-form-input'} size={'large'}
                                    type="password"
                                    placeholder="••••••••"
                                />
                            </Form.Item>
                        </div>
                    </div>
                </Form>
            </Spin>
        </div>
    )
}

ChangePassword.propTypes = {
    changePassword: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired
}

const mapStateToProps = (state) => ({
    user: state.loginReducer.authUser
})

const mapDispatchToProps = (dispatch) => ({
    changePassword: (userId, data) => dispatch(handleChangePassword(userId, data))
})

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword)
