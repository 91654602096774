import React from 'react'
import { FiEye, FiEyeOff } from 'react-icons/fi'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'

const Visibility = ({ text, name, value, isPhoto, callBack }) => {
    const dispatch = useDispatch()

    const onClick = () => {
        dispatch({
            type: isPhoto ? 'TOGGLE_PHOTO' : 'HIDE_ITEM',
            payload: {
                name,
                value
            }
        })
        callBack && callBack()
    }
    return (
        <div onClick={onClick}
             className={`flex items-center gap-x-1 ${value ? 'text-primary-400' : 'text-gray-400'} font-medium cursor-pointer`}>
            {value ? <FiEye size={16}/> : <FiEyeOff size={16}/>}
            {text && <span className={'text-xs'}>{value ? 'Visible' : 'Invisible'}</span>}
        </div>
    )
}

Visibility.defaultProps = {
    text: false,
    name: '',
    value: true,
    isPhoto: false,
    callBack: null
}

Visibility.propTypes = {
    text: PropTypes.bool,
    value: PropTypes.bool,
    isPhoto: PropTypes.bool,
    name: PropTypes.any,
    callBack: PropTypes.func
}

export default Visibility
