import React from 'react'
import { FiChevronDown } from 'react-icons/fi'

const ExpandIcon = (isActive) => (
    <FiChevronDown
        size={16}
        className={'font-medium !cursor-pointer'}
        style={{ transform: `${isActive ? 'rotate(180deg)' : 'rotate(0deg)'}` }}/>
)
export default ExpandIcon
