import React from 'react'
import AuthLayout from './auth-layout'
import AuthBottomLink from './auth-bottom-link'
import { FiArrowLeft } from 'react-icons/fi'

function ResetPasswordSuccess () {
    return (
        <AuthLayout pageTitle={'Password Reset Success'}>
            <AuthBottomLink
                text={<FiArrowLeft size={18}/>}
                link={'/'}
                linkText={'Back to login'}
                size={'sm'}
                color={'gray-500'}
            />
        </AuthLayout>
    )
}

export default ResetPasswordSuccess
