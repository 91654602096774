import { Types } from './Types'

/**
 * @param payload
 * @returns {{payload, type: string}}
 */
export function addAward (payload) {
  return {
    type: Types.NEW_AWARD,
    payload
  }
}

/**
 *
 * @param payload
 * @returns {{payload, type: string}}
 */
export function allAwards (payload) {
  return {
    type: Types.ALL_AWARDS,
    payload
  }
}

/**
 *
 * @param id
 * @returns {{id, type: string}}
 */
export function deleteAward (id) {
  return {
    type: Types.DELETE_AWARD,
    id
  }
}

/**
 *
 * @param payload
 * @returns {{payload, type: string}}
 */
export function updateAward (payload) {
  return {
    type: Types.UPDATE_AWARD,
    payload
  }
}
