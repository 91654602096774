import React from 'react'
import { FiMinimize } from 'react-icons/fi'
import PropTypes from 'prop-types'

// eslint-disable-next-line react/prop-types
const Title = ({ title }) => (
    <div className={'flex items-center gap-x-[9px] max-w-[300px]'}><FiMinimize className={'text-gray-300'}/>
        <span className={'text-sm'}>{title}</span>
    </div>
)

Title.propTypes = {
    title: PropTypes.string
}
export default Title
