import React from 'react'
import { Route } from 'react-router-dom'
import EducationForm from '../../pages/education/education-form'
import ExperienceForm from '../../pages/experience/experience-form'
import ExperienceAskGpt from '../../pages/experience/experience-ask-gpt'
import CertificationForm from '../../pages/certification/certification-form'
import AwardForm from '../../pages/awards/award-form'
import PersonalInfoForm from '../../pages/personal-info/personal-info-form'
import SkillsForm from '../../pages/skills/skills-form'
import TemplateOptions from '../../pages/resume-builder/templates/options'
import CreateResumeForm from '../../pages/resumes/create-resume-form'
import ResumeNotInSync from '../../pages/resumes/resume-not-in-sync'
import SkillsAskGpt from '../../pages/skills/skills-ask-gpt'

export default [
    <Route key={'cv-builder'} path={'cv-builder'}>
        <Route path={'education/form'} element={<EducationForm/>}/>
        <Route path={'experience/form'} element={<ExperienceForm/>}/>
        <Route path={'experience/form/ask-gpt'} element={<ExperienceAskGpt/>}/>
        <Route path={'certificates/form'} element={<CertificationForm/>}/>
        <Route path={'awards-achievement/form'} element={<AwardForm/>}/>
        <Route path={'personal-info/form'} element={<PersonalInfoForm/>}/>
        <Route path={'skills/form'} element={<SkillsForm/>}/>
        <Route path={'build-resume/template'} element={<TemplateOptions/>}/>
        <Route path={'resume-template/form'} element={<CreateResumeForm/>}/>
        <Route path={'resumes/not-in-sync'} element={<ResumeNotInSync/>}/>
        <Route path={'skills/form/ask-gpt'} element={<SkillsAskGpt/>}/>
    </Route>
]
