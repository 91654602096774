import React from 'react'
import { Col, Form, Input, Row } from 'antd'
import PropTypes from 'prop-types'

const SingleCertificate = ({ cert }) => {
    return (
        <Row gutter={[10, 0]}>
            <Col span={24} xs={24} sm={24} lg={24}>
                <Form.Item
                    label="Title *"
                    name={[cert.name, 'title']}
                    rules={[
                        {
                            required: true,
                            message: 'Required'
                        }
                    ]}>
                    <Input disabled placeholder={'AWS Certified Administrator'} size={'large'}/>
                </Form.Item>
            </Col>
            <Col span={24} xs={24} sm={24} lg={24}>
                <Form.Item
                    label="Certification Organization"
                    name={[cert.name, 'organisation']}
                    rules={[
                        {
                            required: true,
                            message: 'Required'
                        }
                    ]}>
                    <Input disabled
                        placeholder={'AWS'}
                        size={'large'}
                    />
                </Form.Item>
            </Col>
        </Row>
    )
}

SingleCertificate.propTypes = {
    cert: PropTypes.object.isRequired
}
export default SingleCertificate
