import { Types } from '../actions/education/Types'

const initialState = {
  educations: {
    data: [],
    meta: {}
  }
}

export default function educationsReducer (state = initialState, action) {
  switch (action.type) {
    case Types.NEW_EDUCATION:
      return {
        ...state,
        educations: {
          ...state.educations,
          data: state.educations.data.concat(action.payload.data.education)
        }
      }
    case Types.ALL_EDUCATIONS:
      return {
        ...state,
        educations: {
          ...state.educations,
          data: action.payload.data.userEducations,
          meta: {
            total: action.payload.data.count,
            page: action.payload.data.page,
            pageSize: action.payload.data.pageSize
          }
        }
      }

    case Types.UPDATE_EDUCATION:
      return {
        ...state,
        educations: {
          ...state.educations,
          data: state.educations.data.map((form) => {
            return form.id === action.payload.data.education.id ? action.payload.data.education : form
          })
        }
      }
    case Types.DELETE_EDUCATION:
      return {
        ...state,
        educations: {
          ...state.educations,
          data: state.educations.data.filter((educations) => educations.id !== action.id)
        }
      }

    default:
      return state
  }
}
