import React from 'react'
import Widget from './widget'
import JobsApplied from './jobs-applied'
import { useSelector } from 'react-redux'
import Validate from '../../resume-builder/components/validate'

function Dashboard () {
    const currentApp = useSelector(state => state.appSettingsReducer.currentApp)

    return (
        <div className={'w-full rounded-lg py-[30px] px-[35px]'}>
            <Widget/>
            <Validate render={currentApp === 'auto-apply'}>
                <JobsApplied/>
            </Validate>
        </div>
    )
}

export default Dashboard
