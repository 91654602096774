import React from 'react'
import PropTypes from 'prop-types'
import { Outlet } from 'react-router-dom'
import Header from '../../dashboard/layout/header'
import { Sidebar } from '../dashboard/sidebar'
import PageCrumbs from '../../../components/page-crumbs'

function Layout () {
  return (
        <div className={'max-w-screen-2xl mx-auto'}>
            <Header/>
            <div className={'flex gap-x-[15px] justify-between mx-12 pb-5'}>
                <Sidebar/>
                <div className={'w-full md:w-[1144px] mb-5 bg-white-base rounded-lg p-8'}>
                    <div className={'mb-8'}>
                        <PageCrumbs/>
                    </div>
                    <Outlet/>
                </div>
            </div>
        </div>
  )
}

Layout.propTypes = {
  children: PropTypes.any
}

export default Layout
