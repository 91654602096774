import React from 'react'
import AuthLayout from './auth-layout'
import TlaFormWrapper from '../../components/tla-form-wrapper'
import { Form, Input } from 'antd'
import AuthBottomLink from './auth-bottom-link'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { handleAuthentication } from '../../actions/authenticate/Actions'
import PropTypes from 'prop-types'

function SignIn (props) {
    const { authenticateUser } = props

    return (<AuthLayout pageTitle={'Log in to your account'}>
            <div>
                <div>
                    <p className={'text-gray-500 text-base text-center mb-8'}>
                        Welcome back! Please enter your details.
                    </p>
                </div>
                <TlaFormWrapper
                    afterSubmit={'/'}
                    buttonText={'Sign in'}
                    onSubmit={authenticateUser}>
                    <Form.Item name="email" label="Email"
                               rules={[{
                                   required: true, message: 'Email is Required'
                               }, {
                                   type: 'email', message: 'Enter a valid email'
                               }]}>
                        <Input size={'large'} placeholder={'Enter your email'}/>
                    </Form.Item>
                    <Form.Item name="password"
                               label={'Password'}
                               rules={[{ required: true, message: 'Password is required!' }, { min: 8, message: '' }]}>
                        <Input.Password
                            size={'large'}
                            type={'password'}
                            placeholder="••••••••"/>
                    </Form.Item>
                    <div className={'flex items-center justify-end mb-4'}>
                        <Link className={'text-primary-700'} to={'/forgot-password'}>Forgot Password</Link>
                    </div>
                </TlaFormWrapper>
                <div className={'text-center w-[350px] mx-auto mt-4'}>
                    <p className={'text-gray-500 text-sm'}>
                        By using our site, you agree to our&nbsp;
                        <a className={'text-primary-700'}
                           href={'https://autoapply.jobs/terms-conditions/'}
                           target={'_blank'}
                           rel="noreferrer">Terms</a> and&nbsp; <br/>
                        <a className={'text-primary-700'} href="https://autoapply.jobs/privacy-policy/" target={'_blank'}
                           rel="noreferrer">Privacy Policy</a>.
                    </p>
                </div>
                <AuthBottomLink
                    size={'sm'}
                    text={'Don’t have an account?'}
                    link={'/sign-up'} linkText={'Sign up'} modal/>
            </div>
        </AuthLayout>)
}

SignIn.propTypes = {
    authenticateUser: PropTypes.func.isRequired
}

/**
 *
 * @param dispatch
 * @returns {{authenticateUser: (function(*): *)}}
 */
const mapDispatchToProps = (dispatch) => {
    return {
        authenticateUser: (values) => dispatch(handleAuthentication(values))
    }
}

export default connect(null, mapDispatchToProps)(SignIn)
