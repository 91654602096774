import React, { useEffect, useState } from 'react'
import TlaTableWrapper from '../../components/table/tla-table-wrapper'
import Column from 'antd/es/table/Column'
import PropTypes from 'prop-types'
import { handleDeleteJobQuery } from '../../actions/job-queries/Actions'
import { connect, useSelector } from 'react-redux'
import { handleGetJobApplications } from '../../actions/job-applications/Actions'
import { DatePicker, Spin } from 'antd'
import JobsAppliedStats from './jobs-applied-stats'
import { formatDate } from '../../utils'
import { FiCalendar } from 'react-icons/fi'
import dayjs from 'dayjs'
import { LoadingOutlined } from '@ant-design/icons'

function JobsApplied (props) {
    const [loading, setLoading] = useState(true)

    const userId = useSelector(state => state.loginReducer.authUser.id)
    const filter = useSelector(state => state.jobApplicationReducer.filter)
    const date = [filter.startDate ?? '', filter.endDate ?? '']
    filter.userId = userId
    const { getJobApplications, jobApplications } = props
    useEffect(() => {
        getData()
    }, [])

    const getData = () => {
        setLoading(true)
        getJobApplications(new URLSearchParams(filter)).then(() => {
            setLoading(false)
        })
    }

    const submitFilter = () => {
        filter.startDate = date[0]
        filter.endDate = date[1]
        getData()
    }
    return (
        <React.Fragment>
            <JobsAppliedStats/>
            <Spin indicator={<LoadingOutlined/>} spinning={loading}>
                <div className={'w-full rounded-lg mt-3.5'}>
                    <div className={'py-3 flex justify-between items-center'}>
                        <h3 className={'text-gray-900 text-lg font-medium'}>Job Title</h3>
                        <DatePicker.RangePicker
                            value={[date[0] ? dayjs(date[0]) : '', date[1] ? dayjs(date[1]) : '']}
                            onChange={(value) => {
                                if (value) {
                                    date[0] = formatDate(value[0], 'YYYY-MM-DD')
                                    date[1] = formatDate(value[1], 'YYYY-MM-DD')
                                } else {
                                    date[0] = ''
                                    date[1] = ''
                                }
                                submitFilter()
                            }}
                            className={'w-[280px] rounded-lg !text-sm'}
                            suffixIcon={<FiCalendar className={'text-gray-700'}/>}
                            size={'large'}/>
                    </div>
                    <TlaTableWrapper
                        size={'large'}
                        filterObj={filter}
                        fetchId={userId}
                        callbackFunction={getJobApplications}
                        data={jobApplications.data}
                        meta={jobApplications.meta}
                        showHeader={false}
                        numberColumn={false}>
                        <Column title={'Job Title'} render={(({ title, link }) => (
                            <a href={link} target={'_blank'} className={'underline'} rel="noreferrer">{title}</a>
                        ))}/>
                        <Column title={'Company name'} dataIndex="companyName"/>
                        <Column title={'Date'} render={(({ createdAt }) => (
                            formatDate(createdAt, 'MMM DD, YYYY')
                        ))}/>
                    </TlaTableWrapper>
                </div>
            </Spin>
        </React.Fragment>
    )
}

JobsApplied.propTypes = {
    jobApplications: PropTypes.object.isRequired,
    getJobApplications: PropTypes.func.isRequired,
    deleteJobQuery: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
    jobApplications: state.jobApplicationReducer.jobApplications
})

const mapDispatchToProps = (dispatch) => ({
    getJobApplications: (params) => dispatch(handleGetJobApplications(params)),
    deleteJobQuery: (id) => dispatch(handleDeleteJobQuery(id))
})

export default connect(mapStateToProps, mapDispatchToProps)(JobsApplied)
