import React, { useEffect, useState } from 'react'
import WidgetItem from './widget-item'
import { connect, useSelector } from 'react-redux'
import { handleGetStatistics } from '../../../../actions/dashboard/Actions'
import PropTypes from 'prop-types'
import { Spin } from 'antd'
import { formatDate } from '../../../../utils'
import Validate from '../../../resume-builder/components/validate'

function Widget ({ getStatistics, statistics, userId }) {
  const currentApp = useSelector(state => state.appSettingsReducer.currentApp)

  const [loading, setLoading] = useState(true)
  useEffect(() => {
    getStatistics(userId).then(() => setLoading(false))
  }, [])

  const items = [
    {
      title: 'Total Jobs Viewed',
      total: statistics?.totalJobsVied ?? 0,
      app: 'auto-apply'
    },
    {
      title: 'AutoApply Jobs (Paid) Applied',
      total: statistics?.totalJobsApplied?.used ?? 0,
      other: `${statistics?.totalJobsApplied?.left ?? 0} Remaining`,
      app: 'auto-apply'
    },
    {
      title: 'Total Job Queries',
      total: statistics?.jobQueries ?? 0,
      other: '',
      app: 'auto-apply'
    },
    {
      title: 'Resume Templates Created',
      total: statistics?.resumeTemplates ?? 0,
      other: '',
      app: 'cv-builder'
    },
    {
      title: 'Custom Letters Generated',
      total: statistics?.customCoverLetters?.used ?? 0,
      other: `${statistics?.customCoverLetters?.left ?? 0} Remaining`,
      app: 'cv-builder'
    },
    {
      title: 'Custom Resumes Generated',
      total: statistics?.customResumes?.used ?? 0,
      other: `${statistics?.customResumes?.left ?? 0} Left`,
      app: 'cv-builder'
    }
  ]

  return (
        <Spin spinning={loading}>
          <div className={'border-b pb-5 border-gray-200 mb-[22px]'}>
            <h6 className={'text-gray-900 text-lg font-medium'}>Overview</h6>
          </div>
            <div className={'grid grid-cols-2 md:grid-cols-3 gap-4 justify-between'}>
                {
                    items.map(({ title, total, other, app }) => (
                        <Validate render={currentApp === app} key={title}>
                            <WidgetItem other={other} title={title} total={total}/>
                        </Validate>
                    ))
                }
            </div>
          <div className={'mb-[22px] mt-4'}>
            <p className={'text-gray-700 text-sm font-medium'}>Package Reset Date: {formatDate(statistics?.dates?.statsEndDate, 'MMM DD, YYYY')}</p>
          </div>
        </Spin>
  )
}

Widget.propTypes = {
  statistics: PropTypes.object.isRequired,
  userId: PropTypes.oneOfType([
    PropTypes.number.isRequired,
    PropTypes.string.isRequired
  ]),
  getStatistics: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
  statistics: state.dashboardReducer.statistics,
  userId: state.loginReducer.authUser.id
})

const mapDispatchToProps = (dispatch) => ({
  getStatistics: (userId) => dispatch(handleGetStatistics(userId))
})

export default connect(mapStateToProps, mapDispatchToProps)(Widget)
