import { Types } from '../../actions/admin/users/Types'

const initialState = {
  users: {
    data: [],
    meta: {}
  },
  subscriptions: [],
  filter: {
    page: 1,
    pageSize: 10
  }
}

export default function adminUsersReducer (state = initialState, action) {
  switch (action.type) {
    case Types.NEW_USER:
      return {
        ...state,
        users: {
          ...state.users,
          data: state.users.data.concat(action.payload.data.newUser),
          meta: {
            total: action.payload.data.count,
            from: action.payload.data.page,
            current_page: parseInt(action.payload.data.page),
            to: action.payload.data.pageSize
          }
        }
      }

    case Types.ALL_USERS:
      // eslint-disable-next-line no-case-declarations,no-unused-vars
      const page = Number(action.payload.data.page)
      // eslint-disable-next-line no-case-declarations,no-unused-vars
      const pageSize = Number(action.payload.data.pageSize)

      return {
        ...state,
        users: {
          ...state.users,
          data: action.payload.data.users,
          meta: {
            total: action.payload.data.count,
            page,
            per_page: pageSize,
            from: page === 1 ? page : ((page - 1) * pageSize) + 1,
            current_page: page,
            to: action.payload.data.pageSize
          }
        }
      }

    case Types.GET_ALL_SUBSCRIPTIONS:
      return {
        ...state,
        subscriptions: action.payload.data.subscriptionTier
      }

    case Types.UPDATE_SUBSCRIPTION:
      return {
        ...state,
        users: {
          ...state.users,
          data: state.users.data.map((user) => {
            return user.id === action.payload.data.id ? action.payload.data.user : user
          })
        }
      }
    case Types.DELETE_USER:
      return {
        ...state,
        users: {
          ...state.users,
          data: state.users.data.filter((users) => users.id !== action.id)
        }
      }

    default:
      return state
  }
}
