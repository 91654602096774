import React from 'react'
import { Form, Input } from 'antd'
import EditIcon from './commons/edit-icon'
import Visibility from './visibility'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

function Summary ({ form }) {
    const data = form.getFieldValue(['userInformation', 'items'])
    const { hiddenItems } = useSelector(state => state.buildResumeReducer?.currentResume?.content?.userInformation)

    return (
        <div className={'bg-white-base py-[13px] px-6 rounded-lg border border-gray-200'}>
            <div className={'flex justify-end gap-x-5'}>
                <Visibility value={hiddenItems.summary !== undefined} name={['userInformation', 'hiddenItems', 'summary']} text/>
                <EditIcon editData={{ ...data, isSummary: true }} link={'cv-builder/personal-info'}/>
            </div>
            <Form.Item label={'Summary'} name={['userInformation', 'items', 'summary']}>
                <Input.TextArea disabled className={'rounded-lg shadow-sm'} rows={5}/>
            </Form.Item>
        </div>
    )
}

Summary.propTypes = {
    form: PropTypes.any
}

export default Summary
