import React from 'react'
import EditIcon from '../commons/edit-icon'
import Visibility from '../visibility'
import { SkillItem } from './skill-item'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

const Skills = ({ form }) => {
    const skills = form.getFieldValue('skillTags')?.items
    const hiddenItems = useSelector(state => state.buildResumeReducer.hiddenItems)
    return (
            <div className={'bg-white-base py-[13px] px-6 rounded-lg'}>
                <div className={'flex items-end justify-end gap-x-4'}>
                    <Visibility
                        name={['skillTags']}
                        value={hiddenItems.skillTags !== undefined}
                        text/>
                    <EditIcon editData={{ previousPath: '/build-resume' }} link={'cv-builder/skills'}/>
                </div>
                <div>
                    <p className={'text-gray-700 font-medium text-sm'}>Soft Skills</p>
                    <div className={'flex flex-wrap gap-2 py-4'}>
                        {
                            skills?.softSkills?.map((soft, index) => (
                                <SkillItem type={'soft'} title={soft} key={soft} index={index}/>
                            ))
                        }
                    </div>
                </div>
                <div>
                    <p className={'text-gray-700 font-medium text-sm'}>Technical Skills</p>
                    <div className={'flex flex-wrap gap-2 py-4'}>
                        {
                            skills?.technicalSkills?.map((soft, index) => (
                                <SkillItem type={'technical'} title={soft} key={soft} index={index}/>
                            ))
                        }
                    </div>
                </div>
            </div>
    )
}

Skills.propTypes = {
    form: PropTypes.any.isRequired
}

export default Skills
