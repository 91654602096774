import { Modal } from 'antd'
import React, { useEffect } from 'react'
import { FiTrash2 } from 'react-icons/fi'
import PropTypes from 'prop-types'

const TlaConfirm = (props) => {
  // eslint-disable-next-line no-unused-vars
  const { title, callBack, children, showIcon, okText, fullText, cancelText, close, danger, okClass } = props

  useEffect(() => {
    if (close) {
      Modal.destroyAll()
    }
  }, [close])
  const confirm = () => {
    Modal.confirm({
      title,
      icon: <></>,
      content: fullText,
      okText,
      cancelText,
      className: 'tla-delete-confirm',
      okButtonProps: {
        className: `btn ${okClass}`,
        danger,
        size: 'large',
        block: true
      },
      cancelButtonProps: {
        className: 'btn',
        size: 'large',
        block: true
      },
      onOk: callBack
    })
  }
  return (
      <span className={'!p-0 text-error-700 hover:!text-error-700 !bg-transparent cursor-pointer'}
              onClick={confirm}>
        {showIcon && <FiTrash2 className={'icon'}/>}
        {children}
      </span>

  )
}

TlaConfirm.defaultProps = {
  title: '',
  fullText: 'Do you want to delete',
  callBack: () => {
  },
  showIcon: true,
  btnType: 'text',
  cancelText: 'No, keep it',
  danger: true,
  close: false,
  okText: 'Delete',
  okClass: 'confirm-delete-btn'
}

TlaConfirm.propTypes = {
  title: PropTypes.string,
  fullText: PropTypes.string,
  children: PropTypes.any,
  callBack: PropTypes.func,
  showIcon: PropTypes.bool,
  btnType: PropTypes.string,
  okText: PropTypes.string,
  cancelText: PropTypes.string,
  okClass: PropTypes.string,
  danger: PropTypes.bool,
  close: PropTypes.bool
}

export default TlaConfirm
