import { Path, Svg } from '@react-pdf/renderer'
import React from 'react'
import PropTypes from 'prop-types'

export const GitlabSimple = ({ color, download }) => (
    download
        ? <Svg stroke="currentColor" fill="white" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round"
               strokeLinejoin="round" height="16px" width="16px" xmlns="http://www.w3.org/2000/svg">
            <Path
                d="M22.65 14.39L12 22.13 1.35 14.39a.84.84 0 0 1-.3-.94l1.22-3.78 2.44-7.51A.42.42 0 0 1 4.82 2a.43.43 0 0 1 .58 0 .42.42 0 0 1 .11.18l2.44 7.49h8.1l2.44-7.51A.42.42 0 0 1 18.6 2a.43.43 0 0 1 .58 0 .42.42 0 0 1 .11.18l2.44 7.51L23 13.45a.84.84 0 0 1-.35.94z"></Path>
        </Svg>

        : <svg stroke="currentColor" fill="none" strokeWidth="2" viewBox="0 0 24 24" strokeLinecap="round"
               strokeLinejoin="round" height="16px" width="16px" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M22.65 14.39L12 22.13 1.35 14.39a.84.84 0 0 1-.3-.94l1.22-3.78 2.44-7.51A.42.42 0 0 1 4.82 2a.43.43 0 0 1 .58 0 .42.42 0 0 1 .11.18l2.44 7.49h8.1l2.44-7.51A.42.42 0 0 1 18.6 2a.43.43 0 0 1 .58 0 .42.42 0 0 1 .11.18l2.44 7.51L23 13.45a.84.84 0 0 1-.35.94z"></path>
        </svg>

)

GitlabSimple.defaultProps = {
    color: 'currentColor',
    download: false
}

GitlabSimple.propTypes = {
    color: PropTypes.string,
    download: PropTypes.bool
}
