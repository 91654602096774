import { Path, Svg } from '@react-pdf/renderer'
import React from 'react'
import PropTypes from 'prop-types'

export const Email = ({ color, download }) => (
    download
        ? <Svg xmlns="http://www.w3.org/2000/svg" width="12" height="13" viewBox="0 0 12 13" fill={color}>
            <Path
                d="M11 3.66602C11 3.11602 10.55 2.66602 10 2.66602H2C1.45 2.66602 1 3.11602 1 3.66602M11 3.66602V9.66602C11 10.216 10.55 10.666 10 10.666H2C1.45 10.666 1 10.216 1 9.66602V3.66602M11 3.66602L6 7.16602L1 3.66602"
                stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
        </Svg>
        : <svg xmlns="http://www.w3.org/2000/svg" width="12" height="13" viewBox="0 0 12 13" fill={color}>
            <path
                d="M11 3.66602C11 3.11602 10.55 2.66602 10 2.66602H2C1.45 2.66602 1 3.11602 1 3.66602M11 3.66602V9.66602C11 10.216 10.55 10.666 10 10.666H2C1.45 10.666 1 10.216 1 9.66602V3.66602M11 3.66602L6 7.16602L1 3.66602"
                stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>

)

Email.defaultProps = {
    color: 'currentColor',
    download: false
}

Email.propTypes = {
    color: PropTypes.string,
    download: PropTypes.bool
}
