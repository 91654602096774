import React, { useState } from 'react'
import Resume from './resume'
import CoverLetter from './cover-letter'

function Apply () {
  const [active, setActive] = useState(0)

  const options = [
    {
      key: 'resume',
      label: 'For Resume',
      component: <Resume/>
    },
    {
      key: 'coverLetter',
      label: 'Cover Letter',
      component: <CoverLetter/>
    }
  ]
  return (
        <div>
            <div className={'flex gap-5 text-sm font-medium mb-3.5'}>
                {
                    options.map((item, index) => (
                        <p onClick={() => setActive(index)} key={item.key}
                           className={`pb-[17px] border-b-2 cursor-pointer
                            ${index === active ? 'text-primary-700 border-primary-700' : ''}`}>
                            {item.label}
                        </p>
                    ))
                }
            </div>
            <div>
                {options[active].component}
            </div>
        </div>
  )
}

export default Apply
