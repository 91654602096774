import React from 'react'
import { Document, Link, Page, Text, View } from '@react-pdf/renderer'
import PropTypes from 'prop-types'
import Header from './simple-title'
import Validate from '../../../components/validate'
import SimpleStyles from '../styles'
import { formatDate } from '../../../../../utils'
import { Github } from '../../../icons/Github'
import { Dot } from '../../../icons/Dot'
import { Colors } from '../../castform/styles'
import { LinkedinSimple } from '../../../icons/LinkedinSimple'
import { GitlabSimple } from '../../../icons/GitlabSimple'

const styles = SimpleStyles

const DownloadSimple = ({ resume, hiddenSections }) => {
    const basics = resume.userInformation?.items
    const skills = resume.skillTags?.items
    const awards = resume.achivementsAwards?.items
    const certifications = resume.certificates?.items
    const work = resume.experiences?.items
    const education = resume.educations?.items
    const projects = resume.projects?.items
    const volunteer = resume.volunteer?.items
    const references = resume.references?.items

    // eslint-disable-next-line no-unused-vars
    const basicHiddenItems = resume.userInformation?.hiddenItems
    const workHiddenItems = resume.experiences?.hiddenItems
    const educationHiddenItems = resume.educations?.hiddenItems
    const awardsHiddenItems = resume.achivementsAwards?.hiddenItems
    const certificationsHiddenItems = resume?.certificates?.hiddenItems

    const { softSkills, technicalSkills } = resume.skillTags?.hiddenItems

    const checkVisibility = (name, index) => {
        return resume.experiences.items[name].description.hiddenItems[index]
    }

    return (
        <Document style={{ backgroundColor: 'red' }}>
            <Page style={{ ...styles.page }}>
                <View style={{ textAlign: 'center' }}>
                    <Validate
                        render={basics?.name && basics?.name !== '' && basicHiddenItems.name !== undefined}>
                        <Text style={{ ...styles.headings, fontFamily: 'Times-Bold', marginBottom: 3 }}>{basics?.name}</Text>
                    </Validate>
                    <View style={{
                        ...styles.flex,
                        justifyContent: 'center',
                        alignItems: 'center',
                        marginBottom: 2
                    }}>
                        {/* <Validate
                            render={basics?.address && basics?.address !== '' && basicHiddenItems.address !== undefined}>
                            <View style={{ ...styles.flex, marginBottom: 5 }}>
                                <Location color={'#334155'} download/>
                                <Text style={{ lineHeight: 1.2, fontSize: 10 }}>
                                    {basics?.address}
                                </Text>
                            </View>
                        </Validate> */}
                        <Validate
                            render={basics?.resumeEmail && basics?.resumeEmail !== '' && basicHiddenItems.resumeEmail !== undefined}>
                            <View style={{ ...styles.flex, marginBottom: 2 }}>
                                <Link style={{ textDecoration: 'none', color: '#000000', fontSize: 10 }}
                                      src={`mailto:${basics?.resumeEmail}`}>
                                    {basics?.resumeEmail}
                                </Link>
                            </View>
                        </Validate>
                        <Validate
                            render={basics?.mobileNumber && basics?.mobileNumber !== '' && basicHiddenItems.mobileNumber !== undefined}>
                            <View style={{ ...styles.flex, marginBottom: 2 }}>
                                <Link style={{ textDecoration: 'none', color: '#000000', fontSize: 10 }}
                                      src={`tel:${basics?.mobileNumber}`}>
                                    {basics?.mobileNumber}
                                </Link>
                            </View>
                        </Validate>
                    </View>

                    {/* <Validate
                        render={basics?.resumeHeadline && basics?.resumeHeadline !== '' && basicHiddenItems.resumeHeadline !== undefined}>
                        <Text style={{ ...styles.subHeading, marginBottom: 5 }}>{basics?.resumeHeadline}</Text>
                    </Validate> */}

                    <View style={{
                        ...styles.flex,
                        justifyContent: 'center'
                    }}>
                        <Validate
                            render={basics?.linkedin && basics?.linkedin !== '' && basicHiddenItems.linkedin !== undefined}>
                            <View>
                                <Link style={{ textDecoration: 'none' }} target={'_blank'} src={basics?.linkedin}
                                      rel="noreferrer">
                                    <LinkedinSimple download color={'#000000'}/>
                                </Link>
                            </View>
                        </Validate>
                        <Validate
                            render={basics?.github && basics?.github !== '' && basicHiddenItems.github !== undefined}>
                            <View>
                                <Link style={{ textDecoration: 'none' }} target={'_blank'} src={basics?.github}
                                      rel="noreferrer">
                                    <Github download color={'#000000'}/>
                                </Link>
                            </View>
                        </Validate>
                        <Validate
                            render={basics?.gitLab && basics?.gitLab !== '' && basicHiddenItems.gitLab !== undefined}>
                            <View>
                                <Link style={{ textDecoration: 'none' }} target={'_blank'} src={basics?.gitLab}
                                      rel="noreferrer">
                                    <GitlabSimple download/>
                                </Link>
                            </View>
                        </Validate>
                    </View>
                </View>
                <View style={{ paddingBottom: 50, paddingLeft: 40, paddingRight: 40 }}>
                    <Validate
                        render={basics?.summary && basics?.summary !== '' && basicHiddenItems.summary !== undefined}>
                        <Header title={'Summary'}/>
                        <Text style={{ ...styles.body, marginBottom: 15 }}>
                            {basics?.summary}
                        </Text>
                    </Validate>
                    <Validate render={work?.length > 0 && Object.keys(workHiddenItems).length !== 0}>
                        <View>
                            <Header title={'Work Experience'}/>
                            {
                                work?.map(({
                                               jobDurationStart, jobDurationEnd,
                                               url, companyName, jobTitle, description, city
                                           }, index) => (
                                    <Validate key={index} render={workHiddenItems[index] !== undefined}>
                                        <View style={{ marginBottom: 10 }}>
                                            <View style={{ marginBottom: 5 }}>
                                                <View style={{
                                                    ...styles.flex,
                                                    justifyContent: 'space-between',
                                                    marginBottom: 3
                                                }}>
                                                    <View>
                                                        <Validate render={companyName !== ''}>
                                                            <Text style={{
                                                                ...styles.body,
                                                                color: Colors.black,
                                                                fontWeight: 700
                                                            }}>{companyName}</Text>
                                                        </Validate>
                                                        <Validate render={jobTitle !== ''}>
                                                            <Text wrap={false} style={{
                                                                ...styles.body,
                                                                flexWrap: 'nowrap',
                                                                fontWeight: 700,
                                                                fontFamily: 'Times-Bold'
                                                            }}>{jobTitle}</Text>
                                                        </Validate>
                                                    </View>
                                                    <Validate
                                                        render={city !== '' && jobDurationStart !== '' && jobDurationEnd !== ''}>
                                                        <View style={{
                                                            ...styles.body,
                                                            ...styles.itemsEnd
                                                        }}>
                                                            <Text>{city}</Text>
                                                            <Text>
                                                                {formatDate(jobDurationStart, 'MMM DD, YYYY')} - {jobDurationEnd ? formatDate(jobDurationEnd, 'MMM DD, YYYY') : 'Present'}
                                                            </Text>
                                                        </View>
                                                    </Validate>
                                                </View>
                                            </View>
                                            <Validate render={description?.items.length > 0}>
                                                {
                                                    description?.items?.map((item, index1) => (
                                                        <Validate
                                                            render={checkVisibility(index, index1) !== undefined}
                                                            key={index1}>
                                                            <View style={{
                                                                ...styles.flex,
                                                                alignItems: 'flex-start',
                                                                alignContent: 'flex-start',
                                                                justifyContent: 'flex-start',
                                                                paddingLeft: 8,
                                                                marginBottom: 2
                                                            }}>
                                                                <View style={{ marginTop: 5 }}>
                                                                    <Dot download size={4}/>
                                                                </View>
                                                                <Text style={{ ...styles.body }}>{item}</Text>
                                                            </View>
                                                        </Validate>
                                                    ))
                                                }
                                            </Validate>
                                        </View>
                                    </Validate>
                                ))
                            }
                        </View>
                    </Validate>
                    <Validate render={projects?.items?.length > 0}>
                        <View>
                            <Header title={'Projects'}/>
                            {
                                projects?.items.map(({
                                                         date,
                                                         url,
                                                         name,
                                                         summary,
                                                         description,
                                                         keywords
                                                     }, index) => (
                                    <View key={index} style={{ marginBottom: 10 }}>
                                        <View>
                                            <View style={{ ...styles.flex, justifyContent: 'space-between' }}>
                                                <Text style={styles.bodyTitle}>{name}</Text>
                                                <Text style={styles.bodySubTitle}>
                                                    {date?.start} - {date?.end}
                                                </Text>
                                            </View>
                                            <View>
                                                <Text style={styles.body}>{description}</Text>
                                                <Text style={styles.body}>{summary}</Text>
                                                <Text style={styles.body}>{url}</Text>
                                            </View>
                                        </View>
                                    </View>

                                ))
                            }
                        </View>
                    </Validate>
                    <Validate render={volunteer?.items?.length > 0}>
                        <View>
                            <Header title={'Volunteer'}/>
                            {
                                volunteer?.items.map(({ date, url, summary, position, organization }, index) => (
                                    <View key={index} style={{ marginBottom: 10 }}>
                                        <View>
                                            <View style={{ ...styles.flex, justifyContent: 'space-between' }}>
                                                <View>
                                                    <Text style={styles.bodyTitle}>{organization}</Text>
                                                    <Text style={styles.bodySubTitle}>{position}</Text>
                                                </View>
                                                <Text>{date?.start}-{date?.end}</Text>
                                            </View>
                                            <View>
                                                <Text style={styles.body}>{summary}</Text>
                                                <Text style={styles.body}>{url}</Text>
                                            </View>
                                        </View>
                                    </View>

                                ))
                            }
                        </View>
                    </Validate>
                    <Validate render={references?.items?.length > 0}>
                        <View>
                            <Header title={'References'}/>
                            {
                                references?.items.map(({ name, email, phone, summary, relationship }, index) => (
                                    <View key={index} style={{ marginBottom: 10 }}>
                                        <View>
                                            <View>
                                                <Text style={styles.bodyTitle}>{name}</Text>
                                                <Text style={styles.bodySubTitle}>{relationship}</Text>
                                                <Text style={styles.bodySubTitle}>{phone}</Text>
                                                <Text style={styles.bodySubTitle}>{email}</Text>
                                            </View>
                                            <View>
                                                <Text style={styles.body}>{summary}</Text>
                                            </View>
                                        </View>
                                    </View>
                                ))
                            }
                        </View>
                    </Validate>
                    <Validate render={education?.length > 0 && Object.keys(educationHiddenItems).length !== 0}>
                        <View>
                            <Header title={'Education'}/>
                            {
                                education?.map(({
                                                    studyDurationEnd,
                                                    studyDurationStart,
                                                    school,
                                                    degreeFieldOfStudy,
                                                    city
                                                }, index) => (
                                    <Validate key={index} render={educationHiddenItems[index] !== undefined}>
                                        <View>
                                            <View style={{
                                                ...styles.flex,
                                                justifyContent: 'space-between',
                                                ...styles.body
                                            }}>
                                                <View>
                                                    <Text>{school}</Text>
                                                    <Text style={{
                                                        ...styles.bodyTitle,
                                                        fontSize: 10,
                                                        fontWeight: 700,
                                                        fontFamily: 'Times-Bold'
                                                    }}>{degreeFieldOfStudy}</Text>
                                                </View>
                                                <View style={styles.itemsEnd}>
                                                    <Text>{city}</Text>
                                                    <Text>
                                                        {formatDate(studyDurationStart, 'YYYY')} - {formatDate(studyDurationEnd, 'YYYY')}
                                                    </Text>
                                                </View>
                                            </View>
                                        </View>
                                    </Validate>
                                ))
                            }
                        </View>
                    </Validate>
                    <Validate
                        render={certifications?.length > 0 && Object.keys(certificationsHiddenItems).length !== 0}>
                        <View>
                            <Header title={'Certifications'}/>
                            <View>
                                {
                                    certifications?.map(({ title, organisation }, index) => (
                                        <Validate key={index}
                                                  render={certificationsHiddenItems[index] !== undefined}>
                                            <View style={{ ...styles.flex, fontSize: 10, marginBottom: 10 }}>
                                                <Text>{title}</Text>
                                                <Text style={{ fontFamily: 'Times-Bold' }}>, {organisation}</Text>
                                            </View>
                                        </Validate>
                                    ))
                                }
                            </View>
                        </View>
                    </Validate>
                    <Validate render={awards?.length > 0 && Object.keys(awardsHiddenItems).length !== 0}>
                        <View>
                            <Header title={'Achievements'}/>
                            <View>
                                {
                                    awards?.map(({ details, title }, index) => (
                                        <Validate key={index} render={awardsHiddenItems[index] !== undefined}>
                                            <View style={{ marginBottom: 10 }}>
                                                <View style={{
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    flexWrap: 'wrap',
                                                    fontSize: 10
                                                }}>
                                                    <Text>{title}</Text>
                                                    <Text style={{ fontFamily: 'Times-Bold' }}>, {details}</Text>
                                                </View>
                                            </View>
                                        </Validate>
                                    ))
                                }
                            </View>
                        </View>
                    </Validate>
                    <Validate render={hiddenSections?.skillTags !== undefined}>
                        <Validate
                            render={(skills?.technicalSkills?.length > 0 || skills?.technical?.softSkills > 0) && (Object.keys(softSkills).length > 0 || Object.keys(technicalSkills).length > 0)}>
                            <View>
                                <Header title={'Skills'}/>
                                <Validate
                                    render={skills?.technicalSkills?.length > 0 && Object.keys(technicalSkills).length !== 0}>
                                    <View style={{ ...styles.flex, alignItems: 'flex-start', marginBottom: 10 }}>
                                        <Text style={{ ...styles.sectionTitle, marginTop: 2 }}>Technical:</Text>
                                        <View style={{
                                            ...styles.sectionContent,
                                            ...styles.flex,
                                            flexWrap: 'wrap',
                                            marginBottom: 0,
                                            gap: 0,
                                            lineHeight: 10,
                                            marginLeft: 5
                                        }}>
                                            {skills?.technicalSkills.map((skill, index) => (
                                                <Validate render={technicalSkills[index] !== undefined}
                                                          key={skill}>
                                                    <View style={{ ...styles.flex, marginBottom: 1, gap: 1, width: 'auto' }}>
                                                        <Dot download size={4}/>
                                                        <Text style={{
                                                            paddingLeft: 2,
                                                            paddingRight: 2,
                                                            lineHeight: 0
                                                        }}>{skill}</Text>
                                                    </View>
                                                </Validate>
                                            ))}
                                        </View>
                                    </View>
                                </Validate>
                                <Validate
                                    render={skills?.softSkills?.length > 0 && Object.keys(softSkills).length !== 0}>
                                    <View style={{ ...styles.flex, alignItems: 'flex-start', marginBottom: 10 }}>
                                        <Text style={{ ...styles.sectionTitle }}>Soft:</Text>
                                        <View style={{
                                            ...styles.sectionContent,
                                            ...styles.flex,
                                            flexWrap: 'wrap',
                                            gap: 1,
                                            marginLeft: 27
                                        }}>
                                            {skills?.softSkills.map((skill, index) => (
                                                <Validate render={softSkills[index] !== undefined} key={skill}>
                                                    <View style={{ ...styles.flex, marginBottom: 1, gap: 1, width: 'auto' }}>
                                                        <Dot download size={4}/>
                                                        <Text style={{
                                                            paddingLeft: 2,
                                                            paddingRight: 2
                                                        }}>{skill}</Text>
                                                    </View>
                                                </Validate>
                                            ))}
                                        </View>
                                    </View>
                                </Validate>
                            </View>
                        </Validate>
                    </Validate>
                </View>
            </Page>
        </Document>
    )
}

DownloadSimple.defaultProps = {
    fontSize: 10
}

DownloadSimple.propTypes = {
    resume: PropTypes.object,
    hiddenSections: PropTypes.object,
    fontSize: PropTypes.number
}

export default DownloadSimple
