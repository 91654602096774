import React from 'react'
import { useSelector } from 'react-redux'
import { Button } from 'antd'

function Subscription () {
    const { linkToUpgrade, subscriptionPackage } = useSelector(state => state.dashboardReducer.userPackage)

    return (
        <div className={'p-5 rounded-lg bg-white-base'}>
            <h3 className={'text-base text-gray-900 font-medium'}>Your Current Subscription</h3>
            {/* {JSON.stringify(subscriptionPackage)} */}
            <h3 className={'text-xl mt-[62px] mb-[18px]'}>{subscriptionPackage?.subscriptionName}</h3>
            <a className={'w-fit'} href="https://autoapply.jobs/pricing/" target={'_blank'} rel="noreferrer">
                <h3 className={'text-base text-gray-400 font-normal mb-8'}>See Package Details</h3>
            </a>
            <a className={'w-fit'} href={linkToUpgrade} target={'_blank'} rel="noreferrer">
                <Button className={'btn btn-primary-light mb-10'} size={'large'}>Invoices & Payment Details</Button>
            </a>
            <a className={'w-fit'} href="https://autoapply.jobs/pricing/" target={'_blank'} rel="noreferrer">
                <Button className={'btn btn-primary-700'} size={'large'}>Update Package</Button>
            </a>
        </div>
    )
}

export default Subscription
