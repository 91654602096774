import React, { useEffect, useState } from 'react'
import Extensions from './index'
import { connect, useSelector } from 'react-redux'
import { handleGetUserPackages } from '../../../../../../actions/dashboard/Actions'
import { Spin } from 'antd'
import PropTypes from 'prop-types'
import { FiUserCheck } from 'react-icons/fi'

function SubscribeNow ({ getPackages }) {
    const [loading, setLoading] = useState(true)
    const userId = useSelector(state => state.loginReducer.authUser.id)
    const { subscriptionPackage } = useSelector(state => state.dashboardReducer.userPackage)

    useEffect(() => {
        getPackages(userId).then(() => setLoading(false)).catch(() => setLoading(false))
    }, [])

    return (
        <Spin spinning={loading}>
            {
                subscriptionPackage?.subscriptionName === 'free'
                    ? <Extensions link={'/subscription'}
                        title={'Be a Premium Member'}
                        text={'Start AutoApplying to jobs, create custom resumes and CLs.'}
                        btnText={'Subscribe Now'}
                    />
                    : <div className={'px-3.5 py-2 rounded-lg bg-primary-600 text-white-base flex items-center gap-x-2 w-fit mx-auto'}>
                        <FiUserCheck size={20}/>
                        <span className={'text-sm font-medium'}>Premium Account</span>
                    </div>
            }

        </Spin>
    )
}

SubscribeNow.propTypes = {
    getPackages: PropTypes.func.isRequired
}

const mapDispatchToProps = (dispatch) => ({
    getPackages: (userId) => dispatch(handleGetUserPackages(userId))
})

export default connect(null, mapDispatchToProps)(SubscribeNow)
