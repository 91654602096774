export const Types = {
  UPDATE_RESUME: 'UPDATE_RESUME',
  UPDATE_RESUME_DB: 'UPDATE_RESUME_DB',
  HIDE_ITEM: 'HIDE_ITEM',
  GET_RESUME: 'GET_RESUME',
  GET_ALL_RESUMES: 'GET_ALL_RESUMES',
  SET_REFRESH_FORM: 'SET_REFRESH_FORM',
  SET_TEMPLATE: 'SET_TEMPLATE',
  SET_DRAFT: 'SET_DRAFT',
  NEW_RESUME: 'NEW_RESUME',
  SAVE_RESUME: 'SAVE_RESUME',
  UPDATE_PERSONAL_PROFILE: 'UPDATE_PERSONAL_PROFILE',
  UPDATE_RESUME_NAME: 'UPDATE_RESUME_NAME',
  DELETE_RESUME: 'DELETE_RESUME',
  TOGGLE_PHOTO: 'TOGGLE_PHOTO'
}
