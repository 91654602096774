import React from 'react'
import { Route, Routes } from 'react-router-dom'
import SignUp from '../../pages/auth/sign-up/sign-up'

export const PublicModalRoute = () => {
    return (
        <Routes>
            <Route path={'/sign-up'} element={<SignUp/>}/>
        </Routes>
    )
}
