import React from 'react'
import { ReactComponent as Question } from '../assets/images/icons/question.svg'
import { ReactComponent as Graduation } from '../assets/images/icons/graduation.svg'
import { ReactComponent as AiGenerator } from '../assets/images/icons/ai-generator.svg'
import { ReactComponent as AppliedJobs } from '../assets/images/icons/applied-jobs.svg'
import { ReactComponent as JobRoles } from '../assets/images/icons/job-roles.svg'
import { ReactComponent as Questions } from '../assets/images/icons/questions.svg'
import { ReactComponent as Sessions } from '../assets/images/icons/sessions.svg'
import { ReactComponent as File } from '../assets/images/icons/file.svg'
import { ReactComponent as File1 } from '../assets/images/icons/file-1.svg'
import { ReactComponent as Loading } from '../assets/images/icons/loading.svg'
import { ReactComponent as Briefcase } from '../assets/images/icons/briefcase.svg'
import { ReactComponent as Automation } from '../assets/images/icons/automation.svg'
import { ReactComponent as Avatar } from '../assets/images/icons/avata.svg'
import { ReactComponent as Upload } from '../assets/images/icons/upload.svg'
import { ReactComponent as Upload1 } from '../assets/images/icons/upload-1.svg'
import { ReactComponent as Resume } from '../assets/images/icons/resume.svg'
import { ReactComponent as Subscription } from '../assets/images/icons/subscription.svg'
import { ReactComponent as Congratulations } from '../assets/images/icons/congratulations.svg'
import { ReactComponent as Apply } from '../assets/images/icons/apply.svg'
import { ReactComponent as Heart } from '../assets/images/icons/heart.svg'
import PropTypes from 'prop-types'

const iconTypes = {
  question: Question,
  graduation: Graduation,
  file: File,
  loading: Loading,
  'file-1': File1,
  avatar: Avatar,
  briefcase: Briefcase,
  automation: Automation,
  resume: Resume,
  subscription: Subscription,
  'ai-generator': AiGenerator,
  upload: Upload,
  'applied-jobs': AppliedJobs,
  'job-roles': JobRoles,
  questions: Questions,
  sessions: Sessions,
  'upload-1': Upload1,
  apply: Apply,
  congratulations: Congratulations,
  heart: Heart
}

const TlaIcon = ({ name, ...props }) => {
  const Icon = iconTypes[name]
  return <Icon {...props} />
}

TlaIcon.propTypes = {
  name: PropTypes.string.isRequired
}
export default TlaIcon
