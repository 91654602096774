import React, { useEffect, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { TlaModal } from '../../../components/auto-apply/pop-ups/tla-modal'
import { Button, Form, Input, Select, Spin, Switch } from 'antd'
import PropTypes from 'prop-types'
import { handleUpdateProfile } from '../../../actions/authenticate/Actions'
import { useNavigate } from 'react-router-dom'
import { TlaError, TlaSuccess } from '../../../utils/messages'
import { handleGetCountries } from '../../../actions/questions/Action'
import Countries from '../../../utils/countries'

function Preferences ({ getCountries, updateProfile }) {
    // eslint-disable-next-line no-unused-vars
    const navigate = useNavigate()
    const [allow, setAllow] = useState(false)
    // eslint-disable-next-line no-unused-vars
    const userId = useSelector(state => state.loginReducer.authUser?.id)
    const otherQuestions = useSelector(state => state.loginReducer.authUser?.userInformation?.otherQuestions)
    const [loading, setLoading] = useState(false)
    const [submitting, setSubmitting] = useState(false)
    const [form] = Form.useForm()
    useEffect(() => {
        getCountries().then(() => {
            setLoading(false)
        }).catch((error) => {
            setLoading(false)
            TlaError(error.response.data.message)
        })
    }, [])

    let questions = otherQuestions ? JSON.parse(otherQuestions) : []
    const initialAnswers = questions.map((q) => {
        if (q.type === 'switch' && q.options.includes('yes')) {
            q.answer = q.answer === '' ? true : q.answer
        } else {
            q.answer = q.answer === '' ? null : q.answer
        }

        return q.answer
    })

    const onFinish = (values) => {
        setSubmitting(true)
        questions = questions.map((q) => {
            if (q.answer === true) {
                q.answer = 'yes'
            } else if (q.answer === false) {
                q.answer = 'no'
            }
            return q
        })

        updateProfile(userId, {
            otherQuestions: JSON.stringify(questions)
        }).then(() => {
            setSubmitting(false)
            navigate(-1)
            TlaSuccess('Answers Saved Successfully')
        }).catch((error) => {
            setSubmitting(false)
            TlaError(error.response.data.message)
        })
    }

    const onFieldsChange = (changedFields, allFields) => {
        let answer = changedFields[0].value
        const name = changedFields[0].name[0]

        if (answer === true) {
            answer = 'yes'
        } else if (answer === false) {
            answer = 'no'
        } else {
            answer = changedFields[0].value
        }
        if (name === 'newGender') {
            questions[7].answer = answer
        } else {
            questions[changedFields[0].name[0]].answer = answer
        }
    }

    const onChange = (value) => {
        if (value === 'other') {
            setAllow(true)
        } else {
            setAllow(false)
        }
    }

    return (
        <TlaModal loading={ loading } width={ 795 } title={ 'My Preferences' }>
            {
                !loading &&
                <Form colon={ false }
                      initialValues={ { ...initialAnswers } }
                      onFieldsChange={ onFieldsChange }
                      onFinish={ onFinish }
                      layout={ 'vertical' }
                      form={ form }
                      requiredMark>
                    <Spin spinning={ submitting }>
                        <div className={ 'w-fit' }>
                            {
                                questions.map(({ type, title, options }, index) => {
                                    if (type === 'text') {
                                        return (
                                            <Form.Item key={ index } name={ index }
                                                       label={ title }
                                                       rules={ [{
                                                           required: true,
                                                           message: 'Required'
                                                       }] }>
                                                <Input size={ 'large' }
                                                       placeholder={ title === 'What is your availability? (notice perod)' ? '4 Weekes' : 'Eg 150,000' }/>
                                            </Form.Item>
                                        )
                                    }
                                    if (type === 'switch') {
                                        return (
                                            <Form.Item className={ 'testing' } key={ index } name={ index }
                                                       label={ title }
                                                       rules={ [{
                                                           required: true,
                                                           message: 'Required'
                                                       }] }
                                                       valuePropName="checked">
                                                <Switch/>
                                            </Form.Item>
                                        )
                                    }

                                    if (type === 'country') {
                                        return (
                                            <Form.Item key={ index } name={ index }
                                                       rules={ [{
                                                           required: true,
                                                           message: 'Required'
                                                       }] }
                                                       label={ 'In which countries you have work rights? (where you don’t need work visa))' }>
                                                <Select size={ 'large' } showSearch mode={ 'multiple' }
                                                        placeholder="Select list of country(ies)">
                                                    {/* <Select.Option
                                                        value={'Ghana'}>
                                                        Ghana
                                                    </Select.Option> */ }
                                                    {
                                                        Countries.map(country => (
                                                            <Select.Option
                                                                key={ country }
                                                                value={ country }>
                                                                { country }
                                                            </Select.Option>
                                                        ))
                                                    }
                                                </Select>
                                            </Form.Item>
                                        )
                                    }

                                    if (type === 'dropdown') {
                                        return (
                                            <React.Fragment key={ index }>
                                                <Form.Item
                                                    name={ index } label={ title }
                                                    rules={ [{
                                                        required: true,
                                                        message: 'Required'
                                                    }] }>
                                                    <Select
                                                        placeholder={ `Select ${title === 'Gender' ? 'gender' : 'option'}` }
                                                        onChange={ title === 'Gender'
                                                            ? onChange
                                                            : () => {
                                                            } }
                                                        size={ 'large' }>
                                                        {
                                                            options.map((option) => (
                                                                <Select.Option key={ option }
                                                                               value={ option }>{ option }</Select.Option>
                                                            ))
                                                        }
                                                    </Select>
                                                </Form.Item>
                                                {
                                                    (title === 'Gender' && allow === true) &&
                                                    <Form.Item name={ 'newGender' } label={ 'Describe' }
                                                               rules={ [{
                                                                   required: true,
                                                                   message: 'Required'
                                                               }] }>
                                                        <Input size={ 'large' }/>
                                                    </Form.Item>
                                                }
                                            </React.Fragment>
                                        )
                                    }

                                    if (type === 'multi-select') {
                                        return (
                                            <Form.Item key={ index } name={ index } label={ title }
                                                       rules={ [{
                                                           required: true,
                                                           message: 'Required'
                                                       }] }>
                                                <Select size={ 'large' } showSearch
                                                        mode={ 'multiple' }
                                                        placeholder={ 'Select option(s)' }>
                                                    {
                                                        options.map((option) => (
                                                            <Select.Option
                                                                key={ option }
                                                                value={ option }>
                                                                { option }
                                                            </Select.Option>
                                                        ))
                                                    }
                                                </Select>
                                            </Form.Item>
                                        )
                                    }

                                    return null
                                })
                            }
                        </div>
                        <div className={ 'flex justify-end' }>
                            <Button className={ 'btn !px-[30px] !rounded-full bg-white-base' } htmlType={ 'submit' }
                                    size={ 'large' }>
                                Save Preferences
                            </Button>
                        </div>
                    </Spin>
                </Form>
            }
        </TlaModal>
    )
}

Preferences.propTypes = {
    getCountries: PropTypes.func.isRequired,
    updateProfile: PropTypes.func.isRequired
}

const mapDispatchToProps = (dispatch) => ({
    getCountries: () => dispatch(handleGetCountries()),
    updateProfile: (userId, data) => dispatch(handleUpdateProfile(userId, data))
})

export default connect(null, mapDispatchToProps)(Preferences)
