import React from 'react'
import { DatePicker, Form, Input } from 'antd'
import { FiCalendar } from 'react-icons/fi'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'

const SingleEducation = ({ form, education }) => {
    const startDate = form.getFieldValue(['educations', 'items', education.name, 'studyDurationStart'])
    const endDate = form.getFieldValue(['educations', 'items', education.name, 'studyDurationEnd'])

    form.setFieldValue(['educations', 'items', education.name, 'studyDurationStart'], startDate ? dayjs(startDate) : null)
    form.setFieldValue(['educations', 'items', education.name, 'studyDurationEnd'], endDate ? dayjs(endDate) : null)

    return (
        <div>
            <Form.Item
                name={[education.name, 'school']}
                rules={[
                    {
                        required: true,
                        message: 'Required'
                    }
                ]}
                label={'School'}>
                <Input disabled size={'large'} placeholder={'Eg NY University'}/>
            </Form.Item>
            <Form.Item
                name={[education.name, 'degreeFieldOfStudy']}
                rules={[
                    {
                        required: true,
                        message: 'Required'
                    }
                ]}
                label={'Degree & Field of study*'}>
                <Input disabled size={'large'} placeholder={'Eg Bachelor in Computer Science'}/>
            </Form.Item>
            <Form.Item
                rules={[
                    {
                        required: true,
                        message: 'Required'
                    }
                ]}
                name={[education.name, 'city']}
                label={'City, Country'}>
                <Input disabled size={'large'} placeholder={'Eg New York, USA'}/>
            </Form.Item>
            <div>
                <p className={'text-sm text-gray-700 mb-2'}>Study Duration</p>
                <div className={'flex flex-wrap mb-[37px] gap-x-[7px]'}>
                    <Form.Item name={[education.name, 'studyDurationStart']}>
                        <DatePicker disabled
                            className={'w-full md:w-[160px]'}
                            format={'YYYY-MM-DD'}
                            size={'large'} placeholder={'Start date'}
                            suffixIcon={<FiCalendar className={'text-gray-700'}/>}/>
                    </Form.Item>
                    <Form.Item name={[education.name, 'studyDurationEnd']}>
                        <DatePicker disabled
                            className={'w-full md:w-[160px]'}
                            format={'YYYY-MM-DD'}
                            size={'large'} placeholder={'End date'}
                            suffixIcon={<FiCalendar className={'text-gray-700'}/>}/>
                    </Form.Item>
                </div>
            </div>
        </div>
    )
}

SingleEducation.propTypes = {
    education: PropTypes.object.isRequired,
    form: PropTypes.any
}

export default SingleEducation
