import React, { useEffect, useState } from 'react'
import TlaTableWrapper from '../../../components/table/tla-table-wrapper'
import Column from 'antd/es/table/Column'
import { connect } from 'react-redux'
import { handleDeleteJobQuery, handleGetAllJobQueries } from '../../../actions/job-queries/Actions'
import PropTypes from 'prop-types'
import { Spin } from 'antd'
import TlaConfirm from '../../../components/tla-confirm'
import TlaEdit from '../../../components/tla-edit'
import { FiEdit2 } from 'react-icons/fi'
import { TlaError, TlaSuccess } from '../../../utils/messages'

function JobQueries (props) {
    const [loading, setLoading] = useState(true)
    // eslint-disable-next-line no-unused-vars
    const [deleting, setDeleting] = useState(false)
    const { getJobQueries, jobQueries, deleteJobQuery, userId } = props

    useEffect(() => {
        getJobQueries(userId).then(() => {
            setLoading(false)
        })
    }, [])

    return (
        <div className={'w-full rounded-lg'}>
            <Spin spinning={loading}>
                <TlaTableWrapper paginate={false}
                    callbackFunction={getJobQueries}
                    data={jobQueries.data} showHeader={false}
                    title={'Job Roles Created'} addText={'Create Job Role'}
                    addLink={'/job-queries/form'} numberColumn={false}
                    meta={jobQueries.meta}>
                    <Column title={'Job Title'} dataIndex={'title'}/>

                    <Column title={'Location'} dataIndex={'location'}/>

                    {/* <Column title={'Job Type'} render={(({ jobType }) => (
                        <span>{jobType.toString()}</span>
                    ))}/>

                    <Column title={'Work Type'} render={(({ remoteOnSite }) => (
                        <span>{remoteOnSite.toString()}</span>
                    ))}/> */}

                    <Column title={'Edit'} render={((record) => (
                        <div className={'flex items-center gap-x-0.5'}>
                            <div className={'p-2.5'}>
                                <TlaConfirm
                                    title={'Delete Query'}
                                    fullText={'Do you really want to delete this query?'}
                                    callBack={() => {
                                        setLoading(true)
                                        deleteJobQuery(record.id).then(() => {
                                            TlaSuccess('Deleted')
                                            setLoading(false)
                                        }).catch((err) => {
                                            TlaError(err.response?.data?.message ?? 'Error Deleting')
                                            setLoading(false)
                                        })
                                    }}/>
                            </div>
                            <div className={'p-2.5'}>
                                <TlaEdit data={record} icon={<FiEdit2 className={'icon'}/>} link={'/job-queries/form'}/>
                            </div>
                        </div>
                    ))}/>
                </TlaTableWrapper>
            </Spin>
        </div>
    )
}

JobQueries.propTypes = {
    jobQueries: PropTypes.object.isRequired,
    getJobQueries: PropTypes.func.isRequired,
    userId: PropTypes.number.isRequired,
    deleteJobQuery: PropTypes.func.isRequired
}

const mapStateToProps = (state) => ({
    jobQueries: state.jobQueryReducer.jobQueries,
    userId: state.loginReducer.authUser.id
})

const mapDispatchToProps = (dispatch) => ({
    getJobQueries: (userId, page) => dispatch(handleGetAllJobQueries(userId, page)),
    deleteJobQuery: (id) => dispatch(handleDeleteJobQuery(id))
})

export default connect(mapStateToProps, mapDispatchToProps)(JobQueries)
