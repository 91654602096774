import React from 'react'
import { Button } from 'antd'
import { useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'

function CloseModal ({ title, height }) {
  const navigate = useNavigate()
  return (
      <Button className={`${height} w-full`} size={'large'} onClick={() => navigate(-1)}>
        {title}
      </Button>
  )
}

CloseModal.defaultProps = {
  title: 'Cancel'
}

CloseModal.propTypes = {
  title: PropTypes.string,
  height: PropTypes.string
}

export default (CloseModal)
