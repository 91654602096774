import React from 'react'
import ProfilePhoto from '../../../commons/profile-photo'
import { useSelector } from 'react-redux'
import { MdStar, MdStarBorder } from 'react-icons/md'
import { FiUser } from 'react-icons/fi'
import { BiEnvelope } from 'react-icons/bi'
import SubscribeNow from '../../../dashboard/layout/sidebar/extensions/subscribe-now'
import Validate from '../../../resume-builder/components/validate'
import TlaAddNew from '../../../../components/auto-apply/pop-ups/tla-add-new'
import autoApply from '../../../../utils/routes/auto-apply'

// eslint-disable-next-line react/prop-types
const ProfileItem = ({ icon, text }) => (
    <div className={'flex items-center gap-x-2.5 py-2.5'}>
        <div className={'bg-primary-100 p-1.5 rounded-full'}>
            {icon}
        </div>
        <p className={'text-base font-normal'}>{text}</p>
    </div>
)

function Sidebar () {
    const loginInfo = useSelector(state => state.loginReducer)
    const currentApp = useSelector(state => state.appSettingsReducer.currentApp)
    const name = loginInfo?.authUserInfo?.name || loginInfo?.authUser?.userInformation?.name
    const { subscriptionPackage } = useSelector(state => state.dashboardReducer.userPackage)

    return (
        <div className={'bg-white-base w-[333px] py-[26px] px-8 rounded-l-[30px]'}>
            <div className={'relative'}>
                <div className={'mx-auto w-fit'}>
                    <ProfilePhoto/>
                </div>
                {
                    subscriptionPackage?.subscriptionName !== 'free' &&
                    <p className={'absolute flex gap-x-1 items-center right-[68px] -bottom-2 py-0.5 px-2.5 rounded-full font-normal text-[13px] text-white-base bg-purple-400 w-fit'}>
                        <MdStar/> Premium User
                    </p>
                }
            </div>
            <p className={'text-[28px] font-normal text-center mt-4 capitalize'}>{name?.toLowerCase()}</p>
            <div className={'flex flex-col gap-3.5 my-7'}>
                <TlaAddNew link={autoApply.profile}>
                    <ProfileItem icon={<FiUser className={'text-primary-600'}/>} text={'View Profile'}/>
                </TlaAddNew>
                <TlaAddNew link={autoApply.preferences}>
                    <ProfileItem icon={<MdStarBorder className={'text-primary-600'}/>} text={'Personal Preferences'}/>
                </TlaAddNew>
                  <TlaAddNew link={autoApply.subscription}>
                    <ProfileItem icon={<BiEnvelope className={'text-primary-600'}/>} text={'Subscription'}/>
                </TlaAddNew>
            </div>
            <Validate render={currentApp === 'auto-apply'}>
                <SubscribeNow/>
            </Validate>
        </div>
    )
}

export default Sidebar
