import { Types } from './Types'

/**
 * @param payload
 */
export const getCountries = (payload) => {
    return {
        type: Types.GET_COUNTRIES,
        payload
    }
}
