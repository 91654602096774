import { Font, StyleSheet } from '@react-pdf/renderer'
import RobotoRegular from '../../../../assets/fonts/roboto/Roboto-Regular.ttf'
import RobotoBold from '../../../../assets/fonts/roboto/Roboto-Bold.ttf'

Font.register({
    fontStyle: '',
    fontWeight: undefined,
    src: '',
    family: 'Roboto',
    fonts: [
        { src: RobotoRegular },
        { src: RobotoBold }
    ]
})

export const Colors = {
    bg: '#4D69A0',
    white: '#FFF',
    body: 'rgba(0, 0, 0, 0.70)',
    black: '#000000'
}
Font.registerHyphenationCallback(word => [word])

const CastFormStyles = StyleSheet.create({
    page: {
        fontFamily: 'Roboto',
        backgroundColor: '#fff'
    },
    body: {
        fontFamily: 'Roboto',
        fontSize: 9,
        color: Colors.body,
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: 'normal'
    },
    bodyTitle: {
        fontFamily: 'Roboto',
        fontSize: 9,
        fontWeight: 400
    },
    bodySubTitle: {
        fontFamily: 'Roboto',
        fontSize: 10,
        opacity: 0.5
    },
    sectionTitle: {
        fontFamily: 'Roboto',
        fontSize: 12,
        color: 'white'
    },
    sectionContent: {
        color: Colors.white,
        fontFamily: 'Roboto',
        fontSize: '10px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: 'normal',
        letterSpacing: '0.1px'
    },
    basics: {
        fontFamily: 'Roboto',
        fontSize: 10,
        color: 'white'
    },
    headings: {
        fontSize: 18,
        fontFamily: 'Roboto',
        fontWeight: 700,
        color: 'white'
    },
    subHeading: {
        fontFamily: 'Roboto',
        fontSize: 10,
        color: '#fff',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: 'normal',
        letterSpacing: '0.2px',
        textTransform: 'capitalize'
    },
    flex: {
        fontFamily: 'Roboto',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        alignContent: 'center',
        gap: 5,
        marginBottom: 10
    },
    p: {
        fontFamily: 'Roboto',
        margin: 10,
        fontSize: 12,
        pAlign: 'justify'
        // fontFamily: 'Times-Roman'
    },
    image: {
        marginVertical: 15,
        marginHorizontal: 100
    },
    header: {
        fontFamily: 'Roboto',
        fontSize: 10,
        marginBottom: 20,
        pAlign: 'center',
        color: 'grey'
    },
    pageNumber: {
        position: 'absolute',
        fontSize: 10,
        bottom: 30,
        left: 0,
        right: 0,
        pAlign: 'center',
        color: 'grey'
    }
})

export default CastFormStyles
