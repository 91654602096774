import api from '../../utils/api'
import { deleteResume, getAllResumes, getResume, saveResume, updateResumeDb } from './ActionCreator'

export const handleGetResume = () => (dispatch) => {
    return new Promise((resolve, reject) => {
        api().get('/users/resume').then((res) => {
            dispatch(getResume(res.data))
            resolve(res)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const handleGetAllResumes = (userId, pageNumber = 1, pageSize = 20) => (dispatch) => {
    return new Promise((resolve, reject) => {
        api().get(`/user-resume?userId=${userId}&page=${pageNumber}&pageSize=${pageSize}`).then((res) => {
            dispatch(getAllResumes(res.data))
            resolve(res)
        }).catch((err) => {
            reject(err)
        })
    })
}

export const handleDeleteResume = (id) => (dispatch) => {
    return new Promise((resolve, reject) => {
        api().delete(`/user-resume/${id}`).then((res) => {
            dispatch(deleteResume(id))
            resolve(res)
        }).catch((err) => {
            reject(err)
        })
    })
}

/**
 * @param userId
 * @param data
 * @returns {function(*): Promise<unknown>}
 */
export const handleSaveResume = (userId, data) => (dispatch) => {
    return new Promise((resolve, reject) => {
        return api('multipart/form-data')
            .post(`/user-resume/${userId}`, data)
            .then((response) => {
                dispatch(saveResume(response.data))
                resolve(response)
            }).catch((err) => {
                reject(err)
            })
    })
}

export const handleUpdateResume = (resumeId, data) => (dispatch) => {
    return new Promise((resolve, reject) => {
        return api()
            .put(`/user-resume/${resumeId}`, data)
            .then((response) => {
                console.log(response.data)
                dispatch(updateResumeDb(response.data))
                resolve(response)
            }).catch((err) => {
                reject(err)
            })
    })
}
