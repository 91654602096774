import React, { useEffect, useState } from 'react'
import { connect, useSelector } from 'react-redux'
import { createGlobalStyle } from 'styled-components'
import ResumeForm from './resume-form'
import { handleGetAllSkills } from '../../actions/skills/Action'
import PropTypes from 'prop-types'
import { Spin } from 'antd'
import { PDFViewer } from '@react-pdf/renderer'
import DownloadPlainWhite from './templates/plain-white/download/download-plain-white'
import DownloadCastForm from './templates/castform/download/download-cast-form'
import DownloadSimple from './templates/simple/download/download-simple'

const BodyStyle = createGlobalStyle`
    body {
        margin: 0;
        padding: 0;
        overflow: hidden;
    }
`

function BuildResume ({ getSkills }) {
    const { content, metadata } = useSelector(state => state.buildResumeReducer.currentResume)
    const currentTemplate = metadata?.template
    const { data } = useSelector(state => state.skillsReducer.skills)
    const userId = useSelector(state => state.loginReducer.authUser.id)
    const photo = useSelector(state => state.loginReducer?.authUser?.userInformation?.photo)
    const showPhoto = useSelector(state => state.buildResumeReducer?.currentResume?.metadata?.photo)

    const hiddenSections = useSelector(state => state.buildResumeReducer.hiddenItems)

    const [loading, setLoading] = useState(false)

    useEffect(() => {
        if (data.length === 0) {
            setLoading(true)
            getSkills(userId).then(() => {
                setLoading(false)
            })
        }
    }, [])

    const templates = {
        autoapply: <DownloadPlainWhite resume={content} hiddenSections={hiddenSections}/>,
        castform: <DownloadCastForm showPhoto={showPhoto} photo={photo} resume={content} hiddenSections={hiddenSections}/>,
        simple: <DownloadSimple photo={photo} resume={content} hiddenSections={hiddenSections}/>
    }

    return (
        <React.Fragment>
            <BodyStyle/>
            <Spin spinning={loading}>
                {
                    !loading &&
                    <div>
                        <div
                            className={'flex justify-center h-screen gap-x-5 w-full max-w-screen-2xl mx-auto mt-[10px]'}>
                            <div className={'w-2/4 overflow-y-auto'}>
                                <ResumeForm/>
                            </div>
                            <div className={'w-2/4 bg-white-base rounded-lg'}>
                                <div style={{ height: 'calc(100% - 110px)' }}
                                     className={'overflow-y-auto overflow-x-hidden w-full p-2 pb-10'}>
                                    <PDFViewer className={'osikani'} width={'100%'}
                                               style={{ height: 'calc(100% - 5px)' }} showToolbar={false}
                                               scale={0.2}>
                                        {templates[currentTemplate]}
                                    </PDFViewer>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </Spin>
        </React.Fragment>
    )
}

BuildResume.propTypes = {
    getSkills: PropTypes.func.isRequired
}

const mapDispatchToProps = (dispatch) => ({
    getSkills: (userId) => dispatch(handleGetAllSkills(userId))
})

export default connect(null, mapDispatchToProps)(BuildResume)
