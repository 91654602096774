import React from 'react'
import { Form, Input } from 'antd'

function SignUpContent () {
  return (
        <>
            <Form.Item name="name" label="Name*"
                       rules={[
                           {
                               required: true,
                               message: 'Name is Required'
                           }
                       ]}>
                <Input size={'large'} placeholder={'Enter your name'}/>
            </Form.Item>
            <Form.Item name="email" label="Email*"
                       rules={[
                           {
                               required: true,
                               message: 'Email is Required'
                           }
                       ]}>
                <Input size={'large'} placeholder={'Enter your email'}/>
            </Form.Item>
            <div className={'grid grid-cols-1 md:grid-cols-2 gap-3'}>
                <Form.Item name="password"
                           label={'Password*'}
                           help={'Must be at least 8 characters.'}
                           rules={[
                               { required: true, message: 'Password is required!' },
                               { min: 8, message: '' }
                           ]}>
                    <Input.Password
                        size={'large'}
                        type={'password'}
                        placeholder="Create a password"/>
                </Form.Item>
                <Form.Item
                    dependencies={['password']}
                    name="passwordConfirm"
                    label={'Confirm password*'}
                    rules={[
                        {
                            required: true,
                            message: 'Password is required!'
                        }, ({ getFieldValue }) => ({
                            validator (_, value) {
                                if (!value || getFieldValue('password') === value) {
                                    return Promise.resolve()
                                }
                                return Promise.reject(new Error('Password mismatch'))
                            }
                        }),
                        {
                            min: 8,
                            message: 'Password should be at least 8 characters'
                        }
                    ]}>
                    <Input.Password
                        size={'large'}
                        type="password"
                        placeholder="Confirm password"
                    />
                </Form.Item>
            </div>
        </>
  )
}

export default SignUpContent
