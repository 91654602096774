import { Types } from '../actions/authenticate/Types'
import { getCookie } from '../utils'

const data = getCookie('authData') ? JSON.parse(getCookie('authData')) : {}

const initialState = {
  authUser: data?.authUser ?? {},
  authUserInfo: data?.authUserInfo ?? {},
  authToken: data?.authToken ?? 'null',
  authenticated: data?.authenticated ?? false
}

/**
 *
 * @param state
 * @param action
 *
 * @returns {{authenticated: boolean, authToken: null, authUser: {}}|{authenticated: boolean, authToken: null, authUser: {}}|{authenticated: boolean, authToken, authUser: *}}
 */
export default function loginReducer (state = initialState, action) {
  switch (action.type) {
    case Types.AUTHENTICATE: {
      document.cookie = `accessToken=${action.payload.access_token}}`
      const authData = {
        authenticated: action.payload.user.verified,
        authUser: action.payload.user,
        authToken: action.payload.access_token
      }

      document.cookie = `authData=${JSON.stringify(authData)}`
      return {
        ...state,
        ...authData
      }
    }

    case Types.GET_PROFILE: {
      return {
        ...state,
        authUserInfo: action.payload.data.user,
        authUser: { ...state.authUser, userInformation: action.payload.data.user }
      }
    }

    case Types.UPDATE_PROFILE: {
      const info = action.payload.data.user
      return {
        ...state,
        authUserInfo: { ...state.authUserInfo, ...info },
        authUser: { ...state.authUser, userInformation: info }
      }
    }

    case Types.REMOVE_AUTH: {
      return {
        ...state,
        authenticated: false,
        authToken: null
      }
    }

    default:
      return state
  }
}
