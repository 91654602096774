import React, { useState } from 'react'
import { Dropdown } from 'antd'
import { FiChevronDown } from 'react-icons/fi'
import { connect, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { handleLogout } from '../../actions/authenticate/Actions'
import { LoadingOutlined } from '@ant-design/icons'
import autoApply from '../../utils/routes/auto-apply'
import TlaAddNew from '../auto-apply/pop-ups/tla-add-new'

const NavProfile = ({ logout }) => {
    const profile = useSelector(state => state.loginReducer?.authUser?.userInformation)
    const [loading, setLoading] = useState(false)
    const initiateLogout = () => {
        setLoading(true)
        logout().then(() => {
            window.location.replace('/sign-in')
            setLoading(false)
            return false
        })
    }

    const items = [
        {
            key: 'account',
            label: (
                <TlaAddNew link={autoApply.profile}>
                    Edit Profile
                </TlaAddNew>
            )
        },
        {
            key: 'settings',
            label: (
                <TlaAddNew link={autoApply.settings}>
                    Change Password
                </TlaAddNew>
            )
        },
        {
            key: 'extensions',
            label: (
                <a href={autoApply.extension} target={'_blank'} rel="noreferrer">Install Extension</a>
            )
        },
        {
            key: 'supports',
            label: (
                <a href={autoApply.support} target={'_blank'} rel="noreferrer">Customer Support</a>
            )
        },
        {
            key: 'logOut',
            label: (
                <div
                    onClick={() => initiateLogout()}
                    rel="noreferrer">
                    Logout
                </div>
            )
        }
    ]
    return (
        <Dropdown placement={'bottomRight'} menu={{ items }}>
            <a onClick={(e) => e.preventDefault()}
               className={'text-gray-700 text-base font-medium flex items-center justify-center cursor-pointer gap-x-2'}>
                {profile?.name}
                {loading ? <LoadingOutlined/> : <FiChevronDown className={'text-gray-500'}/>}
            </a>
        </Dropdown>
    )
}
NavProfile.propTypes = {
    authUser: PropTypes.object.isRequired,
    logout: PropTypes.func.isRequired
}

const mapStateToProps = (state) => {
    return {
        authUser: state.loginReducer.authUser
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        logout: () => dispatch(handleLogout())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NavProfile)
