import React from 'react'
import { TlaModal } from '../pop-ups/tla-modal'

function AutoApplyStatusMessage () {
    return (
        <TlaModal loading={ false } width={ 807 } title={ 'We cant’ turn on AutoApply' }>
            <div className={'text-gray-800 text-xl'}>
                <p className={'mb-[36px]'}>You can cannot turn on autoapply because:</p>
                <ul className={'list-disc mb-10'}>
                    <li className={'ml-6'}>You need to update your job queries if created before July 4, 2024.</li>
                    <li className={'ml-6'}>You have free version, and one month time has not elapsed yet</li>
                    <li className={'ml-6'}>Either you need to wait for 1 month, or buy our premium package.</li>
                </ul>
                <a href={'https://autoapply.jobs/pricing'} target={'_blank'} rel="noreferrer">
                <button className={'bg-primary-600 text-white-base text-base font-medium rounded-full w-full h-[46px]'}>
                    Subscribe Now
                </button>
                </a>
            </div>
        </TlaModal>
    )
}

export default AutoApplyStatusMessage
