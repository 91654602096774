import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Button, Table } from 'antd'
import TlaPagination from './tla-pagination'
import { FiPlus } from 'react-icons/fi'
import TlaAddNew from '../../pop-ups/tla-add-new'

function TlaTableWrapper ({
                             meta,
                             data,
                             callbackFunction,
                             children,
                             numberColumn,
                             numberColumnTitle,
                             hasSelection,
                             showHeader,
                             fetchId,
                             title,
                             subTitle,
                             addLink,
                             size,
                             paginate,
                             filterObj,
                             addText
                         }) {
    const [loading, setLoading] = useState(false)
    const [selectedRowKeys, setSelectedRowKeys] = useState([])

    const onSelectChange = (newSelectedRowKeys) => {
        setSelectedRowKeys(newSelectedRowKeys)
    }

    const rowSelection = {
        selectedRowKeys,
        onChange: onSelectChange
    }

    return (
        <TlaPagination
            paginate={paginate}
            showHeader={showHeader}
            meta={meta}
            loadData={(pageNumber) => {
                // filterObj.page = pageNumber
                const urlParams = new URLSearchParams(filterObj)
                // urlParams.append('page', pageNumber)
                setLoading(true);
                (callbackFunction(urlParams)).then(() => {
                    setLoading(false)
                })
            }}>
            {
                (title || subTitle || addLink) &&
                <div className={'flex justify-between items-center py-5'}>
                    <div>
                        <h3 className={'text-base font-medium text-gray-900'}>{title}</h3>
                        <p className={'text-sm text-gray-500'}>{subTitle}</p>
                    </div>
                    <div>
                        {
                            addLink &&
                            <TlaAddNew link={addLink}>
                                <Button type={'primary'}
                                        className={'btn-rounded'}>
                                    <FiPlus/>&nbsp;{addText}
                                </Button>
                            </TlaAddNew>
                        }
                    </div>
                </div>
            }
            {
                data.length > 0
                    ? <Table rowClassName={'tla-table-row'} size={size} rowSelection={hasSelection ? rowSelection : null}
                           pagination={false} loading={loading}
                           dataSource={data} scroll={{ x: 50 }} rowKey={'id'}>
                        {
                            numberColumn &&
                            <Table.Column
                                responsive={['lg']}
                                width={80}
                                title={<>&nbsp;&nbsp;&nbsp;{numberColumnTitle}</>}
                                render={(text, record, index) => {
                                    let number = index + parseInt(meta.from)
                                    return <>&nbsp;&nbsp;&nbsp;{`${number++}.`}</>
                                }}/>
                        }

                        {children}
                    </Table>
                    : <div className={'h-[200px] flex items-center justify-center'}>
                        <p className={'text-sm font-medium'}>No Data Found</p>
                    </div>
            }
        </TlaPagination>
    )
}

TlaTableWrapper.defaultProps = {
    meta: {
        from: 1,
        to: 10,
        total: 10
    },
    data: [],
    numberColumnTitle: 'S no.',
    numberColumn: true,
    hasSelection: false,
    showHeader: true,
    paginate: true,
    fetchId: null,
    filterObj: null,
    title: null,
    subTitle: null,
    addLink: null,
    size: 'small',
    addText: 'Add Job Query'
}

TlaTableWrapper.propTypes = {
    meta: PropTypes.object.isRequired,
    data: PropTypes.array.isRequired,
    callbackFunction: PropTypes.func.isRequired,
    children: PropTypes.any,
    hasSelection: PropTypes.bool,
    numberColumnTitle: PropTypes.string,
    numberColumn: PropTypes.any,
    showHeader: PropTypes.bool,
    paginate: PropTypes.bool,
    fetchId: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    filterObj: PropTypes.object,
    title: PropTypes.string,
    subTitle: PropTypes.string,
    size: PropTypes.string,
    addLink: PropTypes.string,
    addText: PropTypes.string
}

export default TlaTableWrapper
