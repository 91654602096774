import React from 'react'
import { Route, Routes } from 'react-router-dom'
import CvDifference from '../cv-difference'
import ApplyLoading from '../../pages/resume/ai-generator/apply/apply-loading'
import UploadResume from '../../pages/resume/upload-resume'
import JobQueryForm from '../../pages/auto-apply/job-queries/job-query-form'
import NoSubscription from '../no-subscription'
import QueryError from '../query-error'
import cvBuilderModalRoutes from './cv-builder-modal-routes'
import Settings from '../../pages/auto-apply/profile/settings'
import Subscription from '../../pages/auto-apply/profile/subscription'
import Profile from '../../pages/auto-apply/profile'
import Preferences from '../../pages/auto-apply/profile/preferences'
import AppLock from '../app-lock'
import AutoApplyStatusMessage from '../auto-apply/banner/autoApplyStatusMessage'

export const ModalRoute = () => {
    return (
        <Routes>
            {cvBuilderModalRoutes}
            <Route path={'/auto-apply/settings'} element={<Settings/>}/>
            <Route path={'/auto-apply/forbidden'} element={<AutoApplyStatusMessage/>}/>
            <Route path={'/auto-apply/subscription'} element={<Subscription/>}/>
            <Route path={'/auto-apply/preferences'} element={<Preferences/>}/>
            <Route path={'/auto-apply/profile'} element={<Profile/>}/>
            <Route path={'/resume/success'} element={<ApplyLoading/>}/>
            <Route path={'/resume/upload'} element={<UploadResume/>}/>
            <Route path={'/no-subscription'} element={<NoSubscription/>}/>
            <Route path={'/app-lock'} element={<AppLock/>}/>
            <Route path={'/max-job-queries'} element={<QueryError type={'pro'}/>}/>
            <Route path={'/max-starter-job-queries'} element={<QueryError type={'starter'}/>}/>
            <Route path={'/jobs-applied/:title/difference'} element={<CvDifference/>}/>
            <Route path={'/job-queries/form'} element={<JobQueryForm/>}/>
        </Routes>
    )
}
