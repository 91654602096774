import React from 'react'
import AuthLayout from './auth-layout'
import AuthBottomLink from './auth-bottom-link'

function VerificationMessage () {
  return (
        <AuthLayout pageTitle={'Verify your email'}>
            <div>
                <p className={'text-sm text-center'}>A verification link has been sent to your email</p>
                <AuthBottomLink size={'sm'} text={''} link={'/'} linkText={'Login Here'}/>
            </div>
        </AuthLayout>
  )
}
export default VerificationMessage
