import React from 'react'
import { Col, DatePicker, Form, Input, Row } from 'antd'
import { FiCalendar } from 'react-icons/fi'
import PropTypes from 'prop-types'
import dayjs from 'dayjs'
import { useSelector } from 'react-redux'
import Visibility from '../visibility'

const SingleExperience = ({ form, experience }) => {
    const startDate = form.getFieldValue(['experiences', 'items', experience.name, 'jobDurationStart'])
    const endDate = form.getFieldValue(['experiences', 'items', experience.name, 'jobDurationEnd'])

    form.setFieldValue(['experiences', 'items', experience.name, 'jobDurationStart'], startDate ? dayjs(startDate) : null)
    form.setFieldValue(['experiences', 'items', experience.name, 'jobDurationEnd'], endDate ? dayjs(endDate) : null)

    const currentResume = useSelector(state => state.buildResumeReducer.currentResume)
    const checkVisibility = (name, index) => {
        return currentResume?.content.experiences.items[name].description.hiddenItems[index]
    }

    return (
        <Row gutter={14}>
            <Col span={12} xs={24} md={12}>
                <Form.Item
                    name={[experience.name, 'jobTitle']}
                    rules={[
                        {
                            required: true,
                            message: 'Required'
                        }
                    ]}
                    label={'Job Title *'}>
                    <Input disabled size={'large'} placeholder={'Eg  UI Designer'}/>
                </Form.Item>
            </Col>
            <Col span={12} xs={24} md={12}>
                <Form.Item
                    name={[experience.name, 'companyName']}
                    rules={[
                        {
                            required: true,
                            message: 'Required'
                        }
                    ]}
                    label={'Company Name'}>
                    <Input disabled size={'large'} placeholder={'Eg dennis@infosys.com'}/>
                </Form.Item>
            </Col>
            <Col span={24} xs={24} md={24}>
                <Form.Item
                    rules={[
                        {
                            required: true,
                            message: 'Required'
                        }
                    ]}
                    name={[experience.name, 'city']}
                    label={'City, Country'}>
                    <Input disabled size={'large'} placeholder={'Eg New York, USA'}/>
                </Form.Item>
            </Col>
            <Col span={16} xs={24} md={16}>
                <div className={'flex items-center gap-x-6'}>
                    <Form.Item
                        label={'Job Duration'}
                        name={[experience.name, 'jobDurationStart']}
                        rules={[
                            {
                                required: true,
                                message: 'Required'
                            }
                        ]}>
                        <DatePicker disabled
                            size={'large'} placeholder={'Start date'} suffixIcon={<FiCalendar/>}/>
                    </Form.Item>
                    <Form.Item
                        label={<>&nbsp;</>}
                        rules={[
                            {
                                required: true,
                                message: 'Required'
                            }
                        ]}
                        name={[experience.name, 'jobDurationEnd']}>
                        <DatePicker disabled
                            size={'large'} placeholder={'End date'} suffixIcon={<FiCalendar/>}/>
                    </Form.Item>
                </div>
            </Col>
            <Col span={24}>
                <p className={'text-sm text-gray-700 mb-2'}>Job Description</p>
                <Form.List name={[experience.name, 'description', 'items']}>
                    {(fields, { add, remove }) => (
                        fields.map(({ key, name, ...restField }) => {
                            return (
                                <div key={key} className={'w-full flex gap-x-4 mb-3'}>
                                    <Form.Item noStyle className={'grow'} name={[name]} {...restField}>
                                        <Input.TextArea disabled size={'large'}/>
                                    </Form.Item>
                                    <Visibility
                                        callBack={() => checkVisibility(experience.name, name)}
                                        value={checkVisibility(experience.name, name) !== undefined}
                                        name={['experiences', 'items', experience.name, 'description', 'hiddenItems', name]}/>
                                </div>
                            )
                        })
                    )}
                </Form.List>
            </Col>
        </Row>
    )
}

SingleExperience.propTypes = {
    experience: PropTypes.object,
    form: PropTypes.any
}

export default SingleExperience
