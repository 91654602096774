import React from 'react'
import { Collapse, Form } from 'antd'
import Title from '../commons/title'
import ExpandIcon from '../commons/expand-icon'
import Visibility from '../visibility'
import EditIcon from '../commons/edit-icon'
import DeleteIcon from '../commons/delete-icon'
import PropTypes from 'prop-types'
import SingleEducation from './single-education'
import { handleDeleteEducation } from '../../../../actions/education/Action'
import { connect, useSelector } from 'react-redux'
import { TlaSuccess } from '../../../../utils/messages'

const Education = ({ form, deleteEducation }) => {
    const resume = useSelector(state => state.buildResumeReducer.currentResume)
    const hiddenItems = resume?.content?.educations?.hiddenItems
    return (
        <div className={'bg-white-base py-[13px] px-6 rounded-lg'}>
            <Form.List name={['educations', 'items']}>
                {(fields, { add, remove }) => (
                    <Collapse
                        expandIcon={({ isActive }) => ExpandIcon(isActive)}
                        className={'resume-collapse'}
                        size={'small'}
                        expandIconPosition={'end'}
                        ghost accordion
                        items={
                            fields.map((field) => (
                                {
                                    key: field.key,
                                    label: <Title
                                        title={form.getFieldValue(['educations', 'items', field.name, 'school'])}/>,
                                    children: <SingleEducation form={form} education={field}/>,
                                    extra:
                                        <div className={'flex items-end justify-end gap-x-3'}>
                                            <Visibility
                                                name={['educations', 'hiddenItems', field.name]}
                                                value={hiddenItems[field.name] !== undefined} text/>
                                            <EditIcon link={'cv-builder/education'} editData={form.getFieldValue(['educations', 'items', field.name])}/>
                                            <DeleteIcon deleteItem={() => deleteEducation(
                                                form.getFieldValue(['educations', 'items', field.name, 'id'])
                                            ).then(() => TlaSuccess())}/>
                                        </div>,
                                    collapsible: 'header'
                                }
                            ))
                        }/>
                )}
            </Form.List>
        </div>
    )
}

Education.propTypes = {
    form: PropTypes.any,
    deleteEducation: PropTypes.func
}

const mapDispatchToProps = (dispatch) => ({
    deleteEducation: (id) => dispatch(handleDeleteEducation(id))
})

export default connect(null, mapDispatchToProps)(Education)
