import React from 'react'
import Sidebar from './sidebar'
import { Outlet } from 'react-router-dom'
import Header from '../../dashboard/layout/header'
import { Affix, Spin } from 'antd'

// eslint-disable-next-line react/prop-types
function Layout ({ loading }) {
    return (
        <div className={ 'max-w-screen-2xl mx-auto' }>
            <Header/>
            <Spin spinning={ loading }>
                <div className={ 'flex mt-[18px]' }>
                    <div className={ 'grow' }>
                        <Outlet/>
                    </div>
                    <div className={ 'flex-none' }>
                        <Affix offsetTop={ 95 }>
                            <Sidebar/>
                        </Affix>
                    </div>
                </div>
            </Spin>
        </div>
    )
}

export default Layout
