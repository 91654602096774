import React from 'react'
import { Checkbox, Form } from 'antd'
import PropTypes from 'prop-types'

function ExperienceLevel ({ name }) {
    const plainOptions = ['Senior', 'Junior', 'Mid-level', 'Internship']
    return (
        <div>
            <Form.Item name={name}>
                <Checkbox.Group options={plainOptions} className={'tla-check'} />
            </Form.Item>
            <p className={'text-[13px] font-normal'}>* You can skip or select multiple</p>
        </div>
    )
}

ExperienceLevel.defaultProps = {
    name: 'experienceLevel'
}

ExperienceLevel.propTypes = {
    name: PropTypes.string
}
export default ExperienceLevel
