import { Types } from '../actions/job-queries/Types'

const initialState = {
  jobQueries: {
    data: [],
    meta: {}
  },
  jobsScraped: {
    data: [],
    meta: {}
  },
  formData: {
    remoteOnSite: [''],
    experienceLevel: [''],
    resume: null,
    location: '',
    title: '',
    jobType: ['']
  }
}

/**
 *
 * @param state
 * @param action
 *
 * @returns {{authenticated: boolean, authToken: null, authUser: {}}|{authenticated: boolean, authToken: null, authUser: {}}|{authenticated: boolean, authToken, authUser: *}}
 */
export default function jobQueryReducer (state = initialState, action) {
  switch (action.type) {
    case Types.NEW_JOB_QUERY:
      return {
        ...state,
        jobQueries: {
          ...state.jobQueries,
          data: state.jobQueries.data.concat(action.payload.data.jobQuery)
        }
      }

    case Types.SET_RESUME:
      return {
        ...state,
        formData: {
          ...state.formData,
          resume: action.payload?.resume,
          title: action.payload?.title,
          location: action.payload?.location,
          email: action.payload?.email
        }
      }
    case Types.UPDATE_JOB_QUERY:
      return {
        ...state,
        jobQueries: {
          ...state.jobQueries,
          data: state.jobQueries.data.map((query) => {
            return query.id === action.payload.data.jobQuery.id ? action.payload.data.jobQuery : query
          })
        }
      }
    case Types.GET_JOB_QUERIES: {
      return {
        ...state,
        jobQueries: {
          data: action.payload.data.modifiedJobQueries,
          meta: {
            total: action.payload.data.count,
            from: action.payload.data.page,
            current_page: parseInt(action.payload.data.page),
            to: action.payload.data.pageSize
          }
        }
      }
    }

    case Types.GET_JOBS_SCRAPED: {
      return {
        ...state,
        jobsScraped: {
          data: action.payload.data.jobs,
          meta: {
            total: action.payload.data.count,
            from: action.payload.data.page,
            current_page: parseInt(action.payload.data.page),
            to: action.payload.data.pageSize
          }
        }
      }
    }

    case Types.SUBMIT_CV: {
      return {
        ...state,
        jobQueries: { ...state.jobQueries, data: state.jobQueries.data.concat(action.payload.data) }
      }
    }

    case Types.DELETE_JOB_QUERY: {
      return {
        ...state,
        jobQueries: { ...state.jobQueries, data: state.jobQueries.data.filter((query) => query.id !== action.id) }
      }
    }

    default:
      return state
  }
}
