import React from 'react'
import { Button, Col, Form, Input, Row } from 'antd'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { useLocation } from 'react-router-dom'
import TlaModalFormWrapper from '../../components/tla-modal-form-wrapper'
import CloseModal from '../../components/close-modal'
import { handleAddCertification, handleUpdateCertification } from '../../actions/certifications/Action'

function CertificationForm ({ addCertification, updateCertification }) {
  const { state } = useLocation()

  const formValues = {
    ...state?.data
  }
  return (
        <TlaModalFormWrapper
            resourceId={state?.data?.id ?? null}
            initialValues={formValues}
            formTitle={'Add Certification'}
            onSubmit={state?.data?.id ? updateCertification : addCertification}>
            <Row gutter={14}>
                <Col span={24}>
                    <Form.Item
                        name={'title'}
                        rules={[
                          {
                            required: true,
                            message: 'Required'
                          }
                        ]}
                        label={'Title *'}>
                        <Input size={'large'} placeholder={'AWS Certified Administrator'}/>
                    </Form.Item>
                </Col>
                <Col span={24}>
                    <Form.Item
                        name={'organisation'}
                        rules={[
                          {
                            required: true,
                            message: 'Required'
                          }
                        ]}
                        label={'Certification Organization'}>
                        <Input size={'large'} placeholder={'AWS'}/>
                    </Form.Item>
                </Col>
            </Row>

            <div className={'flex gap-2'}>
                <div className={'w-full'}>
                    <CloseModal height={'!h-11'}/>
                </div>
                <Form.Item className={'w-full'}>
                    <Button className={'btn btn-primary w-full !h-11'} size={'large'} htmlType="submit">
                        Save
                    </Button>
                </Form.Item>
            </div>
        </TlaModalFormWrapper>
  )
}

CertificationForm.propTypes = {
  addCertification: PropTypes.func.isRequired,
  updateCertification: PropTypes.func.isRequired
}

/**
 *
 * @param dispatch
 */
const mapDispatchToProps = (dispatch) => ({
  addCertification: (userId, data) => dispatch(handleAddCertification(userId, data)),
  updateCertification: (certId, data) => dispatch(handleUpdateCertification(certId, data))
})

export default connect(null, mapDispatchToProps)(CertificationForm)
