export const SidebarMenus = {
    home: {
        title: 'Home',
        link: '/home',
        cvBuilderLink: '/cv-builder/home',
        children: [],
        permissions: [],
        icon: 'chart'
    },
    profile: {
        title: 'Profile',
        link: '#',
        children: [
            {
                title: 'Import Resume',
                link: '/resume/upload',
                modal: true,
                children: [],
                permissions: [],
                icon: ''
            },
            {
                title: 'Summary',
                link: '/cv-builder/summary',
                modal: false,
                children: [],
                permissions: [],
                icon: ''
            },
            {
                title: 'Personal Info',
                link: '/cv-builder/profile',
                children: [],
                permissions: [],
                icon: ''
            },
            {
                title: 'Education',
                link: '/cv-builder/education',
                children: [],
                permissions: [],
                icon: ''
            },
            {
                title: 'Experience',
                link: '/cv-builder/experience',
                children: [],
                permissions: [],
                icon: ''
            },
            {
                title: 'Certificates',
                link: '/cv-builder/certificates',
                children: [],
                permissions: [],
                icon: ''
            },
            {
                title: 'Skills',
                link: '/cv-builder/skills',
                children: [],
                permissions: [],
                icon: ''
            },
            {
                title: 'Awards & Achievement',
                link: '/cv-builder/awards-achievement',
                children: [],
                permissions: [],
                icon: ''
            },
            {
                title: 'Change Password',
                link: '/cv-builder/settings',
                children: [],
                permissions: [],
                icon: ''
            }
        ],
        permissions: [],
        icon: 'user'
    },
    resumeBuilder: {
        title: 'Resume Builder',
        link: '#',
        children: [
            {
                title: 'Resume Template',
                link: '/cv-builder/resume-template',
                children: [],
                permissions: [],
                icon: 'resume'
            },
            {
                title: 'AI Generator',
                link: '/cv-builder/ai-generator',
                children: [],
                permissions: [],
                icon: 'ai-generator'
            }
        ],
        permissions: [],
        icon: 'resume'
    },
    applyJobs: {
        title: 'Apply Jobs',
        link: '#',
        children: [
            {
                title: 'Question*',
                link: '/questions',
                children: [],
                permissions: [],
                icon: 'questions'
            },
            {
                title: 'Job Queries',
                link: '/job-queries',
                children: [],
                permissions: [],
                icon: 'job-roles'
            },
            {
                title: 'Applied Jobs',
                link: '/applied-jobs',
                children: [],
                permissions: [],
                icon: 'applied-jobs'
            },
            {
                title: 'AutoApply Sessions',
                link: '/auto-apply-sessions',
                children: [],
                permissions: [],
                icon: 'sessions'
            }
        ],
        permissions: [],
        icon: 'job'
    },
    subscription: {
        title: 'Subscription',
        link: '/subscription',
        cvBuilderLink: '/cv-builder/subscription',
        children: [],
        permissions: [],
        icon: 'subscription'
    },
    settings: {
        title: 'Settings',
        link: '/settings',
        cvBuilderLink: '/cv-builder/settings',
        children: [],
        permissions: [],
        icon: 'settings'
    }
}

export const AdminSidebarMenus = [
    {
        title: 'Users',
        link: '/users',
        children: [],
        permissions: [],
        icon: 'users'
    },
    {
        title: 'Settings',
        link: '/settings',
        children: [],
        permissions: [],
        icon: 'settings'
    }
]

export const ProfileMenus = [
    {
        title: 'Profile',
        link: '/profile',
        children: [],
        permissions: [],
        icon: 'chart'
    },
    {
        title: 'Change Password',
        link: '/change-password',
        children: [],
        permissions: [],
        icon: 'brief-case'
    }
]

export const HeaderMenus = [
    {
        title: 'Home',
        link: '/home',
        children: [],
        permissions: [],
        icon: 'home'
    },
    {
        title: 'Applied Jobs',
        link: '/applied-jobs',
        children: [],
        permissions: [],
        icon: 'applied-jobs'
    }
]
