import React from 'react'
import AuthLayout from './auth-layout'
import TlaFormWrapper from '../../components/tla-form-wrapper'
import { Form, Input } from 'antd'
import AuthBottomLink from './auth-bottom-link'
import { handleRegistration } from '../../actions/authenticate/Actions'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

function Register ({ registerUser }) {
  return (
        <AuthLayout pageTitle={'Create an account'}>
            <div>
                <TlaFormWrapper
                    afterSubmit={'/verify-email-message'}
                    buttonText={'Sign up with email'}
                    onSubmit={registerUser}>
                    <Form.Item name="name" label="Name*"
                               rules={[
                                 {
                                   required: true,
                                   message: 'Name is Required'
                                 }
                               ]}>
                        <Input size={'large'} placeholder={'Enter your name'}/>
                    </Form.Item>
                    <Form.Item name="email" label="Email*"
                               rules={[
                                 {
                                   required: true,
                                   message: 'Email is Required'
                                 }
                               ]}>
                        <Input size={'large'} placeholder={'Enter your email'}/>
                    </Form.Item>
                    <Form.Item name="password"
                               label={'Password*'}
                               help={'Must be at least 8 characters.'}
                               rules={[
                                 { required: true, message: 'Password is required!' },
                                 { min: 8, message: '' }
                               ]}>
                        <Input.Password
                            size={'large'}
                            type={'password'}
                            placeholder="Create a password"/>
                    </Form.Item>
                    <Form.Item
                        dependencies={['password']}
                        name="passwordConfirm"
                        label={'Confirm password*'}
                        rules={[
                          {
                            required: true,
                            message: 'Password is required!'
                          }, ({ getFieldValue }) => ({
                            validator (_, value) {
                              if (!value || getFieldValue('password') === value) {
                                return Promise.resolve()
                              }
                              return Promise.reject(new Error('Password mismatch'))
                            }
                          }),
                          {
                            min: 8,
                            message: 'Password should be at least 8 characters'
                          }
                        ]}>
                        <Input.Password
                            size={'large'}
                            type="password"
                            placeholder="Confirm password"
                        />
                    </Form.Item>
                </TlaFormWrapper>
                <div className={'text-center w-[350px] mx-auto mt-4'}>
                    <p className={'text-gray-500 text-sm'}>
                        By using our site, you agree to our&nbsp;
                        <a className={'text-primary-700'}
                           href={'https://autoapply.jobs/terms-conditions/'}
                           target={'_blank'}
                           rel="noreferrer">Terms</a> and&nbsp; <br/>
                        <a className={'text-primary-700'} href="https://autoapply.jobs/privacy-policy/" target={'_blank'}
                           rel="noreferrer">Privacy Policy</a>.
                    </p>
                </div>
                <AuthBottomLink size={'sm'} text={'Already have an account?'} link={'/'} linkText={'Login'}/>
            </div>
        </AuthLayout>
  )
}

Register.propTypes = {
  registerUser: PropTypes.func.isRequired
}

const mapDispatchToProps = (dispatch) => {
  return {
    registerUser: (values) => dispatch(handleRegistration(values))
  }
}

export default connect(null, mapDispatchToProps)(Register)
