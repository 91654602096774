import api from '../../utils/api'
import { addEducation, allEducations, deleteEducation, updateEducation } from './ActionCreator'

/**
 * Store a newly created resource in storage.
 * @param userId
 * @param data
 * @returns {function(*): Promise<unknown>}
 */
export const handleAddEducation = (userId, data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    api().post(`/users/education/${userId}`, data).then((res) => {
      dispatch(addEducation(res.data))
      resolve(res)
    }).catch((err) => {
      reject(err)
    })
  })
}

/**
 * Display a listing of the resource.
 * @returns {function(*): Promise<unknown>}
 */
export const handleGetAllEducations = (userId, pageNumber = 1) => (dispatch) => {
  return new Promise((resolve, reject) => {
    api().get(`/users/education?userId=${userId}&page=${pageNumber}&pageSize=20`).then((res) => {
      dispatch(allEducations(res.data))
      resolve(res)
    }).catch((err) => {
      reject(err)
    })
  })
}

/**
 * Update the specified resource in storage.
 * @param eduId
 * @param data
 * @returns {function(*): Promise<unknown>}
 */
export const handleUpdateEducation = (eduId, data) => (dispatch) => {
  return new Promise((resolve, reject) => {
    api().put(`/users/education/${eduId}`, data, {
      // headers: { 'Content-type': 'multipart/form-data' }
    }).then((res) => {
      dispatch(updateEducation(res.data))
      resolve(res)
    }).catch((err) => {
      reject(err)
    })
  })
}

/**
 * Remove the specified resource from storage.
 * @param id
 * @returns {function(*): Promise<unknown>}
 */
export const handleDeleteEducation = (id) => (dispatch) => {
  return new Promise((resolve, reject) => {
    api().delete(`/users/education/${id}`).then((res) => {
      dispatch(deleteEducation(id))
      resolve(res)
    }).catch((err) => {
      reject(err)
    })
  })
}
