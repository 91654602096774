import React from 'react'
import { Route, Routes } from 'react-router-dom'
import UsersForm from '../../pages/admin/users/users-form'

export const AdminModalRoute = () => {
  return (
        <Routes>
            <Route path={'/users/subscription'} element={<UsersForm/>}/>
       </Routes>
  )
}
