import dayjs from 'dayjs'

/**
 *
 * @param word
 * @returns {*}
 */
export const capitalize = (word) => {
    return word.replace(/(^\w{1})|(\s+\w{1})/g, letter => letter.toUpperCase())
}

/**
 *
 * @param date
 * @param format
 * @returns {null|string}
 */
export const formatDate = (date, format = 'MMM YYYY') => {
    if (date) {
        return dayjs(date).format(format)
    }

    return null
}

export const getErrors = (errors = []) => {
    if (errors && errors.length > 0) {
        return errors.map(error => error?.message).toString()
    }
}

export const allowedResumeFileTypes = ['application/pdf', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/msword']

/**
 *
 * @param date
 * @returns {dayjs.Dayjs|null}
 */
export const formDate = (date) => {
    if (date) {
        return dayjs(date)
    }
    return null
}

export function getCookie (cname) {
    const name = cname + '='
    const decodedCookie = decodeURIComponent(document.cookie)
    const ca = decodedCookie.split(';')
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i]
        while (c.charAt(0) === ' ') {
            c = c.substring(1)
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length)
        }
    }
    return ''
}

export function makeId (length) {
    let result = ''
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
    const charactersLength = characters.length
    let counter = 0

    while (counter < length) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength))
        counter += 1
    }

    return result
}

export function getResumeName (str) {
    if (str === '') {
        return 'None'
    }

    str = str.split('/')
    return str[str.length - 1]
}

export function formatResume (data) {
    const newContent = {}
    delete data.id

    Object.keys(data).map((section) => {
        let items
        let hiddenItems

        if (section === 'skillTags') {
            const { hiddenSkills, skillItems } = formatSkills(data[section])

            items = skillItems
            hiddenItems = hiddenSkills
        } else {
            if (section === 'experiences') {
                items = formatExperience(data[section])
            } else {
                items = formatResumeSection(data[section])
            }

            hiddenItems = formatHiddenItems(data[section])
        }

        newContent[section] = {
            items,
            hiddenItems
        }

        return null
    })

    return newContent
}

export const formatExperience = (items, single = false) => {
    if (single) {
        return {
            ...items,
            description: {
                items: items.description,
                hiddenItems: { ...Object.keys(items.description) }
            }
        }
    } else {
        return items.map((work) => {
            return {
                ...work,
                description: {
                    items: work.description,
                    hiddenItems: { ...Object.keys(work.description) }
                }
            }
        })
    }
}

export const formatResumeSection = (items) => {
    return items
}

export const formatHiddenItems = (items) => {
    const hidden = {}

    Object.keys(items).forEach((item) => {
        hidden[item] = item
    })

    return hidden
}

export const formatSkills = (skills) => {
    let hiddenSkills
    let skillItems

    if (skills.length > 0) {
        skillItems = {
            softSkills: skills[0].softSkills,
            technicalSkills: skills[0].technicalSkills
        }

        hiddenSkills = {
            softSkills: { ...skills[0].softSkills },
            technicalSkills: { ...skills[0].technicalSkills }
        }
    } else {
        skillItems = {
            softSkills: [],
            technicalSkills: []
        }

        hiddenSkills = {
            softSkills: { },
            technicalSkills: { }
        }
    }

    return { hiddenSkills, skillItems }
}

export function hasNullOrEmptyValues (obj) {
    for (const key in obj) {
        if (obj[key] === null || obj[key] === '' || (Array.isArray(obj[key]) && obj[key].length === 0)) {
            return true // Found null or empty value
        }
    }
    return false // No null or empty values found
}

export function prefHasNullOrEmptyValues (arr) {
    for (const value of arr) {
        if (
            value === null || // Check for null values
            value === '' || // Check for empty strings
            (typeof value === 'string' && value.length === 0) || // Check for empty strings again
            (Array.isArray(value) && value.length === 0) // Check for empty arrays
        ) {
            return true // Found null, empty string, or empty array
        }
    }
    return false // No null, empty string, or empty array values found
}
