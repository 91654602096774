import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import TlaAddNew from '../../../../../components/auto-apply/pop-ups/tla-add-new'

function Extensions ({ title, text, btnText, link, modal }) {
    return (
        <div className={'mx-auto bg-gray-50 px-4 py-5 flex flex-col align-start gap-4 rounded-lg'}>
            <div>
                <h6 className={'text-sm font-medium text-primary-500 mb-2.5'}>
                    {title}
                </h6>
                <p className={'text-sm font-normal text-gray-700'}>
                    {text}
                </p>
            </div>
            {
                modal
                    ? <TlaAddNew link={link}>
                        <span className={'h-[46px] text-white-base text-base font-medium py-2 px-3.5' +
                            ' bg-primary-600 rounded-full hover:text-white-base flex items-center justify-center'}
                              rel="noreferrer">
                            {btnText}
                        </span>
                    </TlaAddNew>
                    : <Link to={link} className={'h-[46px] text-white-base text-base font-medium py-2 px-3.5' +
                        ' bg-primary-600 rounded-full hover:text-white-base flex items-center justify-center'}
                          rel="noreferrer">
                        {btnText}
                    </Link>
            }
        </div>
    )
}

Extensions.propTypes = {
    link: '#',
    modal: false
}

Extensions.propTypes = {
    title: PropTypes.string,
    modal: PropTypes.bool,
    text: PropTypes.string,
    link: PropTypes.string,
    btnText: PropTypes.string
}

export default Extensions
