import { Path, Svg } from '@react-pdf/renderer'
import React from 'react'
import PropTypes from 'prop-types'

export const Github = ({ color, download }) => (
    download
        ? <Svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill={color}>
            <Path
                d="M4.61847 10.2919C2.05268 11.1044 2.05268 8.93769 1.02637 8.66686M8.21058 11.9169V9.82061C8.22983 9.56232 8.19677 9.30267 8.1136 9.05891C8.03043 8.81515 7.89905 8.59288 7.72821 8.40686C9.33952 8.21727 11.0329 7.57269 11.0329 4.61519C11.0328 3.85893 10.7572 3.13167 10.2632 2.58394C10.4971 1.9223 10.4806 1.19096 10.217 0.541857C10.217 0.541857 9.6115 0.352273 8.21058 1.34352C7.03442 1.00705 5.79463 1.00705 4.61847 1.34352C3.21755 0.352273 2.61203 0.541857 2.61203 0.541857C2.34845 1.19096 2.33191 1.9223 2.56584 2.58394C2.06814 3.13574 1.79227 3.86957 1.7961 4.63144C1.7961 7.56727 3.48952 8.21186 5.10084 8.42311C4.93201 8.60726 4.8018 8.82687 4.7187 9.06765C4.63559 9.30842 4.60144 9.56497 4.61847 9.82061V11.9169"
                stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
        </Svg>
        : <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" viewBox="0 0 13 13" fill={color}>
            <path
                d="M4.61847 10.2919C2.05268 11.1044 2.05268 8.93769 1.02637 8.66686M8.21058 11.9169V9.82061C8.22983 9.56232 8.19677 9.30267 8.1136 9.05891C8.03043 8.81515 7.89905 8.59288 7.72821 8.40686C9.33952 8.21727 11.0329 7.57269 11.0329 4.61519C11.0328 3.85893 10.7572 3.13167 10.2632 2.58394C10.4971 1.9223 10.4806 1.19096 10.217 0.541857C10.217 0.541857 9.6115 0.352273 8.21058 1.34352C7.03442 1.00705 5.79463 1.00705 4.61847 1.34352C3.21755 0.352273 2.61203 0.541857 2.61203 0.541857C2.34845 1.19096 2.33191 1.9223 2.56584 2.58394C2.06814 3.13574 1.79227 3.86957 1.7961 4.63144C1.7961 7.56727 3.48952 8.21186 5.10084 8.42311C4.93201 8.60726 4.8018 8.82687 4.7187 9.06765C4.63559 9.30842 4.60144 9.56497 4.61847 9.82061V11.9169"
                stroke="white" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
)

Github.defaultProps = {
    color: 'currentColor',
    download: false
}

Github.propTypes = {
    color: PropTypes.string,
    download: PropTypes.bool
}
