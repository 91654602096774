import { Types } from './Types'

/**
 *
 * @param payload
 * @returns {{payload, type: string}}
 */
export const getJobApplications = (payload) => {
  return {
    type: Types.GET_JOB_APPLICATIONS,
    payload
  }
}

/**
 *
 * @param payload
 * @returns {{payload, type: string}}
 */
export const getAppliedSessions = (payload) => {
  return {
    type: Types.GET_APPLIED_SESSIONS,
    payload
  }
}

/**
 *
 * @param payload
 * @returns {{payload, type: string}}
 */
export const getJobApplicationStats = (payload) => {
  return {
    type: Types.GET_JOB_APPLICATION_STATS,
    payload
  }
}

export const addFilter = (payload) => {
  return {
    type: Types.ADD_JOB_APPLICATION_FILTER,
    payload
  }
}

export const addAutoApplySessionFilter = (payload) => {
  return {
    type: Types.ADD_AUTO_APPLY_SESSION_FILTER,
    payload
  }
}
